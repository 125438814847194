import { useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'

function Accordion({ items }) {
  const [active, setActive] = useState(-1)

  return (
    <div className="w-full flex flex-col">
      {items.map((item, index) => (
        <div key={index} className="w-full border-b border-gray-200 last:border-b-0">
          <button
            type="button"
            onClick={() => {
              setActive(a => (a === index ? -1 : index))
            }}
            className="w-full p-4 flex gap-2 items-center">
            <div className="flex-1">
              <p className="text-start xl:text-lg hyphens-none">{item.title}</p>
            </div>
            <FiChevronDown
              className="w-6 h-6 xl:w-7 xl:h-7 text-gray-500 transition-all"
              style={{ rotate: active === index ? '180deg' : '0deg' }}
            />
          </button>
          <div className={`w-full ${active === index ? 'h-max border-t border-gray-200' : 'h-0'} overflow-hidden`}>
            <div className="p-4 hyphens-none">{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Accordion

import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import { toNumber } from 'lodash'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useCaretPosition } from 'react-use-caret-position'
import { DigitGrouping, OnlyNumbers } from '../Strings'

const TextInput = forwardRef(
  (
    {
      Element = 'input',
      inputType = 'text',
      object,
      error,
      name,
      format,
      unformat,
      squared,
      maxLength,
      placeholder,
      showCount,
      onChange,
      isVideo,
      type,
      disabled,
      onDefaultValueSet,
      decimal = false,
    },
    impRef
  ) => {
    const [defaultValue, setDefaultValue] = useState('')
    const [value, setValue] = useState(defaultValue)
    const [focusing, setFocusing] = useState(false)

    const { ref, updateCaret } = useCaretPosition()

    // useEffect(() => {
    //   if (ref.current) {
    //     ref.current.setSelectionRange(value.length, value.length);
    //   }
    // }, [value]);

    const getFormattedValue = value => {
      switch (type) {
        case 'money':
        case 'number':
          return DigitGrouping(value ? '' + value : '')
        default:
          return format ? format(value ? '' + value : '') : value
      }
    }

    const getUnformattedValue = value => {
      switch (type) {
        case 'money':
        case 'percent':
        case 'number':
          return toNumber(OnlyNumbers(value ? '' + value : '', decimal).replace(',', '.'))
        default:
          return unformat ? unformat(value ? '' + value : '') : value ?? ''
      }
    }

    useEffect(() => {
      const value = object[name]
      setDefaultValue(value)
      setValue(value)
      if (onDefaultValueSet) onDefaultValueSet(getUnformattedValue(object[name]))
    }, [object]) //eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(impRef, () => ({
      value: value,
      reset() {
        setValue(defaultValue)
        onChange(getUnformattedValue(defaultValue))
      },
      valueChanged: getUnformattedValue(value) !== getUnformattedValue(defaultValue),
      toSend() {
        // console.log('sending')
        return { [name]: type === 'percent' ? getUnformattedValue(value) / 100 : getUnformattedValue(value) }
      },
    }))

    const className = `max-xl:text-sm font-light ${
      squared
        ? `outline-none -outline-offset-2 outline-1 outline-gray-200 hover:outline-gray-400
      hover:outline-2 focus:outline-2 focus:outline-dewalt rounded-md`
        : 'outline-none border-b border-gray-200 hover:border-gray-400 hover:border-b-2 focus:border-b-2 focus:border-dewalt'
    } peer`

    const onInputChange = ev => {
      let newValue = ev.target.value
      // console.log(newValue)
      if (newValue === '' && (type === 'number' || type === 'money' || type === 'percent')) newValue = null
      else {
        if (type === 'number' || type === 'money' || type === 'percent') {
          newValue = OnlyNumbers(newValue, decimal)
        } else newValue = getUnformattedValue(newValue)
      }
      // console.log(newValue)
      setValue(newValue)
      if (value === newValue) return
      onChange(newValue)
      updateCaret()
    }

    // const onFocus = () => {
    //   if ((type === 'number' || type === 'money' || type === 'percent') && value) {
    //     setValue(value.replace(''))
    //   }
    // }
    // const onBlur = ev => {
    //   if (value !== null) {
    //     console.log(OnlyNumbers(value))
    //     const unf = getUnformattedValue(value)
    //     const f = getFormattedValue(unf)
    //     console.log(unf, f)
    //     setValue(f)
    //   }
    // }

    return (
      <div className="flex flex-col">
        <div className="relative">
          {Element === 'textarea' ? (
            <textarea
              ref={ref}
              value={value ?? ''}
              className={`w-full h-48 p-3 resize-none ${className}`}
              onChange={onInputChange}
              onFocus={() => setFocusing(true)}
              onBlur={() => setFocusing(false)}
              // onBlur={onBlur}
              {...(disabled ? { disabled: true } : {})}
            />
          ) : (
            <input
              type={inputType}
              ref={ref}
              value={!focusing ? (format ? format(value ?? '') : getFormattedValue(value ?? '')) : value ?? ''}
              className={`w-full ${
                Element === 'input' ? `h-12 px-3 ${type === 'money' ? 'pl-6' : ''}` : 'h-48 p-3 resize-none'
              } max-xl:text-sm font-light ${
                squared
                  ? `outline-none -outline-offset-2 outline-1 ${
                      error ? 'outline-red-400 hover:outline-red-600' : 'outline-gray-200 hover:outline-gray-400'
                    }
                hover:outline-2 focus:outline-2 focus:outline-dewalt rounded-md`
                  : `outline-none border-b ${
                      error ? 'border-red-400 hover:border-red-600' : 'border-gray-200 hover:border-gray-400'
                    } hover:border-b-2 focus:border-b-2 focus:border-dewalt`
              } peer disabled:bg-gray-100 disabled:text-gray-500`}
              onChange={onInputChange}
              onFocus={() => setFocusing(true)}
              onBlur={() => setFocusing(false)}
              // onBlur={onBlur}
              {...(placeholder ? { placeholder: placeholder } : {})}
              {...(maxLength ? { maxLength: maxLength } : {})}
              {...(disabled ? { disabled: true } : {})}
            />
          )}
          {type === 'money' && (
            <span
              className="absolute left-3 top-0 h-full max-xl:text-sm font-light flex items-center
                        select-none pointer-events-none border-b peer-hover:border-b-2 peer-focus:border-b-2 border-transparent">
              $
            </span>
          )}
          {type === 'percent' && (
            <span
              className="absolute right-3 top-0 h-full max-xl:text-sm font-light flex items-center
                        select-none pointer-events-none border-b peer-hover:border-b-2 peer-focus:border-b-2 border-transparent">
              %
            </span>
          )}
        </div>
        {showCount && (
          <div className="mt-2 flex justify-end">
            <span className="text-xs xl:text-sm text-neutral-400">{`${(value ?? '').length} / ${maxLength}`}</span>
          </div>
        )}
        {isVideo && value && (value.includes('www.youtube.com') || value.includes('youtu.be/')) && (
          <div className="pt-12">
            <AspectRatio.Root ratio={16 / 9}>
              <iframe
                className="w-full h-full"
                src={`https://www.youtube.com/embed/${
                  value?.includes('www.youtube.com') ? value?.split('?v=')[1] : value?.split('youtu.be/')[1]
                }`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </AspectRatio.Root>
          </div>
        )}
      </div>
    )
  }
)

export default TextInput

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { RemoveAccents } from './Strings'

const Dropdown = forwardRef(({ items, title = '', onChange, defaultValue = 0, itemsHeight = '2.5' }, ref) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedItem, setSelectedItem] = useState(
    items.find((v, i) => (defaultValue === 0 ? i === 0 : v.value === defaultValue))
  )
  const dropdown = useRef(null)
  const inputRef = useRef(null)
  const [input, setInput] = useState('')
  const itemsContainerRef = useRef(null)
  const [found, setFound] = useState(null)

  useImperativeHandle(ref, () => ({
    setItem: v => {
      const foundItem = items.find(item => item.value === v)
      setSelectedItem(foundItem)
      onChange(foundItem)
    },
  }))

  useEffect(() => {
    const closeDropdown = ev => {
      if (dropdown.current && !dropdown.current.contains(ev.target)) setShowDropdown(false)
    }
    document.addEventListener('mousedown', closeDropdown)
    return () => document.removeEventListener('mousedown', closeDropdown)
  })

  return (
    <div className="w-full h-full relative group" ref={dropdown}>
      <input
        type="text"
        ref={inputRef}
        className="w-0 h-0 overflow-hidden outline-none"
        value={input}
        onChange={ev => {
          const value = ev.target.value
          setInput(value)
          const found = items.find(item =>
            RemoveAccents(item.text.toLowerCase()).startsWith(RemoveAccents(value.toLowerCase()))
          )
          if (found) {
            const selector = `#${found.value.replace(/^\d/, '\\3$& ')}`
            const node = itemsContainerRef.current.querySelector(selector)
            if (node) {
              node.focus()
              setFound(found)
            } else setFound(null)
            inputRef.current.focus()
          }
        }}
        onKeyDown={ev => {
          if (ev.key === 'Enter') {
            // console.log('hola')
            ev.preventDefault()
            if (found) {
              onChange(found)
              setSelectedItem(found)
              setFound(null)
              setShowDropdown(false)
            }
          }
        }}
        tabIndex={showDropdown ? '' : -1}
      />
      <button
        type="button"
        title={title}
        className={`w-full h-full border ${
          showDropdown
            ? 'rounded-b-none rounded-t-md border-dewalt'
            : 'rounded-md border-gray-200 hover:border-gray-400'
        } shadow-sm flex items-center transition-all`}
        onClick={() => {
          setShowDropdown(!showDropdown)
          setInput('')
          setFound(null)
          inputRef.current.focus()
        }}
        tabIndex={!showDropdown ? '' : -1}>
        <span className="w-0 grow pl-3 text-xs xs:text-sm xl:text-base flex justify-start select-none">
          {selectedItem?.text}
        </span>
        <div className="h-full px-3 grid place-items-center">
          <FiChevronDown
            size={20}
            className={`${
              showDropdown ? 'rotate-180 text-amber-500' : 'text-gray-400 group-hover:text-gray-600'
            } transition-all duration-300`}
          />
        </div>
      </button>
      <div
        className={`absolute w-full ${
          showDropdown
            ? `h-max opacity-100 border border-t-0 border-dewalt shadow-lg drop-shadow-lg`
            : 'h-0 opacity-0 border-none pointer-events-none'
        } overflow-y-scroll rounded-b-md top-full left-0 z-[100] transition-all`}
        style={{ maxHeight: `${itemsHeight * 5}rem` }}
        tabIndex={-1}>
        <div className="w-full h-max" ref={itemsContainerRef}>
          {items.map((v, i) => (
            <button
              id={v.value}
              type="button"
              className={`relative w-full px-3 bg-white ${
                v.value === selectedItem?.value
                  ? 'text-amber-500 font-medium pointer-events-none ' +
                    'before:absolute before:w-1 before:h-full before:top-0 before:left-0 before:bg-dewalt'
                  : 'text-black hover:bg-gray-100'
              } ${
                found && found.value === v.value ? 'bg-gray-100' : ''
              } max-xl:text-sm flex items-center text-start select-none transition-colors hyphens-none`}
              style={{ height: `${itemsHeight}rem` }}
              onClick={() => {
                onChange(v)
                setSelectedItem(v)
                setFound(null)
                setShowDropdown(false)
              }}
              tabIndex={-1}
              key={i}>
              {v.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
})

export default Dropdown

import { useEffect, useState } from 'react'
import './Checkout.css'
import axios from 'axios'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  MdError,
  MdOutlineChevronRight,
  MdOutlineClose,
  MdOutlineLocationOn,
  MdOutlineReceiptLong,
} from 'react-icons/md'
import { CapitalizeAllWords, DigitGrouping, FormatCuit, FormatPrice, PickUpAddressToURL } from '../utils/Strings'
import { ShippingMethodIcon, PaymentMethodType } from '../utils/Switches'
import { useMainContext } from '../utils/MainContext'
import { DomicileForm, PersonalDataForm } from './user/UserProfile'
import ButtonWithProgress from '../utils/ButtonWithProgress'
import LoadingBox from '../utils/LoadingBox'
import ShowImage from '../utils/ShowImage'
import { delay, saveCookie, useWindowSize } from '../utils/Html'
import DefaultAlertDialog from '../utils/DefaultAlertDialog'
import { useMercadopago } from 'react-sdk-mercadopago/lib'
import { useRef } from 'react'
import { useCallback } from 'react'
import Toggle from '../utils/Toggle'
import { useAbortableEffect, useDolarBlue } from '../utils/Hooks'
import { round, clamp, toNumber } from 'lodash'
import { UTCoinsFromValue } from '../utils/Numbers'
import * as Portal from '@radix-ui/react-portal'

const provinces = JSON.parse(process.env.REACT_APP_PROVINCES)

function Checkout({ paid }) {
  const { firebase, setFirebase, showToast } = useMainContext()
  const { product_id, shippingParam, paymentParam } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [windowWidth] = useWindowSize()
  const [dolarBlue] = useDolarBlue()

  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const cart = data?.cart ?? null
  const user = data?.user ?? null
  const shippingMethods = data?.shipping_methods ?? []
  const paymentMethods = data?.payment_methods ?? []

  const [step, setStep] = useState('')
  const [shippingMethod, setShippingMethod] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [typeA, setTypeA] = useState('')

  let spendableUTCoins = 0
  if (data && dolarBlue) {
    const max = round(cart.reduce((a, c) => a + c.product.final_price * c.amount, 0) * 0.7, 2)
    const maxUTCoins = round(max / (dolarBlue.value_buy / 10), 1)
    spendableUTCoins = round(clamp(user.ut_coins, maxUTCoins), 1)
  }
  const [spendUTCoins, setSpendUTCoins] = useState(searchParams.has('sut') ? toNumber(searchParams.get('sut')) : 0)

  let calculation = []
  if (data && step && cart) {
    calculation = [
      {
        text: cart.length === 1 ? 'Producto' : `Productos`,
        value: round(
          cart.reduce((a, v) => a + v.product.normal_price * v.amount, 0),
          2
        ),
      },
      ...(cart.some(v => v.product.offer_price)
        ? [
            {
              text: 'Descuento',
              value: round(
                cart.reduce(
                  (a, v) =>
                    a - (v.product.offer_price && v.product.normal_price * v.amount - v.product.offer_price * v.amount),
                  0
                ),
                2
              ),
              color: 'green',
            },
          ]
        : []),
    ]
    if (spendUTCoins) {
      calculation.push({
        text: 'UT Coins',
        value: -1 * round((spendUTCoins * dolarBlue.value_buy) / 10, 2),
        color: 'green',
        highlight: true,
      })
    }
    if (step !== 'shipping' && shippingMethod) {
      calculation.push({
        text: shippingMethod?.type === 'home' ? 'Envío' : 'Retiro',
        value: calculation.reduce((a, v) => a + v.value, 0) > data?.free_shipping_minimum ? 0 : shippingMethod?.price,
      })
    }
    if (
      step === 'confirmation' &&
      paymentMethod &&
      (paymentMethod.title === 'MercadoPago' ||
        paymentMethod.title === 'PagoFacil' ||
        paymentMethod.title === 'RapiPago')
    ) {
      calculation.push({
        text: `${DigitGrouping(data?.mp_add_pct * 100)}% Recargo ${paymentMethod.title}`,
        value: round(calculation.reduce((a, v) => a + v.value, 0) * data?.mp_add_pct, 2),
      })
    }
    calculation.push({
      text: 'Pagás',
      value: round(
        calculation.reduce((a, v) => a + v.value, 0),
        2
      ),
    })
  }

  const [showCalculation, setShowCalculation] = useState(false)

  const [showUpdateForm, setShowUpdateForm] = useState(null)
  const [loading, setLoading] = useState(false)
  const [orderCreated, setOrderCreated] = useState(false)

  const mp = useMercadopago.v2(process.env.REACT_APP_MP_PK)
  const [preferenceId, setPreferenceId] = useState(null)
  const choContainerRef = useRef(null)
  const [paidLoading, setPaidLoading] = useState(false)
  const [creatingOrder, setCreatingOrder] = useState(false)

  if (windowWidth > 1280 && showCalculation) setShowCalculation(false)

  useEffect(() => {
    if (user && !user.cuit) {
      document.getElementById('cuit-dialog').click()
    }
  }, [user])

  useEffect(() => {
    if (error && error.code === 4) {
      document.getElementById('stock-dialog').click()
    }
  }, [error])

  const controllerRef = useRef(new AbortController())

  const createOrder = useCallback(
    async (mercado_pago, signal) => {
      if (!shippingMethod || !paymentMethod || creatingOrder) return

      setCreatingOrder(true)

      try {
        const data = {
          user_id: firebase.user.uid,
          items: cart.map(v => ({ product_id: v.product.product_id, amount: v.amount })),
          shipping_method_id: shippingMethod?.shipping_method_id,
          payment_method_id: paymentMethod?.payment_method_id,
          type_a: typeA ? typeA.cuit : '',
          cart: product_id === 'cart',
          mercado_pago: mercado_pago ?? '',
          spend_ut_coins: spendUTCoins,
          // dev: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
        }

        const result = await axios.post(process.env.REACT_APP_API_LINK + 'create_order', data, {
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
          signal: controllerRef.current.signal,
        })
        // console.log(result)

        let user = null
        if (product_id === 'cart') {
          user = JSON.parse(JSON.stringify(firebase.user))
          user.cart = []
          setFirebase({ ...firebase, user: user })
          saveCookie('cart', [])
        }

        if (spendUTCoins) {
          if (user === null) user = JSON.parse(JSON.stringify(firebase.user))
          user.ut_coins = result.data.ut_coins
          setFirebase({ ...firebase, user: user })
          saveCookie('user', user, true)
        }

        setCreatingOrder(false)

        return result
      } catch (err) {
        // console.log(err)
        throw err
      }
    },
    [cart, firebase, paymentMethod, product_id, setFirebase, shippingMethod, creatingOrder, typeA, spendUTCoins]
  )

  useEffect(() => {
    const controller = controllerRef.current
    return () => controller.abort()
  }, [])

  useEffect(() => {
    if (!firebase.user) {
      navigate('/notloggedin/buy', { replace: true })
    }
  }, [firebase.user, navigate, paymentMethod, shippingMethod, paid])

  useAbortableEffect(signal => {
    ;(async () => {
      try {
        setPaidLoading(paid)

        const [productId, amount] = product_id.split('_')
        const { data } = await axios.get(process.env.REACT_APP_API_LINK + 'get_checkout', {
          params: {
            product_id: productId === 'cart' ? '' : productId,
            amount: amount || '',
            user_id: firebase.user.uid,
          },
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
          signal,
        })
        // console.log(data)
        setData(data)
      } catch (err) {
        // console.log(err)
        if (err.response?.data?.code === 2) {
          navigate('/', { replace: true })
          showToast('El carrito está vacio.', 'bad')
        } else if (err.response?.data?.code === 4) {
          setError(err.response.data)
        }
      }
    })()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data) return

    setLoading(false)

    const shippingMethodFound = data.shipping_methods.find(sm => sm.shipping_method_id === shippingParam)
    const paymentMethodFound = data.payment_methods.find(pm => pm.payment_method_id === paymentParam)

    if (shippingParam)
      if (shippingMethodFound) setShippingMethod(shippingMethodFound)
      else navigate(`/${product_id ? `checkout/${product_id}` : ''}`, { replace: true })

    if (paymentParam)
      if (paymentMethodFound) setPaymentMethod(paymentMethodFound)
      else
        navigate(`/${product_id ? `checkout/${product_id}/${shippingParam}` : ''}`, {
          replace: true,
        })

    if (paid && shippingMethodFound && paymentMethodFound) {
      ;(async () => {
        const mercado_pago = {
          payment_id: searchParams.get('payment_id'),
          preference_id: searchParams.get('preference_id'),
          status: searchParams.get('status'),
          payment_type: searchParams.get('payment_type'),
          external_resource_url: '',
        }
        const result = await createOrder(mercado_pago)
        if (result) navigate(`/checkout/${result.data.order_id}/congrats`, { replace: true })
      })()
    } else setStep(paymentParam ? 'confirmation' : shippingParam ? 'payment' : 'shipping')
  }, [data, searchParams, createOrder, navigate, paid, paymentParam, product_id, shippingParam])

  //#region MercadoPago
  const [gettingPreference, setGettingPreference] = useState(false)

  useEffect(() => {
    if (step === 'confirmation' && paymentMethod?.title === 'MercadoPago' && !preferenceId && !gettingPreference) {
      // // console.log('fetching preference id')
      setGettingPreference(true)
      ;(async () => {
        try {
          // ITEMS & UT COINS
          const items = [
            ...cart.map(({ amount, product }) => ({
              title: product.title,
              id: product.product_id,
              quantity: amount,
              unit_price: product.final_price,
              picture_url: product.images[0],
              currency_id: 'ARS',
            })),
            ...(spendUTCoins
              ? [
                  {
                    title: 'UT Coins',
                    id: 999999,
                    quantity: 1,
                    unit_price: (-1 * spendUTCoins * dolarBlue?.value_buy) / 10,
                    picture_url: '',
                    currency_id: 'ARS',
                  },
                ]
              : []),
          ]
          // ENVIO
          items.push({
            id: 'shipping',
            title: shippingMethod?.type === 'home' ? 'Envío' : 'Retiro',
            quantity: 1,
            unit_price:
              items.reduce((acc, { unit_price, quantity }) => acc + unit_price * quantity, 0) >
              data.free_shipping_minimum
                ? 0
                : shippingMethod?.price,
          })
          // RECARGO MP
          items.push({
            title: 'Recargo MP',
            id: 888888,
            quantity: 1,
            unit_price: round(
              round(
                items.reduce((a, { unit_price, quantity }) => a + unit_price * quantity, 0),
                2
              ) * data.mp_add_pct,
              2
            ),
            picture_url: '',
            currency_id: 'ARS',
          })

          const result = await axios.post(
            'https://api.mercadopago.com/checkout/preferences',
            {
              items,
              statement_descriptor: 'UNIVERSALTOOLSAR',
              back_urls: {
                success: `${window.location.origin}${window.location.pathname}/paid${
                  spendUTCoins ? `?sut=${spendUTCoins}` : ''
                }`,
              },
              auto_return: 'approved',
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_MP_AT}`,
              },
            }
          )
          // console.log(result)

          setGettingPreference(false)
          setPreferenceId(result.data.id)
        } catch (err) {
          // console.log(err)
          setError({ code: 999, message: 'Error al crear pago por MercadoPago.' })
        }
      })()
    } else if (step !== 'confirmation' || paymentMethod?.title !== 'MercadoPago') {
      setPreferenceId(null)
    }
  }, [
    step,
    paymentMethod,
    shippingMethod,
    preferenceId,
    gettingPreference,
    cart,
    data?.free_shipping_minimum,
    data?.mp_add_pct,
    spendUTCoins,
    dolarBlue?.value_buy,
  ])

  const cleanMPButton = () => {
    if (choContainerRef.current) {
      ;[...choContainerRef.current.children].forEach(child => {
        choContainerRef.current.removeChild(child)
      })
    }
  }

  useEffect(() => {
    if (mp && preferenceId) {
      cleanMPButton()

      mp.checkout({
        preference: {
          id: preferenceId,
        },
        render: {
          container: '.cho-container',
          label: 'Pagar y confirmar pedido',
        },
      })
    }
  }, [mp, preferenceId, spendUTCoins, windowWidth])
  //#endregion MercadoPago

  const handleSubmit = async () => {
    if (step === 'shipping' && shippingMethod) {
      setLoading(true)
      await delay(1000)
      navigate(`/checkout/${product_id}/${shippingMethod?.shipping_method_id}`)
    } else if (step === 'payment' && paymentMethod) {
      setLoading(true)
      await delay(1000)
      navigate(`/checkout/${product_id}/${shippingMethod?.shipping_method_id}/${paymentMethod?.payment_method_id}`)
    } else if (step === 'confirmation') {
      setCreatingOrder(true)
      await delay(1000)
      try {
        const result = await createOrder(undefined)

        setCreatingOrder(false)
        setOrderCreated(true)

        await delay(2000)
        navigate(`/checkout/${result.data.order_id}/congrats`)
      } catch (err) {
        setLoading(false)
        setCreatingOrder(false)
        setOrderCreated(false)
        // console.log(err)
        // TODO: Mandar error a DB
      }
    }
  }

  return (
    <section className="relative w-full flex grow items-stretch bg-pagebg text-black">
      {user && !user.cuit && (
        <DefaultAlertDialog
          title={'Completá tus datos'}
          content={
            <div className="flex flex-col">
              <span className="mb-4">
                Antes de seguir con la compra, necesitamos que completes los siguientes datos:
              </span>
              <PersonalDataForm
                user={user}
                uid={firebase.user?.uid}
                preEditable={true}
                onCancel={() => setShowUpdateForm(null)}
                cancelable={false}
              />
            </div>
          }
          alwaysVisible={true}
          trigger={<div id="cuit-dialog" className="invisible" />}
        />
      )}
      {showCalculation && (
        <div
          className="absolute w-full h-full bg-black bg-opacity-40 top-0 left-0 z-[666]"
          onClick={() => {
            setShowUpdateForm(null)
            setShowCalculation(false)
          }}
        />
      )}
      {/* {showUpdateForm && (
        <>
          <div className="absolute w-full h-full bg-black/40 p-2 xl:p-4 top-0 left-0 z-[1000] pointer-events-none">
            <div className="w-full xl:w-[600px] mx-auto max-h-full p-2 xl:p-4 overflow-y-scroll bg-white rounded-md border border-gray-200 pointer-events-auto">
              {showUpdateForm === 'domicile' ? (
                <DomicileForm
                  user={user}
                  provinces={provinces}
                  uid={firebase.user?.uid}
                  preEditable={true}
                  onCancel={() => setShowUpdateForm(null)}
                />
              ) : (
                <PersonalDataForm
                  user={user}
                  uid={firebase.user?.uid}
                  preEditable={true}
                  onCancel={() => setShowUpdateForm(null)}
                />
              )}
            </div>
          </div>
        </>
      )} */}
      <DefaultAlertDialog
        title="Actualizar domicilio"
        content={
          <DomicileForm
            user={user}
            provinces={provinces}
            uid={firebase.user?.uid}
            preEditable={true}
            onCancel={() => setShowUpdateForm(null)}
          />
        }
        trigger={<div id="domicile-form-dialog" hidden />}
      />
      <DefaultAlertDialog
        content={
          <PersonalDataForm
            user={user}
            uid={firebase.user?.uid}
            preEditable={true}
            onCancel={() => setShowUpdateForm(null)}
          />
        }
        trigger={<div id="personal-data-form-dialog" hidden />}
      />
      {!typeA && <TypeADialog cuit={user?.cuit} setTypeA={setTypeA} />}
      {error && error.code === 4 && <StockDialog items={error.unavailable_products} />}
      <div className="w-full bg-pagebg max-xl:pb-48 max-xl:overflow-y-scroll">
        <div className="relative w-full xl:h-full xl:w-content-max-width xl:mx-auto flex max-xl:flex-col">
          {data && !paidLoading ? (
            <>
              <div className="w-full h-max xl:w-0 xl:grow-[1.4]">
                <div className="p-4 xl:py-10 xl:pr-6">
                  <h1 className="mb-6 text-xl xl:text-2xl font-medium">
                    {step === 'shipping'
                      ? '¿Querés recibir o pasas a retirar?'
                      : step === 'payment'
                      ? '¿Cómo pagás?'
                      : step === 'confirmation'
                      ? 'Revisá y confirmá tu pedido'
                      : ''}
                  </h1>
                  {step === 'shipping' ? (
                    <>
                      <div className="mb-8 xl:mb-10">
                        <p className="mb-2 max-xl:hidden">Domicilio</p>
                        <DomicileBox user={user} provinces={provinces} setShowUpdateForm={setShowUpdateForm} />
                      </div>
                      <div className="mb-2 xl:mb-6">
                        <p className="mb-2 max-xl:hidden">Recibir en domicilio</p>
                        <div className="flex flex-col gap-2">
                          {user.street ? (
                            shippingMethods
                              .filter(v => v.type === 'home')
                              .map((v, i) => (
                                <Option
                                  info={{
                                    text: v.title,
                                    subText: v.sub_title,
                                    price: v.price,
                                    onClick: () => {
                                      setShippingMethod(v)
                                    },
                                    link: `/checkout/${product_id}/${v.shipping_method_id}`,
                                    selected: shippingMethod?.shipping_method_id === v.shipping_method_id,
                                  }}
                                  isFree={calculation.at(-1)?.value > data.free_shipping_minimum}
                                  key={i}
                                />
                              ))
                          ) : (
                            <Option
                              info={{
                                innerHtml: (
                                  <>
                                    Para poder recibir la compra,{' '}
                                    <button
                                      type="button"
                                      className="inline text-amber-600 font-medium"
                                      onClick={() => setShowUpdateForm('domicile')}>
                                      completá tu domicilio
                                    </button>
                                  </>
                                ),
                              }}
                              disabled={true}
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        <p className="mb-2 max-xl:hidden">Recibir en punto de retiro</p>
                        <div className="flex flex-col gap-2">
                          {shippingMethods
                            .filter(v => v.type === 'pickup')
                            .map((v, i) => (
                              <Option
                                info={{
                                  text: v.title,
                                  subText: v.sub_title,
                                  price: v.price,
                                  updatable_price: v.updatable_price,
                                  onClick: () => {
                                    setShippingMethod(v)
                                  },
                                  link: `/checkout/${product_id}/${v.shipping_method_id}`,
                                  selected: shippingMethod?.shipping_method_id === v.shipping_method_id,
                                }}
                                isFree={calculation.at(-1)?.value > data.free_shipping_minimum}
                                key={i}
                              />
                            ))}
                        </div>
                      </div>
                    </>
                  ) : step === 'payment' ? (
                    [
                      {
                        title: 'Con débito o crédito',
                        content: paymentMethods
                          .filter(
                            v => v.type === 'cards' && v.available_in.includes(shippingMethod?.shipping_method_id)
                          )
                          .map((v, i) => (
                            <Option
                              info={{
                                icon: v.image,
                                text: v.title,
                                subText: !v.available ? 'No disponible' : '',
                                onClick: () => {
                                  setPaymentMethod(v)
                                },
                                link: `/checkout/${product_id}/${shippingParam}/${v.payment_method_id}`,
                                selected: paymentMethod?.payment_method_id === v.payment_method_id,
                              }}
                              disabled={!v.available}
                              key={i}
                            />
                          )),
                      },
                      {
                        title: 'Con efectivo',
                        content: paymentMethods
                          .filter(v => v.type === 'cash' && v.available_in.includes(shippingMethod?.shipping_method_id))
                          .map((v, i) => (
                            <Option
                              info={{
                                icon: v.image,
                                text: v.title,
                                subText: !v.available ? 'No disponible' : '',
                                ...(v.title === 'PagoFacil' || v.title === 'RapiPago'
                                  ? { subText: 'El pago tarda de 1 a 3 días hábiles en acreditarse' }
                                  : {}),
                                onClick: () => {
                                  setPaymentMethod(v)
                                },
                                link: `/checkout/${product_id}/${shippingParam}/${v.payment_method_id}`,
                                selected: paymentMethod?.payment_method_id === v.payment_method_id,
                              }}
                              disabled={!v.available}
                              key={i}
                            />
                          )),
                      },
                      {
                        title: 'Con otros métodos de pago',
                        content: paymentMethods
                          .filter(
                            v => v.type === 'other' && v.available_in.includes(shippingMethod?.shipping_method_id)
                          )
                          .map((v, i) => (
                            <Option
                              info={{
                                icon: v.image,
                                text: v.title,
                                subText: !v.available ? 'No disponible' : '',
                                onClick: () => {
                                  setPaymentMethod(v)
                                },
                                link: `/checkout/${product_id}/${shippingParam}/${v.payment_method_id}`,
                                selected: paymentMethod?.payment_method_id === v.payment_method_id,
                              }}
                              disabled={!v.available}
                              key={i}
                            />
                          )),
                      },
                    ].map((v, i) => (
                      <div key={i} className="mb-6">
                        <p className="max-xl:text-sm max-xl:font-medium mb-2">{v.title}</p>
                        <div className="flex flex-col gap-2">{v.content}</div>
                      </div>
                    ))
                  ) : (
                    step === 'confirmation' &&
                    shippingMethod &&
                    paymentMethod &&
                    [
                      {
                        title: 'Facturación',
                        content: (
                          <InfoBox
                            info={{
                              title: 'Se facturará a nombre de...',
                              icon: <MdOutlineReceiptLong className="w-full h-full" />,
                              content: (
                                <>
                                  <span className="text-sm text-gray-500">
                                    {typeA
                                      ? `${CapitalizeAllWords(typeA.name)}, ${FormatCuit(typeA.cuit)}`
                                      : `${user.name} ${user.last_name}, ${user.cuit}`}
                                  </span>
                                  <span className="text-sm text-gray-500">
                                    {typeA ? 'Factura Tipo A' : 'Consumidor Final'}
                                  </span>
                                </>
                              ),
                              actions: [
                                ...(typeA
                                  ? [
                                      {
                                        text: 'Pedir Factura B',
                                        onClick: () => {
                                          setTypeA('')
                                          showToast('Factura cambiada a Consumidor Final', 'good')
                                        },
                                      },
                                    ]
                                  : [
                                      {
                                        text: 'Pedir Factura A',
                                        onClick: () => {
                                          document.getElementById('typea-dialog').click()
                                        },
                                      },
                                    ]),
                              ],
                            }}
                          />
                        ),
                      },
                      {
                        title: 'Detalles de la entrega',
                        content: (
                          <>
                            <DomicileBox user={user} provinces={provinces} setShowUpdateForm={setShowUpdateForm} />
                            <InfoBox
                              info={{
                                title: 'Método de entrega',
                                icon: ShippingMethodIcon(shippingMethod?.icon, 'w-full h-full'),
                                content: (
                                  <>
                                    <span className="text-sm">{shippingMethod?.title}</span>
                                    <span className="text-gray-500">{shippingMethod?.sub_title}</span>
                                  </>
                                ),
                                actions: [
                                  {
                                    text: 'Cambiar método de entrega',
                                    link: `/checkout/${product_id}`,
                                  },
                                  ...(shippingMethod?.title === 'Retiro en persona'
                                    ? [
                                        {
                                          text: '¿Dónde retiro?',
                                          onClick: () => document.getElementById('pickup-dialog')?.click(),
                                        },
                                      ]
                                    : []),
                                ],
                              }}
                            />
                            {shippingMethod?.title === 'Retiro en persona' && (
                              <DefaultAlertDialog
                                title={'¿Dónde retiro?'}
                                content={
                                  <div className="w-full">
                                    <p className="mb-1 font-light hyphens-none">
                                      Encontranos en{' '}
                                      <span className="font-medium">
                                        {PickUpAddressToURL(data.pick_up_address).replaceAll('+', ' ')}
                                      </span>
                                    </p>
                                    <p className="mb-2 font-light hyphens-none">
                                      Horario de atención:{' '}
                                      <span className="font-medium">Lunes a Viernes de 9:00 hs a 16:00 hs</span>
                                    </p>
                                    <iframe
                                      title="Ubicación para retirar"
                                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBAPfcne-dRGxByZ-IxT8HSPeJqg6zxu7M&q=${PickUpAddressToURL(
                                        data.pick_up_address
                                      )}`}
                                      className="w-full h-[350px] border-0 focus:outline-0"
                                      loading="lazy"
                                      allowFullScreen
                                      referrerPolicy="no-referrer-when-downgrade"
                                    />
                                  </div>
                                }
                                trigger={<div id="pickup-dialog" className="hidden" />}
                                cancel={{ text: 'Cerrar' }}
                              />
                            )}
                          </>
                        ),
                      },
                      {
                        title: 'Detalles del pago',
                        content: (
                          <>
                            <InfoBox
                              info={{
                                title: 'Método de pago',
                                image: (
                                  <ShowImage
                                    image={paymentMethod?.image}
                                    alt={`Imágen de ${paymentMethod?.title}`}
                                    draggable={false}
                                    imgClassName={'select-none'}
                                  />
                                ),
                                content: (
                                  <>
                                    <span className="text-sm">{paymentMethod?.title}</span>
                                    <span className="text-gray-500">{PaymentMethodType(paymentMethod?.type)}</span>
                                    {(paymentMethod?.title === 'RapiPago' || paymentMethod?.title === 'PagoFacil') && (
                                      <span className="text-gray-500">
                                        El pago tarda de 1 a 3 días hábiles en acreditarse
                                      </span>
                                    )}
                                  </>
                                ),
                                actions: [
                                  {
                                    text: 'Cambiar método de pago',
                                    link: `/checkout/${product_id}/${shippingMethod?.shipping_method_id}`,
                                  },
                                ],
                              }}
                            />
                          </>
                        ),
                      },
                    ].map((v, i) => (
                      <div key={i} className="mb-6">
                        <p className="max-xl:text-sm max-xl:font-medium mb-2">{v.title}</p>
                        <div className="flex flex-col gap-2">{v.content}</div>
                      </div>
                    ))
                  )}
                  {step !== 'confirmation' && (
                    <div className="w-36 h-12 mt-10 max-xl:hidden">
                      <ButtonWithProgress
                        text="Continuar"
                        onClick={handleSubmit}
                        clickable={(step === 'shipping' && shippingMethod) || (step === 'payment' && paymentMethod)}
                        loading={loading}
                        done={false}
                        fullWidth={true}
                      />
                    </div>
                  )}
                </div>
              </div>
              {windowWidth >= 1280 && (
                <div className="w-0 grow min-h-max bg-neutral-50">
                  {cart && (
                    <CartInfo
                      cart={cart}
                      calculation={calculation}
                      toggle={
                        spendableUTCoins > 0 && (
                          <div className="w-full mt-6 flex gap-2 items-center">
                            <Toggle
                              clickable={user?.ut_coins > 0}
                              toggled={!!spendUTCoins}
                              onClick={() => {
                                cleanMPButton()
                                setSpendUTCoins(spendUTCoins ? 0 : spendableUTCoins)
                                setPreferenceId(null)
                              }}
                              size="lg"
                            />
                            <span>Usar {DigitGrouping(spendableUTCoins)} UT Coins en esta compra</span>
                          </div>
                        )
                      }
                      UTCoinsToGain={
                        calculation
                          ? UTCoinsFromValue(calculation.at(-1)?.value, dolarBlue, data.ut_coins_multiplier)
                          : 0
                      }
                      button={
                        step === 'confirmation' && (
                          <div className="w-full h-12 mt-4">
                            {paymentMethod?.title === 'MercadoPago' ? (
                              <div ref={choContainerRef} className="cho-container" />
                            ) : (
                              <ButtonWithProgress
                                text="Confirmar pedido"
                                onClick={handleSubmit}
                                clickable={step === 'confirmation' && shippingMethod && paymentMethod}
                                loading={creatingOrder}
                                done={orderCreated}
                                fullWidth={true}
                              />
                            )}
                          </div>
                        )
                      }
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <LoadingBox text={'Cargando compra...'} />
          )}
        </div>
      </div>
      {data && !paidLoading && windowWidth < 1280 && (
        <CalculationMobile
          cartInfo={
            cart && (
              <CartInfo
                cart={cart}
                calculation={calculation}
                toggle={
                  spendableUTCoins > 0 && (
                    <div className="w-full mt-4 flex gap-2 items-center">
                      <Toggle
                        clickable={user?.ut_coins > 0}
                        toggled={!!spendUTCoins}
                        onClick={() => {
                          setSpendUTCoins(spendUTCoins ? 0 : spendableUTCoins)
                          setPreferenceId(null)
                        }}
                        size="lg"
                      />
                      <span className="w-0 grow">Usar {DigitGrouping(spendableUTCoins)} UT Coins en esta compra</span>
                    </div>
                  )
                }
                UTCoinsToGain={
                  calculation ? UTCoinsFromValue(calculation.at(-1)?.value, dolarBlue, data.ut_coins_multiplier) : 0
                }
                button={
                  step === 'confirmation' && (
                    <div className="w-full h-12 mt-4">
                      {paymentMethod?.title === 'MercadoPago' ? (
                        <div ref={choContainerRef} className="cho-container" />
                      ) : (
                        <ButtonWithProgress
                          text="Confirmar pedido"
                          onClick={handleSubmit}
                          clickable={step === 'confirmation' && shippingMethod && paymentMethod}
                          loading={creatingOrder}
                          done={orderCreated}
                          fullWidth={true}
                        />
                      )}
                    </div>
                  )
                }
              />
            )
          }
          state={{ open: showCalculation, setOpen: setShowCalculation }}
          buttonShowing={step === 'confirmation'}
          hasToggle={spendableUTCoins > 0}
        />
      )}
    </section>
  )
}

function StockDialog({ items }) {
  const { firebase, setFirebase, showToast } = useMainContext()
  const [loading, setLoading] = useState(false)

  const onCartUpdated = updatedCart => {
    firebase.user.cart = updatedCart
    setFirebase({ ...firebase, user: firebase.user })
    saveCookie('cart', updatedCart)
  }

  return (
    <DefaultAlertDialog
      title={'Error en el carrito'}
      content={
        <div className="flex flex-col">
          <span>Parece que el stock de algunos productos cambió:</span>
          <div className="w-full max-h-60 flex flex-col">
            {items
              .filter(item => item.amount > item.product.stock + item.product.offer_cost_stock)
              .map(item => (
                <div
                  className="w-full py-2 flex gap-2 border-b border-gray-200 last:border-b-0"
                  key={item.product.product_id}>
                  <div className="w-20 h-20 p-1 xl:w-24 xl:h-24 rounded-md border border-gray-200 overflow-hidden">
                    <ShowImage image={item.product.images[0]} />
                  </div>
                  <div className="w-0 grow flex flex-col">
                    <span className="text-xs xl:text-sm line-clamp-1">{item.product.title}</span>
                    <span className="font-medium">{FormatPrice(item.product.final_price)}</span>
                    <div className="mt-1 xl:mt-2 flex gap-2">
                      <span>
                        En carrito: <strong>{item.amount}</strong>
                      </span>
                      <span>{'→'}</span>
                      <span>
                        En stock: <strong>{item.product.stock + item.product.offer_cost_stock}</strong>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <span>Tocá en "Actualizar carrito" para arreglar los errores y seguir con la compra.</span>
        </div>
      }
      submit={{
        text: 'Actualizar carrito',
        onSubmit: async ev => {
          ev.preventDefault()
          try {
            setLoading(true)

            let newCart = null

            for (let item of items) {
              const product = item.product
              const total_stock = product.stock + product.offer_cost_stock

              if (total_stock === 0) {
                // Si el stock actual del producto es 0, borro el item del carrito
                const result = await axios.put(
                  process.env.REACT_APP_API_LINK + 'update_cart',
                  { user_id: firebase?.user?.uid, product_id: product.product_id, amount: '', action: 'remove' },
                  { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
                )
                // console.log(result)
                newCart = result.data.cart
              } else if (item.amount > total_stock) {
                // Si el stock actual del producto es menor a lo que esta en el carrito, resto esa diferencia
                const result = await axios.put(
                  process.env.REACT_APP_API_LINK + 'update_cart',
                  {
                    user_id: firebase?.user?.uid,
                    product_id: product.product_id,
                    amount: item.amount - total_stock,
                    action: 'subtract',
                  },
                  { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
                )
                // console.log(result)
                newCart = result.data.cart
              }
            }

            if (newCart !== null) {
              onCartUpdated(newCart)
            }

            setLoading(false)
            window.location.reload(false)
          } catch (err) {
            // console.log(err)
            setLoading(false)
            showToast(err.response?.data?.message ?? process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
          }
        },
      }}
      loading={loading}
      alwaysVisible={true}
      trigger={<div id="stock-dialog" className="invisible" />}
    />
  )
}

function TypeADialog({ cuit, setTypeA }) {
  const { showToast } = useMainContext()
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [found, setFound] = useState(null)
  const cuitRef = useRef(null)

  return (
    <DefaultAlertDialog
      title={'Pedir factura A'}
      content={
        <div className="flex flex-col">
          {/* <span className="mb-4">
            Ingresá el CUIT al que se le emitirá la factura y tocá en "Buscar" para buscar en el padrón. Si la
            información es correcta, tocá en "Confirmar".
          </span> */}
          <form
            className="w-full flex max-xl:flex-col gap-2"
            onSubmit={ev => {
              ;(async () => {
                ev.preventDefault()
                const cuit = new FormData(ev.target).get('cuit').toString()
                try {
                  setLoading(true)

                  const result = await axios.get(process.env.REACT_APP_FB_API_LINK + 'afip/persons', {
                    params: {
                      cuit,
                    },
                  })
                  // console.log(result)

                  setFound(result.data.info.tipoClave === 'CUIT' ? result.data.info : false)
                  setLoading(false)
                } catch (err) {
                  // console.log(err)
                  setFound(null)
                  setLoading(false)
                  showToast(`Error al buscar '${cuit}' en el padrón.`, 'bad')
                }
              })()
            }}>
            <div className="relative w-full xl:w-0 xl:grow h-10">
              <input
                ref={cuitRef}
                type="text"
                className="w-full h-full p-2 pr-6 rounded outline outline-1 -outline-offset-1
                      outline-gray-200 hover:outline-gray-500 focus:outline-2 focus:outline-dewalt peer"
                placeholder="Ingresá el CUIT"
                pattern="[0-9]{11}"
                minLength={11}
                maxLength={11}
                name="cuit"
                onChange={() => setFound(null)}
              />
              <button
                type="button"
                className="absolute top-1/2 -translate-y-1/2 right-3 text-xs xl:text-sm font-medium text-amber-500 hidden peer-placeholder-shown:block"
                onClick={() => {
                  if (cuitRef.current) {
                    cuitRef.current.value = cuit.replace(/-/g, '')
                  }
                }}>
                Es el mismo
              </button>
              <button
                type="button"
                className="absolute top-1/2 -translate-y-1/2 right-3 block peer-placeholder-shown:hidden"
                tabIndex={-1}
                onClick={() => {
                  if (cuitRef.current) {
                    cuitRef.current.value = ''
                    setFound(null)
                  }
                }}>
                <MdOutlineClose className="w-5 h-5 text-gray-400 hover:text-black transition-colors" />
              </button>
            </div>
            <div className="w-full xl:w-32 h-10">
              <ButtonWithProgress text={'Buscar'} clickable={true} loading={loading} done={false} fullWidth={true} />
            </div>
          </form>
          {found !== null &&
            (found === false ? (
              <span className="mt-2 text-red-500 text-sm">El CUIT ingresado no puede recibir Factura A.</span>
            ) : (
              <div className="mt-8 flex flex-col">
                <span className="mb-2 font-medium">Datos encontrados:</span>
                <span className="mb-1">
                  <span className="text-sm">· Nombre Completo / Razón Social:</span>
                  <br />
                  {found.razonSocial ?? `${found.apellido} ${found.nombre}`}
                </span>
                <span>
                  <span className="text-sm">· Actividad Principal:</span>
                  <br />
                  {found.descripcionActividadPrincipal}
                </span>
              </div>
            ))}
        </div>
      }
      submit={
        found
          ? {
              text: 'Confirmar',
              onClick: () => {
                setTypeA({ cuit: found.idPersona, name: found.razonSocial ?? `${found.apellido} ${found.nombre}` })
                setFound(null)
                setDone(true)
                showToast('Factura cambiada a Tipo A', 'good')
              },
            }
          : undefined
      }
      cancel={{
        text: 'Cancelar',
        onClick: () => {
          if (cuitRef.current) cuitRef.current.value = ''
          setFound(null)
          setDone(false)
        },
      }}
      done={done}
      trigger={<div id="typea-dialog" className="invisible" />}
    />
  )
}

function CartInfo({ cart, calculation, toggle, button, UTCoinsToGain }) {
  const amount = cart.length === 1 ? cart[0].amount : null

  return (
    <div className="w-full p-4 max-xl:pt-8 xl:py-10 xl:px-14 flex flex-col justify-center items-center">
      {cart.length === 1 ? (
        <>
          <div className="w-24 h-24 mb-4 bg-white rounded-full overflow-hidden shadow-lg">
            <ShowImage image={cart[0].product.images[0]} alt={`Imágen de ${cart[0].product.title}`} />
          </div>
          <p className="mb-1 text-center">{cart[0].product.title}</p>
          <span className="text-sm text-gray-500">Cantidad: {amount}</span>
          <div className="w-full h-[1px] my-8 bg-gray-200" />
        </>
      ) : (
        <>
          <span className="w-full font-medium">Resumen de la compra</span>
          <div className="w-full h-[1px] mt-4 mb-8 bg-gray-200" />
        </>
      )}
      {calculation.length > 0 && (
        <>
          <div className="w-full flex flex-col gap-2">
            {calculation.slice(0, -1).map((v, i) => (
              <div
                key={i}
                className={`w-full font-light flex justify-between items-center ${
                  v.highlight ? 'bg-white py-1 px-2 !font-bold text-green-600 scale-[1.04] rounded-md shadow-md' : ''
                }`}>
                <span className={`${v.color === 'green' ? 'text-green-600' : ''}`}>{v.text}</span>
                <span
                  className={`text-xl ${
                    v.value === 0 || v.color === 'green'
                      ? `text-green-600 ${v.highlight ? 'font-bold' : 'font-normal'}`
                      : ''
                  }`}>
                  {v.value === 0
                    ? 'Gratis'
                    : v.value < 0
                    ? '- ' + FormatPrice(v.value).replace('-', '')
                    : FormatPrice(v.value)}
                </span>
              </div>
            ))}
          </div>
          <div className="w-full h-[1px] my-8 bg-gray-200" />
          <div className="w-full flex justify-between items-center">
            <span className="text-lg">{calculation.at(-1).text}</span>
            <span className="text-2xl">{FormatPrice(calculation.at(-1).value)}</span>
          </div>
          <div className="w-full mt-1 flex justify-between items-center">
            <span className="text-lg">Ganás</span>
            <span className="text-xl text-green-600 font-bold">{DigitGrouping(UTCoinsToGain)} UT Coins</span>
          </div>
        </>
      )}
      {toggle}
      {button}
    </div>
  )
}

function CalculationMobile({ cartInfo, state: { open, setOpen }, buttonShowing, hasToggle }) {
  return (
    <Portal.Root
      className="fixed bottom-0 left-0 w-full pt-6 overflow-hidden flex flex-col items-center
                z-[777] xl:hidden text-black">
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className={`absolute top-0 left-1/2 -translate-x-1/2 w-12 h-6 ${
          open ? 'bg-neutral-50' : 'bg-white'
        } rounded-t-full flex justify-center`}>
        <MdOutlineChevronRight
          className={`w-7 h-7 text-gray-500 ${open ? 'rotate-90' : '-rotate-90'} transition-transform`}
        />
      </button>
      <div
        className={`w-full ${
          open
            ? 'h-max bg-neutral-50'
            : `${
                buttonShowing ? (hasToggle ? `h-[12.5rem]` : 'h-[10rem]') : hasToggle ? 'h-[8.5rem]' : 'h-[6rem]'
              } bg-white`
        } flex items-end overflow-hidden`}
        style={{ boxShadow: '0px -5px 15px -3px rgba(0, 0, 0, 0.15)' }}>
        {cartInfo}
      </div>
    </Portal.Root>
  )
}

function DomicileBox({ user, provinces, setShowUpdateForm }) {
  return (
    <InfoBox
      info={{
        title: 'Domicilio y teléfono',
        icon: <MdOutlineLocationOn className="w-full h-full" />,
        content: user.street ? (
          <>
            <span className="text-sm xl:text-base">{`${user.street} ${user.street_number}`}</span>
            <span className="text-gray-500">
              C.P. {user.zip_code} - {user.city}, {provinces[user.province].name}
            </span>
            <span className="text-gray-500">
              {user.name} {user.last_name} - {user.area_code} {user.phone_number}
            </span>
          </>
        ) : (
          <>
            <span className="font-medium">Domicilio sin completar</span>
            <span className="mt-1 text-neutral-600 hyphens-none">
              Completalo para saber si podés recibir el envío por moto
            </span>
          </>
        ),
        actions: [
          {
            text: user.street ? 'Modificar' : 'Completar domicilio',
            onClick: () => document.getElementById('domicile-form-dialog')?.click(),
          },
          {
            text: 'Editar datos personales',
            onClick: () => document.getElementById('personal-data-form-dialog')?.click(),
          },
        ],
      }}
    />
  )
}

function InfoBox({ info }) {
  const { title, icon, image, content, actions } = info

  return (
    <div className="w-full p-4 flex max-xl:flex-col xl:items-center bg-neutral-50 rounded-md shadow-sm">
      {title && <span className="text-sm font-medium mb-2 xl:hidden">{title}</span>}
      <div
        className="w-12 h-12 xl:w-16 xl:h-16 p-2 xl:p-3 mr-1 xl:mr-2 bg-white text-gray-400
                      rounded-full border border-gray-200 max-xl:hidden">
        {icon || image}
      </div>
      <div className="max-xl:mb-2 xl:ml-1 flex flex-col text-xs xl:text-sm">{content}</div>
      <div className="xl:w-0 xl:grow xl:flex xl:justify-end text-sm">
        <div className="flex xl:flex-col gap-4 xl:gap-2 xl:items-end">
          {actions?.map((action, index) =>
            action.link ? (
              <Link
                key={index}
                to={action.link}
                className="font-medium text-amber-500 hover:text-amber-600 transition-colors hyphens-none">
                {action.text}
              </Link>
            ) : (
              <button
                key={index}
                type="button"
                className="w-max font-medium text-amber-500 hover:text-amber-600 transition-colors hyphens-none"
                onClick={action.onClick}>
                {action.text}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  )
}

function Option({ info, isFree, disabled = false }) {
  const { text, innerHtml, subText, icon, price, onClick, link, selected } = info
  const Tag = disabled ? 'div' : 'button'
  return (
    <div className="relative">
      {!disabled && <Link to={link} className="absolute top-0 left-0 w-full h-full xl:hidden z-50" />}
      <Tag
        className={`w-full h-20 xl:h-24 p-4 relative flex items-center ${
          !disabled ? 'bg-white hover:bg-neutral-100 hover:shadow-md' : 'bg-neutral-200'
        } rounded-md shadow-sm overflow-hidden transition-all`}
        {...(!disabled
          ? {
              onClick,
            }
          : {
              disabled,
            })}>
        <div className={`absolute w-2 h-full left-0 top-0 bg-dewalt ${!selected ? 'hidden' : ''} max-xl:hidden`} />
        {!disabled ? (
          <div className="w-6 h-6 p-[3px] mx-5 rounded-full border-2 border-dewalt max-xl:hidden">
            <div className={`w-full h-full rounded-full bg-dewalt ${!selected ? 'hidden' : ''}`} />
          </div>
        ) : (
          <MdError className="w-10 h-10 mx-3 text-neutral-400" />
        )}
        {icon && (
          <div
            className={`w-12 h-12 xl:w-14 xl:h-14 p-2 mr-4 bg-neutral-100 rounded-full select-none ${
              disabled ? 'opacity-60' : ''
            }`}>
            <ShowImage image={icon} alt={`Ícono de ${text}`} draggable={false} />
          </div>
        )}
        <div className={`w-0 grow ${!icon ? 'xl:ml-3' : ''} pr-2 text-start flex flex-col xl:gap-1 hyphens-none`}>
          <span className="max-xl:text-sm hyphens-none">{text ?? innerHtml}</span>
          {subText ? <span className="text-xs xl:text-sm text-gray-500">{subText}</span> : ''}
        </div>
        {price != null && (
          <div className="pr-2 flex flex-col">
            {isFree && price !== 0 && <del className="text-gray-500 leading-5">{FormatPrice(price)}</del>}
            <span className={`xl:text-xl ${(price === 0 || isFree) && 'text-green-600'}`}>
              {price === 0 || isFree ? 'Gratis' : FormatPrice(price)}
            </span>
          </div>
        )}
        {!disabled && <MdOutlineChevronRight className="w-5 h-5 text-gray-500 xl:hidden" />}
      </Tag>
    </div>
  )
}

export default Checkout

import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { BiCheckShield } from 'react-icons/bi'
import { DeleteFormatFromPhoneNumber, DigitGrouping, FormatPrice, ProductLink } from '../utils/Strings'
import {
  MdCheck,
  MdInsertLink,
  MdOutlineClose,
  MdOutlineLocalShipping,
  MdOutlineMail,
  MdOutlineReceiptLong,
} from 'react-icons/md'
import Carouselv2 from '../utils/Carouselv2'
import { useMainContext } from '../utils/MainContext'
import { GetDiscountPercent, RoundTo1Decimal, UTCoinsFromValue } from '../utils/Numbers'
import FavoriteButton from '../utils/FavoriteButton'
import { BsFacebook, BsWhatsapp } from 'react-icons/bs'
import Carousel from '../utils/Carousel'
import InfoBlock from '../utils/InfoBlock'
import LoadingBox from '../utils/LoadingBox'
import ButtonWithProgress from '../utils/ButtonWithProgress'
import ShowImage from '../utils/ShowImage'
import { FiShoppingCart } from 'react-icons/fi'
import CopyToClipboard from 'react-copy-to-clipboard'
import { saveCookie, setDocumentTitle, delay } from '../utils/Html'
import PlusMinusInput from '../utils/PlusMinusInput'
import { useAbortableEffect, useDolarBlue } from '../utils/Hooks'
import { round } from 'lodash'

function Product() {
  const { firebase, setFirebase, showToast, setShowCart } = useMainContext()
  const { product_id } = useParams()
  const navigate = useNavigate()
  const user = firebase.user

  const [dolarBlue] = useDolarBlue()
  const [data, setData] = useState(null)
  const product = data?.product ?? {}
  const category = data?.category ?? []
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const UTCoinsToGain =
    data && dolarBlue
      ? UTCoinsFromValue(product.offer_price ?? product.normal_price, dolarBlue, data?.ut_coins_multiplier)
      : null

  const [amount, setAmount] = useState(1)

  useAbortableEffect(
    signal => {
      setDocumentTitle('Cargando...')
      ;(async () => {
        try {
          setData(null)
          const { data } = await axios.get(process.env.REACT_APP_API_LINK + 'get_product', {
            params: { product_id: product_id },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log({ data })
          const { product } = data

          setData(data)
          setShowModal(product.final_price <= data.free_shipping_minimum)
          setDocumentTitle(product.title)
        } catch (err) {
          if (err.code === 'ERR_CANCELED') return
          // console.log(err)
          showToast(err.response?.data?.message ?? process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
        }
      })()
    },
    [product_id, showToast]
  )

  const handleAddToCart = async () => {
    try {
      if (!user) navigate('/notloggedin/cart')

      setLoading(true)
      const result = await axios.put(
        process.env.REACT_APP_API_LINK + 'update_cart',
        { user_id: user.uid, product_id: product_id, amount: +amount, action: 'add' },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      const cart = result.data.cart.map(item => ({
        amount: item.amount,
        product_id: item.product,
      }))
      user.cart = cart
      setFirebase({ ...firebase, user: user })
      saveCookie('cart', cart)

      showToast('¡Producto agregado al carrito!', 'good')
      setLoading(false)

      setShowCart(true)
      // window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    } catch (err) {
      setLoading(false)
      if (err.response?.status === 409 && err.response?.data?.message) {
        showToast(err.response.data.message, 'bad')
      }
      // console.log(err)
    }
  }

  const handlePause = async value => {
    try {
      if (!user) navigate('/notloggedin/cart')
      if (user.role === 'user') {
        showToast('No podés realizar esa acción.', 'bad')
        return
      }

      setLoading(true)
      // const result =
      await axios.put(
        process.env.REACT_APP_API_LINK + 'update_product',
        { user_id: user.uid, product_id: product_id, action: 'update', fields: { paused: value } },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      setLoading(false)
      setDone(true)
      showToast(value ? '¡Listo! El producto está pausado.' : '¡Listo! El producto fue reactivado.', 'good')

      await delay(2000)
      window.location.reload(false)
    } catch (err) {
      // console.log(err)
      setLoading(false)
      showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
    }
  }

  return (
    <section className="w-full grow min-h-max bg-white xl:bg-pagebg">
      <div className="w-full xl:w-content-max-width xl:mx-auto flex flex-col text-black">
        {data ? (
          <>
            <div className="w-full py-3 flex text-sm max-xl:hidden">
              <Link to={`/catalogue/${category.children.name.toLowerCase().replaceAll(' ', '-')}`}>
                Volver al catálogo
              </Link>
            </div>
            {product.removed ? (
              <div className="w-full max-xl:px-4 max-xl:pt-4 xl:mb-4">
                <InfoBlock text="Producto eliminado" />
              </div>
            ) : (
              product.paused && (
                <div className="w-full max-xl:px-4 max-xl:pt-4 xl:mb-4">
                  <InfoBlock text="Producto pausado" />
                </div>
              )
            )}
            <div className="w-full xl:p-4 xl:mb-10 bg-white flex flex-col xl:rounded-md xl:border xl:border-gray-200 xl:shadow-sm">
              {!product?.paused && firebase?.user?.role === 'user' && (
                <div
                  className={`w-full bg-amber-100 relative p-2 xl:mb-4 max-xl:text-sm
                flex justify-center xl:rounded-md shadow-sm text-center ${!showModal ? 'hidden' : ''}`}>
                  <span className="hyphens-none">
                    Las compras mayores o iguales a {FormatPrice(data.free_shipping_minimum)} tienen envío&nbsp;gratis.
                    ¡Aprovechalo!
                  </span>
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false)
                    }}
                    className="absolute h-full right-0 top-0 p-2 aspect-square max-xl:hidden">
                    <MdOutlineClose className="w-full h-full opacity-60" />
                  </button>
                </div>
              )}
              <div className="w-full flex gap-4">
                <div className="w-0 grow-[0.8] h-max gap-inherit max-xl:hidden">
                  <div className="w-full flex flex-col gap-inherit">
                    <div className="w-full flex gap-inherit">
                      <div className="w-0 grow">
                        <div className="w-full h-max aspect-square">
                          <Carouselv2
                            items={[...product.images, ...(product.video ? [product.video] : [])]}
                            contain={true}
                          />
                        </div>
                      </div>
                      <div className="w-0 grow pt-4 flex flex-col">
                        <h1 className="text-2xl font-bold hyphens-none">{product.title}</h1>
                        <div className="py-1">
                          <div className="mt-1 flex gap-2">
                            {product.is_best_seller && (
                              <div className="py-[3px] px-2 bg-amber-500 text-white font-medium rounded-md flex">
                                <span className="text-xs">MÁS&nbsp;VENDIDO</span>
                              </div>
                            )}
                            {product.recommended_by_seller && (
                              <div className="py-[3px] px-2 bg-black text-white font-medium rounded-md flex">
                                <img
                                  src="/universal-tools-logo-notext.svg"
                                  className="w-8 h-4 object-contain"
                                  alt="yaguarete"
                                />
                                <span className="ml-1 text-xs">RECOMENDADO&nbsp;por&nbsp;Universal&nbsp;Tools</span>
                              </div>
                            )}
                          </div>
                          <Link
                            to={`/catalogue/${category.category_id}/${category.children.category_id}/all`}
                            className="w-max mt-2 font-bold text-green-500 hover:text-green-600 transition-colors">
                            Ver productos relacionados
                          </Link>
                        </div>
                        <div className="w-full h-[1px] bg-gray-200 my-2"></div>
                        <div className="pr-20 grid grid-cols-4 gap-1">
                          <TitleTextItem title="Marca" text={product.brand.name} />
                          <TitleTextItem title="Código" text={product.model} />
                          <TitleTextItem title="Tipo" text={category.children.name} />
                        </div>
                        <Link
                          to={`/catalogue/${product.brand.name.toLowerCase()}`}
                          className="flex gap-2 py-2 items-center group">
                          <div
                            className="w-16 aspect-square p-1 rounded-md bg-white border border-gray-200 shadow-sm
                      group-hover:border-gray-500 group-hover:shadow-md transition-all">
                            <ShowImage image={product.brand.image} alt={product.brand.name} />
                          </div>
                          <span className="text-sm text-gray-500 text-start group-hover:text-black transition-colors">
                            Ver más productos<br></br>
                            {product.brand.name}
                          </span>
                        </Link>
                        {product.bullets && (
                          <>
                            <div className="w-full h-[1px] bg-gray-200 my-2"></div>
                            <span className="font-medium mb-2">Sobre este producto</span>
                            <ul className="pl-4 text-sm">
                              {product.bullets.map((v, i) => (
                                <li className="list-disc mb-1 last:mb-0" key={i}>
                                  {v}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                    {product.description && (
                      <>
                        <div className="w-full px-8 flex flex-col justify-center">
                          <div className="w-full py-8 border-t border-gray-200">
                            <h2 className="text-2xl mb-6">Descripción</h2>
                            <div
                              className="text-xl font-light text-neutral-600 hyphens-none break-words"
                              dangerouslySetInnerHTML={{ __html: product.description }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="w-full px-8 flex flex-col justify-center">
                      <div className="w-full py-8 border-t border-gray-200">
                        <h2 className="text-2xl mb-6">Contacto</h2>
                        <div className="flex">
                          <div className="w-20 h-20 p-4 bg-neutral-100 rounded-full">
                            <BsWhatsapp className="w-full h-full text-[#25D366]" />
                          </div>
                          <div className="ml-4 flex flex-col justify-center">
                            <span className="text-lg">Por cualquier duda, contactate directamente con nosotros.</span>
                            <span className="text-lg font-bold">+54 9 {data.contact_number}</span>
                            <a
                              href={`https://api.whatsapp.com/send/?phone=549${DeleteFormatFromPhoneNumber(
                                data.contact_number
                              )}&text=${encodeURIComponent(
                                `Hola! Te consulto por el producto ${product.title} (${product.brand.name} ${
                                  product.model
                                }).\nhttps://universaltools.com.ar${ProductLink(product.product_id, product.title)}`
                              )}`}
                              className="w-max font-bold underline underline-offset-2 text-[#25D366] transition-colors"
                              target="_blank"
                              rel="noreferrer">
                              Abrir en WhatsApp
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full xl:w-0 xl:grow-[0.3] relative">
                  <div className="flex flex-col gap-4 sticky top-0">
                    <div className="w-full relative max-xl:mt-4 xl:p-4 flex flex-col xl:rounded-md xl:border xl:border-gray-200 xl:shadow-sm">
                      {(!user || user.role === 'user') && (
                        <div className="absolute top-0 right-4 xl:top-4 xl:right-4">
                          <FavoriteButton product_id={product.product_id} always_visible={true} size="lg" />
                        </div>
                      )}
                      <span className="max-xl:pl-4 mb-2 xl:mb-4 text-sm text-gray-500">{`${product.sales} ${
                        product.sales === 1 ? 'vendido' : 'vendidos'
                      }`}</span>
                      <div className="xl:hidden">
                        <div className="px-4">
                          <h1 className="text-lg font-medium leading-5 text-neutral-800 hyphens-none">
                            {product.title}
                          </h1>
                          <div className="py-1">
                            <div className="mt-1 flex gap-2">
                              {product.is_best_seller && (
                                <div className="py-[3px] px-2 bg-amber-500 text-white font-medium rounded-md flex">
                                  <span className="text-xs">MÁS&nbsp;VENDIDO</span>
                                </div>
                              )}
                              {product.recommended_by_seller && (
                                <div className="py-[3px] px-2 bg-black text-white font-medium rounded-md flex">
                                  <img
                                    src="/universal-tools-logo-notext.svg"
                                    className="w-8 h-4 object-contain"
                                    alt="yaguarete"
                                  />
                                  <span className="ml-1 text-xs">RECOMENDADO</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="w-full aspect-square my-4">
                          <Carousel
                            propItems={[
                              ...product.images.map(v => ({ image: v, link: '' })),
                              ...(product.video ? [{ image: '', video: product.video, link: '' }] : []),
                            ]}
                            interval={0}
                            contain={true}
                            counter={true}
                            padding={1}
                            previewable={true}
                          />
                        </div>
                      </div>
                      <div className="w-full max-xl:px-4 flex flex-col">
                        {product.offer_price ? (
                          <>
                            <span className="xl:text-lg font-light text-gray-500">
                              <del>{FormatPrice(product.normal_price)}</del>
                            </span>
                            <div className="flex items-center xl:mb-4">
                              <span className="text-3xl xl:text-4xl xl:leading-6">
                                {FormatPrice(product.offer_price ?? product.normal_price)}
                              </span>
                              <span className="xl:text-lg ml-2 text-green-600">{`${GetDiscountPercent(
                                product.normal_price,
                                product.offer_price
                              )}% OFF`}</span>
                            </div>
                            <span className="leading-6 text-green-600">
                              Ahorrá <strong>{FormatPrice(product.normal_price - product.offer_price)}</strong>
                            </span>
                          </>
                        ) : (
                          <span className="my-2 text-4xl leading-6">
                            {FormatPrice(product.offer_price ?? product.normal_price)}
                          </span>
                        )}
                        <span className="mt-1 flex items-center flex-wrap gap-1">
                          <span>{FormatPrice(round(product.final_price * (1 + data.mp_add_pct)))}</span>
                          <small className="inline">por </small>
                          <img alt="" src="/mercado-pago-notext.png" className="inline w-6 h-5" />
                          <img alt="" src="/rapipago-notext.jpg" className="inline w-6 h-5 rounded-lg" />
                          <img alt="" src="/pagofacil.png" className="inline w-6 h-5" />
                        </span>
                        {!!UTCoinsToGain && (
                          <div className="relative w-full px-5 py-3 mt-4 rounded-md shadow-lg overflow-hidden">
                            <div className="absolute top-0 left-0 w-1 h-full bg-green-600" />
                            <span className="font-light hyphens-none">
                              ¡Recibirás{' '}
                              <strong className="font-bold text-green-600">
                                {DigitGrouping(UTCoinsToGain)}
                                &nbsp;UT&nbsp;Coins
                              </strong>{' '}
                              por unidad!
                            </span>
                            <span className="block mt-1 text-sm">
                              {DigitGrouping(UTCoinsToGain)} UT Coins (al día de hoy) ={' '}
                              {FormatPrice(RoundTo1Decimal(UTCoinsToGain * (dolarBlue?.value_buy / 10)))}
                            </span>
                          </div>
                        )}
                        <div className="xl:hidden">
                          <div className="w-full h-[1px] bg-gray-200 my-4"></div>
                          <div className="grid grid-cols-4 gap-1">
                            <TitleTextItem title="Marca" text={product.brand.name} />
                            <TitleTextItem title="Código" text={product.model} />
                            <TitleTextItem title="Tipo" text={category.children.name} />
                          </div>
                          <Link
                            to={`/catalogue/${product.brand.name.toLowerCase()}`}
                            className="flex gap-2 py-2 items-center group">
                            <div
                              className="w-16 aspect-square p-1 rounded-md bg-white border border-gray-200 shadow-sm
                      group-hover:border-gray-500 group-hover:shadow-md transition-all">
                              <ShowImage image={product.brand.image} alt={product.brand.name} />
                            </div>
                            <span className="text-sm text-gray-500 text-start group-hover:text-black transition-colors">
                              Ver más productos<br></br>
                              {product.brand.name}
                            </span>
                          </Link>
                        </div>
                        <div className="w-full h-[1px] bg-gray-200 my-4"></div>
                        <div className="text-neutral-800 flex items-center my-2">
                          <BiCheckShield className="w-7 h-7" />
                          <span className="ml-2">
                            Garantía: <span className="font-medium">{product.warranty}</span>
                          </span>
                        </div>
                        <div className="text-neutral-800 flex items-center my-2">
                          <MdCheck className="w-7 h-7" />
                          <span className="ml-2">Stock disponible</span>
                        </div>
                        <div className="text-neutral-800 flex items-center my-2">
                          <MdOutlineReceiptLong className="w-7 h-7" />
                          <span className="ml-2">Realizamos factura A y B</span>
                        </div>
                        <div className="text-neutral-800 flex items-center my-2">
                          <MdOutlineLocalShipping
                            className={`w-7 h-7 ${
                              (product.offer_price ?? product.normal_price) >= data.free_shipping_minimum
                                ? 'text-green-600'
                                : ''
                            }`}
                          />
                          <span
                            className={`ml-2 ${
                              (product.offer_price ?? product.normal_price) >= data.free_shipping_minimum &&
                              'font-bold text-green-600'
                            }`}>
                            {(product.offer_price ?? product.normal_price) >= data.free_shipping_minimum
                              ? 'Envío gratis'
                              : 'Envíos'}{' '}
                            a todo el país
                          </span>
                        </div>
                        <div className="w-full h-[1px] bg-gray-200 my-4" />
                        {!product.paused && !product.removed && (
                          <>
                            {(!user || user.role === 'user') && product.stock + product.offer_cost_stock > 0 && (
                              <div className="mb-4 flex items-center">
                                <span>Cantidad:</span>
                                <div className="w-40 h-12 ml-2">
                                  <PlusMinusInput
                                    min={1}
                                    max={product.stock + product.offer_cost_stock}
                                    state={[amount, setAmount]}
                                  />
                                </div>
                              </div>
                            )}
                            <span
                              className={`${
                                product.stock + product.offer_cost_stock === 1
                                  ? 'text-lg text-green-600 font-bold'
                                  : 'text-sm text-gray-500'
                              }`}>
                              {product.stock + product.offer_cost_stock === 0
                                ? 'Sin stock'
                                : product.stock + product.offer_cost_stock === 1
                                ? '¡ÚLTIMA UNIDAD EN STOCK!'
                                : `${product.stock + product.offer_cost_stock} unidades en stock`}
                            </span>
                          </>
                        )}
                        {product.stock + product.offer_cost_stock > 0 &&
                          (product.removed ? (
                            <div className="">
                              <InfoBlock text="Producto eliminado" />
                            </div>
                          ) : product.paused ? (
                            <>
                              <div className="mt-4">
                                <InfoBlock text="Producto pausado" />
                              </div>
                              {user && user.role === 'admin' && (
                                <>
                                  <div className="w-full h-14 mt-4">
                                    <ButtonWithProgress
                                      text="Reactivar producto"
                                      onClick={() => handlePause(false)}
                                      loading={loading}
                                      done={done}
                                      clickable={user.role === 'admin'}
                                      fullWidth={true}
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    className="w-full py-4 mt-2 border border-gray-200 hover:border-black
                                        rounded-md shadow-sm transition-colors text-center"
                                    onClick={() => navigate(`/admin/products/${product_id}/modify`)}>
                                    <span className="font-medium">Modificar producto</span>
                                  </button>
                                </>
                              )}
                            </>
                          ) : !user || user.role === 'user' ? (
                            <>
                              <Link
                                to={user ? `/checkout/${product.product_id}_${amount}` : `/notloggedin/buy`}
                                className="w-full py-4 mt-4 bg-dewalt font-medium hover:bg-black hover:text-dewalt
                                        rounded-md shadow-sm transition-colors text-center">
                                Comprar
                              </Link>
                              <div className="relative w-full mt-2">
                                <div className="relative w-full">
                                  {loading && (
                                    <div className="absolute top-0 left-0 w-full h-full bg-white/70 p-2 flex justify-center items-center z-10">
                                      <img src="/loading.svg" className="w-max h-full" alt="Cargando" />
                                    </div>
                                  )}
                                  <button
                                    type="button"
                                    className="w-full py-4 border border-gray-200 hover:border-black
                                rounded-md shadow-sm transition-colors text-center"
                                    onClick={handleAddToCart}>
                                    <span className="font-medium">Agregar al carrito</span>
                                  </button>
                                </div>
                                {user?.cart.some(item => item.product_id === product_id) && (
                                  <button
                                    type="button"
                                    className="absolute top-2 right-2 p-2 flex items-center rounded-md border border-gray-200
                                  hover:border-black transition-colors group select-none"
                                    onClick={() => {
                                      setShowCart(true)
                                      // window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
                                    }}
                                    title="Ver en carrito">
                                    <FiShoppingCart className="w-6 h-6 text-gray-300 group-hover:text-black transition-colors" />
                                    <span
                                      className="w-6 h-6 ml-2 text-sm bg-dewalt text-white font-medium
                                              flex justify-center items-center rounded-full">
                                      {user?.cart.find(item => item.product_id === product_id).amount}
                                    </span>
                                  </button>
                                )}
                              </div>
                            </>
                          ) : (
                            user &&
                            user.role === 'admin' && (
                              <>
                                <div className="w-full h-14 mt-4">
                                  <ButtonWithProgress
                                    text="Pausar producto"
                                    onClick={() => handlePause(true)}
                                    loading={loading}
                                    done={done}
                                    clickable={user.role === 'admin'}
                                    fullWidth={true}
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="w-full py-4 mt-2 border border-gray-200 hover:border-black
                                      rounded-md shadow-sm transition-colors text-center"
                                  onClick={() => navigate(`/admin/products/${product_id}/modify`)}>
                                  <span className="font-medium">Modificar producto</span>
                                </button>
                              </>
                            )
                          ))}
                        {!product.paused && !product.removed && (
                          <>
                            <span className="mx-auto mt-4 text-xs xl:text-sm">Compartir:</span>
                            <div className="w-full mt-2 flex gap-8 justify-center items-center">
                              {[
                                {
                                  copy: `${window.location.origin}${ProductLink(product.product_id, product.title)}`,
                                  icon: <MdInsertLink className="w-8 h-8 text-gray-500" />,
                                  title: 'Copiar enlace',
                                },
                                {
                                  link: `mailto:?subject=${
                                    product.title
                                  }&body=https://www.universaltools.com.ar${ProductLink(
                                    product.product_id,
                                    product.title
                                  )}`,
                                  icon: <MdOutlineMail className="w-8 h-8 text-gray-500" />,
                                  title: 'Enviar por correo',
                                },
                                {
                                  link: `https://www.facebook.com/sharer.php?u=https://www.universaltools.com.ar${ProductLink(
                                    product.product_id,
                                    product.title
                                  )}`,
                                  icon: <BsFacebook className="w-7 h-7 text-[#1778f2]" />,
                                  title: 'Compartir por Facebook',
                                },
                                {
                                  link: `whatsapp://send?text=Mirá este producto de Universal Tools:%0A${
                                    product.title
                                  }%0Ahttps://www.universaltools.com.ar${ProductLink(
                                    product.product_id,
                                    product.title
                                  )}`,
                                  icon: <BsWhatsapp className="w-7 h-7 text-[#25D366]" />,
                                  title: 'Compartir por WhatsApp',
                                },
                              ].map((v, i) =>
                                v.copy ? (
                                  <CopyToClipboard
                                    key={i}
                                    text={v.copy}
                                    onCopy={() => showToast('Enlace copiado', 'good')}>
                                    <button key={i} className="hover:brightness-0 transition-all" title={v.title}>
                                      {v.icon}
                                    </button>
                                  </CopyToClipboard>
                                ) : (
                                  <a
                                    href={v.link}
                                    key={i}
                                    className="hover:brightness-0 transition-all"
                                    rel="noreferrer"
                                    target="_blank"
                                    title={v.title}>
                                    {v.icon}
                                  </a>
                                )
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {/* <div className="w-full px-4 py-10 flex flex-col rounded-md shadow-sm border border-gray-200">
                    <span className="mb-6 text-xl">Como te llega el producto</span>
                    <span className="mb-4 font-thin text-neutral-600">
                      Podés elegir si pasas a retirar el producto o te lo enviamos mediante{" "}
                      <strong>Correo&nbsp;Argentino</strong>.
                    </span>
                    <ul className="pl-4 [&>*]:list-disc [&>*]:mt-1 text-neutral-600">
                      <li className="">Retiro por depósito</li>
                      <li>
                        Envío a domicilio: <strong>$ 1.500</strong>
                      </li>
                      <li>
                        Envío a sucursal cercana: <strong>$ 1.000</strong>
                      </li>
                    </ul>
                  </div> */}
                    {product.description && (
                      <>
                        <div className="w-full px-4 py-8 flex flex-col border-t xl:border border-gray-200 xl:rounded-md xl:shadow-sm xl:hidden">
                          <span className="mb-6 text-xl">Descripción</span>
                          <div
                            className="font-light tracking-wide text-neutral-600 hyphens-none break-words"
                            dangerouslySetInnerHTML={{ __html: product.description }}
                          />
                        </div>
                      </>
                    )}
                    <div className="w-full px-4 py-8 flex flex-col border-t xl:border border-gray-200 xl:rounded-md xl:shadow-sm">
                      <span className="mb-6 text-xl">Garantía</span>
                      <span className="text-neutral-600 text-lg">
                        Garantía Oficial <span className="font-bold">{product.brand.name}</span>{' '}
                        <span className="font-bold">{`${
                          product.warranty !== 'De por vida' ? 'de ' : ''
                        }${product.warranty?.toLowerCase()}`}</span>
                      </span>
                    </div>
                    <div className="w-full px-4 py-8 flex flex-col gap-4 border-t xl:border border-gray-200 xl:rounded-md xl:shadow-sm">
                      <span className="text-xl mb-2">Medios de pago</span>
                      <div className="flex flex-col gap-2">
                        <span className="text-neutral-600">Hasta 12 cuotas sin tarjeta</span>
                        <div className="w-full h-10 flex gap-6 items-center">
                          <img src="/mercado-pago.svg" alt="Mercado Pago" className="h-full w-max py-2" />
                          <img src="/mercado-credito.svg" alt="Mercado Crédito" className="h-full w-max" />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-neutral-600">Tarjetas de crédito</span>
                        <div className="w-full flex gap-6 items-center">
                          <img src="/visa.svg" alt="Visa" className="h-full w-max" />
                          <img src="/naranja.svg" alt="Naranja" className="h-full w-max" />
                          <img src="/amex.svg" alt="American Express" className="h-full w-max" />
                          <img src="/mastercard.svg" alt="MasterCard" className="h-full w-max" />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-neutral-600">Tarjetas de débito</span>
                        <div className="w-full flex flex-wrap gap-6 items-center">
                          <img src="/visa-debito.svg" alt="Visa Débito" className="h-full w-max" />
                          <img src="/maestro.svg" alt="Maestro" className="h-full w-max" />
                          <img src="/mastercard-debito.svg" alt="MasterCard Débito" className="h-full w-max" />
                          <img src="/cabal-debito.svg" alt="Cabal Débito" className="h-full w-max" />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-neutral-600">Efectivo</span>
                        <span className="text-sm text-neutral-600">
                          Podés pagar en efectivo cuando retirás<br></br>o tambíen por estos medios:
                        </span>
                        <div className="w-full flex gap-6 items-center">
                          <img src="/pagofacil.svg" alt="PagoFacil" className="h-full w-max" />
                          <img src="/rapipago.svg" alt="Rapipago" className="h-full w-max" />
                        </div>
                      </div>
                      <span className="text-neutral-600">Transferencia bancaria</span>
                      <div className="flex flex-col gap-2">
                        <span className="text-neutral-600">Dólares físicos</span>
                        <span className="text-sm text-neutral-600 hyphens-none">
                          Podés pagar con dólares físicos en persona a la cotización actual del Dolar Blue
                        </span>
                      </div>
                    </div>
                    <div className="w-full px-4 py-8 flex flex-col border-t xl:border border-gray-200 xl:rounded-md xl:shadow-sm xl:hidden">
                      <span className="mb-6 text-xl">Contacto</span>
                      <div className="flex">
                        <div className="w-16 h-16 p-4 bg-neutral-100 rounded-full">
                          <BsWhatsapp className="w-full h-full text-[#25D366]" />
                        </div>
                        <div className="w-0 grow ml-4 flex flex-col justify-center">
                          <span className="mb-2 hyphens-none">
                            Por cualquier duda, contactate directamente con nosotros.
                          </span>
                          <span className="font-medium">+54 9 {data.contact_number}</span>
                          <a
                            href={`https://api.whatsapp.com/send/?phone=549${DeleteFormatFromPhoneNumber(
                              data.contact_number
                            )}&text=${encodeURIComponent(
                              `Hola! Te consulto por el producto ${product.title} (${product.brand.name} ${
                                product.model
                              }).\nhttps://universaltools.com.ar${ProductLink(product.product_id, product.title)}`
                            )}`}
                            className="w-max font-bold text-[#25D366] transition-colors"
                            target="_blank"
                            rel="noreferrer">
                            Abrir en WhatsApp
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingBox text={'Cargando producto...'} />
        )}
      </div>
    </section>
  )
}

function TitleTextItem({ title, text }) {
  return (
    <>
      <span className="font-medium col-span-1 xl:col-span-2">{title}</span>
      <span className="col-span-3 xl:col-span-2 max-xl:text-end">{text}</span>
    </>
  )
}

export default Product

import Sidebar from "../../Sidebar";
import ViewOrders from "../ViewOrders";

function UserOrders() {
  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={false} />
      <ViewOrders admin={false} />
    </div>
  );
}

export default UserOrders;

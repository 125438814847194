import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { setDocumentTitle } from '../utils/Html'
import { useMainContext } from '../utils/MainContext'

function NotLoggedIn({ reason }) {
  const { firebase } = useMainContext()
  const navigate = useNavigate()

  const text =
    reason === 'buy'
      ? '¡Hola! Para comprar, necesitas&nbsp;una&nbsp;cuenta.'
      : reason === 'fav'
      ? '¡Hola! Para guardar favoritos, necesitas&nbsp;una&nbsp;cuenta.'
      : reason === 'cart'
      ? '¡Hola! Para agregar al carrito, necesitas&nbsp;una&nbsp;cuenta.'
      : ''

  useEffect(() => {
    if (firebase.user) navigate('/', { replace: true })
    setDocumentTitle(text.replace(/&nbsp;/g, ' '))
  }, [firebase.user, navigate, text, reason])

  return (
    <section className="w-full grow bg-white xl:bg-pagebg text-black flex justify-center">
      <div
        className="w-[28rem] h-max p-2 py-8 xl:p-10 xl:mt-4 flex flex-col justify-center items-center bg-white
                  xl:rounded-md xl:shadow-sm xl:border xl:border-gray-200">
        <h1 className="mb-6 text-2xl text-center font-medium hyphens-none" dangerouslySetInnerHTML={{ __html: text }} />
        <div className="w-full max-xl:px-6">
          <Link
            to="/signup"
            className="w-full h-12 bg-dewalt flex justify-center items-center font-medium rounded-md shadow-sm
          hover:bg-black hover:text-dewalt transition-all">
            Registrarme
          </Link>
          <Link
            to="/login/user"
            className="w-full h-12 flex justify-center items-center font-medium rounded-md
          hover:text-amber-500 transition-all">
            Iniciar sesión
          </Link>
        </div>
      </div>
    </section>
  )
}

export default NotLoggedIn

import { isString } from 'lodash'

export const inputsToObj = (inputsRef, creating = false) => {
  let obj = {}

  inputsRef.current.forEach(input => {
    let inputObj = input.toSend()
    let [key, value] = Object.entries(inputObj)[0]

    if (isString(value)) {
      value = value.trim()
    }

    if (creating || input.valueChanged) obj = { ...obj, ...{ [key]: value } }
  })

  return obj
}

import { isEqual } from 'lodash'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { MdOutlineDelete, MdOutlineImage } from 'react-icons/md'
import ShowImage from '../ShowImage'

const ImageDnD = forwardRef(({ object, name, maxLength, showOnFirst, showCount, onChange }, ref) => {
  const [defaultValue, setDefaultValue] = useState([])
  const [images, setImages] = useState(defaultValue)

  useEffect(() => {
    const value = object[name].map((v, i) => ({ id: '' + i, src: v }))
    setDefaultValue(value)
    setImages(value)
  }, [object]) //eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    value: images,
    reset: () => setImages(defaultValue),
    valueChanged: !isEqual(images, defaultValue),
    toSend() {
      return {
        [name]: images.map(image => ({
          src: image.src,
          blob: image.blob,
          remove: !!image.remove,
        })),
      }
    },
  }))

  const handleDragEnd = result => {
    if (!result.destination) {
      return
    }

    const { source, destination } = result

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    const updatedImages = Array.from(images.filter(image => !image.remove))
    const [removed] = updatedImages.splice(source.index, 1)
    updatedImages.splice(destination.index, 0, removed)

    setImages([...updatedImages, ...(images.filter(image => image.remove) ?? [])])
    onChange()
  }

  const handleFiles = files => {
    let newImages = []

    files.forEach(file => {
      if (
        (maxLength === undefined || newImages.length + images.length < maxLength) &&
        file &&
        (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp')
      ) {
        const id =
          '' + ((newImages.length === 0 ? images : newImages).reduce((max, v) => (+v.id > max ? +v.id : max), -1) + 1)
        // console.log(id);
        newImages.push({
          id: id,
          blob: file,
          src: URL.createObjectURL(file),
        })
      }
    })

    setImages([...images, ...newImages])
    onChange()
  }

  const handleDrop = ev => {
    ev.preventDefault()

    if (ev.dataTransfer.items) {
      // console.log("items");
      const files = [...ev.dataTransfer.items].filter(item => item.kind === 'file').map(item => item.getAsFile())
      handleFiles(files)
    } else {
      // console.log("files");
      handleFiles(ev.dataTransfer.files)
    }
  }

  return (
    <div className="w-full flex flex-col">
      <div className="w-full py-4 overflow-x-scroll">
        <div className={`${images.length === 0 ? 'w-full' : 'w-max'} flex`}>
          {(maxLength === undefined || images.length < maxLength) && (
            <div className={`${images.length > 0 ? 'w-24 mr-2' : 'w-full'} h-24`}>
              <AddImageLabel htmlFor={'picture'} onDrop={handleDrop} />
              <input
                type="file"
                id="picture"
                accept="image/jpeg, image/png, image/webp"
                className="hidden"
                multiple
                onChange={ev => {
                  handleFiles([...ev.target.files])
                  onChange()
                }}
              />
            </div>
          )}
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="image-list" direction="horizontal">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="flex">
                  {images
                    .filter(image => !image.remove)
                    .map((image, index) => (
                      <Draggable key={image.id} draggableId={image.id} index={index}>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="relative bg-white w-24 h-24 mx-2 transition-shadow group select-none hover:shadow-md"
                            style={{
                              ...provided.draggableProps.style,
                            }}>
                            <div className="w-full h-full">
                              {index === 0 && showOnFirst !== undefined && showOnFirst}
                              <div
                                className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 z-10
                            hidden group-hover:flex justify-center items-center cursor-pointer">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setImages(
                                      isEqual(defaultValue, [])
                                        ? images.filter(im => im.id !== image.id)
                                        : images.map(im => (im.id === image.id ? { ...im, remove: true } : im))
                                    )
                                    onChange()
                                  }}
                                  className="w-7 h-7 p-1 bg-dewalt hover:bg-amber-300 text-white rounded-full transition-colors"
                                  title="Eliminar">
                                  <MdOutlineDelete className="w-full h-full" />
                                </button>
                              </div>
                              <ShowImage image={image.src} alt={`Imágen ${index + 1}`} />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      {showCount && (
        <div className="mt-2 flex justify-end">
          <span className="text-xs xl:text-sm text-neutral-400">{`${images.length} / ${maxLength}`}</span>
        </div>
      )}
    </div>
  )
})

export const AddImageLabel = ({ htmlFor, onDrop, img }) => {
  return (
    <label
      htmlFor={htmlFor}
      className="w-full h-full cursor-pointer"
      onDragOver={ev => ev.preventDefault()}
      onDrop={onDrop}>
      {img ? (
        <div className="w-full h-full">
          <ShowImage image={img} alt={`Imágen de ${img}`} />
        </div>
      ) : (
        <div
          className="w-full h-full border-2 border-dashed border-dewalt rounded-md
      flex flex-col justify-center items-center group hover:shadow-md transition-all">
          <MdOutlineImage className="w-6 h-6 text-dewalt" />
          <span className="text-sm text-neutral-400">Agregar</span>
        </div>
      )}
    </label>
  )
}

export default ImageDnD

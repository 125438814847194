import * as RadixToast from "@radix-ui/react-toast";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { MdDone, MdErrorOutline, MdInfoOutline, MdOutlineClose } from "react-icons/md";
import "./Toast.css";

const Toast = forwardRef((_, ref, duration = 3000) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [type, setType] = useState("info");
  const timerRef = useRef(0);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useImperativeHandle(ref, () => ({
    show(text, type) {
      setText(text);
      setType(type);
      setOpen(false);
      window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(() => {
        setOpen(true);
      }, 100);
    },
  }));

  return (
    <RadixToast.Provider swipeDirection="down" duration={duration}>
      <RadixToast.Root
        className={`ToastRoot px-3 py-2 xl:px-6 xl:py-4 ${
          type === "good" ? "bg-green-300" : type === "bad" ? "bg-red-300" : "bg-amber-200"
        } flex items-center gap-4 rounded-md shadow-lg`}
        open={open}
        onOpenChange={setOpen}
      >
        <div
          className={`w-6 h-6 ${
            type === "good" ? "text-green-600" : type === "bad" ? "text-red-600" : "text-amber-600"
          } text-sm select-none`}
        >
          {
            {
              good: <MdDone className="w-full h-full" />,
              bad: <MdErrorOutline className="w-full h-full" />,
              info: <MdInfoOutline className="w-full h-full" />,
            }[type]
          }
        </div>
        <RadixToast.Title className="w-0 grow text-xs xl:text-sm text-black text-start hyphens-none line-clamp-2">
          {text}
        </RadixToast.Title>
        <RadixToast.Action className="ToastAction" asChild altText="Cerrar">
          <button className="p-2 text-sm text-neutral-600 hover:text-black cursor-pointer transition-colors">
            <MdOutlineClose className="w-4 h-4" />
          </button>
        </RadixToast.Action>
      </RadixToast.Root>
      <RadixToast.Viewport className="fixed bottom-0 left-1/2 -translate-x-1/2 w-[600px] max-w-[100vw] m-0 flex flex-col gap-2 p-6 list-none z-[99999999999] outline-none" />
    </RadixToast.Provider>
  );
});

export default Toast;

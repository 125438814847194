import { useEffect } from 'react'
import {
  MdOutlineCategory,
  MdOutlineHome,
  MdOutlineInventory2,
  MdOutlineMiscellaneousServices,
  MdOutlineShoppingBag,
  MdPersonOutline,
} from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from '../../Sidebar'
import { setDocumentTitle } from '../../utils/Html'
import { useMainContext } from '../../utils/MainContext'

function AdminHome() {
  const {
    firebase: { user },
  } = useMainContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user || user.role === 'user') {
      navigate('/', { replace: true })
    }

    setDocumentTitle('Menú Admin')
  }, [user, navigate])

  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={true} />
      {user && (
        <div className="w-full xl:w-content-max-width mx-auto xl:px-10 pb-2 xl:pb-8">
          <div
            className="max-xl:px-2 mb-0 max-xl:mb-2 flex flex-col max-xl:bg-white
                     max-xl:border-b max-xl:border-gray-200 max-xl:shadow-sm">
            <h1 className="text-2xl max-xl:text-xl font-medium xl:my-8 max-xl:my-4">
              Menú {user.role === 'admin' ? 'Admin' : 'Operador'}
            </h1>
          </div>
          <div className="max-xl:p-2 flex flex-col gap-8">
            <div className="w-full flex flex-col gap-4">
              <span className="text-xl font-medium">Usuarios</span>
              <div className="w-full xl:h-40 flex max-xl:flex-col gap-4">
                <Feature
                  info={{
                    title: 'Gestión de Usuarios',
                    description: 'Bucar, ver información / pedidos de usuarios y manejar sus UT Coins.',
                    icon: <MdPersonOutline className="w-full h-full" />,
                    link: '/admin/users',
                  }}
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-4">
              <span className="text-xl font-medium">Ventas</span>
              <div className="w-full xl:h-40 flex max-xl:flex-col gap-4">
                <Feature
                  info={{
                    title: 'Pedidos',
                    description: 'Revisar y actualizar el estado de los pedidos.',
                    icon: <MdOutlineShoppingBag className="w-full h-full" />,
                    link: '/admin/orders',
                  }}
                />
                <Feature
                  info={{
                    title: 'Productos',
                    description:
                      user.role === 'admin'
                        ? 'Agregar, revisar y modificar los productos.'
                        : 'Revisar los productos disponibles.',
                    icon: <MdOutlineInventory2 className="w-full h-full" />,
                    link: '/admin/products',
                  }}
                />
              </div>
            </div>
            {user.role === 'admin' && (
              <div className="w-full flex flex-col gap-4">
                <span className="text-xl font-medium">Configuración</span>
                <div className="w-full xl:h-40 flex max-xl:flex-col gap-4">
                  <Feature
                    info={{
                      title: 'Página principal',
                      description: 'Modificar banners, categorías populares y secciones.',
                      icon: <MdOutlineHome className="w-full h-full" />,
                      link: '/admin/config/home',
                    }}
                  />
                  <Feature
                    info={{
                      title: 'Marcas / Categorías',
                      description: 'Agregar, revisar y modificar las marcas y las categorías.',
                      icon: <MdOutlineCategory className="w-full h-full" />,
                      link: '/admin/config/brands_categories',
                    }}
                  />
                </div>
                <div className="w-full xl:h-40 flex max-xl:flex-col gap-4">
                  <Feature
                    info={{
                      title: 'Variables / Facturación',
                      description:
                        'Modificar variables que afectan al flujo de compra y los datos de facturación que el comprador utiliza.',
                      icon: <MdOutlineMiscellaneousServices className="w-full h-full" />,
                      link: '/admin/config/vars',
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

function Feature({ info }) {
  const { title, description, icon, link } = info

  return (
    <Link
      to={link}
      className="w-full xl:w-0 xl:grow h-32 xl:h-full p-4 bg-white flex items-center gap-4 
                border-2 border-transparent hover:border-dewalt
                rounded-md shadow-sm hover:shadow-lg transition-all group">
      <div className="relative w-14 h-14 xl:w-20 xl:h-20 [&>*]:duration-200">
        <div
          className="w-full h-full rounded-full border-2 border-gray-200
                    bg-transparent group-hover:bg-dewalt
                    group-hover:border-transparent transition-all"
        />
        <div
          className="absolute top-0 left-0 w-full h-full p-3 text-gray-300
                    group-hover:text-white transition-all">
          {icon}
        </div>
      </div>
      <div className="max-xl:w-0 max-xl:grow flex flex-col justify-start xl:gap-2 hyphens-none">
        <span className="text-lg xl:text-xl font-medium">{title}</span>
        <span className="max-xl:text-sm text-neutral-600">{description}</span>
      </div>
    </Link>
  )
}

export default AdminHome

import Sidebar from "../../Sidebar";
import ViewOrder from "../ViewOrder";

function UserOrder({ summary }) {
  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={false} />
      <ViewOrder admin={false} summary={summary} />
    </div>
  );
}

export default UserOrder;

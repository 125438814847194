import axios from 'axios'
import { isEqual, uniqueId } from 'lodash'
import { createContext, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../../../Sidebar'
import { DeleteButton } from '../../../utils/forms/BannerDnD'
import Section from '../../../utils/forms/Section'
import TextInput from '../../../utils/forms/TextInput'
import { inputsToObj } from '../../../utils/forms/Utils'
import { deleteFile, fileExists, isImageFileValid, uploadFile, delay, setDocumentTitle } from '../../../utils/Html'
import InputLabel from '../../../utils/InputLabel'
import LoadingBox from '../../../utils/LoadingBox'
import { useMainContext } from '../../../utils/MainContext'
import { NewBrandImagePath } from '../../../utils/Strings'
import { AddImageLabel } from '../../../utils/forms/ImageDnD'
import {
  MdAdd,
  MdChevronRight,
  MdClose,
  MdDeleteOutline,
  MdDone,
  MdEdit,
  MdOutlineAdd,
  MdOutlineChevronRight,
} from 'react-icons/md'
import { useAbortableEffect } from '../../../utils/Hooks'
import DropdownInput from '../../../utils/forms/DropdownInput'
import DefaultAlertDialog from '../../../utils/DefaultAlertDialog'

const BrandsCategoriesContext = createContext({
  activeSectionState: [],
  errorsState: [],
  errorIdsState: [],
  loadingState: [],
  doneState: [],
})
const useBCContext = () => useContext(BrandsCategoriesContext)

function AdminBrandsCategories() {
  const { firebase, showToast } = useMainContext()
  const navigate = useNavigate()

  const [pageLoaded, setPageLoaded] = useState(false)
  const [brands, setBrands] = useState(null)
  const [categories, setCategories] = useState(null)

  const [activeSection, setActiveSection] = useState('')
  const [errors, setErrors] = useState([])
  const [errorIds, setErrorIds] = useState([])
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const brandsRef = useRef(null)
  const brandsInputsRef = useRef([])
  const categoriesRef = useRef([])
  const categoriesInputsRef = useRef([])

  useAbortableEffect(
    signal => {
      if (!firebase.user || firebase.user.role === 'user') {
        navigate('/', { replace: true })
      }
      setDocumentTitle('Modificar marcas / categorías')
      ;(async () => {
        setPageLoaded(false)
        setErrors([])
        setErrorIds([])
        setActiveSection('')
        setLoading(false)
        setDone(false)

        try {
          let result = await axios.get(process.env.REACT_APP_API_LINK + 'get_brands', {
            params: {
              user_id: firebase.user?.uid,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)
          setBrands(result.data.brands)

          result = await axios.get(process.env.REACT_APP_API_LINK + 'get_categories', {
            params: {
              products_amount: true,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)
          setCategories(result.data.categories)

          setPageLoaded(true)
        } catch (err) {
          if (err.code === 'ERR_CANCELED') return
          // console.log(err)
          showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
        }
      })()
    },
    [firebase.user, navigate, showToast]
  )

  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={true} />
      <div className="w-full xl:w-content-max-width mx-auto flex">
        {pageLoaded ? (
          <div className="w-full px-2 xl:px-60 pb-8">
            <div className="my-8 max-xl:my-4">
              <h1 className="text-xl xl:text-2xl font-medium">Marcas / Categorías</h1>
            </div>
            <div className="w-full flex flex-col gap-4 xl:gap-6">
              <BrandsCategoriesContext.Provider
                value={{
                  activeSectionState: [activeSection, setActiveSection],
                  errorsState: [errors, setErrors],
                  errorIdsState: [errorIds, setErrorIds],
                  loadingState: [loading, setLoading],
                  doneState: [done, setDone],
                }}>
                <SectionContainer
                  ref={brandsRef}
                  info={{
                    title: 'Marcas',
                    content: (
                      <BrandsSection
                        ref={el => (brandsInputsRef.current[0] = el)}
                        object={brands}
                        onChange={() => brandsRef.current.handleInputChange()}
                      />
                    ),
                    errors: errors.filter(e => e.input === 'brands'),
                  }}
                  inputsRef={brandsInputsRef}
                  state={brands}
                  setState={setBrands}
                />
                <SectionContainer
                  ref={categoriesRef}
                  info={{
                    title: 'Categorías',
                    content: (
                      <CategoriesSection
                        ref={el => (categoriesInputsRef.current[0] = el)}
                        object={categories}
                        onChange={() => categoriesRef.current.handleInputChange()}
                      />
                    ),
                    errors: errors.filter(e => e.input === 'categories'),
                  }}
                  inputsRef={categoriesInputsRef}
                  state={categories}
                  setState={setCategories}
                />
              </BrandsCategoriesContext.Provider>
            </div>
          </div>
        ) : (
          <div className="w-full xl:px-60 pb-8">
            <LoadingBox text={'Cargando configuración...'} />
          </div>
        )}
      </div>
    </div>
  )
}

const SectionContainer = forwardRef(({ info, inputsRef, state, setState }, ref) => {
  const { firebase, showToast } = useMainContext()
  const [activeSection, setActiveSection] = useBCContext().activeSectionState
  const [, setErrors] = useBCContext().errorsState
  const [, setErrorsIds] = useBCContext().errorIdsState
  const [loading, setLoading] = useBCContext().loadingState
  const [done, setDone] = useBCContext().doneState
  const [allowActions, setAllowActions] = useState(false)

  useImperativeHandle(ref, () => ({
    send: async () => await handleSend(),
    handleInputChange: () => {
      // setErrors([])
      // setErrorsIds([])
      setAllowActions(true)
      setActiveSection(info.title)
    },
  }))

  const handleCancel = () => {
    inputsRef.current.forEach(r => r.reset())
    setErrors([])
    setErrorsIds([])
    setAllowActions(false)
    setActiveSection('')
  }

  const handleSend = async () => {
    const params = inputsToObj(inputsRef)

    // console.log(params)

    if (!isEqual(params, {})) {
      try {
        setLoading(true)

        // console.log({ params })

        if (params.brands) {
          const items = params.brands

          const errs = []

          if (items.some(item => item.name === '')) {
            errs.push({ input: 'brands', text: 'Hay una o más marcas sin nombre.' })
          }

          if (items.some(item => item.image === '')) {
            errs.push({ input: 'brands', text: 'Hay una o más marcas sin imágen.' })
          }

          if (errs.length > 0) {
            setErrors(errs)
            setLoading(false)
            showToast(errs.length === 1 ? errs[0].text : 'Hay errores en el formulario.', 'bad')
            return
          }

          const finalItems = []

          for (let item of items) {
            if (item.remove) {
              // Si  tiene la key 'remove', significa que es un item borrado y se borra de Firebase
              let path = item.image?.includes('universal-tools-web.appspot.com')
                ? item.image?.split('?')[0].split('universal-tools-web.appspot.com')[1].split('/').at(-1)
                : undefined
              if (path) {
                // Si path no es 'undefined', significa que es una imagen subida a Firebase
                path = decodeURIComponent(path)
                if (await fileExists(firebase.storage, path)) {
                  await deleteFile(firebase.storage, path)
                  // console.log(`Se borró imagen: ${path}`)
                }
              }

              if (item.brand_id.length === 20) {
                // Una vez q se borro la imagen, borro el item
                const result = await axios.put(
                  process.env.REACT_APP_API_LINK + 'update_brands',
                  {
                    user_id: firebase.user?.uid,
                    action: 'delete',
                    brand_id: item.brand_id,
                    name: '',
                    image: '',
                    type: '',
                  },
                  { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
                )
                // console.log(result)
              }

              continue
            }

            if (item.blob) {
              // Si el objeto 'image' tiene la key 'blob', significa que es una imágen agregada y se sube a Firebase
              let tryCount = 0
              let newPath = NewBrandImagePath(item.blob.type.split('/')[1])
              while (await fileExists(firebase.storage, newPath)) {
                // Si existe un archivo con el path pasado, creo un nuevo path y lo intento de nuevo
                newPath = NewBrandImagePath(item.blob.type.split('/')[1])

                tryCount++
                if (tryCount >= 10) {
                  setErrors({ input: 'images', text: 'Error al subir una o más fotos' })
                  return
                }
              }

              const src = await uploadFile(firebase.storage, item.blob, newPath)
              // console.log(`Nueva imagen: ${src}`)

              item.image = src
              delete item.blob
            }

            if (item.add) {
              delete item.add
              delete item.brand_id
              delete item.update

              const result = await axios.post(
                process.env.REACT_APP_API_LINK + 'create_brand',
                {
                  user_id: firebase.user?.uid,
                  brand: item,
                },
                { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
              )

              // console.log(result)
              item = result.data.brand
            } else if (item.update) {
              delete item.update

              const result = await axios.put(
                process.env.REACT_APP_API_LINK + 'update_brands',
                {
                  user_id: firebase.user?.uid,
                  action: 'update',
                  ...item,
                },
                { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
              )
              // console.log(result)
            }

            finalItems.push(item)
          }

          // console.log(finalItems)
          setState(finalItems)
        }

        if (params.categories) {
          const items = params.categories

          const errs = []
          const errIds = []

          const noNameCategories = items.filter(item => !item.remove && item.name === '')
          if (noNameCategories.length > 0) {
            errIds.push(...noNameCategories.map(item => item.category_id))
            errs.push({ input: 'categories', text: 'Hay una o más categorías sin nombre.' })
          }

          const noNameSubCategories = items.reduce(
            (acc, item) => [
              ...acc,
              ...(!item.remove
                ? item.children.some(child => !child.remove && child.name === '')
                  ? [item, ...item.children.filter(child => !child.remove && child.name === '')]
                  : []
                : []),
            ],
            []
          )
          if (noNameSubCategories.length > 0) {
            errIds.push(...noNameSubCategories.map(item => item.category_id))
            errs.push({ input: 'categories', text: 'Hay una o más sub-categorías sin nombre.' })
          }

          const emptyCategories = items.filter(
            item =>
              !item.remove &&
              isEqual(
                item.children.filter(child => !child.remove),
                []
              )
          )
          if (emptyCategories.length > 0) {
            errIds.push(...emptyCategories.map(item => item.category_id))
            errs.push({ input: 'categories', text: 'Hay una o más categorías sin sub-categorías.' })
          }

          if (errs.length > 0) {
            setErrors(errs)
            setErrorsIds(errIds)
            setLoading(false)
            showToast(errs.length === 1 ? errs[0].text : 'Hay errores en el formulario.', 'bad')
            return
          }

          const finalItems = []

          const checkCategoryItem = async (item, parent_id) => {
            if (item.remove) {
              if (item.category_id.length === 20) {
                // Una vez q se borro la imagen, borro el item
                const result = await axios.put(
                  process.env.REACT_APP_API_LINK + 'update_categories',
                  {
                    user_id: firebase.user?.uid,
                    action: 'delete',
                    category_id: item.category_id,
                    parent_id: parent_id,
                    name: '',
                    // sure: true,
                  },
                  { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
                )
                // console.log(result)

                // if (result.data.products_to_update && result.data.products_to_update.length > 0) {
                //   // TODO: mostrar popup para avisar que hay productos en la categoria y llamar de nuevo con 'sure: true'
                // }
              }
              return null
            }

            if (item.add) {
              delete item.add
              delete item.category_id
              delete item.update

              const result = await axios.post(
                process.env.REACT_APP_API_LINK + 'create_category',
                {
                  user_id: firebase.user?.uid,
                  category: { ...item, children: [] },
                  parent_id: parent_id,
                },
                { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
              )

              // console.log(result)
              item = { ...result.data.category, children: item.children, products: 0 }
            } else if (item.update) {
              delete item.update

              const result = await axios.put(
                process.env.REACT_APP_API_LINK + 'update_categories',
                {
                  user_id: firebase.user?.uid,
                  action: 'update',
                  category_id: item.category_id,
                  parent_id: parent_id,
                  name: item.name,
                },
                { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
              )
              // console.log(result)
            }

            return item
          }

          for (let item of items) {
            item = await checkCategoryItem(item, '')

            if (item === null) continue

            let children = []
            for (let child of item.children) {
              child = await checkCategoryItem(child, item.category_id)

              if (child === null) continue

              children.push(child)
            }
            item.children = children

            finalItems.push(item)
          }

          // console.log(finalItems)
          setState(finalItems)
        }

        setLoading(false)
        setDone(true)

        showToast('¡Listo! Los cambios estan guardados.', 'good')

        await delay(1000)

        setDone(false)
        setAllowActions(false)
        setActiveSection('')
      } catch (err) {
        // console.log(err)
        setErrors([{ input: 'unkn', text: 'Ocurrió un error desconocido.' }])
        setLoading(false)
        showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
      }
    } else {
      setLoading(false)
      setDone(false)
      setAllowActions(false)
      setActiveSection('')
    }
  }

  return (
    <Section
      info={{
        ...info,
        allowActions,
        loading,
        done,
        handleCancel,
        handleSend,
        modifiable: activeSection === '' || activeSection === info.title,
      }}
    />
  )
})

const BrandsSection = forwardRef(({ object, onChange }, ref) => {
  const { showToast } = useMainContext()
  const [defaultValue, setDefaultValue] = useState(object)
  const [items, setItems] = useState(defaultValue)
  const itemsRef = useRef([])

  useEffect(() => {
    setDefaultValue(object)
    setItems(object)
  }, [object])

  useImperativeHandle(ref, () => ({
    value: items,
    reset: () => {
      itemsRef.current.forEach(item => item?.reset())
      setItems(defaultValue)
    },
    valueChanged: !isEqual(items, defaultValue),
    toSend: () => ({ brands: items }),
  }))

  const handleFile = (file, item) => {
    // console.log(file)
    if (
      isImageFileValid(file, null, err => {
        if (err) {
          if (err === 'type') showToast('El formato de imágen no es válido.', 'bad')
          else showToast('Error inesperado al leer la imágen.', 'bad')
          return
        }
        setItems(
          items.map(it =>
            it.brand_id === item.brand_id ? { ...item, blob: file, image: URL.createObjectURL(file), update: true } : it
          )
        )
        onChange()
      })
    ) {
    }
  }

  const handleNewItem = () => {
    const id = uniqueId()
    setItems([...items, { brand_id: id, image: '', name: '', type: 'domestic', add: true }])
    setTimeout(() => {
      const node = document.getElementById(`brand-${id}`)
      if (node) node.scrollIntoView()
    }, 100)
    onChange()
  }

  return (
    <div className="w-full flex flex-col">
      <button
        type="button"
        className="w-full py-2 text-gray-500 flex justify-center items-center rounded-md border border-gray-200 hover:border-gray-500"
        onClick={handleNewItem}>
        <MdOutlineAdd className="w-5 h-5 mr-1" />
        Agregar marca
      </button>
      {items
        .filter(brand => !brand.remove)
        .map((brand, index) => {
          return (
            <div
              id={`brand-${brand.brand_id}`}
              key={brand.brand_id}
              className="w-full py-4 xl:py-2 flex items-center gap-4 group border-b last:border-none border-gray-200">
              <div className="w-24 h-24">
                <AddImageLabel htmlFor={`picture-${brand.brand_id}`} img={brand.image} />
                <input
                  type="file"
                  id={`picture-${brand.brand_id}`}
                  accept="image/jpeg, image/png, image/webp"
                  className="hidden"
                  onChange={ev => {
                    handleFile(ev.target.files[0], brand)
                  }}
                />
              </div>
              <div className="flex-1 flex max-xl:flex-col max-xl:items-stretch items-center gap-2 xl:gap-4">
                <div className="flex-1 flex flex-col">
                  <InputLabel text="Nombre" />
                  <TextInput
                    object={brand}
                    name={'name'}
                    placeholder={'Nombre de la marca'}
                    onChange={value => {
                      setItems(
                        items.map(item =>
                          item.brand_id === brand.brand_id ? { ...brand, name: value, update: true } : item
                        )
                      )
                      onChange()
                    }}
                  />
                </div>
                <div className="flex-1 flex flex-col">
                  <InputLabel text="Tipo" />
                  <div className="w-full h-12">
                    <DropdownInput
                      ref={el => (itemsRef.current[index + 0] = el)}
                      object={object.find(item => item.brand_id === brand.brand_id) ?? brand}
                      name={'type'}
                      items={[
                        {
                          text: 'Doméstica',
                          value: 'domestic',
                        },
                        {
                          text: 'Industrial',
                          value: 'industrial',
                        },
                        {
                          text: 'Profesional',
                          value: 'professional',
                        },
                      ]}
                      onChange={value => {
                        setItems(
                          items.map(item =>
                            item.brand_id === brand.brand_id ? { ...brand, type: value, update: true } : item
                          )
                        )
                        onChange()
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:pt-4">
                <DeleteButton
                  onClick={() => {
                    setItems(items.map(item => (item.brand_id === brand.brand_id ? { ...brand, remove: true } : item)))
                    onChange()
                  }}
                />
              </div>
            </div>
          )
        })}
    </div>
  )
})

const CategoriesSection = forwardRef(({ object, onChange }, ref) => {
  const [errorIds] = useBCContext().errorIdsState

  const [defaultValue, setDefaultValue] = useState(object)
  const [items, setItems] = useState(defaultValue)

  const [category, setCategory] = useState(null)

  const [editable, setEditable] = useState('')

  useEffect(() => {
    setDefaultValue(object)
    setItems(object)
  }, [object])

  useImperativeHandle(ref, () => ({
    value: items,
    reset: () => {
      setItems(defaultValue)
      setEditable('')
      setCategory(null)
    },
    valueChanged: !isEqual(items, defaultValue),
    toSend: () => ({ categories: items }),
  }))

  return (
    <div className="w-full flex max-xl:flex-col gap-4 max-xl:items-center">
      <div className="w-full xl:w-0 xl:grow">
        <InputLabel text="Categoría" />
        <div className="w-full h-60 bg-gray-200 rounded-md border border-gray-200 flex flex-col overflow-hidden overflow-y-auto">
          {items
            .filter(item => !item.remove)
            .toSorted((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
            .map(item => (
              <div
                key={item.category_id}
                className="w-full pl-2 pr-4 py-2 bg-white border-b border-gray-200 last:border-b-0 flex gap-2 items-center">
                <div className="w-0 grow relative">
                  {!errorIds.includes(item.category_id) ? (
                    category === item.category_id && (
                      <div className="absolute top-1/2 -translate-y-1/2 left-0 -translate-x-1/2">
                        <MdChevronRight className="w-5 h-5 text-gray-500" />
                      </div>
                    )
                  ) : (
                    <div className="absolute top-1/2 -translate-y-1/2 left-0 -translate-x-1/2">
                      <MdChevronRight className="w-5 h-5 text-red-500" />
                    </div>
                  )}
                  <input
                    type="text"
                    id={`${item.category_id}-input`}
                    disabled={editable !== item.category_id}
                    className={`w-full px-2 py-1 border-b-2 border-b-gray-200 disabled:border-b-0 outline-none peer
                              ${category === item.category_id ? 'disabled:font-bold' : ''} ${
                      errorIds.includes(item.category_id) ? 'text-red-500' : ''
                    }`}
                    value={`${
                      editable !== item.category_id
                        ? `(${item.children?.reduce((acc, child) => acc + child.products, 0)}) `
                        : ''
                    }${item.name}`}
                    onChange={ev => {
                      setItems(
                        items.map(it => (it.category_id === item.category_id ? { ...it, name: ev.target.value } : it))
                      )
                    }}
                    maxLength={30}
                  />
                  <div
                    className="absolute top-0 left-0 w-full h-full pointer-events-none cursor-auto
                              peer-disabled:pointer-events-auto peer-disabled:cursor-pointer"
                    onClick={() => setCategory(item.category_id)}
                  />
                </div>
                <div className="flex gap-2">
                  {editable === item.category_id ? (
                    <>
                      <button
                        type="button"
                        title="Cancelar"
                        onClick={() => {
                          setItems(
                            items.map(it =>
                              it.category_id === item.category_id
                                ? {
                                    ...it,
                                    name:
                                      defaultValue?.find(it => it.category_id === item.category_id)?.name ?? it.name,
                                  }
                                : it
                            )
                          )
                          setEditable('')
                        }}>
                        <MdClose className="w-full h-full text-gray-500 hover:text-black transition-colors" />
                      </button>
                      <button
                        type="button"
                        title="Guardar"
                        onClick={() => {
                          setItems(
                            items.map(it => (it.category_id === item.category_id ? { ...it, update: true } : it))
                          )
                          setEditable('')
                          onChange()
                        }}>
                        <MdDone className="w-full h-full text-gray-500 hover:text-black transition-colors" />
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="button" title="Editar" onClick={() => setEditable(item.category_id)}>
                        <MdEdit className="w-full h-full text-gray-500 hover:text-black transition-colors" />
                      </button>
                      {item.children && item.children.length > 0 && item.children.some(child => child.products) ? (
                        <DefaultAlertDialog
                          title={'Eliminar categoría'}
                          content={
                            <p>
                              La categoría {item.name} tiene{' '}
                              {`${item.children.reduce((acc, child) => acc + child.products, 0)} producto${
                                item.children.reduce((acc, child) => acc + child.products, 0) > 1 ? 's' : ''
                              }`}
                              . ¿Estás seguro/a?
                            </p>
                          }
                          submit={{
                            text: 'Aceptar',
                            onClick: () => {
                              setItems(
                                items.map(it => (it.category_id === item.category_id ? { ...it, remove: true } : it))
                              )
                              if (category === item.category_id) setCategory(null)
                              onChange()
                            },
                          }}
                          trigger={
                            <button type="button" title="Eliminar" className="w-5 h-5">
                              <MdDeleteOutline className="w-full h-full text-gray-500 hover:text-black transition-colors" />
                            </button>
                          }
                        />
                      ) : (
                        <button
                          type="button"
                          title="Eliminar"
                          onClick={() => {
                            setItems(
                              items.map(it => (it.category_id === item.category_id ? { ...it, remove: true } : it))
                            )
                            if (category === item.category_id) setCategory(null)
                            onChange()
                          }}
                          className="w-5 h-5">
                          <MdDeleteOutline className="w-full h-full text-gray-500 hover:text-black transition-colors" />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="w-full">
          <button
            type="button"
            className="w-full py-2 flex justify-center items-center text-gray-500 hover:text-black transition-colors"
            onClick={async () => {
              const id = uniqueId()
              setItems([
                ...items,
                {
                  category_id: id,
                  name: '',
                  children: [],
                  add: true,
                },
              ])
              setEditable(id)
              await delay(50)
              document.getElementById(`${id}-input`)?.focus()
            }}>
            <MdAdd className="w-5 h-5 inline" /> Agregar categoría
          </button>
        </div>
      </div>
      <MdOutlineChevronRight className="w-6 h-6 max-xl:-mb-4 xl:mt-8 text-neutral-400 max-xl:rotate-90" />
      <div className="w-full xl:w-0 xl:grow">
        <InputLabel text="Sub-categorías" />
        {category && items.find(item => item.category_id === category) ? (
          <>
            <div className="w-full h-60 bg-gray-200 rounded-md border border-gray-200 flex flex-col overflow-hidden overflow-y-auto">
              {items
                .find(item => item.category_id === category)
                .children.filter(item => !item.remove)
                .map(item => (
                  <div
                    key={item.category_id}
                    className="w-full pl-2 pr-4 py-2 bg-white border-b border-gray-200 last:border-b-0 flex gap-2 items-center">
                    <div className="w-0 grow relative">
                      {errorIds.includes(item.category_id) && (
                        <div className="absolute top-1/2 -translate-y-1/2 left-0 -translate-x-1/2">
                          <MdChevronRight className="w-5 h-5 text-red-500" />
                        </div>
                      )}
                      <input
                        type="text"
                        disabled={editable !== item.category_id}
                        className="w-full px-2 py-1 border-b-2 border-b-gray-200 disabled:border-b-0 outline-none peer"
                        value={`${editable !== item.category_id ? `(${item.products}) ` : ''}${item.name}`}
                        onChange={ev => {
                          setItems(
                            items.map(it =>
                              it.category_id === category
                                ? {
                                    ...it,
                                    children: it.children.map(child =>
                                      child.category_id === item.category_id
                                        ? { ...child, name: ev.target.value }
                                        : child
                                    ),
                                  }
                                : it
                            )
                          )
                        }}
                      />
                    </div>
                    <div className="flex gap-2">
                      {editable === item.category_id ? (
                        <>
                          <button
                            type="button"
                            title="Cancelar"
                            onClick={() => {
                              setItems(
                                items.map(it =>
                                  it.category_id === category
                                    ? {
                                        ...it,
                                        children: it.children.map(child =>
                                          child.category_id === item.category_id
                                            ? {
                                                ...child,
                                                name:
                                                  defaultValue
                                                    .find(it => it.category_id === category)
                                                    ?.children?.find(it => it.category_id === item.category_id)?.name ??
                                                  child.name,
                                              }
                                            : child
                                        ),
                                      }
                                    : it
                                )
                              )
                              setEditable('')
                            }}>
                            <MdClose className="w-full h-full text-gray-500 hover:text-black transition-colors" />
                          </button>
                          <button
                            type="button"
                            title="Guardar"
                            onClick={() => {
                              setItems(
                                items.map(it =>
                                  it.category_id === category
                                    ? {
                                        ...it,
                                        children: it.children.map(child =>
                                          child.category_id === item.category_id ? { ...child, update: true } : child
                                        ),
                                      }
                                    : it
                                )
                              )
                              setEditable('')
                              onChange()
                            }}>
                            <MdDone className="w-full h-full text-gray-500 hover:text-black transition-colors" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button type="button" title="Editar" onClick={() => setEditable(item.category_id)}>
                            <MdEdit className="w-full h-full text-gray-500 hover:text-black transition-colors" />
                          </button>
                          {item.products ? (
                            <DefaultAlertDialog
                              title={'Eliminar sub-categoría'}
                              content={
                                <p>
                                  La sub-categoría {item.name} tiene{' '}
                                  {`${item.products} producto${item.products > 1 ? 's' : ''}`}. ¿Estás seguro/a?
                                </p>
                              }
                              submit={{
                                text: 'Aceptar',
                                onClick: () => {
                                  setItems(
                                    items.map(it =>
                                      it.category_id === category
                                        ? {
                                            ...it,
                                            children: it.children.map(child =>
                                              child.category_id === item.category_id
                                                ? { ...child, remove: true }
                                                : child
                                            ),
                                          }
                                        : it
                                    )
                                  )
                                  if (category === item.category_id) setCategory(null)
                                  onChange()
                                },
                              }}
                              trigger={
                                <button type="button" title="Eliminar" className="w-5 h-5">
                                  <MdDeleteOutline className="w-full h-full text-gray-500 hover:text-black transition-colors" />
                                </button>
                              }
                            />
                          ) : (
                            <button
                              type="button"
                              title="Eliminar"
                              onClick={() => {
                                setItems(
                                  items.map(it =>
                                    it.category_id === category
                                      ? {
                                          ...it,
                                          children: it.children.map(child =>
                                            child.category_id === item.category_id ? { ...child, remove: true } : child
                                          ),
                                        }
                                      : it
                                  )
                                )
                                onChange()
                              }}
                              className="w-5 h-5">
                              <MdDeleteOutline className="w-full h-full text-gray-500 hover:text-black transition-colors" />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <div className="w-full">
              <button
                type="button"
                className="w-full py-2 flex justify-center items-center text-gray-500 hover:text-black transition-colors"
                onClick={() => {
                  const id = uniqueId()
                  setItems(
                    items.map(it =>
                      it.category_id === category
                        ? {
                            ...it,
                            children: [
                              ...it.children,
                              {
                                category_id: id,
                                name: '',
                                products: 0,
                                add: true,
                              },
                            ],
                          }
                        : it
                    )
                  )
                  setEditable(id)
                }}>
                <MdAdd className="w-5 h-5 inline" /> Agregar sub-categoría
              </button>
            </div>
          </>
        ) : (
          <span className="pl-2 text-gray-500">Seleccioná una categoría</span>
        )}
      </div>
    </div>
  )
})

export default AdminBrandsCategories

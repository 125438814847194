import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { BsPersonCircle } from 'react-icons/bs'
import { MdError, MdOutlineVerifiedUser, MdClose } from 'react-icons/md'
import { RiRotateLockFill } from 'react-icons/ri'
import { useMainContext } from '../utils/MainContext'
import {
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from 'firebase/auth'
import { AES, enc } from 'crypto-js'
import axios from 'axios'
import ButtonWithProgress from '../utils/ButtonWithProgress'
import { setDocumentTitle, removeCookie, saveCookie, delay } from '../utils/Html'
import { CapitalizeAllWords } from '../utils/Strings'

function Login({ step }) {
  const { firebase, setFirebase } = useMainContext()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [showVerified, setShowVerified] = useState(false)

  const [form, setForm] = useState({
    email: '',
    password: '',
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ input: null, code: null, text: null })

  useEffect(() => {
    if (firebase.user) navigate('/', { replace: true })

    setError({ input: null, code: null, text: null })
    setDocumentTitle('Iniciar sesión')

    if (searchParams.has('p')) {
      try {
        const encryptedData = decodeURIComponent(searchParams.get('p'))
        const str = AES.decrypt(encryptedData, 'xd').toString(enc.Utf8)
        const decryptedData = JSON.parse(str)

        // ue = User E-mail (mail para mostrar en el input de /login/user)
        // fse = First Step E-mail (viene de continuar en /login/user)
        setForm(f => ({ ...f, email: decryptedData['ue'] ?? decryptedData['fse'] ?? f.email }))

        // v = Verified (viene de link de verificación)
        setShowVerified('v' in decryptedData && decryptedData['v'] === 1)
      } catch (err) {
        // console.log(err)
        navigate('/login/user', { replace: true })
      }
    }
  }, [searchParams]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleCredentialsLogin = async ev => {
    ev.preventDefault()
    if (step === 'email' && form.email !== '') {
      if (new RegExp(process.env.REACT_APP_REGEX_EMAIL).test(form.email)) {
        setLoading(true)
        await delay(1000)
        try {
          const methods = await fetchSignInMethodsForEmail(firebase.auth, form.email)

          setLoading(false)

          if (methods.length > 0) {
            const data = { fse: form.email }
            const encryptedData = AES.encrypt(JSON.stringify(data), 'xd').toString()

            navigate(`/login/pass?p=${encodeURIComponent(encryptedData)}`)
          } else {
            setError({ input: 'email', text: 'No existe un usuario con ese e-mail' })
          }
        } catch (e) {
          setLoading(false)
          switch (e.code) {
            case 'auth/invalid-email':
              setError({ input: 'email', code: e.code, text: 'E-mail inválido' })
              break
            default:
              setError({ input: 'email', code: e.code, text: e.message })
              // TODO: Mandar error a DB
              break
          }
          // console.log(e.message)
        }
      } else {
        setError({ input: 'email', code: '', text: 'El e-mail ingresado es inválido' })
      }
    } else if (step === 'password' && form.password !== '') {
      setLoading(true)
      try {
        const result = await signInWithEmailAndPassword(firebase.auth, form.email, form.password)
        // Signed in
        // Esto lo hago para conseguir un objeto limpio de user y poder asignarle "ut_coins" abajo
        // console.log('SE GUARDA USER')
        const resultUser = result.user
        const user = JSON.parse(JSON.stringify(resultUser))
        delete user.stsTokenManager
        delete user.providerData
        delete user.appName

        try {
          const loginResult = await axios.get(process.env.REACT_APP_API_LINK + 'login_user', {
            params: { user_id: user.uid },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
          })
          // console.log(loginResult)

          const data = loginResult.data

          user.role = data.role
          user.ut_coins = data.ut_coins
          saveCookie('user', user, true)

          user.cart = data.cart
          saveCookie('cart', data.cart)

          user.favs = data.favs
          saveCookie('favs', data.favs)
        } catch (err) {
          // console.log(err)
          if (err.response.data.code === 0) {
            // console.log('Usuario en Firebase pero no en base, registrando.')
            try {
              let [name, ...last_name] = user.displayName.split(' ')
              name = CapitalizeAllWords(name)
              last_name = CapitalizeAllWords(last_name.join(' '))

              const registerResult = await axios.post(
                process.env.REACT_APP_API_LINK + 'register_user',
                {
                  uid: user.uid,
                  name,
                  last_name,
                  area_code: '',
                  phone_number: '',
                  email: user.email,
                  cuit: '',
                  google: true,
                },
                {
                  headers: {
                    'X-Api-Key': process.env.REACT_APP_API_KEY,
                  },
                }
              )
              // console.log(registerResult)

              const displayName = `${name}_${last_name}`

              await updateProfile(resultUser, {
                displayName,
              })

              user.role = 'user'
              user.ut_coins = 0
              user.displayName = displayName
              saveCookie('user', user, true)

              user.cart = []
              saveCookie('cart', [])

              user.favs = []
              saveCookie('favs', [])
            } catch (err) {
              // console.log('Erorr al registrar usuario.')
              // console.log(err)
              setError({
                input: 'login',
                code: err.code,
                text: 'Error al conectarse con el servidor: loginreg',
              })
              // TODO: Mandar error a DB
            }
          } else {
            // console.log('Error al logear usuario.')
            // console.log(err)
            setError({
              input: 'login',
              code: err.code,
              text: 'Error al conectarse con el servidor: login',
            })
            // TODO: Mandar error a DB
          }
        }

        setLoading(false)
        // Si el usuario todavía no validó el e-mail, muestro un error
        // y cierro la sesión
        // console.log(user)
        if (user.emailVerified) {
          setFirebase({ ...firebase, user: user })

          await delay(1000)

          navigate('/')
        } else {
          setError({
            input: 'email',
            code: 'auth/email-not-verified', // Este codigo lo invente yo
            text: 'El e-mail ingresado todavía no está verificado',
          })

          removeCookie('user')
          removeCookie('cart')
          removeCookie('favs')

          firebase.auth.signOut()
        }
      } catch (err) {
        setLoading(false)
        switch (err.code) {
          case 'auth/user-not-found':
            setError({
              input: 'login',
              code: err.code,
              text: 'No existe un usuario con ese e-mail',
            })
            break
          case 'auth/invalid-email':
            setError({
              input: 'email',
              code: err.code,
              text: 'El e-mail ingresado es inválido',
            })
            break
          case 'auth/wrong-password':
            setError({
              input: 'password',
              code: err.code,
              text: 'La contraseña ingresada es incorrecta',
            })
            break
          case 'auth/too-many-requests':
            setError({
              input: 'login',
              code: err.code,
              text: 'Demasiados intentos, espere unos minutos',
            })
            break
          default:
            setError({
              input: 'login',
              code: err.code,
              text: 'Ocurrió un error desconocido al inciar sesión',
            })
            // TODO: Mandar error a DB
            break
        }
        // console.log(err)
      }
    }
  }

  const handleGoogleLogin = async () => {
    try {
      // Muestro un popup para que el usuario inicie sesión con Google
      const result = await signInWithPopup(firebase.auth, new GoogleAuthProvider())
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const token = GoogleAuthProvider.credentialFromResult(result).accessToken;
      const resultUser = result.user
      const user = JSON.parse(JSON.stringify(resultUser))
      delete user.stsTokenManager
      delete user.providerData
      delete user.appName

      try {
        setLoading(true)
        const loginResult = await axios.get(process.env.REACT_APP_API_LINK + 'login_user', {
          params: { user_id: user.uid },
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
        })
        // console.log(loginResult)

        const data = loginResult.data
        user.role = data.role
        user.ut_coins = data.ut_coins
        saveCookie('user', user, true)

        user.cart = data.cart
        saveCookie('cart', data.cart)
        user.favs = data.favs
        saveCookie('favs', data.favs)

        setFirebase({ ...firebase, user: user })
        navigate('/')
        // IdP data available using getAdditionalUserInfo(result)
      } catch (err) {
        // console.log(err)
        if (err.response.data.code === 0) {
          try {
            let [name, ...last_name] = user.displayName.split(' ')
            name = CapitalizeAllWords(name)
            last_name = CapitalizeAllWords(last_name.join(' '))

            const registerResult = await axios.post(
              process.env.REACT_APP_API_LINK + 'register_user',
              {
                uid: user.uid,
                name,
                last_name,
                area_code: '',
                phone_number: '',
                email: user.email,
                cuit: '',
                google: true,
              },
              {
                headers: {
                  'X-Api-Key': process.env.REACT_APP_API_KEY,
                },
              }
            )
            // console.log(registerResult)

            const displayName = `${name}_${last_name}`

            await updateProfile(resultUser, {
              displayName,
            })

            user.role = 'user'
            user.ut_coins = 0
            user.displayName = displayName
            saveCookie('user', user, true)

            user.cart = []
            saveCookie('cart', [])
            user.favs = []
            saveCookie('favs', [])

            setFirebase({ ...firebase, user: user })
            navigate('/')
            // IdP data available using getAdditionalUserInfo(result)
          } catch (err) {
            // console.log(err)
            setLoading(false)
            setError({
              input: 'login',
              code: err.code,
              text: 'Error al conectarse con el servidor: gloginreg',
            })
            // TODO: Mandar error a DB
          }
        } else {
          setLoading(false)
          setError({
            input: 'login',
            code: err.code,
            text: 'Error al conectarse con el servidor: glogin',
          })
          // TODO: Mandar error a DB
        }
      }
    } catch (err) {
      setLoading(false)
      switch (err.code) {
        case 'auth/popup-blocked':
          setError({
            input: 'login',
            code: err.code,
            text: 'Habilitá los pop-ups para poder acceder con Google',
          })
          break
        case 'auth/popup-closed-by-user':
        case 'auth/cancelled-popup-request':
          // console.log(err)
          return
        default:
          setError({
            input: 'login',
            code: err.code,
            text: 'Error al conectarse con el servidor: gloginauth',
          })
          // TODO: Mandar error a DB
          break
      }
    }
  }

  const handleForgotPass = async () => {
    try {
      setLoading(true)
      const data = { ue: form.email }
      const encryptedData = AES.encrypt(JSON.stringify(data), 'xd').toString()

      await sendPasswordResetEmail(firebase.auth, form.email, {
        url: `${window.location.origin}/login/user?p=${encodeURIComponent(encryptedData)}`,
        handleCodeInApp: true,
      })

      setLoading(false)
      navigate(`/login/forgot?p=${encodeURIComponent(encryptedData)}`)
    } catch (err) {
      setLoading(false)
      setError({
        input: 'login',
        code: err.code ?? null,
        text: err.message ?? 'Error desconocido',
      })
      // TODO: Mandar error a DB
      // console.log(err)
    }
  }

  const handleInputChange = ev => {
    setError({ input: null, code: null, text: null })
    setForm(f => ({ ...f, [ev.target.name]: ev.target.value }))
  }

  return (
    <section className="w-full grow bg-white xl:bg-pagebg">
      <div className="w-full p-4 max-lg:pb-12 lg:w-[900px] lg:mx-auto lg:py-12 flex flex-col lg:flex-row text-black">
        <div className="w-full lg:w-0 lg:grow">
          <h1 className="text-2xl xl:text-4xl xl:font-light pb-4 hyphens-none">
            Ingresá tu {step === 'email' ? 'e-mail' : step === 'password' ? 'contraseña' : '?'}
          </h1>
          {step === 'password' && (
            <div
              className="w-72 h-12 mb-3 py-2 flex items-center
                         bg-white rounded-full border border-gray-200 shadow-sm">
              <BsPersonCircle className="w-6 h-6 mx-4 text-neutral-400" />
              <div className="grow flex flex-col">
                <span className="text-xs tracking-wide">{form.email}</span>
                <Link className="w-max text-xs font-medium text-amber-500 tracking-wide" to="/login/user">
                  Entrar a otra cuenta
                </Link>
              </div>
            </div>
          )}
          <div className="max-xl:hidden">
            <div className="w-60 h-4 mb-3 flex items-center">
              <div className="w-0 grow h-[1px] bg-neutral-300" />
              <span className="mx-2 text-sm">o también podés</span>
              <div className="w-0 grow h-[1px] bg-neutral-300" />
            </div>
            <button
              type="button"
              className="w-72 h-12 mb-8 py-2 bg-white flex items-center 
            border border-gray-200 shadow-sm rounded-md hover:border-dewalt transition-all"
              onClick={handleGoogleLogin}>
              <img src="/google.svg" className="w-6 h-6 mx-4 object-contain" alt="Google" />
              <span>Ingresar con Google</span>
            </button>
            {step === 'password' && (
              <div className="mb-8">
                <span className="block font-medium text-sm mb-1">Necesito ayuda</span>
                <button
                  type="button"
                  onClick={handleForgotPass}
                  className="w-56 h-10 flex justify-start items-center 
                           hover:bg-black hover:text-white active:text-neutral-400 transition-all group">
                  <RiRotateLockFill className="w-6 h-6 ml-0 group-hover:ml-3 mr-3 transition-all" size="20" />
                  <span className="text-sm select-none">Olvidé mi contraseña</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="w-full lg:w-0 lg:grow-[1.2]">
          <form
            onSubmit={handleCredentialsLogin}
            className="w-full xl:p-10 max-xl:py-4 bg-white xl:border xl:border-gray-200 xl:rounded-md xl:shadow-sm">
            <div
              className={`w-full bg-black ${
                showVerified ? 'h-12 mb-5 border' : 'h-0 mb-0 border-0'
              } pl-3 font-bold text-dewalt border-gray-400 rounded-md shadow-md
              flex items-center overflow-y-hidden transition-all duration-500`}>
              <MdOutlineVerifiedUser className="w-6 h-6 mr-2" />
              <span className="block">Tu cuenta ha sido verificada!</span>
              <div className="h-full grow flex justify-end items-center">
                <button
                  type="button"
                  onClick={() => setShowVerified(!showVerified)}
                  className="w-max h-max mr-3 hover:text-black transition-all">
                  <MdClose className="w-6 h-6" />
                </button>
              </div>
            </div>
            <div className={`${step !== 'email' ? 'hidden' : ''}`}>
              <label>
                <span className={`block pl-2 mb-1 text-sm ${error.input === 'email' ? 'text-red-600' : ''}`}>
                  E-mail
                </span>
              </label>
              <input
                type="email"
                name="email"
                value={form.email}
                onChange={handleInputChange}
                disabled={step !== 'email' || loading}
                className={`w-full h-12 border ${
                  error.input === 'email' ? 'border-red-600' : 'border-gray-400'
                } px-3 rounded-md hover:border-gray-500 focus:border-dewalt focus:border-2 outline-none`}
                autoFocus={step === 'email'}
              />
            </div>
            <div className={`${step !== 'password' ? 'hidden' : ''}`}>
              <label>
                <span className={`block pl-2 mb-1 text-sm ${error.input === 'password' ? 'text-red-600' : ''}`}>
                  Contraseña
                </span>
              </label>
              <input
                type="password"
                name="password"
                value={form.password}
                onChange={handleInputChange}
                disabled={step !== 'password' || loading}
                className={`w-full h-12 border ${
                  error.input === 'password' ? 'border-red-600' : 'border-neutral-400'
                } px-3 rounded-md hover:border-black focus:border-dewalt focus:border-2 outline-none`}
                autoFocus={step === 'password'}
              />
            </div>
            <div
              className={`w-full ${
                error.text !== null ? 'h-max mt-4 mb-2' : 'h-0 mt-12'
              } overflow-y-hidden text-red-700 flex items-center`}
              tabIndex="-1">
              <MdError size="20" className="w-5 h-5" />
              <span className="grow ml-1 text-sm">{error.text}</span>
            </div>
            <div className="w-full flex xl:items-center max-xl:flex-col">
              <div className="w-full xl:w-36 h-12">
                <ButtonWithProgress
                  text={step === 'email' ? 'Continuar' : step === 'password' ? 'Iniciar sesión' : ''}
                  clickable={form[step] !== ''}
                  loading={loading}
                  done={firebase.user}
                  fullWidth={true}
                />
              </div>
              {step === 'email' ? (
                <>
                  <div className="grow flex flex-col items-end ml-8 max-xl:hidden">
                    <span className="w-max pb-1 text-sm text-neutral-600">¿No tenés cuenta?</span>
                    <Link
                      to="/signup"
                      className="w-max font-medium text-black hover:text-amber-500 active:text-black transition-all">
                      Registrate
                    </Link>
                  </div>
                  <Link
                    to="/signup"
                    className="w-full h-12 mt-2 text-amber-500 font-medium flex justify-center items-center xl:hidden">
                    Registrate
                  </Link>
                </>
              ) : (
                <button
                  type="button"
                  onClick={handleForgotPass}
                  className="w-full h-12 mt-2 text-amber-500 font-medium flex justify-center items-center xl:hidden">
                  Olvidé mi contraseña
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="xl:hidden">
          <div className="w-60 h-4 my-4 mx-auto flex items-center">
            <div className="w-0 grow h-[1px] bg-neutral-300" />
            <span className="mx-2 text-sm">o también podés</span>
            <div className="w-0 grow h-[1px] bg-neutral-300" />
          </div>
          <button
            type="button"
            className="w-full h-12 mx-auto py-2 bg-white flex justify-center items-center 
            border border-gray-200 shadow-sm rounded-md hover:border-dewalt transition-all"
            onClick={handleGoogleLogin}>
            <img src="/google.svg" className="w-6 h-6 mx-4 object-contain" alt="Google" />
            <span>Ingresar con Google</span>
          </button>
        </div>
      </div>
    </section>
  )
}

export default Login

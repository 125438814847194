import { useEffect, useRef, useState } from 'react'
import { BsPersonCircle } from 'react-icons/bs'
import { FiChevronDown } from 'react-icons/fi'
import {
  MdOutlineHome,
  MdOutlineLogout,
  MdOutlineMenu,
  MdOutlineSell,
  MdOutlineSettings,
  MdOutlineShoppingBag,
  MdPersonOutline,
} from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'
import { isElementInViewport, removeCookie, useWindowSize } from './utils/Html'
import { useMainContext } from './utils/MainContext'

function Sidebar({ admin }) {
  const { firebase, topbarSize } = useMainContext()
  const [menuHovered, setMenuHovered] = useState(false)
  const location = useLocation()
  const [windowWidth] = useWindowSize()

  const [elementInViewport, setElementInViewport] = useState(true)
  const ref = useRef(null)

  useEffect(() => {
    const onScroll = () => {
      setElementInViewport(isElementInViewport(ref.current, topbarSize.height))
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [topbarSize.height])

  const menus = admin
    ? [
        {
          text: 'Inicio',
          link: '/admin/home',
          icon: <MdOutlineHome className="w-7 mx-4 h-full" />,
        },
        {
          text: 'Usuarios',
          link: '/admin/users',
          icon: <MdPersonOutline className="w-7 mx-4 h-full" />,
        },
        {
          text: 'Ventas',
          subMenu: [
            {
              text: 'Pedidos',
              link: '/admin/orders',
            },
            {
              text: 'Productos',
              link: '/admin/products',
            },
          ],
          icon: <MdOutlineSell className="w-7 mx-4 h-full" />,
        },
        ...(firebase.user?.role === 'admin'
          ? [
              {
                text: 'Configuración',
                icon: <MdOutlineSettings className="w-7 mx-4 h-full" />,
                subMenu: [
                  {
                    text: 'Página principal',
                    link: '/admin/config/home',
                  },
                  {
                    text: 'Marcas / Categorías',
                    link: '/admin/config/brands_categories',
                  },
                  {
                    text: 'Variables / Facturación',
                    link: '/admin/config/vars',
                  },
                ],
              },
            ]
          : []),
        {
          text: 'Cerrar sesión',
          onClick: () => document.getElementById('logout-dialog')?.click(),
          icon: <MdOutlineLogout className="w-7 mx-4 h-full" />,
        },
      ]
    : [
        {
          text: 'Pedidos',
          link: '/user/orders',
          icon: <MdOutlineShoppingBag className="w-7 mx-4 h-full" />,
        },
        {
          text: 'Cuenta',
          link: '/user/profile',
          icon: <BsPersonCircle className="w-6 mx-4 h-full" />,
        },
        {
          text: 'Cerrar sesión',
          onClick: () => {
            logOut()
          },
          icon: <MdOutlineLogout className="w-7 mx-4 h-full" />,
        },
      ]

  const logOut = async () => {
    try {
      await firebase.auth.signOut()
      removeCookie('user')
      removeCookie('cart')
      removeCookie('favs')
      window.location.reload(false)
    } catch (e) {
      // console.log(e)
    }
  }

  return (
    <>
      <div ref={ref} className={`absolute top-0 left-0 h-full ${windowWidth <= 1540 ? 'hidden' : ''} z-[101]`}>
        <div
          className={`w-16 ${
            menuHovered ? 'w-72 shadow-2xl' : 'w-16 shadow-none'
          } h-full left-0 top-0 pt-6 bg-neutral-50
          flex flex-col border-r border-gray-200 transition-all duration-300
          overflow-hidden group/container`}
          onMouseEnter={() => setMenuHovered(true)}
          onMouseLeave={() => setMenuHovered(false)}>
          <div
            className={`${
              elementInViewport
                ? 'block'
                : 'fixed top-6 w-16 bg-transparent group-hover/container:w-72 transition-all duration-300 overflow-hidden'
            }`}
            style={{ marginTop: !elementInViewport && topbarSize.height }}>
            <div className="w-72 h-10 mb-6 flex items-center select-none">
              <div className="w-16 h-full flex justify-center items-center">
                <MdOutlineMenu className="w-7 h-7 text-amber-500" />
              </div>
              <span className="text-neutral-500 font-medium">
                {admin ? (firebase.user?.role === 'admin' ? 'Menú Admin' : 'Menú Operador') : 'Mi cuenta'}
              </span>
            </div>
            {menus.map((v, i) => (
              <SidebarItem key={i} info={v} currentLink={location.pathname} menuHovered={menuHovered} />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`absolute h-full ${
          menuHovered
            ? 'w-[calc(100%-18rem)] opacity-50 pointer-events-auto select-auto'
            : 'w-[calc(100%-4rem)] opacity-0 pointer-events-none select-none'
        } right-0 top-0 bg-black transition-all duration-300 z-[886]`}
      />
    </>
  )
}

function SidebarItem({ info, currentLink, menuHovered }) {
  const { text, link, icon, onClick, subMenu } = info
  const Tag = link ? Link : 'button'

  const [opened, setOpened] = useState(subMenu && subMenu.some(v => currentLink.startsWith(v.link)))

  return (
    <>
      <Tag
        className={`w-72 relative h-14 flex items-center group select-none ${
          link && currentLink.startsWith(link) ? 'pointer-events-none' : ''
        }`}
        to={link ?? null}
        onClick={
          subMenu
            ? () => {
                setOpened(!opened)
              }
            : onClick ?? ''
        }
        disabled={!subMenu && link && currentLink.startsWith(link)}>
        <div
          className={`absolute w-1 h-full left-0 top-0 ${
            (link && currentLink.startsWith(link)) || (subMenu && subMenu.some(v => currentLink.startsWith(v.link)))
              ? 'bg-amber-500'
              : 'group-hover:bg-amber-500 transition-all'
          }`}
        />
        <div
          className={`w-16 h-full flex justify-center items-center ${
            link && currentLink.startsWith(link) ? 'text-amber-500' : 'text-neutral-400'
          }`}>
          {icon}
        </div>
        <span
          className={`w-0 grow text-start text-sm text-neutral-500 ${
            link && currentLink.startsWith(link) ? 'font-medium' : ''
          }`}>
          {text}
        </span>
        {menuHovered && subMenu && (
          <FiChevronDown
            className={`w-6 h-6 mr-6 text-neutral-500 ${opened ? 'rotate-180' : 'rotate-0'} transition-all`}
          />
        )}
      </Tag>
      {menuHovered && subMenu && (
        <div className={`w-72 ${opened ? 'h-max' : 'hidden'} left-0 top-full flex flex-col`}>
          {subMenu.map((v, i) => (
            <Link
              key={i}
              to={v.link}
              className={`w-full h-6 pl-16 text-start text-sm text-neutral-500 hover:text-amber-500 transition-all ${
                currentLink.startsWith(v.link) && 'font-medium'
              }`}>
              {v.text}
            </Link>
          ))}
        </div>
      )}
    </>
  )
}

export default Sidebar

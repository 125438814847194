import { Link } from 'react-router-dom'
import { FormatPrice, ProductLink } from './Strings'
import { GetDiscountPercent } from './Numbers'
import FavoriteButton from './FavoriteButton'
import { useMainContext } from './MainContext'
import ShowImage from './ShowImage'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'

function ProductCard({ product, free_shipping_minimum }) {
  const { product_id, title, normal_price, offer_price, images, is_best_seller, recommended_by_seller } = product
  const { firebase } = useMainContext()
  const user = firebase.user

  return (
    <div
      className={`relative max-xl:w-[calc(var(--content-max-width)/1.5/5)] xl:w-[calc(var(--content-max-width)/5)] bg-white xl:h-96 
                border border-gray-200 shadow-sm hover:drop-shadow-xl rounded-b-md
                flex flex-col hover:border-dewalt transition-all duration-300 group`}>
      {(!user || user.role === 'user') && (
        <div className="absolute top-2 right-2 xl:top-4 xl:right-4 z-20">
          <FavoriteButton product_id={product_id} />
        </div>
      )}
      <Link to={ProductLink(product_id, title)}>
        <AspectRatio.Root ratio={1}>
          <div className={`relative w-full h-full p-2 border-b border-gray-200 mx-auto`}>
            <div className="absolute top-0 left-0 p-2 z-10">
              <div className="flex flex-col gap-1">
                {is_best_seller && (
                  <div className="w-max py-[3px] px-2 bg-amber-500 text-white font-semibold rounded-md flex">
                    <span className="text-xs">MÁS&nbsp;VENDIDO</span>
                  </div>
                )}
              </div>
            </div>
            <div className="absolute bottom-0 left-0 p-2 z-10">
              {recommended_by_seller && (
                <div className="w-max py-[3px] px-2 bg-black text-white font-semibold rounded-md flex items-center">
                  <img src="/universal-tools-logo-notext.svg" className="w-8 h-4 object-contain" alt="yaguarete" />
                  <span className="ml-1 text-xs">RECOMENDADO</span>
                </div>
              )}
            </div>
            <ShowImage image={images[0]} alt={`Imágen 1 de ${title}`} draggable={false} />
            {images.length > 1 && (
              <div
                className="absolute w-full h-full p-2 bg-white left-0 top-0 select-none opacity-0 group-hover:opacity-100
                          transition-all duration-300 z-10">
                <ShowImage image={images[1]} alt={`Imágen 2 de ${title}`} draggable={false} />
              </div>
            )}
            {/* <div
              className="absolute min-w-max left-1/2 -translate-x-1/2 bottom-2 p-1 px-4 bg-white rounded-md shadow-md 
                       opacity-0 group-hover:opacity-100 transition-all z-20"
            >
              <span className="text-sm text-black font-semibold">Ver producto</span>
            </div> */}
          </div>
        </AspectRatio.Root>
        <div className={`w-full p-2 xl:p-4 flex flex-col`}>
          <div className="flex items-center gap-1">
            <span className="text-xl xl:text-2xl">{FormatPrice(offer_price ?? normal_price)}</span>
            {offer_price && (
              <span className="text-xs xl:text-sm text-green-600">{`${GetDiscountPercent(
                normal_price,
                offer_price
              )}% OFF`}</span>
            )}
          </div>
          {(offer_price ?? normal_price) >= free_shipping_minimum && (
            <span className="text-xs xl:text-sm text-green-600 font-medium">Envío gratis</span>
          )}
          <span className="mt-2 text-xs xl:text-sm text-gray-500 max-xl:text-black line-clamp-2 group-hover:text-black transition-all">
            {title}
          </span>
        </div>
      </Link>
    </div>
  )
}

export default ProductCard

import { useState, useMemo, useRef } from 'react'
import { DraggableCore } from 'react-draggable'
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'

import './Carousel.css'
import { useWindowSize } from './Html'
import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ShowImage from './ShowImage'
import ZoomableImage from './ZoomableImage'

function Carousel({
  propItems,
  startingIndex,
  contain = false,
  interval = 5000,
  counter = false,
  padding = 0,
  previewable = false,
}) {
  const [windowWidth] = useWindowSize()
  const [items, setItems] = useState([])
  const [activeItem, setActiveItem] = useState(0)
  const [hovering, setHovering] = useState(false)
  const intervals = useRef([])
  const navigate = useNavigate()

  const [dragging, setDragging] = useState(false)
  const [dragDirection, setDragDirection] = useState(0)
  const [position, setPosition] = useState(0)
  const firstItem = useRef(null)
  const container = useRef(null)

  const [touchStart, setTouchStart] = useState({ pos: { x: 0, y: 0 }, time: 0 })
  const [preview, setPreview] = useState(null)

  const handleDrag = (e, data) => {
    // e.preventDefault()
    setDragging(true)
    setDragDirection(data.deltaX < 0 ? -1 : data.deltaX > 0 ? 1 : 0) // < 0 ? LEFT : > 0 ? RIGHT
    const newPos = position + data.deltaX
    setPosition(newPos > 0 || newPos < -(windowWidth * (items.length - 1)) ? position : newPos)
  }

  const handleStoppedDrag = ev => {
    setDragging(false)
    // En vez de usar el medio de la imagen como referencia, uso un 10% menos del lado contrario al que esta swipeando
    // O sea en vez de tener que mover el 50% de la imagen para cambiar, con mover el 10% ya lo toma
    const item = Math.floor(Math.abs(position - windowWidth / (dragDirection < 0 ? 1.111 : 10)) / windowWidth)
    setActiveItem(item)

    setPosition(-item * windowWidth)
    setDragDirection(0)

    const touch = ev.clientX ? ev : [...ev.changedTouches]?.at(0)

    if (
      Math.abs(touch.clientX - touchStart.pos.x) < 10 &&
      Math.abs(touch.clientY - touchStart.pos.y) < 10 &&
      Date.now() - touchStart.time <= 150
    ) {
      if (activeItem.link) navigate(activeItem.link)
      else setPreview(propItems[item].image)
    }
  }

  useMemo(() => {
    setItems(
      propItems.map((v, i) => {
        return { ...v, position: startingIndex ? i - startingIndex : i }
      })
    )
    setActiveItem(startingIndex ?? 0)
  }, [propItems, startingIndex])

  const onSelector = useCallback(
    index => {
      setItems(it =>
        it.map((v, i) => {
          v.position = index !== i ? i - index : 0
          return v
        })
      )
      setActiveItem(index)
      setPosition(-index * windowWidth)
    },
    [windowWidth]
  )

  const prevItem = useCallback(() => {
    onSelector(activeItem === 0 ? items.length - 1 : activeItem - 1, 1)
  }, [activeItem, items.length, onSelector])

  const nextItem = useCallback(() => {
    onSelector(activeItem === items.length - 1 ? 0 : activeItem + 1, -1)
  }, [activeItem, items.length, onSelector])

  useMemo(() => {
    if (!hovering && !dragging) {
      intervals.current.forEach(v => clearInterval(v))
      intervals.current = []
      if (interval) {
        const int = setInterval(nextItem, interval)
        intervals.current.push(int)
      }
    }
  }, [nextItem, hovering, dragging, interval])

  useMemo(() => {
    if (hovering || dragging) {
      intervals.current.forEach(v => clearInterval(v))
      intervals.current = []
      return
    }
  }, [hovering, dragging])

  // const [dragDisabled, setDragDisabled] = useState(true)

  return (
    <>
      {previewable && preview && <ZoomableImage image={preview} close={() => setPreview(null)} />}
      {windowWidth < 1280 ? (
        <div
          className={`relative w-full h-full overflow-x-hidden`}
          ref={container}
          onTouchStart={ev => {
            const touch = ev.touches[0]
            setTouchStart({ pos: { x: touch?.clientX, y: touch?.clientY }, time: Date.now() })
          }}
          // onTouchMove={ev => {
          //   if (touchStart.pos.x !== null && Math.abs(ev.touches[0].clientX - touchStart.pos.x) < 10) {
          //     ev.preventDefault()
          //   }
          // }}
          // onTouchEnd={() => {
          //   setTouchStart({ pos: { x: 0, y: 0 }, time: 0 })
          // }}
          // onTouchMove={ev => {
          //   ev.preventDefault()
          //   const deltaX = ev.touches[0]?.clientX - startXRef.current
          //   // console.log(Math.abs(deltaX))
          //   setDragDisabled(Math.abs(deltaX) < 10)
          // }}
        >
          {counter && (
            <span className="absolute top-0 left-4 py-1 px-3 text-xs font-medium rounded-full bg-neutral-50 z-50 pointer-events-none">
              {`${activeItem + 1} / ${propItems.length}`}
            </span>
          )}
          {/* <div
            className={`absolute top-0 left-0 w-full h-full z-50 cancel-drag ${dragDisabled ? 'block' : 'hidden'}`}
          /> */}
          <DraggableCore axis="x" onDrag={handleDrag} onStop={handleStoppedDrag}>
            <div className={`w-full h-full flex ${!dragging ? 'transition-all' : ''}`} style={{ translate: position }}>
              {items.map((v, i) => {
                return v.link ? (
                  <Link
                    to={v.link}
                    className="min-w-full h-full"
                    key={i}
                    stlye={padding ? { padding: `${padding}rem` } : null}
                    ref={i === 0 ? firstItem : null}>
                    <ShowImage image={v.image} alt={`Banner de ${v.link}`} draggable={false} contain={contain} />
                  </Link>
                ) : (
                  <div
                    key={i}
                    className="min-w-full h-full flex justify-center items-center"
                    style={padding ? { padding: `${padding}rem` } : null}
                    ref={i === 0 ? firstItem : null}>
                    {v.video ? (
                      <AspectRatio.Root ratio={16 / 9}>
                        <iframe
                          className="w-full h-full"
                          src={`https://www.youtube.com/embed/${
                            v.video.includes('www.youtube.com')
                              ? v.video.split('?v=')[1]
                              : v.video.split('youtu.be/')[1]
                          }`}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </AspectRatio.Root>
                    ) : (
                      <ShowImage image={v.image} alt={`Banner de ${v.link}`} draggable={false} contain={contain} />
                    )}
                  </div>
                )
              })}
            </div>
          </DraggableCore>
        </div>
      ) : (
        <div
          className="relative h-full overflow-x-hidden group/container"
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}>
          {items.map((v, i) => {
            return (
              <Item
                key={i}
                image={v.image}
                link={v.link}
                video={v.video}
                visible={v.visible}
                position={v.position}
                contain={contain}
              />
            )
          })}
          <div className="absolute h-max left-1/2 -translate-x-1/2 scale-50 bottom-1 xl:scale-100 xl:bottom-4 flex justify-around gap-2">
            {items.map((v, i) => (
              <button
                key={i}
                className={`h-2 bg-white transition-all duration-500 ${
                  items.findIndex(i => i.position === 0) === i
                    ? 'w-4 bg-opacity-100 drop-shadow-lg'
                    : 'w-2 bg-opacity-20 border border-white shadow-sm'
                } rounded-3xl`}
                onClick={() => {
                  if (i !== activeItem) onSelector(i, i - activeItem)
                }}
              />
            ))}
          </div>
          <div className="absolute w-full h-0 top-1/2 left-0 flex justify-between transition-all">
            <button className="carousel_button left-0 translate-x-1/2 group" onClick={prevItem}>
              <HiOutlineChevronLeft className="carousel_button_icon" />
            </button>
            <button className="carousel_button right-0 -translate-x-1/2 group" onClick={nextItem}>
              <HiOutlineChevronRight className="carousel_button_icon" />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

function Item({ image, link, video, position, contain }) {
  return (
    <Link
      to={link}
      className="absolute w-full h-full transition-all duration-500"
      style={{ transform: `translateX(${window.innerWidth * position}px)` }}>
      <div className="w-[1600px] mx-auto h-full">
        <ShowImage image={image} alt={`Banner de ${image}`} draggable={false} contain={contain} />
      </div>
    </Link>
  )
}

export default Carousel

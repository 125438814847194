import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { useEffect, useRef } from 'react'
import ButtonWithProgress from './ButtonWithProgress'

function DefaultAlertDialog({
  trigger,
  title,
  content,
  submit,
  cancel = { text: 'Cancelar' },
  loading,
  done,
  identifierClass,
  alwaysVisible,
}) {
  const cancelRef = useRef(null)

  useEffect(() => {
    if (done && cancelRef.current) cancelRef.current.click()
  }, [done])

  const Tag = submit && 'onSubmit' in submit ? 'form' : 'div'

  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="bg-black bg-opacity-60 fixed inset-0 transition-all z-[888] animate-overlay-show" />
        <AlertDialog.Content
          className={`w-full xl:w-[550px] max-xl:px-2 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                    transition-all focus:outline-none z-[999] animate-content-show ${identifierClass || ''}`}>
          <Tag onSubmit={submit?.onSubmit} className={`w-full p-4 bg-white rounded-md shadow-lg`}>
            {title && <AlertDialog.Title className="mb-4 text-black text-xl font-medium">{title}</AlertDialog.Title>}
            <AlertDialog.Description asChild>
              <div className="max-h-[60vh] pb-2 overflow-y-auto mb-6 text-neutral-800 hyphens-none">{content}</div>
            </AlertDialog.Description>
            <div className="flex justify-end gap-4">
              {!alwaysVisible && (
                <AlertDialog.Cancel asChild>
                  <button
                    ref={cancelRef}
                    type="button"
                    className="h-10 px-4 bg-white text-gray-500 rounded-md
                  border border-gray-500 hover:text-black hover:border-black transition-all"
                    onClick={cancel.onClick ?? null}>
                    {cancel.text}
                  </button>
                </AlertDialog.Cancel>
              )}
              {submit && (
                <div className="w-max h-10">
                  <ButtonWithProgress
                    text={submit.text}
                    clickable={true}
                    loading={loading}
                    done={done}
                    {...(submit.onClick ? { onClick: submit.onClick } : {})}
                  />
                </div>
              )}
            </div>
          </Tag>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}

export default DefaultAlertDialog

import axios from 'axios'
import { round, isEqual, uniqueId } from 'lodash'
import { createContext, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../../../Sidebar'
import Section from '../../../utils/forms/Section'
import TextInput from '../../../utils/forms/TextInput'
import { inputsToObj } from '../../../utils/forms/Utils'
import InputLabel from '../../../utils/InputLabel'
import LoadingBox from '../../../utils/LoadingBox'
import { useMainContext } from '../../../utils/MainContext'
import { CapitalizeAllWords, FormatCuit, OnlyNumbers } from '../../../utils/Strings'
import ToggleInput from '../../../utils/forms/ToggleInput'
import { delay, setDocumentTitle } from '../../../utils/Html'
import { useAbortableEffect } from '../../../utils/Hooks'
import { z } from 'zod'
import { MdOutlineAdd } from 'react-icons/md'

const VarsContext = createContext({
  activeSectionState: [],
  errorsState: [],
  loadingState: [],
  doneState: [],
})
const useVarsContext = () => useContext(VarsContext)

function AdminVarsConfig() {
  const { firebase, showToast } = useMainContext()
  const navigate = useNavigate()

  const [pageLoaded, setPageLoaded] = useState(false)
  const [vars, setVars] = useState(null)

  const [activeSection, setActiveSection] = useState('')
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  // console.log(errors)

  useAbortableEffect(
    signal => {
      if (!firebase.user || firebase.user.role === 'user') {
        navigate('/', { replace: true })
      }
      setDocumentTitle('Modificar variables / facturación')
      ;(async () => {
        setPageLoaded(false)
        setVars(null)
        setActiveSection('')
        setErrors([])
        setLoading(false)
        setDone(false)

        try {
          let result = await axios.get(process.env.REACT_APP_API_LINK + 'get_vars', {
            params: {
              user_id: firebase.user?.uid,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })

          const dataVars = result.data.vars
          dataVars.ut_coins_multiplier = round(dataVars.ut_coins_multiplier * 100)
          dataVars.mp_add_pct = round(dataVars.mp_add_pct * 100)
          dataVars.shipping_methods = dataVars.shipping_methods.reduce(
            (acc, item) => ({ ...acc, [item.icon]: item.price }),
            {}
          )
          dataVars.billing = dataVars.billing.map(item => ({ ...item, id: uniqueId() }))
          setVars(dataVars)
          // console.log(dataVars)

          setPageLoaded(true)
        } catch (err) {
          if (err.code === 'ERR_CANCELED') return
          // console.log(err)
          showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
        }
      })()
    },
    [firebase.user, navigate, showToast]
  )

  const variablesRef = useRef(null)
  const variablesInputsRef = useRef([])
  const transferenciaRef = useRef(null)
  const transferenciaInputsRef = useRef([])
  const vouchersRef = useRef(null)
  const vouchersInputsRef = useRef([])
  const mediaRef = useRef(null)
  const mediaInputsRef = useRef([])

  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={true} />
      <div className="w-full xl:w-content-max-width mx-auto">
        {pageLoaded ? (
          <div className="w-full px-2 xl:px-60 pb-8">
            <div className="my-8 max-xl:my-4">
              <h1 className="text-xl xl:text-2xl font-medium">Variables / Facturación</h1>
            </div>
            <div className="w-full flex flex-col gap-4 xl:gap-6">
              <VarsContext.Provider
                value={{
                  activeSectionState: [activeSection, setActiveSection],
                  errorsState: [errors, setErrors],
                  loadingState: [loading, setLoading],
                  doneState: [done, setDone],
                }}>
                <SectionContainer
                  ref={variablesRef}
                  info={{
                    title: 'Variables',
                    content: (
                      <div className="w-full flex flex-col gap-4">
                        <span className="font-medium">Dirección de retiro</span>
                        <PickUpAddressInputs
                          ref={el => (variablesInputsRef.current[0] = el)}
                          object={vars.pick_up_address}
                          onChange={() => variablesRef.current.handleInputChange()}
                        />
                        <span className="font-medium">Otras variables</span>
                        <div className="w-full flex max-xl:flex-col gap-4">
                          <div className="flex flex-1 gap-4">
                            <div className="max-xl:flex-1">
                              <InputLabel
                                text="Porcentaje UT Coins"
                                error={errors.some(e => e.input === 'ut_coins_multiplier')}
                              />
                              <div className="w-full xl:w-40">
                                <TextInput
                                  ref={el => (variablesInputsRef.current[1] = el)}
                                  error={errors.some(e => e.input === 'ut_coins_multiplier')}
                                  object={vars}
                                  name={'ut_coins_multiplier'}
                                  onChange={() => variablesRef.current.handleInputChange()}
                                  type="percent"
                                  maxLength={3}
                                  decimal={true}
                                />
                              </div>
                            </div>
                            <div className="max-xl:flex-1">
                              <InputLabel
                                text="Envío gratis desde"
                                error={errors.some(e => e.input === 'free_shipping_minimum')}
                              />
                              <div className="w-full xl:w-40">
                                <TextInput
                                  ref={el => (variablesInputsRef.current[2] = el)}
                                  error={errors.some(e => e.input === 'free_shipping_minimum')}
                                  object={vars}
                                  name={'free_shipping_minimum'}
                                  onChange={() => variablesRef.current.handleInputChange()}
                                  type="money"
                                  decimal={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 gap-4">
                            <div>
                              <InputLabel
                                text="Porcentaje adicional MercadoPago"
                                error={errors.some(e => e.input === 'mp_add_pct')}
                              />
                              <div className="w-40">
                                <TextInput
                                  ref={el => (variablesInputsRef.current[3] = el)}
                                  error={errors.some(e => e.input === 'mp_add_pct')}
                                  object={vars}
                                  name={'mp_add_pct'}
                                  onChange={() => variablesRef.current.handleInputChange()}
                                  type="percent"
                                  maxLength={3}
                                  decimal={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <span className="font-medium">Métodos de envío</span>
                        <ShippingMethodsInputs
                          ref={el => (variablesInputsRef.current[4] = el)}
                          object={vars.shipping_methods}
                          onChange={() => variablesRef.current.handleInputChange()}
                        />
                      </div>
                    ),
                    loading,
                    done,
                    errors: errors.filter(e =>
                      [
                        'street',
                        'street_number',
                        'zip_code',
                        'city',
                        'province',
                        'ut_coins_multiplier',
                        'free_shipping_minimum',
                        'mp_add_pct',
                      ].includes(e.input)
                    ),
                  }}
                  inputsRef={variablesInputsRef}
                  state={vars}
                  setState={setVars}
                />
                <SectionContainer
                  ref={transferenciaRef}
                  info={{
                    title: 'Información Bancaria',
                    content: (
                      <div className="w-full flex flex-col gap-4">
                        <TransferInputs
                          ref={el => (transferenciaInputsRef.current[0] = el)}
                          object={vars.billing}
                          onChange={() => transferenciaRef.current.handleInputChange()}
                        />
                      </div>
                    ),
                    loading,
                    done,
                    errors: errors.filter(e =>
                      ['is_checking', 'full_name', 'cuit', 'bank', 'cbu', 'alias'].includes(e.input.split('-')[1] ?? '')
                    ),
                  }}
                  inputsRef={transferenciaInputsRef}
                  state={vars}
                  setState={setVars}
                />
                <SectionContainer
                  ref={vouchersRef}
                  info={{
                    title: 'Facturación',
                    content: (
                      <div className="w-full flex flex-col gap-4">
                        <VouchersInputs
                          ref={el => (vouchersInputsRef.current[0] = el)}
                          object={vars.vouchers}
                          onChange={() => vouchersRef.current.handleInputChange()}
                        />
                      </div>
                    ),
                    loading,
                    done,
                    errors: errors.filter(e =>
                      ['caption', 'activities_starting_date', 'sales_point'].includes(e.input)
                    ),
                  }}
                  inputsRef={vouchersInputsRef}
                  state={vars}
                  setState={setVars}
                />
                <SectionContainer
                  ref={mediaRef}
                  info={{
                    title: 'Contacto / Redes Sociales',
                    content: (
                      <div className="w-full flex flex-col gap-4">
                        <MediaInputs
                          ref={el => (mediaInputsRef.current[0] = el)}
                          object={vars.media}
                          onChange={() => mediaRef.current.handleInputChange()}
                        />
                      </div>
                    ),
                    loading,
                    done,
                    errors: errors.filter(e =>
                      [
                        'landline_phone',
                        'contact_number',
                        'email',
                        'facebook',
                        'instagram',
                        'twitter',
                        'youtube',
                      ].includes(e.input)
                    ),
                  }}
                  inputsRef={mediaInputsRef}
                  state={vars}
                  setState={setVars}
                />
              </VarsContext.Provider>
            </div>
          </div>
        ) : (
          <div className="w-full xl:px-60 pb-8">
            <LoadingBox text={'Cargando configuración...'} />
          </div>
        )}
      </div>
    </div>
  )
}

const SectionContainer = forwardRef(({ info, inputsRef }, ref) => {
  const { firebase, showToast } = useMainContext()
  const [activeSection, setActiveSection] = useVarsContext().activeSectionState
  const [, setErrors] = useVarsContext().errorsState
  const [loading, setLoading] = useVarsContext().loadingState
  const [done, setDone] = useVarsContext().doneState
  const [allowActions, setAllowActions] = useState(false)

  useImperativeHandle(ref, () => ({
    send: async () => await handleSend(),
    handleInputChange: () => {
      // setErrors([])
      setAllowActions(true)
      setActiveSection(info.title)
    },
  }))

  const handleCancel = () => {
    inputsRef.current.forEach(r => r.reset())
    setErrors([])
    setAllowActions(false)
    setActiveSection('')
  }

  const handleSend = async () => {
    let params = inputsToObj(inputsRef)

    if (!isEqual(params, {})) {
      try {
        setErrors([])
        setLoading(true)

        // console.log(params)

        let errors = []

        const billingSchema = z.object({
          id: z.string(),
          is_checking: z.boolean().optional(),
          full_name: z
            .string()
            .trim()
            .min(1, { message: 'El Nombre Completo no puede estar vacío' })
            .max(100, { message: 'El Nombre Completo no puede superar los 100 carácteres.' })
            .optional(),
          cuit: z
            .string()
            .trim()
            .min(1, { message: 'El CUIT no puede estar vacío' })
            .max(13, { message: 'El CUIT no puede superar los 13 carácteres.' })
            .refine(v => new RegExp(/^\d{2}-\d{8}-\d$/).test(FormatCuit(v)), {
              message: 'El CUIT tiene que tener el formato "XX-XXXXXXXX-X"',
            })
            .optional(),
          bank: z
            .string()
            .trim()
            .min(1, { message: 'El Banco no puede estar vacío.' })
            .max(50, { message: 'El Banco no puede superar los 50 carácteres.' })
            .optional(),
          cbu: z.coerce.string().trim().length(22, { message: 'El CBU tiene que tener 22 dígitos.' }).optional(),
          alias: z
            .string()
            .trim()
            .min(1, { message: 'El Alias no puede estar vacío.' })
            .max(50, { message: 'El Alias no puede superar los 50 carácteres.' })
            .optional(),
        })

        const Form = z
          .object({
            pick_up_address: z.object({
              street: z
                .string()
                .trim()
                .min(1, { message: 'La Calle no puede estar vacía.' })
                .max(100, { message: 'La Calle no puede superar los 100 carácteres.' })
                .optional(),
              street_number: z
                .string()
                .min(1, { message: 'El Número de Calle no puede estar vacío.' })
                .max(5, { message: 'El Número de Calle no puede ser mayor a 999.999' })
                .optional(),
              zip_code: z
                .number()
                .min(1, { message: 'El Código Postal no puede estar vacío.' })
                .max(99999, { message: 'El Código Postal no puede ser mayor a 99.999' })
                .optional(),
              city: z
                .string()
                .trim()
                .min(1, { message: 'El Barrio no puede estar vacío.' })
                .max(100, { message: 'El Barrio no puede superar los 100 carácteres.' })
                .optional(),
              province: z
                .string()
                .trim()
                .min(1, { message: 'La Provincia no puede estar vacía.' })
                .max(100, { message: 'La Provincia no puede superar los 100 carácteres.' })
                .optional(),
            }),
            ut_coins_multiplier: z.number().max(100, 'El Porcentaje UT Coins no puede superar el 100%'),
            free_shipping_minimum: z
              .number()
              .max(10000000, 'El Precio para Envío Gratis no puede superar los $ 10.000.000'),
            mp_add_pct: z.number().max(100, 'El Porcentaje adicional MP no puede superar el 100%'),
            shipping_methods: z.object({
              motorbike: z
                .number()
                .max(9999999, { message: 'El precio de "Envío por moto" no puede superar los 7 dígitos.' })
                .optional(),
              truck: z
                .number()
                .max(9999999, {
                  message: 'El precio de "Envío a domicilio por correo" no puede superar los 7 dígitos.',
                })
                .optional(),
              store: z
                .number()
                .max(9999999, { message: 'El precio de "Retiro en correo" no puede superar los 7 dígitos.' })
                .optional(),
            }),
            billing: z.array(billingSchema).refine(data => {
              let errorsFound = false
              for (let i = 0; i < data.length; i++) {
                const validationResult = billingSchema.safeParse(data[i])
                if (!validationResult.success) {
                  errors.push(
                    ...validationResult.error.errors.map(item => ({
                      path: [`${data[i].id}-${item.path.at(-1)}`],
                      message: `Opción ${i + 1}: ${item.message}`,
                    }))
                  )
                  errorsFound = true
                }
              }
              return !errorsFound
            }),
            vouchers: z.object({
              caption: z
                .string()
                .min(1, { message: 'La Leyenda no puede estar vacía.' })
                .max(100, { message: 'La Leyenda no puede superar los 100 carácteres.' })
                .optional(),
              activities_starting_date: z
                .string()
                .trim()
                .regex(/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/, {
                  message: 'El Inicio de Actividades tiene que ser en formato: DD/MM/AAAA',
                })
                .optional(),
              sales_point: z
                .number()
                .min(1, { message: 'El Punto de Venta no puede estar vacío.' })
                .max(9999, { message: 'El Punto de Venta no puede ser mayor a 9999.' })
                .optional(),
            }),
            media: z.object({
              contact_number: z
                .string()
                .trim()
                .min(1, { message: 'El Número de Contacto no puede estar vacío.' })
                .max(20, { message: 'El Número de Contacto no puede superar los 20 carácteres.' })
                .optional(),
              email: z.string().trim().email('El E-mail es inválido').optional(),
              facebook: z
                .string()
                .min(1, 'El link de "Facebook" no puede estar vacío.')
                .url('El link de "Facebook" es inválido.')
                .optional(),
              instagram: z
                .string()
                .min(1, 'El link de "Instagram" no puede estar vacío.')
                .url('El link de "Instagram" es inválido.')
                .optional(),
              twitter: z
                .string()
                .min(1, 'El link de "Twitter" no puede estar vacío.')
                .url('El link de "Twitter" es inválido.')
                .optional(),
              youtube: z
                .string()
                .min(1, 'El link de "YouTube" no puede estar vacío.')
                .url('El link de "YouTube" es inválido.')
                .optional(),
            }),
          })
          .partial()

        const validation = Form.safeParse(params)

        if (!validation.success) {
          setLoading(false)
          errors.push(...validation.error.errors)
          if (errors.length > 1) {
            showToast('Hay errores en el formulario.', 'bad')
            setErrors(errors.map(e => ({ input: e.path.at(-1), text: e.message })))
          } else {
            showToast(errors[0].message, 'bad')
            setErrors([{ input: errors[0].path.at(-1), text: errors[0].message }])
          }
          return
        }

        params = validation.data

        // console.log(params)

        if ('billing' in params) {
          params.billing.forEach(item => delete item.id)
          params.billing = params.billing.map(item => ({ ...item, cuit: FormatCuit(item.cuit) }))
        }

        const result = await axios.put(
          process.env.REACT_APP_API_LINK + 'update_vars',
          {
            user_id: firebase.user?.uid,
            ...params,
          },
          { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
        )
        // console.log(result)

        setLoading(false)
        setDone(true)

        showToast('¡Listo! Los cambios estan guardados.', 'good')

        await delay(1000)

        setDone(false)
        setAllowActions(false)
        setActiveSection('')

        window.location.reload(false)
      } catch (err) {
        // console.log(err)
        setErrors([{ input: 'unkn', text: 'Ocurrió un error desconocido.' }])
        setLoading(false)
        showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
      }
    } else {
      setLoading(false)
      setDone(false)
      setAllowActions(false)
      setActiveSection('')
    }
  }

  return (
    <Section
      info={{
        ...info,
        allowActions,
        loading,
        done,
        handleCancel,
        handleSend,
        modifiable: activeSection === '' || activeSection === info.title,
      }}
    />
  )
})

const PickUpAddressInputs = forwardRef(({ object, onChange }, ref) => {
  const [errors] = useVarsContext().errorsState
  const inputsRef = useRef([])

  useImperativeHandle(ref, () => ({
    reset: () => inputsRef.current.forEach(input => input.reset()),
    valueChanged: inputsRef.current.some(input => input.valueChanged),
    toSend: () => ({ pick_up_address: inputsToObj(inputsRef) }),
  }))

  return (
    <>
      <div className="xl:w-[450px] flex gap-4">
        <div className="w-0 grow xl:grow-[0.75]">
          <InputLabel text="Calle" error={errors.some(e => e.input === 'street')} />
          <TextInput
            ref={el => (inputsRef.current[0] = el)}
            error={errors.some(e => e.input === 'street')}
            object={object}
            name="street"
            format={v => CapitalizeAllWords(v)}
            onChange={onChange}
          />
        </div>
        <div className="w-0 grow xl:grow-[0.25]">
          <InputLabel text="Número" error={errors.some(e => e.input === 'street_number')} />
          <TextInput
            ref={el => (inputsRef.current[1] = el)}
            error={errors.some(e => e.input === 'street_number')}
            object={object}
            name="street_number"
            unformat={v => OnlyNumbers(v)}
            onChange={onChange}
            maxLength={6}
          />
        </div>
      </div>
      <div className="flex max-xl:flex-col gap-4">
        <div className="xl:w-0 xl:grow">
          <InputLabel text="Código postal" error={errors.some(e => e.input === 'zip_code')} />
          <TextInput
            ref={el => (inputsRef.current[2] = el)}
            error={errors.some(e => e.input === 'zip_code')}
            object={object}
            name="zip_code"
            format={v => v}
            onChange={onChange}
            maxLength={5}
            type="number"
          />
        </div>
        <div className="xl:w-0 xl:grow">
          <InputLabel text="Barrio" error={errors.some(e => e.input === 'city')} />
          <TextInput
            ref={el => (inputsRef.current[3] = el)}
            error={errors.some(e => e.input === 'city')}
            object={object}
            name="city"
            format={v => CapitalizeAllWords(v)}
            onChange={onChange}
          />
        </div>
        <div className="xl:w-0 xl:grow">
          <InputLabel text="Provincia" error={errors.some(e => e.input === 'province')} />
          <TextInput
            ref={el => (inputsRef.current[4] = el)}
            error={errors.some(e => e.input === 'province')}
            object={object}
            name="province"
            format={v => CapitalizeAllWords(v)}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  )
})

const ShippingMethodsInputs = forwardRef(({ object, onChange }, ref) => {
  const [errors] = useVarsContext().errorsState
  const inputsRef = useRef([])

  useImperativeHandle(ref, () => ({
    reset: () => inputsRef.current.forEach(input => input.reset()),
    valueChanged: inputsRef.current.some(input => input.valueChanged),
    toSend: () => ({ shipping_methods: inputsToObj(inputsRef) }),
  }))

  return (
    <div className="w-full flex max-xl:flex-col gap-4">
      <div className="max-xl:flex-1">
        <InputLabel text={'Envío a domicilio por correo'} />
        <div className="w-full">
          <TextInput
            ref={el => (inputsRef.current[0] = el)}
            error={errors.some(e => e.input === 'truck')}
            object={object}
            name={'truck'}
            onChange={onChange}
            type="money"
            maxLength={7}
          />
        </div>
      </div>
      <div className="max-xl:flex-1">
        <InputLabel text={'Retiro en correo'} />
        <div className="w-full">
          <TextInput
            ref={el => (inputsRef.current[1] = el)}
            error={errors.some(e => e.input === 'store')}
            object={object}
            name={'store'}
            onChange={onChange}
            type="money"
            maxLength={7}
          />
        </div>
      </div>
      <div className="max-xl:flex-1">
        <InputLabel text={'Envío por moto'} />
        <div className="w-full">
          <TextInput
            ref={el => (inputsRef.current[2] = el)}
            error={errors.some(e => e.input === 'motorbike')}
            object={object}
            name={'motorbike'}
            onChange={onChange}
            type="money"
            maxLength={7}
          />
        </div>
      </div>
    </div>
  )
})

const TransferInputs = forwardRef(({ object, onChange }, ref) => {
  const [errors] = useVarsContext().errorsState
  const [defaultValue, setDefaultValue] = useState(object)
  const [items, setItems] = useState(defaultValue)
  const itemsRef = useRef([])

  useEffect(() => {
    setDefaultValue(object)
    setItems(object)
  }, [object])

  useImperativeHandle(ref, () => ({
    value: items,
    reset: () => {
      itemsRef.current.forEach(item => item?.reset())
      setItems(defaultValue)
    },
    valueChanged: !isEqual(items, defaultValue),
    toSend: () => ({ billing: items }),
  }))

  const handleNewItem = () => {
    const id = uniqueId()
    setItems(ci => [...ci, { id, is_checking: false, full_name: '', cuit: '', bank: '', cbu: '', alias: '' }])
    setTimeout(() => {
      const node = document.getElementById(`option-${id}`)
      if (node) node.scrollIntoView()
    }, 100)
    onChange()
  }

  return (
    <div className="w-full flex flex-col gap-4">
      <button
        type="button"
        className="w-full py-2 text-gray-500 flex justify-center items-center rounded-md border border-gray-200 hover:border-gray-500"
        onClick={handleNewItem}>
        <MdOutlineAdd className="w-5 h-5 mr-1" />
        Agregar opción
      </button>
      {items.map((item, index) => (
        <div key={item.id} className="w-full flex flex-col gap-2">
          <p id={`option-${item.id}`} className="flex font-semibold">
            Opción {index + 1}{' '}
            <button
              type="button"
              onClick={() => {
                setItems(ci => ci.filter(citem => citem.id !== item.id))
                onChange()
              }}
              className="pl-2 font-medium text-amber-500 hover:text-amber-600 transition-colors">
              Eliminar
            </button>
          </p>
          <div className="flex">
            <div className="flex items-center">
              <ToggleInput
                object={item}
                name="is_checking"
                onChange={v => {
                  setItems(ci => ci.map(citem => (citem.id === item.id ? { ...citem, is_checking: v } : citem)))
                  onChange()
                }}
              />
              <InputLabel text="Es cuenta corriente" />
            </div>
          </div>
          <div className="flex max-xl:flex-col gap-4">
            <div className="xl:w-0 xl:grow">
              <InputLabel text="Nombre Completo" error={errors.some(e => e.input === `${item.id}-full_name`)} />
              <TextInput
                error={errors.some(e => e.input === `${item.id}-full_name`)}
                object={item}
                name="full_name"
                format={v => CapitalizeAllWords(v)}
                unformat={v => CapitalizeAllWords(v)}
                onChange={v => {
                  setItems(ci => ci.map(citem => (citem.id === item.id ? { ...citem, full_name: v } : citem)))
                  onChange()
                }}
              />
            </div>
            <div className="xl:w-0 xl:grow">
              <InputLabel text="CUIT" error={errors.some(e => e.input === `${item.id}-cuit`)} />
              <TextInput
                error={errors.some(e => e.input === `${item.id}-cuit`)}
                object={item}
                name="cuit"
                format={v => FormatCuit(v)}
                maxLength={13}
                onChange={v => {
                  setItems(ci => ci.map(citem => (citem.id === item.id ? { ...citem, cuit: v } : citem)))
                  onChange()
                }}
              />
            </div>
          </div>
          <div className="flex max-xl:flex-col gap-4">
            <div className="xl:w-0 xl:grow">
              <InputLabel text="Banco" error={errors.some(e => e.input === `${item.id}-bank`)} />
              <TextInput
                error={errors.some(e => e.input === `${item.id}-bank`)}
                object={item}
                name="bank"
                // format={(v) => CapitalizeAllWords(v)}
                onChange={v => {
                  setItems(ci => ci.map(citem => (citem.id === item.id ? { ...citem, bank: v } : citem)))
                  onChange()
                }}
              />
            </div>
            <div className="xl:w-0 xl:grow">
              <InputLabel text="CBU" error={errors.some(e => e.input === `${item.id}-cbu`)} />
              <TextInput
                error={errors.some(e => e.input === `${item.id}-cbu`)}
                object={item}
                name="cbu"
                unformat={v => OnlyNumbers(v)}
                maxLength={22}
                onChange={v => {
                  setItems(ci => ci.map(citem => (citem.id === item.id ? { ...citem, cbu: v } : citem)))
                  onChange()
                }}
              />
            </div>
            <div className="xl:w-0 xl:grow">
              <InputLabel text="Alias" error={errors.some(e => e.input === `${item.id}-alias`)} />
              <TextInput
                error={errors.some(e => e.input === `${item.id}-alias`)}
                object={item}
                name="alias"
                onChange={v => {
                  setItems(ci => ci.map(citem => (citem.id === item.id ? { ...citem, alias: v } : citem)))
                  onChange()
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
})

const VouchersInputs = forwardRef(({ object, onChange }, ref) => {
  const [errors] = useVarsContext().errorsState
  const inputsRef = useRef([])

  useImperativeHandle(ref, () => ({
    reset: () => inputsRef.current.forEach(input => input.reset()),
    valueChanged: inputsRef.current.some(input => input.valueChanged),
    toSend: () => ({ vouchers: inputsToObj(inputsRef) }),
  }))

  return (
    <>
      <div>
        <InputLabel text="Leyenda" error={errors.some(e => e.input === 'caption')} />
        <TextInput
          ref={el => (inputsRef.current[0] = el)}
          error={errors.some(e => e.input === 'caption')}
          object={object}
          name="caption"
          onChange={onChange}
          format={v => v.toUpperCase()}
        />
      </div>
      <div className="flex max-xl:flex-col gap-4">
        <div className="xl:w-0 xl:grow">
          <InputLabel text="Inicio de Actividades" error={errors.some(e => e.input === 'activities_starting_date')} />
          <TextInput
            ref={el => (inputsRef.current[1] = el)}
            error={errors.some(e => e.input === 'activities_starting_date')}
            object={object}
            name="activities_starting_date"
            maxLength={10}
            onChange={onChange}
          />
        </div>
        <div className="xl:w-0 xl:grow">
          <InputLabel text="Punto de Venta" error={errors.some(e => e.input === 'sales_point')} />
          <TextInput
            ref={el => (inputsRef.current[2] = el)}
            error={errors.some(e => e.input === 'sales_point')}
            object={object}
            name="sales_point"
            onChange={onChange}
            maxLength={4}
            type="number"
          />
        </div>
      </div>
    </>
  )
})

const MediaInputs = forwardRef(({ object, onChange }, ref) => {
  const [errors] = useVarsContext().errorsState
  const inputsRef = useRef([])

  useImperativeHandle(ref, () => ({
    reset: () => inputsRef.current.forEach(input => input.reset()),
    valueChanged: inputsRef.current.some(input => input.valueChanged),
    toSend: () => ({ media: inputsToObj(inputsRef) }),
  }))

  return (
    <>
      <div className="flex max-xl:flex-col gap-4">
        <div className="w-full xl:w-max">
          <InputLabel text="Teléfono fijo" error={errors.some(e => e.input === 'landline_phone')} />
          <div className="xl:w-44">
            <TextInput
              ref={el => (inputsRef.current[0] = el)}
              error={errors.some(e => e.input === 'landline_phone')}
              object={object}
              name="landline_phone"
              onChange={onChange}
            />
          </div>
        </div>
        <div className="w-full xl:w-max">
          <InputLabel text="Número de celular" error={errors.some(e => e.input === 'contact_number')} />
          <div className="xl:w-44">
            <TextInput
              ref={el => (inputsRef.current[0] = el)}
              error={errors.some(e => e.input === 'contact_number')}
              object={object}
              name="contact_number"
              onChange={onChange}
            />
          </div>
        </div>
        <div className="w-full xl:w-max">
          <InputLabel text="E-mail" error={errors.some(e => e.input === 'email')} />
          <div className="xl:w-60">
            <TextInput
              ref={el => (inputsRef.current[1] = el)}
              error={errors.some(e => e.input === 'email')}
              object={object}
              name="email"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="flex max-xl:flex-col gap-4">
        <div className="xl:w-0 xl:grow">
          <InputLabel text="Facebook" error={errors.some(e => e.input === 'facebook')} />
          <TextInput
            ref={el => (inputsRef.current[2] = el)}
            error={errors.some(e => e.input === 'facebook')}
            object={object}
            name="facebook"
            onChange={onChange}
          />
        </div>
        <div className="xl:w-0 xl:grow">
          <InputLabel text="Instagram" error={errors.some(e => e.input === 'instagram')} />
          <TextInput
            ref={el => (inputsRef.current[1] = el)}
            error={errors.some(e => e.input === 'instagram')}
            object={object}
            name="instagram"
            onChange={onChange}
          />
        </div>
      </div>
      <div className="flex max-xl:flex-col gap-4">
        <div className="xl:w-0 xl:grow">
          <InputLabel text="Twitter" error={errors.some(e => e.input === 'twitter')} />
          <TextInput
            ref={el => (inputsRef.current[3] = el)}
            error={errors.some(e => e.input === 'twitter')}
            object={object}
            name="twitter"
            onChange={onChange}
          />
        </div>
        <div className="xl:w-0 xl:grow">
          <InputLabel text="YouTube" error={errors.some(e => e.input === 'youtube')} />
          <TextInput
            ref={el => (inputsRef.current[4] = el)}
            error={errors.some(e => e.input === 'youtube')}
            object={object}
            name="youtube"
            onChange={onChange}
          />
        </div>
      </div>
    </>
  )
})

export default AdminVarsConfig

export const Clamp = (num, min, max) => Math.min(Math.max(num, min), max)

export const GetRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
}

export const GetDiscountPercent = (normal_price, offer_price) => {
  return Math.round(((normal_price - offer_price) / normal_price) * 100)
}

export const RoundTo1Decimal = value => {
  return value ? Math.round(value * 10) / 10 : undefined
}

export const UTCoinsFromValue = (value, dolarBlue, ut_coins_multiplier) =>
  RoundTo1Decimal((value / dolarBlue?.value_sell) * ut_coins_multiplier * 10)

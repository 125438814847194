import { isEqual } from 'lodash'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import Toggle from '../Toggle'

const ToggleInput = forwardRef(({ object, name, onChange }, ref) => {
  const [defaultValue, setDefaultValue] = useState(object[name])
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    const value = !!object[name]
    setDefaultValue(value)
    setValue(value)
  }, [object]) //eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    value: value,
    reset: () => setValue(defaultValue),
    valueChanged: !isEqual(value, defaultValue),
    toSend() {
      return { [name]: value }
    },
  }))

  return (
    <Toggle
      toggled={value}
      onClick={() => {
        setValue(!value)
        onChange(!value)
      }}
      size="lg"
    />
  )
})

export default ToggleInput

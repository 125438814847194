import { MdSearchOff, MdErrorOutline, MdSend, MdOutlineClose } from 'react-icons/md'
import { BsDash, BsGrid, BsList, BsPlus } from 'react-icons/bs'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useEffect, useState } from 'react'

import Dropdown from '../utils/Dropdown'
import { setDocumentTitle, saveCookie, useWindowSize } from '../utils/Html'
import { FormatPrice, CapitalizeAllWords, ProductLink } from '../utils/Strings'
import { useMainContext } from '../utils/MainContext'
import axios from 'axios'
import Breadcrumb from '../utils/Breadcrumb'
import FavoriteButton from '../utils/FavoriteButton'
import LoadingBox from '../utils/LoadingBox'
import ShowImage from '../utils/ShowImage'
import PlusMinusInput from '../utils/PlusMinusInput'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import { FiChevronDown } from 'react-icons/fi'
import { toNumber, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { Sticky, StickyContainer } from 'react-sticky'
import { useAbortableEffect } from '../utils/Hooks'

function Catalogue({ setSearchQuery }) {
  const { firebase, topbarSize } = useMainContext()
  const [windowWidth] = useWindowSize()
  let { category, subCategory, search } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()
  search = search ? search.replace(/_/g, ' ') : ''

  const [canSearch, setCanSearch] = useState(false)

  const [data, setData] = useState(null)
  const products = data?.products ?? null
  const brands = data?.brands ?? null
  const categories = data?.categories ?? null
  const [error, setError] = useState({ code: null, text: null })

  const [showFilters, setShowFilters] = useState(false)
  const [view, setView] = useState('list')
  const gridColumns = 3
  const limit = 30

  const categoryFound = category && categories ? categories.find(c => c.category_id === category) : null
  const subCategoryFound =
    subCategory && categories && categoryFound && categoryFound.children
      ? categoryFound.children.find(sc => sc.category_id === subCategory)
      : null

  const searchTitle =
    search !== 'all'
      ? CapitalizeAllWords(search)
      : data
      ? subCategoryFound
        ? 'Todo en ' + CapitalizeAllWords(subCategoryFound.name)
        : categoryFound
        ? 'Todo en ' + CapitalizeAllWords(categoryFound.name)
        : 'Todos los productos'
      : 'Cargando...'

  setDocumentTitle(searchTitle)

  useEffect(() => {
    setSearchQuery(search === 'all' ? '' : search)
  }, [search, setSearchQuery])

  const resetData = () => {
    setData(null)
    setShowFilters(false)
    setError({ code: null, text: null })
  }

  useEffect(() => {
    if (windowWidth < 1280) {
      setView('list')
    } else {
      setShowFilters(false)
    }
  }, [windowWidth])

  useMemo(() => {
    const paramsToRemove = []

    const sortParam = searchParams.get('sort')
    if (sortParam && sortParam !== 'price_asc' && sortParam !== 'price_desc') {
      paramsToRemove.push('sort')
    }

    const fromParam = searchParams.get('from')
    if (fromParam && (!toNumber(fromParam) || fromParam === '0')) {
      paramsToRemove.push('from')
    }

    if (!isEmpty(paramsToRemove))
      setSearchParams(
        new URLSearchParams([...searchParams.entries()].filter(([key]) => !paramsToRemove.find(p => p === key)))
      )

    setCanSearch(true)
  }, [searchParams, setSearchParams])

  useAbortableEffect(
    signal => {
      if (!search || !canSearch) return

      resetData()
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
      ;(async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_API_LINK + 'get_catalogue', {
            params: {
              search: search === 'all' ? '' : search,
              category: category ?? '',
              subcategory: subCategory ?? '',
              brand: searchParams.get('brand') ?? '',
              min_price: searchParams.get('min') ?? '',
              max_price: searchParams.get('max') ?? '',
              from: searchParams.get('from') ?? '',
              sort: searchParams.get('sort') ?? '',
              limit,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(response)

          setData(response.data)
        } catch (err) {
          if (err?.code === 'ERR_CANCELED') return

          // console.error(err.response?.data?.message ?? err.response?.data)
          if (err.code === 'ERR_BAD_RESPONSE') setError({ code: err.response.status, text: err.response.data })
          else setError({ code: err.code, text: err.message })
          // TODO: Mandar error a DB
        }
      })()
    },
    [canSearch, search, category, subCategory, searchParams]
  )

  // useMemo(() => {
  //   if (!products) return;

  //   setProducts(
  //     // En price_asc y price_desc, si el precio de ambos es el mismo, trato de ordenar por el mas vendido
  //     products.sort((a, b) => {
  //       switch (sortBy) {
  //         default:
  //         case "relevant":
  //           return a.sales < b.sales ? 1 : a.sales > b.sales ? -1 : 0;
  //         case "price_asc":
  //           var aPrice = a.offer_price ?? a.normal_price;
  //           var bPrice = b.offer_price ?? b.normal_price;
  //           return aPrice < bPrice
  //             ? -1
  //             : aPrice > bPrice
  //             ? 1
  //             : a.sales < b.sales
  //             ? 1
  //             : a.sales > b.sales
  //             ? -1
  //             : 0;
  //         case "price_desc":
  //           aPrice = a.offer_price ?? a.normal_price;
  //           bPrice = b.offer_price ?? b.normal_price;
  //           return aPrice < bPrice
  //             ? 1
  //             : aPrice > bPrice
  //             ? -1
  //             : a.sales < b.sales
  //             ? 1
  //             : a.sales > b.sales
  //             ? -1
  //             : 0;
  //       }
  //     })
  //   );
  // }, [products, sortBy]);

  const handlePagination = ev => {
    setSearchParams(
      new URLSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        from: (Math.floor((searchParams.get('from') ?? 0) / limit) + (ev.target.name === 'next' ? 1 : -1)) * limit,
      })
    )
  }

  return (
    <section className="w-full grow bg-white xl:bg-pagebg">
      <div className="w-full xl:w-content-max-width xl:mx-auto xl:pb-40 flex max-xl:flex-col gap-2 text-black">
        {search && !error.text ? (
          !data ? (
            <LoadingBox text={'Buscando productos...'} />
          ) : (
            <>
              <StickyContainer className="w-80 max-xl:hidden overflow-auto">
                <Sticky topOffset={-1 * topbarSize.height}>
                  {({ style, isSticky }) => {
                    return (
                      <div className="w-full pt-6" style={{ ...style, marginTop: isSticky && topbarSize.height }}>
                        {data && (
                          <>
                            <div className="mb-4">
                              {category && categoryFound && (
                                <div className="mb-4">
                                  <Breadcrumb
                                    items={[
                                      { name: 'Todos', link: `/catalogue/${search ?? 'all'}` },
                                      ...(category
                                        ? [
                                            {
                                              name: CapitalizeAllWords(categoryFound.name),
                                              link: subCategory ? `/catalogue/${category}/${search ?? 'all'}` : '',
                                            },
                                          ]
                                        : []),
                                      ...(subCategory && subCategoryFound
                                        ? [
                                            {
                                              name: CapitalizeAllWords(subCategoryFound.name),
                                            },
                                          ]
                                        : []),
                                    ]}
                                  />
                                </div>
                              )}
                              {/* <span className="block mb-2 text-sm text-neutral-600">RESULTADOS PARA:</span> */}
                              <p
                                className={`py-2 xl:mb-1 text-2xl font-medium overflow-visible ${
                                  searchTitle.toLowerCase() === 'ofertas'
                                    ? 'px-4 py-[0.65rem] text-3xl text-center font-extrabold tracking-wide uppercase bg-[#ff0000] text-white rounded-lg'
                                    : ''
                                }`}>
                                {searchTitle}
                              </p>
                              {products.length > 0 && (
                                <>
                                  <span className="text-sm text-neutral-600 max-xl:hidden">{`${data.total_products} ${
                                    data.total_products === 1 ? 'producto' : 'productos'
                                  }`}</span>
                                </>
                              )}
                            </div>
                            <Filters brands={brands} />
                            {products.length > 0 && (
                              <div className="pt-4">
                                <CategoriesFilter {...{ categories, search }} />
                                <BrandFilter {...{ brands, products }} />
                                <PriceFilter />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )
                  }}
                </Sticky>
              </StickyContainer>
              <div className="w-full xl:w-0 xl:grow xl:pt-6 h-max flex flex-col">
                {products.length > 0 ? (
                  <>
                    <div
                      className={`w-full h-max ${
                        showFilters ? 'max-xl:flex-col' : ''
                      } p-4 xl:p-2 xl:mb-2 bg-white xl:border xl:border-gray-200 xl:rounded-md 
                      shadow-sm flex justify-between`}>
                      <div className="w-full flex flex-col">
                        <span
                          className={`font-semibold xl:hidden ${
                            searchTitle.toLowerCase() === 'ofertas'
                              ? 'py-1 text-center text-2xl font-bold uppercase bg-[#ff0000] text-white rounded-md'
                              : ''
                          }`}>
                          {searchTitle}
                        </span>
                        <div className="mt-2 xl:hidden">
                          <Filters brands={brands} />
                        </div>
                        <div className="max-xl:w-full max-xl:pt-4 flex items-center">
                          <span className="pr-2 pb-[1px] pl-2 font-medium max-xl:text-sm max-xl:hidden">
                            Ordenar por
                          </span>
                          <div className="w-48 h-10">
                            <Dropdown
                              items={[
                                // ...(!searchParams.get("min") && !searchParams.get("max")
                                //   ? [{ text: "Más vendidos", value: "relevant" }]
                                //   : []),
                                { text: 'Más vendidos', value: 'relevant' },
                                { text: 'Menor precio', value: 'price_asc' },
                                { text: 'Mayor precio', value: 'price_desc' },
                              ]}
                              title="Ordenar por"
                              onChange={v =>
                                setSearchParams(
                                  new URLSearchParams({
                                    ...Object.fromEntries(
                                      [...searchParams.entries()].filter(([key]) => key !== 'from')
                                    ),
                                    sort: v.value,
                                  })
                                )
                              }
                              defaultValue={searchParams.get('sort') ?? 0}
                            />
                          </div>
                          <div className="w-0 grow flex justify-end xl:hidden">
                            <button
                              type="button"
                              onClick={() => setShowFilters(!showFilters)}
                              className="flex items-center">
                              <span className="text-sm mr-1">Filtros</span>
                              <FiChevronDown
                                className={`w-5 h-5 text-amber-500 ${
                                  showFilters ? 'rotate-180' : 'rotate-0'
                                } transition-all`}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {showFilters && (
                        <div className="w-full py-4 flex flex-col gap-4">
                          <CategoriesFilter {...{ categories, search }} />
                          <BrandFilter {...{ brands, products }} />
                          {data && <PriceFilter />}
                        </div>
                      )}
                      <div className="flex gap-2 max-xl:hidden">
                        <button
                          type="button"
                          title="Ver en cuadrícula"
                          className={`w-10 h-10 rounded-md border ${
                            view === 'grid'
                              ? 'text-dewalt border-dewalt'
                              : 'text-gray-500 border-gray-200 hover:border-gray-500'
                          } transition-all shadow-sm`}
                          onClick={() => setView('grid')}>
                          <BsGrid className="w-full h-full p-2" />
                        </button>
                        <button
                          type="button"
                          title="Ver en lista"
                          className={`w-10 h-10 rounded-md border ${
                            view === 'list'
                              ? 'text-dewalt border-dewalt'
                              : 'text-gray-500 border-gray-200 hover:border-gray-500'
                          } transition-all shadow-sm`}
                          onClick={() => setView('list')}>
                          <BsList className="w-full h-full p-2" />
                        </button>
                      </div>
                    </div>
                    <div
                      className={`relative w-full h-max ${
                        view === 'list'
                          ? 'bg-white xl:border xl:border-gray-200 xl:rounded-md shadow-sm'
                          : 'grid grid-cols-3 gap-2'
                      }`}
                      style={{
                        gridTemplateRows: `repeat(${Math.floor(products.length / gridColumns)}, minmax(0, 1fr))`,
                      }}>
                      <div
                        className={`absolute top-0 left-0 w-full h-full bg-black/50 ${
                          showFilters ? 'pointer-events-auto' : 'pointer-events-none hidden'
                        } z-50`}
                        onClick={() => setShowFilters(false)}
                      />
                      {products.map((v, i) => (
                        <Item
                          user={firebase.user}
                          product={{
                            ...v,
                            brand: brands?.find(brand => brand.brand_id === v.brand_id),
                          }}
                          view={view}
                          key={i}
                        />
                      ))}
                    </div>
                    <div className="w-full py-4 xl:py-8 xl:text-lg flex justify-center items-center">
                      {1 + Math.floor((searchParams.get('from') ?? 0) / limit) > 1 && (
                        <button
                          type="button"
                          name="prev"
                          onClick={handlePagination}
                          className="mr-4 xl:mr-6 text-amber-500 hover:text-amber-600 transition-colors">{`< Anterior`}</button>
                      )}
                      <span
                        className="w-9 h-9 xl:w-10 xl:h-10 bg-neutral-200 text-neutral-500 font-bold
                                  flex justify-center items-center">
                        {1 + Math.floor((searchParams.get('from') ?? 0) / limit)}
                      </span>
                      <span className="ml-2 text-neutral-500 font-light">
                        de {Math.ceil(data.total_products / limit)}
                      </span>
                      {1 + Math.floor((searchParams.get('from') ?? 0) / limit) <
                        Math.ceil(data.total_products / limit) && (
                        <button
                          type="button"
                          name="next"
                          onClick={handlePagination}
                          className="ml-4 xl:ml-6 text-amber-500 hover:text-amber-600 transition-colors">{`Siguiente >`}</button>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="w-full py-8 bg-white text-black flex flex-col justify-start items-center rounded-md shadow-md">
                    <MdSearchOff className="w-28 h-28 text-gray-200" />
                    <p className="mt-2 text-neutral-600 text-xl text-center">No hay resultados para tu búsqueda.</p>
                  </div>
                )}
              </div>
            </>
          )
        ) : (
          <div className="w-full py-16 bg-white text-black flex flex-col justify-start items-center rounded-md shadow-md">
            {error.text ? (
              <MdErrorOutline className="w-32 h-32 text-gray-200" />
            ) : (
              <MdSearchOff className="w-32 h-32 text-gray-200" />
            )}
            <p className="mt-3 text-xl text-center">
              {error.text ? (
                <>
                  Ups! Hubo un problema al realizar la búsqueda.<br></br>Intentá de nuevo más tarde.
                </>
              ) : (
                'Usá el buscador de arriba para encontrar lo que querés.'
              )}
            </p>
          </div>
        )}
      </div>
    </section>
  )
}

function Filters({ brands }) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div className="w-full flex flex-wrap gap-2">
      {[
        ...(searchParams.has('brand')
          ? [
              {
                name: brands?.find(item => item.brand_id === searchParams.get('brand'))?.name,
                onClick: () =>
                  setSearchParams(
                    new URLSearchParams({
                      ...Object.fromEntries(
                        [...searchParams.entries()].filter(([key]) => key !== 'from' && key !== 'brand')
                      ),
                    })
                  ),
              },
            ]
          : []),
        ...(searchParams.has('min') && searchParams.get('min') !== ''
          ? [
              {
                name: `>= ${FormatPrice(searchParams.get('min'))}`,
                onClick: () =>
                  setSearchParams(
                    new URLSearchParams({
                      ...Object.fromEntries(
                        [...searchParams.entries()].filter(([key]) => key !== 'from' && key !== 'min')
                      ),
                    })
                  ),
              },
            ]
          : []),
        ...(searchParams.has('max') && searchParams.get('max') !== ''
          ? [
              {
                name: `<= ${FormatPrice(searchParams.get('max'))}`,
                onClick: () =>
                  setSearchParams(
                    new URLSearchParams({
                      ...Object.fromEntries(
                        [...searchParams.entries()].filter(([key]) => key !== 'from' && key !== 'max')
                      ),
                    })
                  ),
              },
            ]
          : []),
      ].map((item, index) => (
        <button
          key={index}
          type="button"
          onClick={item.onClick}
          className="px-2 py-1 bg-gray-200 flex items-center rounded-md border border-gray-400 group">
          <span className="text-black/60 text-sm font-medium pr-2">{item.name}</span>
          <MdOutlineClose className="w-4 h-4 text-black/50 group-hover:text-black" />
        </button>
      ))}
    </div>
  )
}

function CategoriesFilter({ categories, search }) {
  const getParams = () =>
    new URLSearchParams([...new URLSearchParams(window.location.search)].filter(([key]) => key !== 'from')).toString()

  return (
    categories &&
    ((categories.length > 1 && !categories.every(v => v.category_id === categories[0].category_id)) ||
      (categories.length === 1 &&
        !categories[0]?.children?.every(v => v.category_id === categories[0]?.children[0]?.category_id))) && (
      <div className="xl:mb-8">
        <span className="block mb-2 font-medium">Categorías</span>
        <ul className="text-sm flex flex-col gap-1">
          {categories.length > 1 && !categories.every(v => v.category_id === categories[0].category_id)
            ? categories
                .toSorted((a, b) => (a.count === b.count ? a.name > b.name : a.count < b.count))
                .map((v, i) => (
                  <li key={i} className="w-max">
                    <Link
                      to={`/catalogue/${v.category_id}/${search}?${getParams()}`}
                      className="text-neutral-600 hover:text-black">
                      {v.name} <span className="text-neutral-400">({v.count})</span>
                    </Link>
                  </li>
                ))
            : categories[0]?.children
                ?.toSorted((a, b) => (a.count === b.count ? a.name > b.name : a.count < b.count))
                ?.map((v, i) => (
                  <li key={i} className="w-max group">
                    <Link
                      to={`/catalogue/${categories[0].category_id}/${v.category_id}/${search}?${getParams()}`}
                      className="text-neutral-600 group-hover:text-black transition-colors">
                      {v.name}{' '}
                      <span className="text-neutral-400 group-hover:text-black transition-colors">({v.count})</span>
                    </Link>
                  </li>
                ))}
        </ul>
      </div>
    )
  )
}

function BrandFilter({ brands }) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    brands &&
    brands.length > 1 && (
      <div className="xl:mb-8">
        <span className="block mb-2 font-medium">Marca</span>
        <ul className="text-sm flex flex-col gap-1">
          {brands
            .toSorted((a, b) => (a.count === b.count ? a.name > b.name : a.count < b.count))
            .map((brand, i) => (
              <li key={i}>
                <button
                  type="button"
                  className="text-neutral-600 hover:text-black"
                  onClick={() =>
                    setSearchParams(
                      new URLSearchParams({
                        ...Object.fromEntries([...searchParams.entries()].filter(([key]) => key !== 'from')),
                        brand: `${brand.brand_id}`,
                      })
                    )
                  }>
                  {brand.name} <span className="text-neutral-400">({brand.count})</span>
                </button>
              </li>
            ))}
        </ul>
      </div>
    )
  )
}

function PriceFilter() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [minPrice, setMinPrice] = useState(searchParams.get('min') ?? '')
  const [maxPrice, setMaxPrice] = useState(searchParams.get('max') ?? '')

  const handlePriceFilter = ev => {
    ev.preventDefault()
    setSearchParams(
      new URLSearchParams({
        // sort: "price_asc",
        ...Object.fromEntries([...searchParams.entries()].filter(([key]) => key !== 'from')),
        ...Object.fromEntries(new FormData(ev.target).entries()),
      })
    )
  }

  return (
    <div className="xl:mb-8">
      <span className="block mb-2 font-medium">Precio</span>
      <form className="w-full xl:pr-24 h-7 flex items-center" onSubmit={handlePriceFilter}>
        <input
          type="text"
          className="w-0 grow h-full px-2 text-sm bg-white rounded-md
                    border border-gray-200 hover:border-gray-500 focus:border-dewalt outline-none"
          name="min"
          value={minPrice}
          onChange={ev => setMinPrice(ev.target.value)}
          placeholder="Mínimo"
          pattern="^[0-9]+([,.][0-9]+)?$"
        />
        <BsDash className="w-5 h-5 mx-1 text-gray-400" />
        <input
          type="text"
          className="w-0 grow h-full px-2 text-sm bg-white rounded-md
                    border border-gray-200 hover:border-gray-500 focus:border-dewalt outline-none"
          name="max"
          value={maxPrice}
          onChange={ev => setMaxPrice(ev.target.value)}
          placeholder="Máximo"
          pattern="^[0-9]+([,.][0-9]+)?$"
        />
        <button
          type="submit"
          className="w-7 h-7 p-2 ml-2 bg-dewalt disabled:bg-neutral-300 enabled:hover:bg-amber-500
                    rounded-full transition-colors"
          disabled={!minPrice && !maxPrice}>
          <MdSend className="w-full h-full text-white" />
        </button>
      </form>
    </div>
  )
}

function Item({ user, product, view }) {
  const {
    product_id,
    title,
    image,
    normal_price,
    offer_price,
    brand,
    stock,
    offer_cost_stock,
    is_best_seller,
    recommended_by_seller,
  } = product
  const { firebase, setFirebase, showToast, setShowCart } = useMainContext()
  const navigate = useNavigate()
  const productLink = ProductLink(product_id, title)
  const brandSearchLink = `/catalogue/all?brand=${brand?.brand_id}`

  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState(
    user?.cart.some(item => item.product_id === product_id)
      ? user?.cart.find(item => item.product_id === product_id).amount
      : 0
  )

  const handleUpdateCart = async (action, amount) => {
    try {
      if (!user) navigate('/notloggedin/cart')

      setLoading(true)
      const result = await axios.put(
        process.env.REACT_APP_API_LINK + 'update_cart',
        {
          user_id: user.uid,
          product_id: product_id,
          action: action,
          amount: action === 'remove' ? '' : amount,
        },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      const cart = result.data.cart
      user.cart = cart
      setFirebase({ ...firebase, user: user })
      saveCookie('cart', cart)

      showToast(action === 'remove' ? 'El producto se borró del carrito.' : '¡Se actualizó el carrito!', 'good')

      setLoading(false)
      // window.scroll({ left: 0, top: 0, behavior: 'smooth' })
      setShowCart(true)
    } catch (err) {
      setLoading(false)
      if (err.response?.status === 409 && err.response?.data?.message) {
        showToast(err.response.data.message, 'bad')
      }
      // console.log(err)
    }
  }

  useEffect(() => {
    if (!user?.cart) return

    const cartItem = user?.cart.find(item => item.product_id === product_id)
    if (!cartItem) return

    setAmount(cartItem.amount)
  }, [user?.cart, product_id])

  return (
    <div
      className={`${
        view === 'grid'
          ? `w-full h-[540px] bg-white rounded-md shadow-sm border border-gray-200 hover:shadow-xl flex flex-col transition-shadow`
          : `w-full h-40 xs:h-44 xl:h-60 p-2 xl:p-4 border-t border-gray-200 first:border-t-0 flex max-xl:last:border-b max-xl:last:border-gray-200`
      } relative group`}>
      {(!user || user.role === 'user') && (
        <div className="absolute top-2 right-2 xl:top-4 xl:right-4">
          <FavoriteButton product_id={product_id} />
        </div>
      )}
      <div
        className={
          view === 'grid' ? 'w-full p-2 aspect-square border-b border-gray-200' : 'h-full aspect-square relative'
        }>
        <AspectRatio.Root ratio={1}>
          <Link to={productLink} className="w-full h-full">
            <ShowImage image={image} alt={title} />
          </Link>
        </AspectRatio.Root>
      </div>
      <div className={`${view === 'grid' ? 'w-full h-full p-4' : 'w-0 grow-[8] h-full pl-2 xl:pl-4'} flex flex-col`}>
        {(is_best_seller || recommended_by_seller) && (
          <div className="mb-1 flex gap-2 max-xl:text-[0.5rem] xl:text-xs">
            {is_best_seller && (
              <span className="py-[3px] px-2 bg-amber-500 text-white font-medium rounded-md flex items-center">
                MÁS&nbsp;VENDIDO
              </span>
            )}
            {recommended_by_seller && (
              <div className="py-[3px] px-2 bg-black text-white font-medium rounded-md flex items-center">
                <img
                  src="/universal-tools-logo-notext.svg"
                  className="w-8 h-4 max-xl:w-6 max-xl:h-2 mr-1 object-contain"
                  alt="yaguarete"
                />
                RECOMENDADO
              </div>
            )}
          </div>
        )}
        <Link
          to={productLink}
          className={`mb-1 xl:mb-2 ${
            view === 'grid' ? 'text-sm' : 'text-xs xl:text-xl pr-4 xl:pr-10'
          } w-full xl:font-light hyphens-none line-clamp-2`}>
          {title}
        </Link>
        {offer_price && (
          <p className="text-xs xl:text-sm leading-3 font-light">
            <del>{FormatPrice(normal_price)}</del>
          </p>
        )}
        <Link to={productLink} className="w-max flex items-center">
          <span className="text-lg xl:text-2xl font-medium">{FormatPrice(offer_price ?? normal_price)}</span>
          {offer_price && (
            <span className="text-xs ml-1 xl:text-base xl:ml-2 text-green-600">{`${Math.round(
              ((normal_price - offer_price) / normal_price) * 100
            )}% OFF`}</span>
          )}
        </Link>
        {(!user || user.role === 'user') && (
          <div className={`${view === 'grid' ? 'w-full pr-24 h-0' : 'w-max'} grow flex items-end`}>
            {user?.cart.some(item => item.product_id === product_id) ? (
              <div className="w-full bg-dewalt flex flex-col items-center rounded-md">
                <span className="w-max py-[1px] text-xs xl:text-sm font-medium">En el carrito</span>
                <div className={`relative ${view === 'grid' ? 'w-full' : 'w-[8.5rem] xl:w-[10.5rem]'} h-7 xl:h-10`}>
                  {loading && (
                    <div className="absolute top-0 left-0 w-full h-full p-1 bg-white/70 flex justify-center items-center">
                      <img src="/loading.svg" className="w-max h-full" alt="Cargando" />
                    </div>
                  )}
                  <PlusMinusInput
                    min={0}
                    max={stock + offer_cost_stock}
                    state={[amount, setAmount]}
                    onValueChange={v => {
                      // console.log(v)
                      handleUpdateCart(v === 0 ? 'remove' : v > amount ? 'add' : 'subtract', Math.abs(v - amount))
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="relative">
                {loading && (
                  <div className="absolute top-0 left-0 w-full h-full p-1 bg-white/70 flex justify-center items-center">
                    <img src="/loading.svg" className="w-max h-full" alt="Cargando" />
                  </div>
                )}
                <button
                  type="button"
                  className="w-max h-7 p-2 xl:h-8 xl:p-4 xl:pl-2 bg-dewalt border border-transparent hover:border-gray-500
                          shadow-sm hover:shadow-md flex items-center rounded-md transition-all"
                  onClick={() => handleUpdateCart('add', 1)}>
                  <BsPlus className="w-5 h-5 mr-1 xl:w-6 xl:h-6 xl:mr-2" />
                  <span className="text-xs xl:text-sm font-medium">Agregar al carrito</span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="absolute right-0 bottom-0 w-max h-max m-2 max-[360px]:mb-11 xl:m-4 flex flex-col justify-end items-end">
        <Link
          to={brandSearchLink}
          title={`Ver productos ${brand?.name}`}
          className="w-12 h-12 p-[2px] xl:w-20 xl:h-20 xl:p-2 rounded-md bg-white border border-gray-200 hover:border-gray-500 
                     shadow-sm hover:shadow-md transition-all">
          <ShowImage image={brand?.image} alt={brand?.name} />
        </Link>
      </div>
    </div>
  )
}

export default Catalogue

import { Link } from "react-router-dom";
import ShowImage from "./ShowImage";
import "./Cardv2.css";

function Cardv2({ img, title, sub_img, link, grow = true }) {
  return (
    <div
      className={`w-0 grow px-2 first:pl-0 last:pr-0 ${
        grow ? "hover:grow-[1.1]" : ""
      } transition-all duration-300`}
    >
      <Link
        to={link}
        className={`w-full overflow-hidden ${
          title !== undefined ? (img !== undefined ? "h-40 xl:h-72" : "h-full") : "h-32 xl:h-60"
        } shadow-sm hover:shadow-lg outline outline-2 -outline-offset-2 outline-transparent hover:outline-white
       hover:drop-shadow-xl rounded-lg flex flex-col ${
         !title ? "justify-center" : ""
       } relative transition-all duration-300 group`}
      >
        {img && (
          <>
            <ShowImage image={img} alt={`Img`} contain={false} />
            <div
              className="absolute w-full h-full left-0 top-0 bg-white mix-blend-overlay opacity-0
                  group-hover:opacity-25 transition-all duration-300"
            />
            <div
              className="absolute w-full h-full left-0 top-0 bg-white mix-blend-multiply opacity-90
                  innershadow"
            />
          </>
        )}
        {title !== undefined && (
          <div
            className={`absolute top-0 left-0 w-full h-full px-3 xl:px-4 pb-4 xl:pb-6 text-white text-sm xl:text-2xl 
          font-bold font-sans-condensed uppercase hyphens-none text-center
          flex justify-center items-end select-none transition-all duration-300`}
          >
            <div className="flex flex-col items-center gap-2">
              {sub_img && (
                <div className="w-36 h-14 max-xl:hidden">
                  <ShowImage image={sub_img} alt={"Sub img"} />
                </div>
              )}
              {title}
            </div>
          </div>
        )}
      </Link>
    </div>
  );
}

export default Cardv2;

import axios from 'axios'
import { useState, useEffect, useRef } from 'react'

export const useAbortableEffect = (callback, deps) => {
  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    callback(signal)

    return () => controller.abort()
  }, deps) //eslint-disable-line react-hooks/exhaustive-deps
}

export const useDolarBlue = () => {
  const [dolarBlue, setDolarBlue] = useState(null)

  useAbortableEffect(
    signal => {
      if (dolarBlue !== null) return
      ;(async () => {
        try {
          const result = await axios.get(process.env.REACT_APP_DOLAR_URL, { signal })
          setDolarBlue({ ...result.data.blue, last_update: result.data.last_update })
        } catch (err) {
          // console.log(err)
        }
      })()
    },
    [dolarBlue]
  )

  return [dolarBlue, () => setDolarBlue(null)]
}

export const useUTCoinsInSite = (user_id, isAuthorized) => {
  const [utCoinsInSite, setUTCoinsInSite] = useState(null)

  useAbortableEffect(
    signal => {
      if (!isAuthorized || utCoinsInSite !== null) return
      ;(async () => {
        try {
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_ut_coins_in_site_admin', {
            params: {
              user_id,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          setUTCoinsInSite(result.data.ut_coins_in_site)
        } catch (err) {
          // console.log(err)
        }
      })()
    },
    [utCoinsInSite]
  )

  return [utCoinsInSite]
}

export const useElementSize = () => {
  const ref = useRef(null)
  const [size, setSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (!ref.current) return

    const observer = new ResizeObserver(() => {
      setSize({ width: ref.current.clientWidth, height: ref.current.clientHeight })
    })
    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [])

  return { ref, size }
}

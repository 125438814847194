import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md'
import { useMainContext } from './MainContext'
import { useNavigate } from 'react-router-dom'
import { saveCookie } from './Html'

function FavoriteButton({ product_id, always_visible, size = 'base' }) {
  const { firebase, setFirebase, showToast } = useMainContext()
  const navigate = useNavigate()

  const [active, setActive] = useState(false)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setActive(firebase.user?.favs?.find(v => v === product_id))
  }, [firebase.user?.favs, product_id])

  const updateFavorite = async () => {
    if (!firebase.user) {
      navigate('/notloggedin/fav')
      return
    }

    setDisabled(true)

    try {
      const result = await axios.put(
        process.env.REACT_APP_API_LINK + 'update_fav_on_user',
        {
          product_id: product_id,
          user_id: firebase.user?.uid,
          action: active ? 'delete' : 'add',
        },
        {
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
        }
      )

      showToast(active ? 'Producto borrado de favoritos.' : '¡Producto agregado a favoritos!', 'good')

      firebase.user.favs = result.data.favs
      setFirebase({ ...firebase, user: firebase.user })
      saveCookie('favs', result.data.favs)
      setDisabled(false)
    } catch (err) {
      if (err.response.status === 400) {
        firebase.user.favs = err.response.data.favs
        setFirebase({ ...firebase, user: firebase.user })
        saveCookie('favs', err.response.data.favs)
      } else {
        // console.log(err);
        // TODO: Mandar error a DB
      }
      showToast(`Ocurrió un error al ${active ? 'borrar' : 'agregar'} el producto a favoritos.`, 'bad')
      setDisabled(false)
    }
  }

  return (
    <button
      type="button"
      className={`relative z-[5] ${size === 'lg' ? 'w-7 h-7' : 'w-6 h-6'} text-dewalt 
                  before:absolute before:-top-1/4 before:-left-1/4 before:w-[150%] before:h-[150%]
                  before:bg-white before:bg-opacity-90 before:rounded-3xl transition-all ${
                    active || always_visible ? 'block' : `hidden max-xl:block`
                  } xl:group-hover:block`}
      onClick={updateFavorite}
      disabled={disabled}>
      <MdFavoriteBorder className="relative w-full h-full" />
      <MdFavorite
        className={`absolute w-full h-full top-0 left-0 ${active ? 'p-0' : 'p-4'} transition-all duration-300`}
      />
    </button>
  )
}

export default FavoriteButton

import React, { useEffect, useState } from "react";
import { MdDone } from "react-icons/md";

function ButtonWithProgress({ text, onClick, clickable, loading, done, fullWidth = false }) {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setClicked(loading || done);
  }, [loading, done]);

  return (
    <button
      type={onClick ? "button" : "submit"}
      className={`${
        fullWidth ? "w-full" : "w-max"
      } h-full relative px-4 overflow-hidden flex flex-col justify-start items-center font-medium transition-all ${
        loading
          ? "bg-black text-dewalt"
          : clickable
          ? "bg-dewalt text-black hover:bg-black hover:text-dewalt"
          : "bg-neutral-300 text-neutral-500"
      } rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-black group`}
      {...(onClick
        ? {
            onClick: () => {
              if (!clicked) onClick();
            },
          }
        : {})}
      disabled={!clickable}
    >
      <div className="w-max h-0 overflow-hidden">{text}</div>
      <div
        className={`absolute w-full h-full flex justify-center items-center transition-all duration-300 left-0 ${
          done ? "bottom-[200%]" : loading ? "bottom-full" : "bottom-0 active:-bottom-2"
        }`}
      >
        {text}
      </div>
      <div
        className={`absolute w-full h-full flex justify-center items-center transition-all duration-300 left-0 ${
          done ? "bottom-full" : loading ? "bottom-0" : "-bottom-full"
        }`}
      >
        <img src="/loading.svg" className={`w-6 h-6`} alt="Loading" />
      </div>
      <div
        className={`absolute w-full h-full flex justify-center items-center transition-all duration-300 left-0 ${
          done ? "bottom-0" : loading ? "-bottom-full" : "-bottom-[200%]"
        }`}
      >
        <MdDone className="w-6 h-6" />
      </div>
    </button>
  );
}

export default ButtonWithProgress;

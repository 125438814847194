import { AES, enc } from 'crypto-js'
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const delay = ms => {
  return new Promise(r => setTimeout(r, ms))
}

export const cookieExists = name => {
  return cookies.get(name)
}

export const saveCookie = (name, data, encrypt = false, isSessionCookie = false) => {
  const cookies = new Cookies()
  // console.log({ data: JSON.parse(JSON.stringify(data)) })
  cookies.set(name, encrypt ? AES.encrypt(JSON.stringify(data), 'xd').toString() : data, {
    path: '/',
    sameSite: 'strict',
    // secure: true,
    ...(!isSessionCookie
      ? {
          maxAge: 60 * 60 * 24 * 30, // 30 días
        }
      : {}),
  })
}

export const readCookie = (name, isEncrypted) => {
  const cookie = cookies.get(name, { path: '/' })
  return isEncrypted ? JSON.parse(AES.decrypt(cookie, 'xd').toString(enc.Utf8)) : cookie
}

export const removeCookie = name => {
  cookies.remove(name, { path: '/' })
}

export const setDocumentTitle = title => {
  document.title = `${title ? title + ' - ' : ''}${process.env.REACT_APP_TITLE_END}`
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export const isElementInViewport = (el, offset) => {
  var rect = el.getBoundingClientRect()

  return rect.top - offset >= 0
}

export const useOutsideAlerter = (ref, onClick, excludeClass, active) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && !event.target.className?.includes?.(excludeClass)) {
        if (onClick) onClick()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, excludeClass, onClick])
}

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [])

  useEffect(() => {
    if (!ref || !ref.current) return
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [observer, ref])

  return isIntersecting
}

export const fileExists = async (storage, path) => {
  const fileRef = ref(storage, path)
  try {
    await getDownloadURL(fileRef)
    return true
  } catch (err) {
    if (err.code === 'storage/object-not-found') {
      return false
    } else {
      throw err
    }
  }
}

export const uploadFile = async (storage, blob, path) => {
  const fileRef = ref(storage, path)
  await uploadBytes(fileRef, blob)
  const fileUrl = await getDownloadURL(fileRef)
  return fileUrl
}

export const deleteFile = async (storage, path) => {
  const fileRef = ref(storage, path)
  await deleteObject(fileRef)
}

export const checkIfImageExists = url => {
  const img = new Image()
  img.src = url

  if (img.complete) {
    return true
  } else {
    img.onload = () => {
      return true
    }

    img.onerror = () => {
      return false
    }
  }
}

export const isImageFileValid = (file, resolution, callback) => {
  const checkType = () =>
    file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp')

  if (!checkType()) {
    callback('type')
  } else if (resolution) {
    let fileReader = new FileReader()

    fileReader.onload = e => {
      let img = new Image()

      img.onload = () => {
        let { width, height } = img
        if (width >= resolution.width && height >= resolution.height) callback(null)
        else callback('resolution')
      }
      img.onerror = e => {
        callback(e)
      }

      img.src = e.target.result
    }

    fileReader.onerror = e => {
      callback(e)
    }

    fileReader.readAsDataURL(file)
  } else {
    callback(null)
  }
}

export const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

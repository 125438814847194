import axios from 'axios'
import { round } from 'lodash'
import { useCallback } from 'react'
import { useEffect, useRef, useState } from 'react'
import { MdPerson, MdSearch } from 'react-icons/md'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Sidebar from '../../../Sidebar'
import ButtonWithProgress from '../../../utils/ButtonWithProgress'
import { setDocumentTitle } from '../../../utils/Html'
import LoadingBox from '../../../utils/LoadingBox'
import { useMainContext } from '../../../utils/MainContext'
import ShowImage from '../../../utils/ShowImage'
import { DigitGrouping, TimestampToDate } from '../../../utils/Strings'

function AdminUsers() {
  const { firebase, showToast } = useMainContext()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  const users = data?.users ?? null

  useEffect(() => {
    if (!firebase.user || firebase.user.role === 'user') {
      navigate('/', { replace: true })
    }
    setDocumentTitle('Usuarios')
  }, [firebase.user, navigate])

  const controllerRef = useRef(new AbortController())

  const fetchUsers = useCallback(
    async ev => {
      ev.preventDefault()

      const form = new FormData(ev.target)
      const query = form.get('query')

      try {
        setLoading(true)
        setData(null)

        let result = await axios.get(process.env.REACT_APP_API_LINK + 'get_users_admin', {
          params: {
            user_id: firebase.user?.uid,
            query,
          },
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
          signal: controllerRef.current.signal,
        })
        // console.log(result)

        setData(result.data)
      } catch (err) {
        if (err.code === 'ERR_CANCELED') return
        // console.log(err)
        showToast(process.env.REACT_APP_UNKNOWN_ERROR, 'bad')
      } finally {
        setLoading(false)
      }
    },
    [firebase.user, showToast]
  )

  useEffect(() => {
    const controller = controllerRef.current

    return () => controller.abort()
  }, [])

  return (
    <div className="relative w-full bg-pagebg grow text-black">
      <Sidebar admin={true} />
      <div className="w-full xl:w-content-max-width mx-auto xl:px-10 pb-4">
        <div className="xl:px-60">
          <div
            className="max-xl:px-2 mb-0 max-xl:mb-2 flex flex-col max-xl:bg-white
                     max-xl:border-b max-xl:border-gray-200 max-xl:shadow-sm">
            <h1 className="text-2xl max-xl:text-xl font-medium xl:my-8 max-xl:my-4">Usuarios</h1>
            <form onSubmit={fetchUsers} className="w-full max-xl:pb-2 flex max-xl:flex-col gap-2">
              <input
                type="text"
                name="query"
                maxLength={28}
                className="w-full xl:w-0 xl:grow h-10 bg-white p-2 rounded-md border border-gray-200
                      hover:border-gray-500 focus:border-dewalt outline-none max-xl:text-sm"
                placeholder="DNI/CUIT/CUIL, # de usuario, Nombre y/o apellido"
                defaultValue={searchParams.get('query') ?? ''}
              />
              <div className="w-full xl:w-40 h-10">
                <ButtonWithProgress clickable={true} loading={loading} text={'Buscar'} fullWidth={true} />
              </div>
            </form>
          </div>
          <div className="w-full max-xl:px-2 xl:mt-4 flex flex-col gap-2 xl:gap-4">
            {users ? (
              users.length > 0 ? (
                users.map((user, index) => (
                  <div key={index} className="relative w-full bg-white p-2 xl:p-4 rounded-md">
                    <Link
                      to={`/admin/users/${user.user_id}`}
                      className="absolute top-0 left-0 w-full h-full xl:hidden"
                    />
                    <div className="flex gap-2 xl:gap-4 items-center">
                      <div className="w-10 h-10 xl:w-14 xl:h-14 bg-gray-100 rounded-full border border-gray-200">
                        {user.profile_pic ? (
                          <ShowImage image={user.profile_pic} contain={true} />
                        ) : (
                          <MdPerson className="w-full h-full p-2 text-gray-400" />
                        )}
                      </div>
                      <div className="w-0 grow flex flex-col">
                        <span className="max-xl:text-sm">
                          {user.name} {user.last_name}
                        </span>
                        <span className="text-sm max-xl:text-xs text-gray-500">
                          {user.cuit || 'CUIT no disponible'}
                        </span>
                        <span className="text-sm max-xl:text-xs text-gray-500">
                          <span className="max-xl:hidden">Registrado el </span>
                          {TimestampToDate(user.date_created)}
                        </span>
                      </div>
                      <div className="w-0 grow flex flex-col items-end max-xl:text-sm">
                        <span>{DigitGrouping(round(user.ut_coins, 2))} UT Coins</span>
                        <Link
                          to={`/admin/users/${user.user_id}`}
                          className="w-max font-medium text-amber-500 hover:text-amber-600">
                          Ver usuario
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span>No se encontró ningún usuario.</span>
              )
            ) : (
              <div className="mt-4 flex justify-center items-center gap-2">
                {loading ? (
                  <LoadingBox text="Buscando usuario/s..." />
                ) : (
                  <>
                    <MdSearch size={30} className="w-max text-gray-500" />
                    <span className="max-xl:text-sm text-gray-500">Buscá un usuario o vacío para ver todos</span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminUsers

import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { MdClose, MdOutlineClose, MdOutlineSearch, MdOutlineShoppingBag, MdSearchOff } from 'react-icons/md'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import LoadingBox from '../utils/LoadingBox'
import { useMainContext } from '../utils/MainContext'
import ShowImage from '../utils/ShowImage'
import {
  FormatPrice,
  GetRoleLink,
  ProductLink,
  TimestampToDateText,
  TimestampToDateTextWithTime,
} from '../utils/Strings'
import ExcelJS from 'exceljs'
import { setDocumentTitle, useWindowSize } from '../utils/Html'
import { round, toNumber } from 'lodash'
import Dropdown from '../utils/Dropdown'
import { useAbortableEffect } from '../utils/Hooks'

function ViewOrders({ admin }) {
  const { firebase } = useMainContext()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [windowWidth] = useWindowSize()

  const searchInputRef = useRef(null)
  const [search, setSearch] = useState('')
  const fetchFrom = searchParams.get('fetch') ?? 'all'
  const queryUser = searchParams.get('user') ?? ''
  const from = searchParams.get('from') ?? 0
  const limit = searchParams.get('limit') ?? 15

  const [data, setData] = useState(null)
  const orders = data?.orders ?? null
  const possibleEvents = data?.possible_events ?? null
  const total_orders = data?.total_orders ?? null

  useEffect(() => {
    if (!firebase.user) {
      navigate('/', { replace: true })
    } else if (!admin && (firebase.user.role === 'admin' || firebase.user.role === 'operator')) {
      navigate(`/admin/orders`, { replace: true })
    } else if (admin && firebase.user.role === 'user') {
      navigate(`/user/orders`, { replace: true })
    }

    setDocumentTitle(firebase.user?.role === 'admin' ? 'Pedidos' : 'Mis Pedidos')
  }, [firebase.user, navigate, admin])

  useAbortableEffect(
    signal => {
      ;(async () => {
        try {
          setData(null)

          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_orders_from_user', {
            params: {
              user_id: firebase.user.uid,
              search: toNumber(search) ? search.padStart(6, '0') : search,
              fetch: fetchFrom,
              q_user_id: queryUser,
              from,
              limit,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)
          setData(result.data)
        } catch (err) {
          // console.log(err)
          // console.error(err.response?.data?.message ?? err.response?.data)
        }
      })()
    },
    [firebase.user, search, fetchFrom, queryUser, from, limit]
  )

  useEffect(() => {
    if (orders && search) {
      searchInputRef.current.value = search
    }
  }, [orders, search])

  useEffect(() => {
    if (searchParams.get('limit') === '0' && searchParams.get('from') !== 0) {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), limit: 0, from: 0 })
    }
  }, [searchParams, setSearchParams])

  const handlePagination = ev => {
    setSearchParams(
      new URLSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        from: (Math.floor((searchParams.get('from') ?? 0) / limit) + (ev.target.name === 'next' ? 1 : -1)) * limit,
      })
    )
  }

  return (
    <div className="w-full xl:w-content-max-width mx-auto xl:px-10 pb-4">
      <div
        className="max-xl:px-2 mb-0 max-xl:mb-2 flex flex-col max-xl:bg-white
                     max-xl:border-b max-xl:border-gray-200 max-xl:shadow-sm">
        <h1 className="text-2xl max-xl:text-xl font-medium xl:my-8 max-xl:my-4">Pedidos</h1>
        {orders && (
          <>
            <div className="flex gap-4 max-xl:flex-col xl:items-center">
              <div
                className={`h-9 ${
                  firebase.user?.role === 'operator' || firebase.user?.role === 'admin' ? 'xl:mb-4' : 'mb-4'
                } flex items-center gap-4`}>
                <div className="relative w-64 max-xl:w-52 h-full">
                  <MdOutlineSearch className="absolute top-1/2 left-2 -translate-y-1/2 w-5 h-5 text-gray-500" />
                  <form
                    onSubmit={ev => {
                      ev.preventDefault()
                      setSearchParams(new URLSearchParams({}))
                      setSearch(searchInputRef.current.value)
                    }}
                    className="w-full h-full">
                    <input
                      ref={searchInputRef}
                      type="text"
                      className="w-full h-full text-sm px-8 bg-white rounded-full xl:outline-none 
                    max-xl:outline max-xl:outline-2 max-xl:outline-gray-200
                    focus:outline-2 focus:outline-offset-0 focus:outline-dewalt transition-all"
                      placeholder="Buscar por # de pedido..."
                    />
                  </form>
                  {search && (
                    <div className="absolute top-0 right-0 w-9 h-9 p-1">
                      <button
                        className="w-full h-full p-1 rounded-full hover:bg-neutral-100 transition-all"
                        onClick={() => {
                          searchInputRef.current.value = ''
                          setSearch('')
                          setSearchParams(new URLSearchParams({}))
                        }}>
                        <MdOutlineClose className="w-full h-full text-gray-500" />
                      </button>
                    </div>
                  )}
                </div>
                {orders && total_orders > 0 && (
                  <>
                    <div className="w-[1px] h-6 bg-neutral-300" />
                    <span className="text-sm text-neutral-600">{`${total_orders} ${
                      total_orders === 1 ? 'pedido' : 'pedidos'
                    }`}</span>
                  </>
                )}
              </div>
              {!!queryUser && (firebase.user?.role === 'admin' || firebase.user?.role === 'operator') && (
                <button
                  type="button"
                  className="w-max h-max py-1 px-2 xl:mb-4 text-xs xl:text-sm bg-gray-100 text-gray-500 flex items-center
                          rounded-md border border-gray-500"
                  onClick={() => setSearchParams(new URLSearchParams({}))}>
                  Usuario: {queryUser}
                  <MdClose className="w-4 h-4 text-gray-500" />
                </button>
              )}
            </div>
            {(firebase.user?.role === 'operator' || firebase.user?.role === 'admin') && (
              <div className="w-full px-3 py-3 xl:mb-4 bg-white flex items-center rounded-md">
                <div className="w-0 grow flex gap-2 max-xs:flex-col justify-between xs:items-center text-xs xl:text-sm">
                  <div className="flex gap-2 items-center">
                    <div className="w-40 xl:w-52 h-8">
                      <Dropdown
                        items={[
                          {
                            text: 'Sin filtro',
                            value: 'all',
                          },
                          {
                            text: 'Este mes',
                            value: 'this_month',
                          },
                          {
                            text: 'Mes pasado',
                            value: 'last_month',
                          },
                          {
                            text: 'Este año',
                            value: 'this_year',
                          },
                          {
                            text: 'Año pasado',
                            value: 'last_year',
                          },
                        ]}
                        onChange={item =>
                          setSearchParams(
                            new URLSearchParams({
                              fetch: item.value,
                            })
                          )
                        }
                        defaultValue={fetchFrom}
                      />
                    </div>
                    <div className="w-20 xl:w-24 h-8">
                      <Dropdown
                        items={[
                          {
                            text: '15',
                            value: '15',
                          },
                          {
                            text: '30',
                            value: '30',
                          },
                          {
                            text: '50',
                            value: '50',
                          },
                          {
                            text: 'Todos',
                            value: '0',
                          },
                        ]}
                        onChange={item =>
                          setSearchParams(
                            new URLSearchParams({
                              ...Object.fromEntries(searchParams.entries()),
                              from: 0,
                              limit: item.value,
                            })
                          )
                        }
                        defaultValue={'' + limit}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`w-max pr-2 font-medium text-amber-500 [&:not]:disabled:hover:text-amber-600 disabled:text-gray-300 transition-colors`}
                    onClick={() => {
                      if (!firebase.user?.role === 'admin' && !firebase.user.role === 'operator') return
                      handleExcelExport(orders, possibleEvents)
                    }}
                    disabled={!orders || orders.length === 0}>
                    Exportar a Excel
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="w-full max-xl:px-2 flex flex-col gap-4 max-xl:gap-2">
        {orders ? (
          orders.length > 0 ? (
            <>
              {orders.map((order, i) => (
                <div
                  className="relative flex flex-col rounded-md shadow-sm hover:shadow-lg transition-all overflow-hidden"
                  key={i}>
                  <Link
                    to={`/user/orders/${order.order_id}`}
                    className="xl:hidden absolute top-0 left-0 w-full h-full z-50"
                  />
                  <div className="w-full bg-white px-6 py-3 max-xl:px-4 flex text-sm max-xl:text-xs">
                    {admin ? (
                      <>
                        <span className="text-gray-500">
                          {`#${order.order_id} | ${TimestampToDateTextWithTime(order.date_created)}`}
                        </span>
                        <div className="w-0 grow flex justify-end text-gray-500">
                          <span className="xl:mr-2 text-gray-500 max-xs:hidden">
                            {order.user.name} {order.user.last_name}
                            <span className="max-xl:hidden">{` | ${order.user.email}`}</span>
                          </span>
                          &nbsp;
                          <a
                            href={`https://wa.me/549${order.user.area_code}${order.user.phone_number}`}
                            className="text-amber-500 hover:text-amber-600 max-xl:hidden"
                            rel="noreferrer"
                            target={'_blank'}>
                            WhatsApp
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <span className="text-black font-medium">{TimestampToDateText(order.date_created)}</span>
                        <div className="w-0 grow flex justify-end">
                          <span className="text-gray-500">Pedido #{order.order_id}</span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="w-full h-36 max-xl:h-32 px-6 py-4 max-xl:p-4 bg-white flex items-center border-t border-gray-200">
                    <div className="w-28 xl:h-28 max-xl:w-24 max-xl:h-full flex items-center relative">
                      <AspectRatio.Root ratio={1 / 1} className="relative">
                        {order.products.length === 1 ? (
                          <Link
                            to={ProductLink(order.products[0].product_id, order.products[0].title)}
                            className="w-full h-full p-2 border border-gray-200 rounded-md hover:border-black transition-colors">
                            <ShowImage image={order.products[0].image} alt={`Imágen de ${order.products[0].title}`} />
                          </Link>
                        ) : (
                          <div className="w-full h-full p-1 border border-gray-200 rounded-md flex flex-col justify-center items-center">
                            <MdOutlineShoppingBag className="w-full h-full text-gray-400" />
                            <span className="text-xs xl:text-sm text-gray-500">
                              {order.products.reduce((a, c) => a + c.amount, 0)} productos
                            </span>
                          </div>
                        )}
                      </AspectRatio.Root>
                    </div>
                    <div className="w-0 grow-[1.5] h-full pl-6 max-xl:pl-4 flex flex-col">
                      <div className="h-0 grow pb-2 flex flex-col text-sm max-xl:text-xs">
                        <span
                          className={`mb-2 ${
                            order.events.at(-1).event === 'cancelled'
                              ? 'text-red-600'
                              : order.events.at(-1).event === 'delivered'
                              ? 'text-green-600'
                              : 'text-amber-500'
                          } font-medium`}>
                          {possibleEvents[order.events.at(-1).event]}
                        </span>
                        <Link to={ProductLink(order.products[0].product_id, order.products[0].title)}>
                          <span className="font-medium text-black hover:text-neutral-600 transition-colors line-clamp-2 xl:line-clamp-1">
                            {order.products.length > 1 ? `${order.products[0].amount}x ` : ''}
                            {order.products[0].title}
                          </span>
                        </Link>
                        <span className="text-gray-500">
                          {order.products.length === 1
                            ? `${order.products[0].amount + order.products[0].amount_offer_stock} ${
                                order.products[0].amount + order.products[0].amount_offer_stock === 1
                                  ? 'unidad'
                                  : 'unidades'
                              }`
                            : `y ${order.products.slice(1, order.products.length).reduce((a, c) => a + c.amount, 0)} ${
                                order.products.slice(1, order.products.length).reduce((a, c) => a + c.amount, 0) === 1
                                  ? 'producto'
                                  : 'productos'
                              } más`}
                        </span>
                        <span className="mt-2 text-gray-500">
                          {windowWidth > 1280 ? `${order.payment.payment_method.title} | ` : ''}
                          {order.shipping.shipping_method.title}
                        </span>
                      </div>
                    </div>
                    <div className="w-0 grow h-full flex justify-end max-xl:hidden">
                      <div className="flex flex-col h-full">
                        <span className="text-2xl text-end">
                          {FormatPrice(
                            round(
                              order.products.reduce(
                                (a, p) => a + (p.offer_price ?? p.normal_price) * (p.amount + p.amount_offer_stock),
                                0
                              ) -
                                (order.ut_coins.spent * order.ut_coins.dollar.buy) / 10 +
                                order.shipping.price +
                                (order.surcharge ?? 0),
                              2
                            )
                          )}
                        </span>
                        <div className="h-0 grow flex items-end gap-4">
                          <Link
                            to={`/${GetRoleLink(firebase.user?.role)}/orders/${order.order_id}/budget`}
                            className="h-max p-2 px-10 text-sm bg-dewalt/50 text-black
                                    hover:bg-black hover:text-dewalt
                                    rounded-md font-medium transition-all"
                            target={'_blank'}
                            rel="noreferrer">
                            Ver presupuesto
                          </Link>
                          <Link
                            to={`/${GetRoleLink(firebase.user?.role)}/orders/${order.order_id}`}
                            className="h-max p-2 px-10 text-sm bg-dewalt text-black
                                    hover:bg-black hover:text-dewalt
                                    rounded-md font-medium transition-all">
                            Ver pedido
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <span className="w-full p-2 text-xs xl:text-sm flex justify-center items-center text-gray-500">
                No hay más resultados
              </span> */}
              <div className="w-full py-4 xl:py-8 xl:text-lg flex justify-center items-center">
                {limit !== '0' && 1 + Math.floor((from ?? 0) / limit) > 1 && (
                  <button
                    type="button"
                    name="prev"
                    onClick={handlePagination}
                    className="mr-4 xl:mr-6 text-amber-500 hover:text-amber-600 transition-colors">{`< Anterior`}</button>
                )}
                <span
                  className="w-9 h-9 xl:w-10 xl:h-10 bg-neutral-200 text-neutral-500 font-bold
                            flex justify-center items-center">
                  {limit === '0' ? 1 : 1 + Math.floor((from ?? 0) / limit)}
                </span>
                <span className="ml-2 text-neutral-500 font-light">
                  de {limit === '0' ? 1 : Math.ceil(total_orders / limit)}
                </span>
                {limit !== '0' && 1 + Math.floor((from ?? 0) / limit) < Math.ceil(total_orders / limit) && (
                  <button
                    type="button"
                    name="next"
                    onClick={handlePagination}
                    className="ml-4 xl:ml-6 text-amber-500 hover:text-amber-600 transition-colors">{`Siguiente >`}</button>
                )}
              </div>
            </>
          ) : (
            <div className="w-full xl:py-8 flex flex-col justify-center items-center">
              <MdSearchOff className="w-16 h-16 xl:w-28 xl:h-28 text-gray-400" />
              <p className="max-xl:text-sm mt-2 text-neutral-600 text-xl text-center">
                {search ? 'No hay resultados para tu búsqueda.' : 'Todavía no tenes ningún pedido.'}
              </p>
            </div>
          )
        ) : (
          <LoadingBox text={'Cargando pedidos...'} />
        )}
      </div>
    </div>
  )
}

const handleExcelExport = (orders, possibleEvents) => {
  const workbook = new ExcelJS.Workbook()
  const ordersSheet = workbook.addWorksheet('Pedidos')

  // Agrego la primera fila de titulos y les acomodo el estilo
  let titleRow = []
  titleRow[0] = 'INFORMACIÓN PEDIDO'
  titleRow[12] = 'INFORMACIÓN CLIENTE'
  titleRow[16] = 'INFORMACIÓN ENVÍO'
  // titleRow[19] = 'TOTALES'
  ordersSheet.addRow(titleRow)

  ordersSheet.mergeCells('A1:L1')
  ordersSheet.mergeCells('M1:P1')
  ordersSheet.mergeCells('Q1:R1')
  // sheet.mergeCells('T1:X1')

  const titleStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF000000' },
    },
    font: {
      color: { argb: 'FFFFFFFF' },
      bold: true,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
    },
  }

  ordersSheet.getRow(1).eachCell((cell, col) => {
    if (col === 19) return

    cell.style = titleStyle
    cell.border = {
      left: { style: 'medium', color: { argb: 'FFFFFFFF' } },
      ...(col < 3
        ? {
            right: { style: 'medium', color: { argb: 'FFFFFFFF' } },
          }
        : {}),
    }
  })

  // Agrego la segunda fila que serían los headers
  ordersSheet.addRow([
    'Estado Pedido',
    'Fecha Recibido',
    '# Pedido',
    'Cantidad',
    'Cantidad (Stock Oferta)',
    'Marca',
    'Código',
    'Costo Bruto',
    'Costo (Stock Oferta) Bruto',
    'Precio Bruto',
    'Diferencia IVA',
    'Ganancia',
    'Forma Pago',
    'Nombre Completo',
    'CUIT / CUIL',
    'Celular',
    'Forma Entrega',
    'Precio Entrega',
  ])

  const headerStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFF00' },
    },
    font: {
      color: { argb: 'FF000000' },
      bold: true,
    },
  }

  ordersSheet.getRow(2).eachCell(cell => {
    cell.style = headerStyle
    cell.border = {
      top: { style: 'medium', color: { argb: 'FF000000' } },
      left: { style: 'medium', color: { argb: 'FF000000' } },
      bottom: { style: 'medium', color: { argb: 'FF000000' } },
      right: { style: 'medium', color: { argb: 'FF000000' } },
    }
  })

  let totals = {
    cancelled: {
      costs: 0,
      prices: 0,
      iva_difference: 0,
      profits: 0,
    },
    pending: {
      costs: 0,
      prices: 0,
      iva_difference: 0,
      profits: 0,
    },
    completed: {
      costs: 0,
      prices: 0,
      iva_difference: 0,
      profits: 0,
    },
  }
  // Agrego la info
  const rowsToAdd = orders.reduce(
    (acc, order) => [
      ...acc,
      ...order.products.reduce((pacc, prod) => {
        const event = possibleEvents[order.events.at(-1).event].toUpperCase()
        const final_price = prod.offer_price ?? prod.normal_price
        const total_amount = prod.amount + prod.amount_offer_stock

        const net_cost = prod.cost_price / 1.21
        const cost_iva = prod.cost_price - net_cost
        const net_offer_cost = prod.offer_cost_price / 1.21
        const offer_cost_iva = prod.offer_cost_price - net_offer_cost
        const price_iva = final_price - final_price / (1 + prod.aliquot)

        const iva_difference =
          cost_iva * prod.amount + offer_cost_iva * prod.amount_offer_stock - price_iva * total_amount

        const profit =
          final_price * total_amount -
          (net_cost * prod.amount + net_offer_cost * prod.amount_offer_stock) +
          iva_difference

        const status = event === 'PEDIDO CANCELADO' ? 'cancelled' : event === 'ENTREGADO' ? 'completed' : 'pending'

        totals[status].costs += prod.cost_price * prod.amount + prod.offer_cost_price * prod.amount_offer_stock
        totals[status].prices += final_price * total_amount
        totals[status].iva_difference += iva_difference
        totals[status].profits += profit

        return [
          ...pacc,
          [
            /* ESTADO PEDIDO */
            event,
            /* FECHA RECIBIDO */
            new Date(order.date_created * 1000).toLocaleDateString('es-AR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
            /* # PEDIDO */
            '' + order.order_id,
            /* CANTIDAD */
            prod.amount,
            /* CANTIDAD (STOCK OFERTA) */
            prod.amount_offer_stock,
            /* MARCA */
            prod.brand,
            /* CÓDIGO */
            prod.model,
            /* COSTO BRUTO */
            prod.cost_price,
            /* COSTO (STOCK OFERTA) BRUTO */
            prod.offer_cost_price,
            /* PRECIO BRUTO */
            final_price,
            /* DIFERENCIA IVA */
            round(iva_difference, 2),
            /* GANANCIA */
            round(profit, 2),
            /* FORMA PAGO */
            order.payment.payment_method.title,
            /* NOMBRE COMPLETO */
            `${order.user.name} ${order.user.last_name}`,
            /* CUIT */
            order.user.cuit,
            /* CELULAR */
            `${order.user.area_code} ${order.user.phone_number}`,
            /* FORMA ENTREGA */
            order.shipping.shipping_method.title,
            /* PRECIO ENTREGA */
            order.shipping.price > 0 ? order.shipping.price : 'Gratis',
            /* DATOS ENTREGA */
          ],
        ]
      }, []),
    ],
    []
  )

  ordersSheet.addRows(rowsToAdd)

  // let row = sheet.getRow(3)
  // row.getCell(20).value = 'EN PROG. + ENTREG.'
  // row.getCell(21).value = totals.pending.costs + totals.completed.costs
  // row.getCell(22).value = totals.pending.prices + totals.completed.prices
  // row.getCell(23).value = totals.pending.iva_difference + totals.completed.iva_difference
  // row.getCell(24).value = totals.pending.profits + totals.completed.profits

  // row = sheet.getRow(4)
  // row.getCell(20).value = 'ENTREGADOS'
  // row.getCell(21).value = totals.completed.costs
  // row.getCell(22).value = totals.completed.prices
  // row.getCell(23).value = totals.completed.iva_difference
  // row.getCell(24).value = totals.completed.profits

  // row = sheet.getRow(5)
  // row.getCell(20).value = 'EN PROGRESO'
  // row.getCell(21).value = totals.pending.costs
  // row.getCell(22).value = totals.pending.prices
  // row.getCell(23).value = totals.pending.iva_difference
  // row.getCell(24).value = totals.pending.profits

  // row = sheet.getRow(6)
  // row.getCell(20).value = 'CANCELADOS'
  // row.getCell(21).value = totals.cancelled.costs
  // row.getCell(22).value = totals.cancelled.prices
  // row.getCell(23).value = totals.cancelled.iva_difference
  // row.getCell(24).value = totals.cancelled.profits

  const getCellBgStyle = color => ({
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: color,
      },
    },
  })

  ordersSheet.getRows(3, rowsToAdd.length).forEach((row, index) =>
    row.eachCell(cell => {
      cell.style = getCellBgStyle(
        rowsToAdd[index][0] === 'PEDIDO CANCELADO'
          ? 'FFF4CCCC'
          : rowsToAdd[index][0] === 'ENTREGADO'
          ? 'FFD9EAD3'
          : 'FFFFF2CC'
      )
    })
  )

  // sheet.getRows(3, 4).forEach((row, index) =>
  //   row.eachCell((cell, col) => {
  //     if (col <= 19) return

  //     cell.style = getCellBgStyle(
  //       index === 0 ? 'FFC9DAF8' : index === 1 ? 'FFD9EAD3' : index === 2 ? 'FFFFF2CC' : 'FFF4CCCC'
  //     )
  //   })
  // )

  // Formato de las columnas de precio
  const numFmt = '$ #,##0.00'
  ordersSheet.getColumn(8).numFmt = numFmt
  ordersSheet.getColumn(9).numFmt = numFmt
  ordersSheet.getColumn(10).numFmt = numFmt
  ordersSheet.getColumn(11).numFmt = numFmt
  ordersSheet.getColumn(12).numFmt = numFmt
  ordersSheet.getColumn(18).numFmt = numFmt

  const totalsSheet = workbook.addWorksheet('Totales')

  let row = totalsSheet.addRow(['TOTALES'])
  row.getCell(1).style = titleStyle
  totalsSheet.mergeCells('A1:E1')

  row = totalsSheet.addRow(['', 'Costos', 'Precios Brutos', 'Diferencias IVA', 'Ganancias'])

  row = totalsSheet.addRow([
    'EN PROG. + ENTREG.',
    round(totals.pending.costs + totals.completed.costs, 2),
    round(totals.pending.prices + totals.completed.prices, 2),
    round(totals.pending.iva_difference + totals.completed.iva_difference, 2),
    round(totals.pending.profits + totals.completed.profits, 2),
  ])

  row = totalsSheet.addRow([
    'ENTREGADOS',
    round(totals.completed.costs, 2),
    round(totals.completed.prices, 2),
    round(totals.completed.iva_difference, 2),
    round(totals.completed.profits, 2),
  ])

  row = totalsSheet.addRow([
    'EN PROGRESO',
    round(totals.pending.costs, 2),
    round(totals.pending.prices, 2),
    round(totals.pending.iva_difference, 2),
    round(totals.pending.profits, 2),
  ])

  row = totalsSheet.addRow([
    'CANCELADOS',
    round(totals.cancelled.costs, 2),
    round(totals.cancelled.prices, 2),
    round(totals.cancelled.iva_difference, 2),
    round(totals.cancelled.profits, 2),
  ])

  totalsSheet.getRow(2).eachCell(cell => {
    cell.style = headerStyle
    cell.border = {
      top: { style: 'medium', color: { argb: 'FF000000' } },
      left: { style: 'medium', color: { argb: 'FF000000' } },
      bottom: { style: 'medium', color: { argb: 'FF000000' } },
      right: { style: 'medium', color: { argb: 'FF000000' } },
    }
  })

  totalsSheet
    .getRows(3, 4)
    .forEach((row, index) =>
      row.eachCell(
        cell =>
          (cell.style = getCellBgStyle(
            index === 0 ? 'FFC9DAF8' : index === 1 ? 'FFD9EAD3' : index === 2 ? 'FFFFF2CC' : 'FFF4CCCC'
          ))
      )
    )

  totalsSheet.getColumn(2).numFmt = numFmt
  totalsSheet.getColumn(3).numFmt = numFmt
  totalsSheet.getColumn(4).numFmt = numFmt
  totalsSheet.getColumn(5).numFmt = numFmt

  // Acomodo el ancho de todas las columnas en base al valor mas grande que tengan
  const adjustColumnsLength = sheet =>
    sheet.columns.forEach(column => {
      const lengths = column.values.map(v => v.toString().length + 2)
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'))
      column.width = maxLength
    })

  adjustColumnsLength(ordersSheet)
  adjustColumnsLength(totalsSheet)

  // Exporto el .xlsx generado
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    const now = new Date()
    anchor.download = `Pedidos ${now.toLocaleDateString().replaceAll('/', '-')}.xlsx`
    anchor.click()
    window.URL.revokeObjectURL(url)
  })
}

// function LoadingSkeletonSearch() {
//   return (
//     <div className="h-9 mb-4 flex items-center gap-4">
//       <Skeleton className="w-64 max-xl:w-52 h-full" />
//       <div className="w-[1px] h-6 bg-neutral-300"></div>
//       <Skeleton className="w-24 h-full" />
//     </div>
//   );
// }

// function LoadingSkeletonOrders() {
//   return Array(3)
//     .fill("")
//     .map((_, i) => <Skeleton key={i} className="w-full h-[10.5rem] xl:h-[11.5rem]" />);
// }

export default ViewOrders

import Sidebar from "../../../Sidebar";
import ViewOrders from "../../ViewOrders";

function AdminOrders() {
  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={true} />
      <ViewOrders admin={true} />
    </div>
  );
}

export default AdminOrders;

import { useRef, useState } from 'react'
import { MdMoreVert } from 'react-icons/md'
import { Link } from 'react-router-dom'
import DefaultAlertDialog from './DefaultAlertDialog'
import { useOutsideAlerter } from './Html'

function DropdownMenu({ items, side = 'right', identifierClass }) {
  const [showMenu, setShowMenu] = useState(false)
  const ref = useRef(null)
  useOutsideAlerter(ref, () => setShowMenu(false), identifierClass, showMenu)

  return (
    <div ref={ref} className={`relative w-10 h-10 rounded-full hover:bg-neutral-100 cursor-pointer transition-colors`}>
      {items
        .filter(item => !!item.dialog)
        .map((item, i) => (
          <DefaultAlertDialog
            key={i}
            title={item.dialog.title}
            content={item.dialog.content}
            trigger={<button id={`dialog-${item.dialog.id}`} className="invisible"></button>}
            loading={item.dialog.loading}
            done={item.dialog.done}
            submit={item.dialog.submit}
            identifierClass={identifierClass}
          />
        ))}
      <div className={`w-full h-full p-2`} onClick={() => setShowMenu(!showMenu)}>
        <MdMoreVert className="w-full h-full pointer-events-none" />
      </div>
      {showMenu && (
        <div
          className={`absolute top-full ${
            side === 'right' ? 'right-0' : 'left-0'
          } w-60 bg-white rounded-md border border-gray-200 shadow-lg z-50 text-sm`}>
          {items.map((v, i) =>
            v.link ? (
              <Link key={i} to={v.link} className="w-full px-4 py-3 text-start hover:bg-neutral-100 transition-colors">
                {v.text}
              </Link>
            ) : (
              <button
                key={i}
                onClick={() => {
                  if (v.dialog) {
                    document.getElementById(`dialog-${v.dialog.id}`)?.click()
                  } else {
                    v.onClick()
                  }
                  setShowMenu(false)
                }}
                className="w-full px-4 py-3 text-start hover:bg-neutral-100 transition-colors">
                {v.text}
              </button>
            )
          )}
        </div>
      )}
    </div>
  )
}

export default DropdownMenu

import './LoadingBox.css'

function LoadingBox({ text }) {
  return (
    // <div
    //   className="w-full py-10 xl:mt-4 bg-white text-black xl:border xl:border-gray-200
    //          flex justify-center items-center xl:rounded-md shadow-sm"
    // >
    //   <img src="/loading.svg" className="w-16 h-16 select-none" draggable={false} alt="Loading" />
    //   <span className="ml-4 text-xl font-light">{text}</span>
    // </div>
    <div className="w-full h-[200px] xl:h-full py-12 flex flex-col items-center select-none">
      <div className="relative w-1/2 xl:max-w-sm">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/universal-tools-logo-notext.svg" className="w-full h-max opacity-0" draggable={false} />
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img
          src="/universal-tools-logo-notext.svg"
          className="absolute top-0 left-0 w-full h-max invert"
          style={{
            animation: 'yaguarete',
            animationDuration: '2000ms',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'ease-in-out',
          }}
          draggable={false}
        />
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img
          src="/universal-tools-logo-notext.svg"
          className="absolute top-0 left-0 w-full h-max invert"
          style={{
            animation: 'yaguarete',
            animationDuration: '2500ms',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'ease-in-out',
          }}
          draggable={false}
        />
      </div>
      <span className="mt-4 text-xl font-light text-black">{text}</span>
    </div>
  )
}

export default LoadingBox

import { useState } from 'react'
import { useMainContext } from '../utils/MainContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { MdDone, MdOutlineLocalShipping, MdOutlineShoppingBag, MdOutlineStore, MdPersonOutline } from 'react-icons/md'
import { TbCoin, TbMotorbike } from 'react-icons/tb'
import LoadingBox from '../utils/LoadingBox'
import ShowImage from '../utils/ShowImage'
import { DigitGrouping } from '../utils/Strings'
import { useAbortableEffect } from '../utils/Hooks'

function Congrats() {
  const { firebase, showToast } = useMainContext()
  const { order_id } = useParams()
  const navigate = useNavigate()

  // const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null)
  const order = data?.order ?? null
  const payment_method = order?.payment?.payment_method ?? null
  const shipping_method = order?.shipping?.shipping_method ?? null
  const user = order?.user ?? null
  const products = order?.products ?? null

  useAbortableEffect(
    signal => {
      if (!firebase.user) {
        navigate('/notloggedin/buy', { replace: true })
      }
      ;(async () => {
        setData(null)

        try {
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_congrats', {
            params: { order_id: order_id, user_id: firebase.user.uid },
            headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY },
            signal,
          })
          // console.log(result)
          setData(result.data)
        } catch (err) {
          // console.log(err)
          if (err.response?.data?.code === 0) {
            navigate('/', { replace: true })
            showToast('La órden no pertenece al usuario.', 'bad')
          }
        }
      })()
    },
    [firebase.user, navigate, order_id, showToast]
  )

  return (
    <div className="w-full grow bg-white xl:bg-pagebg">
      {data ? (
        <div className="w-full relative max-xl:flex max-xl:flex-col">
          <div className="w-full h-48 bg-green-500 max-xl:hidden" />
          <div className="xl:absolute w-full xl:w-[600px] xl:left-1/2 xl:-translate-x-1/2 top-0 flex flex-col">
            <div className="flex px-8 py-6 xl:p-9 max-xl:bg-green-500">
              <h1 className="w-0 grow text-lg xl:text-xl font-medium text-white flex items-center">
                Gracias por tu pedido!
              </h1>
              <div className="w-16 h-16 xl:w-20 xl:h-20 relative">
                <div className="w-full h-full bg-white rounded-full overflow-hidden shadow-lg">
                  {products?.length === 1 ? (
                    <ShowImage image={products[0].images[0]} alt={`Imágen de ${products[0].title}`} />
                  ) : (
                    <MdOutlineShoppingBag className="w-full h-full p-2 text-gray-400" />
                  )}
                </div>
                <div className="absolute -bottom-1 -right-1 w-6 h-6 xl:w-7 xl:h-7 bg-green-700 rounded-full">
                  <MdDone className="w-full h-full p-1 text-white" />
                </div>
              </div>
            </div>
            <div className="w-full p-4 max-xl:py-6 xl:mb-4 bg-white text-black flex flex-col gap-4 xl:rounded-md border-b xl:border border-gray-200">
              <div className="flex gap-4">
                <div className="w-12 h-12 xl:w-16 xl:h-16 xl:ml-2 rounded-full border border-gray-200 flex justify-center items-center">
                  {
                    {
                      truck: <MdOutlineLocalShipping className="w-7 h-7 xl:w-9 xl:h-9 text-neutral-600" />,
                      motorbike: <TbMotorbike className="w-7 h-7 xl:w-9 xl:h-9 text-neutral-600" />,
                      store: <MdOutlineStore className="w-7 h-7 xl:w-9 xl:h-9 text-neutral-600" />,
                      person: <MdPersonOutline className="w-7 h-7 xl:w-9 xl:h-9 text-neutral-600" />,
                    }[shipping_method.icon]
                  }
                </div>
                <div className="w-0 grow min-h-full flex flex-col justify-center gap-1">
                  <span className="max-xl:text-sm font-medium">{shipping_method.title}</span>
                  <span className="text-sm max-xl:text-xs text-gray-500">{`${
                    user.street
                      ? `${user.city}, ${JSON.parse(process.env.REACT_APP_PROVINCES)[user.province].name} - `
                      : ''
                  }${user.name} ${user.last_name}, ${user.area_code} ${user.phone_number}`}</span>
                </div>
              </div>
              {payment_method?.title.includes('Transferencia') ? (
                <Link
                  to={`/user/orders/${order_id}/bank_transfer`}
                  className="w-full p-2 max-xl:text-sm font-medium text-center bg-dewalt text-black
                hover:bg-black hover:text-dewalt rounded-md shadow-sm transition-colors">
                  Pagar ahora
                </Link>
              ) : (
                <Link
                  to={`/user/orders/${order_id}`}
                  className="w-full p-2 max-xl:text-sm font-medium text-center bg-dewalt text-black
                hover:bg-black hover:text-dewalt rounded-md shadow-sm transition-colors">
                  Ver pedido
                </Link>
              )}
            </div>
            <div className="w-full p-4 max-xl:py-6 xl:p-6 bg-white text-black xl:rounded-md border-b xl:border border-gray-200">
              <div className="flex gap-4">
                <TbCoin className="w-12 h-12 xl:w-16 xl:h-16 p-2 text-dewalt rounded-full border-2 border-dewalt" />
                <div className="w-0 grow min-h-full flex flex-col justify-center gap-1">
                  <div className="max-xl:text-sm">
                    Cuando pagues, vas a ganar{' '}
                    <span className="font-medium">{DigitGrouping(order.ut_coins.to_gain)}&nbsp;UT&nbsp;Coins</span>!
                  </div>
                  <Link
                    to="/ut_coins"
                    className="w-max text-sm text-amber-500 font-medium hover:text-amber-600 transition-colors">
                    ¿Qué son los UT Coins?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingBox text={'Cargando página...'} />
      )}
    </div>
  )
}

export default Congrats

import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAbortableEffect } from '../utils/Hooks'
import { setDocumentTitle } from '../utils/Html'
import LoadingBox from '../utils/LoadingBox'

function Categories() {
  const [categories, setCategories] = useState(null)

  useEffect(() => {
    setDocumentTitle('Categorías')
  }, [])

  useAbortableEffect(signal => {
    ;(async () => {
      const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_categories', {
        params: {
          products_amount: true,
        },
        headers: {
          'X-Api-Key': process.env.REACT_APP_API_KEY,
        },
        signal,
      })
      // console.log(result)
      setCategories(result.data.categories)
    })()
  }, [])

  return (
    <section className="w-full bg-pagebg grow">
      <div className="w-full xl:w-content-max-width xl:mx-auto text-black">
        {/* <h1 className="w-max mx-auto my-2 xl:my-3 text-xs xl:text-base">LA CALIDAD MÁS ALTA EN HERRAMIENTAS</h1>
        <div className="w-1/2 xl:w-[500px] mx-auto h-[1px] bg-neutral-300" /> */}
        <div className="pb-6 px-4 xl:px-0">
          <p className="my-4 xl:my-6 font-sans-condensed text-black text-2xl xl:text-4xl">Todas las categorías</p>
          <div className="w-full p-6 bg-white flex gap-6 max-xl:flex-col rounded-md shadow-md hyphens-none">
            {categories ? (
              <>
                <div className="w-full flex flex-col">
                  {categories
                    .toSorted((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
                    .slice(0, Math.ceil(categories.length / 2))
                    .map(category => (
                      <CategoryItem category={category} />
                    ))}
                </div>
                {categories.slice(Math.ceil(categories.length / 2), categories.length).length > 0 && (
                  <div className="w-full flex flex-col max-xl:pt-6 max-xl:border-t max-xl:border-t-gray-200">
                    {categories
                      .toSorted((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
                      .slice(Math.ceil(categories.length / 2), categories.length)
                      .map(category => (
                        <CategoryItem category={category} />
                      ))}
                  </div>
                )}
              </>
            ) : (
              <LoadingBox text={'Cargando categorías...'} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export function CategoryItem({ category }) {
  return (
    <div className="w-full py-6 first:pt-0 last:pb-0 border-b border-b-gray-200 last:border-b-0">
      <Link
        to={`/catalogue/${category.category_id}/all`}
        className="w-max mb-4 text-lg font-bold text-black hover:text-amber-500 transition-colors">
        {category.name}
      </Link>
      <div className="text-sm flex flex-col gap-2">
        {category.children
          .toSorted((a, b) => a.name > b.name)
          .map(child => (
            <Link
              to={`/catalogue/${category.category_id}/${child.category_id}/all`}
              className="w-max text-gray-500 hover:text-amber-500 transition-colors">
              {child.name}
            </Link>
          ))}
      </div>
    </div>
  )
}

export default Categories

import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { AES } from 'crypto-js'
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth'
import { HiOutlineIdentification } from 'react-icons/hi'
import { MdError, MdLockOutline, MdMailOutline, MdOutlineMarkEmailRead, MdOutlineSmartphone } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import ButtonWithProgress from '../utils/ButtonWithProgress'
import InputLabel from '../utils/InputLabel'
import { useMainContext } from '../utils/MainContext'
import { CapitalizeAllWords, FormatCuit, OnlyNumbers } from '../utils/Strings'
import { delay, setDocumentTitle } from '../utils/Html'
import DefaultAlertDialog from '../utils/DefaultAlertDialog'
import { useCaretPosition } from 'react-use-caret-position'

function Signup() {
  const { firebase } = useMainContext()
  const navigate = useNavigate()

  const [emailInput, setEmailInput] = useState('')
  const [nameInput, setNameInput] = useState('')
  const [lastNameInput, setLastNameInput] = useState('')
  const [docInput, setDocInput] = useState('')
  const [cuitInput, setCuitInput] = useState('')
  const [areaCodeInput, setAreaCodeInput] = useState('')
  const [phoneNumberInput, setPhoneNumberInput] = useState('')
  const [passwordInput, setPasswordInput] = useState('')
  const [passwordConfirmInput, setPasswordConfirmInput] = useState('')
  const [creatingAccount, setCreatingAccount] = useState(false)
  const [accountCreated, setAccountCreated] = useState('')
  const [step, setStep] = useState('email')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ input: null, text: null })

  const nameInputRef = useRef(null)
  const lastNameInputRef = useRef(null)
  const docInputRef = useRef(null)
  const areaCodeInputRef = useRef(null)
  const phoneNumberInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const passwordConfirmInputRef = useRef(null)

  useEffect(() => {
    if (firebase.user) navigate('/', { replace: true })
  }, [firebase.user, navigate])

  useEffect(() => {
    setStep('email')
    setError({ input: null, text: null })
    setDocumentTitle('Registrarse')
  }, [])

  const checkEmail = async ev => {
    ev.preventDefault()
    if (step !== 'email' || emailInput === '') return

    if (new RegExp(process.env.REACT_APP_REGEX_EMAIL).test(emailInput)) {
      setLoading(true)
      await delay(500)

      try {
        const methods = await fetchSignInMethodsForEmail(firebase.auth, emailInput)

        setLoading(false)

        if (methods.length > 0) {
          const data = { ue: emailInput }
          const encryptedData = AES.encrypt(JSON.stringify(data), 'xd').toString()

          setError({
            input: 'email',
            text: `Ya hay una <a href='/login/user?p=${encodeURIComponent(
              encryptedData
            )}' style='display: inline; font-weight: bold'>cuenta asociada</a> a ese e-mail`,
          })
        } else {
          setError({ input: null, text: null })
          setStep('name')
        }
      } catch (e) {
        setLoading(false)
        switch (e.code) {
          case 'auth/invalid-email':
            setError({ input: 'email', text: 'E-mail inválido' })
            break
          default:
            setError({ input: 'email', text: e.message })
            // TODO: Mandar error a DB
            break
        }
        // console.log(e.message)
      }
    } else {
      setError({ input: 'email', text: 'E-mail inválido' })
    }
  }

  const checkName = async ev => {
    ev.preventDefault()
    if (step !== 'name' || (nameInput === '' && lastNameInput === '')) return

    const regex = new RegExp(process.env.REACT_APP_REGEX_NAME)
    if (!regex.test(nameInput)) setError({ input: 'fname', text: 'Nombre inválido' })
    else if (!regex.test(lastNameInput)) setError({ input: 'lname', text: 'Apellido inválido' })
    else if (!new RegExp(/\d{1,8}/).test(docInput)) setError({ input: 'doc', text: 'DNI inválido' })
    else {
      setError({ input: null, text: null })
      setLoading(true)
      try {
        const result = await axios.get(process.env.REACT_APP_FB_API_LINK + 'afip/persons', {
          params: {
            doc: docInput,
          },
        })
        const info = result.data.info
        const cuit = FormatCuit(info.idPersona)

        setCuitInput(cuit)
        setError({ input: null, text: null })
        setStep('phone')
      } catch (err) {
        if (err.code === 'ERR_CANCELED') return
        setError({
          input: 'doc',
          text: err.response.data.message ?? 'Error desconocido al consultar CUIT / CUIL con el documento',
        })
        // console.log(err)
        return
      } finally {
        setLoading(false)
      }
    }
  }

  const checkPhoneNumber = ev => {
    ev.preventDefault()
    if (step !== 'phone' || (areaCodeInput === '' && phoneNumberInput === '')) return

    if (areaCodeInput.length < 2) {
      setError({ input: 'areacode', text: 'Cód. Área inválido' })
    } else if (phoneNumberInput.length < 6) {
      setError({ input: 'phonenumber', text: 'Número inválido' })
    } else {
      setError({ input: null, text: null })
      setStep('password')

      // Vacio los refs y los inputs por si el browser autocompletó estos campos
      passwordInputRef.current.value = ''
      passwordConfirmInputRef.current.value = ''
    }
  }

  const checkPassword = async ev => {
    ev.preventDefault()
    if (step !== 'password' || passwordInput === '' || passwordConfirmInput === '') return

    if (passwordConfirmInput !== passwordInput) {
      setError({ input: 'passwordconf', text: 'La contraseña no coincide' })
      return
    }

    if (!new RegExp(process.env.REACT_APP_REGEX_PWD).test(passwordInput)) {
      setError({
        input: '',
        text: 'La contraseña tiene que tener al menos 6 dígitos',
      })
    }

    setError({ input: null, text: null })
    setLoading(true)
    setCreatingAccount(true)
    await delay(500)

    let user = null

    try {
      const userCredential = await createUserWithEmailAndPassword(firebase.auth, emailInput, passwordInput)
      user = userCredential.user
    } catch (err) {
      setLoading(false)
      setCreatingAccount(false)
      setError({ input: '', text: `Error al crear la cuenta (${err.code})` })
    }

    try {
      await axios.post(
        process.env.REACT_APP_API_LINK + 'register_user',
        {
          uid: user.uid,
          name: nameInput,
          last_name: lastNameInput,
          cuit: cuitInput,
          area_code: areaCodeInput,
          phone_number: phoneNumberInput,
          email: emailInput,
          google: false,
        },
        {
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
        }
      )

      await updateProfile(user, { displayName: `${nameInput}_${lastNameInput}` })

      const data = { ue: emailInput, v: 1 }
      const encryptedData = AES.encrypt(JSON.stringify(data), 'xd').toString()

      await sendEmailVerification(user, {
        url: `${window.location.origin}/login/user?p=${encodeURIComponent(encryptedData)}`,
        handleCodeInApp: true,
      })

      await firebase.auth.signOut()

      setLoading(false)
      setCreatingAccount(false)
      setAccountCreated(true)

      await delay(1000)

      setStep('validation')
    } catch (err) {
      // console.log(err)
      await user.delete()
      setLoading(false)
      setCreatingAccount(false)
      setError({
        input: '',
        text: err.response?.data?.message ?? `Error al crear la cuenta (${err.code})`,
      })
    }
  }

  const onInputChange = (input, value) => {
    setError({ input: null, text: null })
    switch (input) {
      case 'email':
        if (step === 'email') setEmailInput(value)
        break
      case 'fname':
        if (step === 'name') {
          const newValue = value !== '' ? CapitalizeAllWords(value) : value
          nameInputRef.current.value = newValue
          setNameInput(newValue)
        }
        break
      case 'lname':
        if (step === 'name') {
          const newValue = value !== '' ? CapitalizeAllWords(value) : value
          lastNameInputRef.current.value = newValue
          setLastNameInput(newValue)
        }
        break
      case 'doc':
        if (step === 'name') {
          docInputRef.current.value = value
          setDocInput(value)
        }
        break
      case 'areacode':
        if (step === 'phone') {
          const newValue = OnlyNumbers(value).substring(0, 4)
          areaCodeInputRef.current.value = newValue
          setAreaCodeInput(newValue)
        }
        break
      case 'phonenumber':
        if (step === 'phone') {
          const newValue = OnlyNumbers(value).substring(0, 10)
          phoneNumberInputRef.current.value = newValue
          setPhoneNumberInput(newValue)
        }
        break
      case 'password':
        if (step === 'password') setPasswordInput(value)
        break
      case 'passwordconf':
        if (step === 'password') setPasswordConfirmInput(value)
        break
      default:
        return
    }
  }

  return (
    <section className="w-full grow bg-white xl:bg-pagebg">
      <div
        className="xl:w-[550px] xl:mx-auto px-4 xl:px-4 py-4 xl:py-6 xl:my-12 bg-white text-black
                  xl:border xl:border-gray-200 xl:shadow-sm xl:rounded-md">
        <h1 className="text-2xl xl:text-3xl mt-0">Registrarse</h1>
        <h2 className="font-light xl:text-lg mb-6 xl:mb-10">Ingresá los datos para crear tu cuenta</h2>
        <Step
          icon={<MdMailOutline size="20" className="w-8 h-8" />}
          titles={{
            inactive: 'E-mail',
            active: 'Ingresá tu e-mail',
            done: 'E-mail ingresado ✓',
          }}
          state={step === 'email' ? 'active' : emailInput !== '' ? 'done' : 'inactive'}
          inputs={
            <>
              <InputLabel text="E-mail" error={error.input === 'email'} />
              <input
                type="email"
                disabled={step !== 'email'}
                onChange={ev => {
                  onInputChange('email', ev.target.value)
                }}
                className={`w-full xl:w-[340px] h-12 mt-1 border ${
                  error.input === 'email'
                    ? 'border-red-600'
                    : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                } px-2 rounded-md focus:border-2 outline-none`}
              />
            </>
          }
          error={error}
          submitable={emailInput !== ''}
          onSubmit={checkEmail}
          loading={loading}
          setStep={() => setStep('email')}
          creatingAccount={creatingAccount}
        />
        <Step
          icon={<HiOutlineIdentification size="20" className="w-8 h-8" />}
          titles={{
            inactive: 'Información personal',
            active: 'Ingresá tu información personal',
            done: 'Información personal ingresada ✓',
          }}
          state={step === 'name' ? 'active' : nameInput !== '' && lastNameInput !== '' ? 'done' : 'inactive'}
          inputs={
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <div className="flex-1">
                  <InputLabel text="Nombre" error={error.input === 'fname'} />
                  <input
                    type="text"
                    autoComplete="off"
                    ref={nameInputRef}
                    onChange={ev => {
                      onInputChange('fname', ev.target.value)
                    }}
                    className={`w-full h-12 mt-1 border ${
                      error.input === 'fname'
                        ? 'border-red-600'
                        : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                    } px-2 rounded-md focus:border-2 outline-none`}
                  />
                </div>
                <div className="flex-1">
                  <InputLabel text="Apellido" error={error.input === 'lname'} />
                  <input
                    type="text"
                    autoComplete="off"
                    ref={lastNameInputRef}
                    onChange={ev => {
                      onInputChange('lname', ev.target.value)
                    }}
                    className={`w-full h-12 mt-1 border ${
                      error.input === 'lname'
                        ? 'border-red-600'
                        : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                    } px-2 rounded-md focus:border-2 outline-none`}
                  />
                </div>
              </div>
              <div className="w-full">
                <InputLabel text="DNI" error={error.input === 'doc'} />
                <div className="w-full h-12 relative">
                  <input
                    type="text"
                    autoComplete="off"
                    ref={docInputRef}
                    onChange={ev => {
                      onInputChange('doc', ev.target.value)
                    }}
                    className={`w-full h-full mt-1 border ${
                      error.input === 'doc'
                        ? 'border-red-600'
                        : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                    } px-2 rounded-md focus:border-2 outline-none`}
                    maxLength={8}
                    placeholder={'12345678'}
                  />
                  {/* <GetCUILButton setCuit={setCuitInput} cuitRef={cuitInputRef} /> */}
                </div>
              </div>
              <p className="text-sm font-light">
                <span className="text-amber-500 font-medium">AVISO: </span>El DNI se utilizará para la facturación.
              </p>
            </div>
          }
          error={error}
          submitable={nameInput !== '' && lastNameInput !== ''}
          onSubmit={checkName}
          loading={loading}
          setStep={() => setStep('name')}
          creatingAccount={creatingAccount}
        />
        <Step
          icon={<MdOutlineSmartphone size="20" className="w-8 h-8" />}
          titles={{
            inactive: 'Teléfono',
            active: 'Ingresá tu teléfono',
            done: 'Teléfono ingresado ✓',
          }}
          state={step === 'phone' ? 'active' : areaCodeInput !== '' && phoneNumberInput !== '' ? 'done' : 'inactive'}
          inputs={
            <div className="w-full flex gap-4">
              <div className="w-0 grow-[0.3] xl:grow-[0.2]">
                <InputLabel text="Cód. Area" error={error.input === 'areacode'} />
                <input
                  type="text"
                  autoComplete="off"
                  ref={areaCodeInputRef}
                  onChange={ev => {
                    onInputChange('areacode', ev.target.value)
                  }}
                  className={`w-full h-12 mt-1 border ${
                    error.input === 'areacode'
                      ? 'border-red-600'
                      : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                  } px-2 rounded-md focus:border-2 outline-none`}
                  placeholder="11"
                  maxLength={4}
                  pattern="[0-9]{1,4}$"
                />
              </div>
              <div className="w-0 grow-[0.7] xl:grow-[0.8]">
                <InputLabel text="Número" error={error.input === 'phonenumber'} />
                <input
                  type="text"
                  autoComplete="off"
                  ref={phoneNumberInputRef}
                  onChange={ev => {
                    onInputChange('phonenumber', ev.target.value)
                  }}
                  className={`w-full h-12 mt-1 border ${
                    error.input === 'phonenumber'
                      ? 'border-red-600'
                      : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                  } px-2 rounded-md focus:border-2 outline-none`}
                  placeholder="12345678"
                  maxLength={10}
                  pattern="[0-9]{1,10}$"
                />
              </div>
            </div>
          }
          error={error}
          submitable={areaCodeInput !== '' && phoneNumberInput !== ''}
          onSubmit={checkPhoneNumber}
          setStep={() => setStep('phone')}
          creatingAccount={creatingAccount}
        />
        <Step
          icon={<MdLockOutline size="20" className="w-8 h-8" />}
          titles={{
            inactive: 'Contraseña',
            active: 'Creá tu contraseña',
            done: 'Contraseña ingresada ✓',
          }}
          state={step === 'password' ? 'active' : step === 'validation' || step === 'finished' ? 'done' : 'inactive'}
          inputs={
            <div className="w-full flex max-xl:flex-col gap-4">
              <div className="w-full xl:w-0 xl:grow">
                <InputLabel text="Contraseña" error={error.input === 'password'} />
                <input
                  type="password"
                  autoComplete="off"
                  ref={passwordInputRef}
                  onChange={ev => {
                    onInputChange('password', ev.target.value)
                  }}
                  className={`w-full h-12 mt-1 border ${
                    error.input === 'password'
                      ? 'border-red-600'
                      : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                  } px-2 rounded-md focus:border-2 outline-none`}
                />
              </div>
              <div className="w-full xl:w-0 xl:grow">
                <InputLabel text="Confirmar contraseña" error={error.input === 'passwordconf'} />
                <input
                  type="password"
                  autoComplete="off"
                  ref={passwordConfirmInputRef}
                  onChange={ev => {
                    onInputChange('passwordconf', ev.target.value)
                  }}
                  className={`w-full h-12 mt-1 border ${
                    error.input === 'passwordconf'
                      ? 'border-red-600'
                      : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                  } px-2 rounded-md focus:border-2 outline-none`}
                />
              </div>
            </div>
          }
          error={error}
          submitable={passwordInput !== '' && passwordConfirmInput !== ''}
          submitText="Crear cuenta"
          loading={loading}
          accountCreated={accountCreated}
          onSubmit={checkPassword}
          setStep={() => setStep('password')}
          creatingAccount={creatingAccount}
        />
        <Step
          icon={<MdOutlineMarkEmailRead size="20" className="w-8 h-8" />}
          titles={{
            inactive: 'Validación',
            active: 'Validá tu e-mail',
            done: 'E-mail validado ✓',
          }}
          state={step === 'validation' ? 'active' : step === 'finished' ? 'done' : 'inactive'}
          inputs={
            <>
              <span className="block mb-8 text-xl">Tu cuenta ha sido creada con éxito!</span>
              <span className="hyphens-none">
                Para terminar, ingresá al link de verificación que te enviamos a: <strong>{emailInput}</strong>
              </span>
            </>
          }
          noSubmit={true}
          creatingAccount={creatingAccount}
        />
        <input autoComplete="on" style={{ display: 'none' }} />
      </div>
    </section>
  )
}

function Step({
  icon,
  titles,
  state = 'inactive',
  inputs,
  error = { input: null, text: null },
  submitable = false,
  submitText = 'Continuar',
  creatingAccount,
  accountCreated = false,
  loading = false,
  onSubmit,
  noSubmit = false,
  setStep,
}) {
  return (
    <div
      className={`w-full px-4 flex flex-col rounded-b-md ${
        state === 'active' ? 'h-[26rem] xl:h-[22rem] pb-4 shadow-xl text-black' : 'h-12 text-gray-400'
      } overflow-y-hidden transition-all duration-700`}>
      <div
        className={`h-12 flex items-center [&>*]:transition-all [&>*]:duration-700 ${
          state === 'done' ? 'text-amber-500 font-medium' : ''
        }`}>
        {icon}
        <span className="pl-4 flex-1 xl:text-lg">{titles[state]}</span>
        {!creatingAccount && !accountCreated && state === 'done' && setStep && (
          <button type="button" onClick={setStep}>
            Editar
          </button>
        )}
      </div>
      <form
        onSubmit={ev => onSubmit(ev)}
        className={`h-0 grow mt-5 ${state === 'done' || state === 'inactive' ? 'hidden' : ''} flex flex-col`}>
        {inputs}
        <div className="w-full h-0 grow flex flex-col justify-end">
          <div className="w-full h-10 flex items-end">
            <div
              className={`w-full ${
                error.text !== null ? 'h-10' : 'h-0'
              } overflow-hidden text-red-600 flex justify-start items-center transition-all`}>
              <MdError size="20" className="w-5 h-5" />
              <span className="grow ml-1 text-sm leading-4" dangerouslySetInnerHTML={{ __html: error.text }} />
            </div>
          </div>
          {!noSubmit && (
            <>
              <div className="w-full xl:w-32 h-12">
                <ButtonWithProgress
                  text={submitText}
                  clickable={submitable}
                  loading={loading}
                  done={accountCreated}
                  fullWidth={true}
                />
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export function GetCUILButton({ setCuit = () => {}, cuitRef }) {
  const { showToast } = useMainContext()
  const [doc, setDoc] = useState('')
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const { ref, updateCaret } = useCaretPosition()

  return (
    <DefaultAlertDialog
      trigger={
        <button type="button" className="absolute top-1/2 -translate-y-1/2 right-4 font-medium text-sm text-amber-500">
          No sé mi CUIT / CUIL
        </button>
      }
      title="Consultar CUIT / CUIL"
      content={
        <div className="flex flex-col gap-4">
          <p>Ingresá tu DNI y apreta en Consultar para encontrar tu CUIT / CUIL.</p>
          <input
            ref={ref}
            type="text"
            name="dni"
            placeholder="DNI"
            className="w-full h-12 mt-1 border border-gray-400 hover:border-gray-500 focus:border-dewalt px-2 rounded-md focus:border-2 outline-none"
            value={doc}
            onChange={ev => {
              setDoc(OnlyNumbers('' + ev.target.value))
              updateCaret()
            }}
            inputMode="numeric"
            maxLength={8}
          />
        </div>
      }
      submit={{
        text: 'Consultar',
        onClick: async () => {
          try {
            setLoading(true)
            const result = await axios.get(process.env.REACT_APP_FB_API_LINK + 'afip/persons', {
              params: {
                doc,
              },
            })
            const info = result.data.info
            const cuit = FormatCuit(info.idPersona)

            setCuit(cuit)
            cuitRef.current.value = cuit

            showToast(`Se encontró el ${info.tipoClave} ${cuit}`, 'good')
            setDone(true)
          } catch (err) {
            if (err.code === 'ERR_CANCELED') return
            showToast(err.response.data.message ?? 'Error desconocido al consultar CUIT / CUIL', 'bad')
            // console.log(err)
          } finally {
            setLoading(false)
          }
        },
      }}
      cancel={{
        text: 'Cancelar',
        onClick: () => {
          setDoc('')
          setLoading(false)
          setDone(false)
        },
      }}
      loading={loading}
      done={done}
    />
  )
}

export default Signup

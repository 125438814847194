import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '../utils/Card'
import { useAbortableEffect } from '../utils/Hooks'
import LoadingBox from '../utils/LoadingBox'

function Brands() {
  const [brands, setBrands] = useState(null)
  const navigate = useNavigate()

  useAbortableEffect(signal => {
    ;(async () => {
      try {
        const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_brands', {
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
          signal,
        })
        // console.log(result)
        setBrands(result.data.brands)
      } catch (err) {
        if (err.code === 'ERR_CANCELED') return
        // console.log(err)
        navigate('/', { replace: true })
      }
    })()
  }, [])

  return (
    <div className="bg-pagebg grow text-black">
      <div className="max-w-content-max-width mx-auto py-4">
        {brands ? (
          <>
            <Section
              title={'Industriales'}
              titleSide={'left'}
              brands={brands.filter(brand => brand.type === 'industrial')}
            />
            <Section
              title={'Profesionales'}
              titleSide={'left'}
              brands={brands.filter(brand => brand.type === 'professional')}
            />
            <Section
              title={'Domésticas'}
              titleSide={'left'}
              brands={brands.filter(brand => brand.type === 'domestic')}
            />
          </>
        ) : (
          <LoadingBox />
        )}
      </div>
    </div>
  )
}

function Section({ image, title, titleSide, brands }) {
  return (
    <div className="w-full relative">
      {image && (
        <div
          className="absolute w-full h-44 xl:h-[27rem] top-4 left-0 z-[1]"
          style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      )}
      <div className="xl:w-content-max-width mx-auto relative z-[2]">
        <div
          className={`w-full px-2 xl:px-0 pt-6 xl:pt-10 pb-4 mb-2 xl:mb-8 flex ${
            titleSide === 'left' ? 'justify-start' : 'flex-row-reverse justify-end'
          } items-center gap-4`}>
          <div className={`${titleSide === 'left' ? 'xl:-ml-8' : 'xl:-mr-8'}`}>
            <h3 className="w-max p-2 font-sans-condensed bg-white text-black text-2xl xl:text-5xl font-bold shadow-lg">
              {title}
            </h3>
          </div>
          <div className="w-0 grow h-1 xl:h-2 bg-white" />
        </div>
        <div className="w-full mb-8 xl:w-full overflow-x-scroll scrollbar-hide">
          <div className="w-max px-2 xl:px-0 xl:w-full h-max flex gap-4 pb-6">
            {brands.map(brand => (
              <div key={brand.brand_id} className={`w-44 xl:w-64`}>
                <Card
                  flex={false}
                  grow={false}
                  img={brand.image}
                  title={brand.name}
                  link={`/catalogue/all?brand=${brand.brand_id}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Brands

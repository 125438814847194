import { MdOutlineLocalShipping, MdOutlineStore, MdPersonOutline } from "react-icons/md";
import { TbMotorbike } from "react-icons/tb";

export const ShippingMethodIcon = (icon, className) => {
  return {
    truck: <MdOutlineLocalShipping className={className} />,
    motorbike: <TbMotorbike className={className} />,
    store: <MdOutlineStore className={className} />,
    person: <MdPersonOutline className={className} />,
  }[icon];
};

export const PaymentMethodType = (type) => {
  return {
    cards: "Con débito o crédito",
    cash: "Con efectivo",
    other: "Con otras formas de pago",
  }[type];
};

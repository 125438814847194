import { useRef, useEffect, useState } from 'react'
import { useMainContext } from './MainContext'
import axios from 'axios'
import { MdDeleteOutline, MdOutlineRemoveShoppingCart } from 'react-icons/md'
import PlusMinusInput from './PlusMinusInput'
import { FormatPrice, ProductLink } from './Strings'
import { Link, useNavigate } from 'react-router-dom'
import { saveCookie, useOutsideAlerter } from './Html'
import ShowImage from './ShowImage'
import { round, isEqual, sortBy } from 'lodash'
import LoadingBox from './LoadingBox'
import ButtonWithProgress from './ButtonWithProgress'

function Cart() {
  const { firebase, setFirebase, showCart, setShowCart } = useMainContext()
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState(null)
  const [cart, setCart] = useState(null)
  const [cartUpdated, setCartUpdated] = useState(false)

  const ref = useRef(null)
  useOutsideAlerter(ref, () => setShowCart(false), 'cartButton')

  useEffect(() => {
    if (firebase.user && ((showCart && !cart) || cartUpdated)) {
      ;(async () => {
        try {
          setCart(null)
          setLoading(true)
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_cart', {
            params: { user_id: firebase.user.uid, page: false },
            headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY },
          })

          setData(result.data)
          setCart(result.data.cart)

          const cookieCart = result.data.cart.map(v => ({
            product_id: v.product.product_id,
            amount: v.amount,
          }))
          firebase.user.cart = cookieCart
          setFirebase({ ...firebase, user: firebase.user })
          saveCookie('cart', cookieCart)

          setCartUpdated(false)
          setLoading(false)
          // console.log(result)
        } catch (err) {
          setCartUpdated(false)
          setLoading(false)
          // console.log(err)
        }
      })()
    }
  }, [showCart, cartUpdated]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cart || cartUpdated) return

    if (
      !isEqual(
        sortBy([...firebase.user?.cart]),
        sortBy([...cart.map(item => ({ amount: item.amount, product_id: item.product.product_id }))])
      )
    ) {
      // console.log('UPDATE')
      setCartUpdated(true)
    }
  }, [firebase.user?.cart, cart, cartUpdated])

  return (
    <div ref={ref} className="w-full pt-2 flex flex-col">
      <span className="px-4 text-lg">Carrito</span>
      <div className="w-full h-[1px] bg-neutral-300" />
      <div className="w-full min-h-[6rem] flex justify-center items-center">
        {firebase.user ? (
          loading ? (
            <LoadingBox />
          ) : cart && cart.length > 0 ? (
            <div className="w-full flex flex-col">
              <div className="w-full max-h-[220px] px-4 overflow-auto">
                <div className="w-full my-2 flex flex-col gap-2">
                  {cart.map((v, i) => (
                    <CartProduct
                      key={i}
                      product={v.product}
                      amount={v.amount}
                      user_id={firebase.user.uid}
                      cart={cart}
                      setCart={setCart}
                    />
                  ))}
                </div>
              </div>
              <div className="p-4 pt-0">
                <CartSummary cart={cart} mpAddPct={data?.mp_add_pct} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-gray-500">
              <MdOutlineRemoveShoppingCart className="mb-2 text-3xl" />
              <span className="w-full text-center">Tu carrito está vacío</span>
            </div>
          )
        ) : (
          <div className="w-full flex justify-center items-center">
            <span className="select-none">
              <Link
                to="/login/user"
                className="inline font-medium text-amber-500 hover:text-amber-600 transition-colors">
                Iniciá sesión
              </Link>{' '}
              para armar tu carrito
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

// function LoadingSkeleton({ skeletonAmount }) {
//   return (
//     <div className="w-full flex flex-col">
//       <div className="w-full mb-2 flex flex-col">
//         {Array(skeletonAmount)
//           .fill("")
//           .map((_, i) => (
//             <div key={i} className="py-2">
//               <div className="w-full h-20 flex gap-2">
//                 <Skeleton className="w-20 h-20" />
//                 <div className="w-0 grow flex flex-col">
//                   <div className="w-full h-0 grow flex flex-col gap-1">
//                     <Skeleton className="w-full h-3" />
//                     <Skeleton className="w-full h-3" />
//                   </div>
//                   <div className="w-full flex justify-between items-end">
//                     <Skeleton className="w-28 h-9" />
//                     <Skeleton className="w-20 h-6" />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//       </div>
//       <div className="flex flex-col">
//         <Skeleton className="w-full h-8 mt-4" />
//         <div className="w-full h-[1px] my-3 bg-gray-200" />
//         <Skeleton className="w-full h-8 mb-4" />
//       </div>
//       <div className="w-full h-10">
//         <Skeleton className="w-full h-full" />
//       </div>
//     </div>
//   );
// }

export function CartProduct({ product, amount, user_id, cart, setCart, isPage }) {
  const { firebase, setFirebase } = useMainContext()
  const { product_id, image, title, model, brand, stock, offer_cost_stock, offer_price, normal_price } = product
  const total_stock = stock + offer_cost_stock
  const [loading, setLoading] = useState(false)
  const [amountInput, setAmountInput] = useState(amount)

  const productLink = ProductLink(product_id, title)

  const onCartUpdated = updatedCart => {
    firebase.user.cart = updatedCart
    setFirebase({ ...firebase, user: firebase.user })
    saveCookie('cart', updatedCart)

    const newCart = updatedCart.map(item => ({
      amount: item.amount,
      product: cart.find(i => i.product.product_id === item.product_id).product,
    }))
    setCart(newCart)
  }

  const removeProduct = async () => {
    try {
      setLoading(true)
      const result = await axios.put(
        process.env.REACT_APP_API_LINK + 'update_cart',
        { user_id: user_id, product_id: product_id, amount: '', action: 'remove' },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      onCartUpdated(result.data.cart)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      // console.log(err)
    }
  }

  const updateAmount = async (action, amount) => {
    try {
      setLoading(true)
      const result = await axios.put(
        process.env.REACT_APP_API_LINK + 'update_cart',
        {
          user_id: user_id,
          product_id: product_id,
          action: action,
          amount: action === 'remove' ? '' : amount,
        },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      onCartUpdated(result.data.cart)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      // console.log(err)
    }
  }

  return (
    <>
      <div className={`w-full ${isPage ? 'h-24 xl:h-32' : 'h-20'} relative flex animate-overlay-show duration-500`}>
        {loading ? (
          <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-70 flex justify-center items-center">
            <img src="/loading.svg" className="w-12 h-12" alt="Loading" />
          </div>
        ) : (
          total_stock < amount && (
            <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-70 flex flex-col justify-center items-center">
              <span>El stock del producto cambió</span>
              <button
                type="button"
                onClick={async () => {
                  if (total_stock === 0) {
                    removeProduct()
                  } else {
                    await updateAmount('subtract', amount - total_stock)
                    setAmountInput(total_stock)
                  }
                }}
                className="font-bold text-amber-500 hover:text-amber-600 transition-colors">
                Actualizar
              </button>
            </div>
          )
        )}
        <Link
          to={productLink}
          className={`${isPage ? 'w-24 h-24 xl:w-32 xl:h-32' : 'w-20 h-20'} p-2 rounded-md border border-gray-200`}>
          <ShowImage image={image} alt={`Imágen de ${title}`} />
        </Link>
        <div className={`w-0 grow h-full ml-2 flex flex-col ${!isPage ? 'text-xs' : 'max-xl:text-sm'}`}>
          <div className="w-full flex">
            <div className="w-0 grow flex flex-col">
              <Link to={productLink} className="w-max max-w-full line-clamp-1 hover:text-neutral-700 transition-colors">
                {title}
              </Link>
              <span className="text-gray-500 uppercase">
                {brand.name}・{model}
              </span>
            </div>
            <div className={`ml-2 ${isPage ? 'w-8 h-8' : 'w-7 h-7'}`}>
              <button
                type="button"
                title="Eliminar producto"
                className="w-full h-full rounded-full hover:bg-gray-100 transition-colors group/button"
                onClick={removeProduct}>
                <MdDeleteOutline className="w-full h-full p-1 text-gray-400 group-hover/button:text-gray-600 transition-colors" />
              </button>
            </div>
          </div>
          <div className="h-0 grow flex items-end justify-between">
            <div className="flex xl:flex-row-reverse max-xl:flex-col xl:items-center xl:gap-2">
              {isPage && <span className="xl:mb-1 text-sm text-gray-500">{total_stock} en stock</span>}
              <div className="w-24 2xs:w-28 h-9">
                <PlusMinusInput
                  state={[amountInput, setAmountInput]}
                  min={0}
                  max={total_stock}
                  onValueChange={v => {
                    // console.log(v)
                    updateAmount(v === 0 ? 'remove' : v > amount ? 'add' : 'subtract', Math.abs(v - amount))
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col items-end">
              {offer_price && (
                <del className={`${!isPage ? 'text-sm' : ''} text-gray-500`}>
                  {FormatPrice(amountInput * normal_price)}
                </del>
              )}
              <span className={`leading-5 ${!isPage ? 'text-lg' : '2xs:text-lg xs:text-xl xs:leading-5 xl:text-2xl'}`}>
                {FormatPrice(amountInput * (offer_price ?? normal_price))}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-gray-200 last:hidden" />
    </>
  )
}

export function CartSummary({ cart, isPage, mpAddPct }) {
  const { setShowCart } = useMainContext()
  const navigate = useNavigate()
  const cartTotal = cart.reduce((a, v) => a + (v.product.offer_price ?? v.product.normal_price) * v.amount, 0)
  const canBuy = !cart.some(item => item.amount > item.product.stock + item.product.offer_cost_stock)

  return (
    <>
      <div className={`${!isPage ? 'mt-4 text-lg' : 'text-xl gap-1'} flex flex-col`}>
        {cart.some(v => v.product.offer_price) && (
          <>
            <div className="flex items-center">
              <span>Subtotal</span>
              <span className="w-0 grow text-end">
                {FormatPrice(cart.reduce((a, v) => a + v.product.normal_price * v.amount, 0))}
              </span>
            </div>
            <div className="flex items-center text-green-600">
              <span>Descuento</span>
              <span className="w-0 grow text-end">
                {'- ' +
                  FormatPrice(
                    cart.reduce(
                      (a, v) =>
                        a + (v.product.offer_price && (v.product.normal_price - v.product.offer_price) * v.amount),
                      0
                    )
                  )}
              </span>
            </div>
          </>
        )}
        {!isPage && <div className="w-full h-[1px] my-3 bg-gray-200" />}
        <div className="flex items-center">
          <span>Total</span>
          <span className={`w-0 grow text-end ${isPage ? 'text-2xl' : 'text-xl'} font-medium`}>
            {FormatPrice(cartTotal)}
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-sm">
            Pagando por <img alt="" src="/mercado-pago-notext.png" className="inline w-6 h-6" />
          </span>
          <span className={`w-0 grow text-end ${isPage ? 'text-xl' : 'text-lg'}`}>
            {FormatPrice(round(cartTotal * (1 + mpAddPct)))}
          </span>
        </div>
      </div>
      <div className="w-full h-10 mt-4">
        <ButtonWithProgress
          text={isPage ? 'Continuar compra' : 'Ver carrito'}
          fullWidth={true}
          onClick={() => {
            navigate(isPage ? '/checkout/cart' : '/cart')
            setShowCart(false)
          }}
          clickable={canBuy}
        />
      </div>
    </>
  )
}

export default Cart

import { useEffect, useState } from "react";
import { MdOutlineHideImage } from "react-icons/md";

function ShowImage({
  image,
  alt = "Imágen",
  draggable = true,
  contain = true,
  imgClassName,
  errorClassName,
}) {
  const [isImgValid, setIsImgValid] = useState(true);

  useEffect(() => {
    setIsImgValid(true);
  }, [image]);

  return (
    <div className="w-full h-full rounded-[inherit]">
      {isImgValid ? (
        <img
          src={image}
          className={`w-full h-full text-[0rem] select-none ${
            contain ? "object-contain" : "object-cover"
          } ${imgClassName ?? ""} rounded-[inherit]`}
          alt={alt}
          draggable={draggable}
          onError={() => setIsImgValid(false)}
        />
      ) : (
        <div
          className={`w-full h-full bg-white text-gray-300 flex justify-center items-center ${
            errorClassName ?? ""
          } rounded-[inherit]`}
        >
          <MdOutlineHideImage className="w-1/2 h-1/2" />
        </div>
      )}
    </div>
  );
}

export default ShowImage;

import { MdOutlineError } from 'react-icons/md'

function InfoBlock({ text, className }) {
  return (
    <div
      className={`relative pl-1 pr-3 py-4 bg-neutral-100 flex items-center rounded-md text-sm overflow-hidden ${
        className ?? ''
      }`}>
      <div className="absolute top-0 left-0 w-1 h-full bg-dewalt"></div>
      <MdOutlineError className="w-6 h-6 mx-3 text-dewalt" />
      <span className="w-0 grow hyphens-none">{text}</span>
    </div>
  )
}

export default InfoBlock

import * as Portal from '@radix-ui/react-portal'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { MdClose, MdZoomOut } from 'react-icons/md'
import { useEffect, useState } from 'react'

function ZoomableImage({ image, close }) {
  const [scale, setScale] = useState(1)

  useEffect(() => {
    document.body.classList.add('no-scroll')

    return () => document.body.classList.remove('no-scroll')
  }, [])

  return (
    <Portal.Root className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[999999999999999] overflow-hidden xl:hidden">
      <div
        className="absolute top-0 left-0 w-full h-full bg-black/50 z-0"
        onTouchMove={ev => ev.preventDefault()}
        onClick={close}
      />
      <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full max-h-[200vw] z-10">
        <TransformWrapper
          wheel={{ disabled: true }}
          centerOnInit={true}
          initialScale={1}
          maxScale={3}
          doubleClick={{ step: 3 }}
          onTransformed={(_, state) => {
            setScale(state.scale)
          }}>
          {({ resetTransform }) => (
            <>
              <div className="absolute left-1/2 -translate-x-1/2 bottom-4 z-50">
                {scale > 1 && (
                  <button
                    type="button"
                    onClick={() => resetTransform()}
                    className="bg-black/50 p-2 text-white flex items-center rounded-full">
                    <MdZoomOut size={24} />
                    <span className="px-2 text-sm">Reiniciar&nbsp;zoom</span>
                  </button>
                )}
              </div>
              <div className="absolute -top-14 right-4 flex gap-2">
                <button type="button" onClick={close} className="bg-black/50 p-2 text-white rounded-full">
                  <MdClose size={24} />
                </button>
              </div>
              <TransformComponent wrapperStyle={{ height: 'max-content' }}>
                <img alt="" src={image} className="w-full" />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>
    </Portal.Root>
  )
}

export default ZoomableImage

import { useEffect, useMemo, useRef } from 'react'
import { BrowserRouter as Router, Route, Routes, Outlet, useSearchParams, useLocation } from 'react-router-dom'

import NotFound from './components/routes/NotFound'
import Topbar from './components/Topbar'
import Home from './components/routes/Home'
import Login from './components/routes/Login'
import Signup from './components/routes/Signup'
import ForgotPassword from './components/routes/ForgotPassword'
import Categories from './components/routes/Categories'
import Footer from './components/Footer'
import Catalogue from './components/routes/Catalogue'
import Product from './components/routes/Product'
import Checkout from './components/routes/Checkout'

import { MainContext } from './components/utils/MainContext'

import { initializeApp } from 'firebase/app'
import {
  getAuth,
  fetchSignInMethodsForEmail,
  updatePassword,
  reauthenticateWithPopup,
  GoogleAuthProvider,
  updateProfile,
} from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { useState } from 'react'
import NotLoggedIn from './components/routes/NotLoggedIn'
import UserOrders from './components/routes/user/UserOrders'
import UserProfile from './components/routes/user/UserProfile'
import Congrats from './components/routes/Congrats'
import UserOrder from './components/routes/user/UserOrder'
import BankTransfer from './components/routes/user/BankTransfer'
import AdminOrders from './components/routes/admin/sell/AdminOrders'
import AdminOrder from './components/routes/admin/sell/AdminOrder'
import AdminHome from './components/routes/admin/AdminHome'
import AdminProducts from './components/routes/admin/sell/AdminProducts'
import AdminProduct from './components/routes/admin/sell/AdminProduct'
import Toast from './components/utils/Toast'
import AdminHomeConfig from './components/routes/admin/config/AdminHomeConfig'
import AdminBrandsCategories from './components/routes/admin/config/AdminBrandsCategories'
import AdminVarsConfig from './components/routes/admin/config/AdminVarsConfig'
import CartPage from './components/routes/CartPage'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import WhatsAppProfilePic from './assets/foto-perfil-santiago.jpg'
import { cookieExists, readCookie, saveCookie } from './components/utils/Html'
import UTCoins from './components/routes/UTCoins'
import OrderBudget from './components/routes/OrderBudget'
// import PDF from './components/PDF'
import { useCallback } from 'react'
import AdminUsers from './components/routes/admin/users/AdminUsers'
import AdminViewUser from './components/routes/admin/users/AdminViewUser'
import { useAbortableEffect, useElementSize } from './components/utils/Hooks'
import Brands from './components/routes/Brands'
import AdminAddStock from './components/routes/admin/sell/AdminAddStock'
import firebaseConfig from './firebase-config.json'
import Help from './components/routes/Help'
import DefaultAlertDialog from './components/utils/DefaultAlertDialog'
import InputLabel from './components/utils/InputLabel'
import { MdError } from 'react-icons/md'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import axios from 'axios'
import { CapitalizeAllWords, FormatCuit, OnlyNumbers } from './components/utils/Strings'
import Favorites from './components/routes/Favorites'
import LoadingBox from './components/utils/LoadingBox'

function App() {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  // const firebaseConfig = {
  //   apiKey: 'AIzaSyBhTGaWhQGusAdUshhfPD3UnDL5bd8anZ4',
  //   authDomain: 'universal-tools-web.firebaseapp.com',
  //   projectId: 'universal-tools-web',
  //   storageBucket: 'universal-tools-web.appspot.com',
  //   messagingSenderId: '1009984472508',
  //   appId: '1:1009984472508:web:9d8b88d9b0910fe51d97b9',
  //   measurementId: 'G-X4BTPEC1TL',
  // }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  auth.useDeviceLanguage()
  const user = auth.currentUser
  const storage = getStorage(app)
  // const remoteConfig = getRemoteConfig(app)
  // remoteConfig.settings.minimumFetchIntervalMillis = 3600000
  // remoteConfig.defaultConfig = {
  //   contact_number: '11 3958-6926',
  // }

  const location = useLocation()
  const empty = {
    topbar:
      location &&
      (location.pathname.startsWith('/checkout') ||
        location.pathname.startsWith('/login') ||
        location.pathname.startsWith('/signup')),
    footer:
      location &&
      (location.pathname.startsWith('/login') ||
        location.pathname.startsWith('/signup') ||
        location.pathname.startsWith('/checkout') ||
        location.pathname.startsWith('/admin') ||
        location.pathname.endsWith('/budget')),
  }

  const toastRef = useRef(null)

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0)
  }, [location])

  // Inicializo el user que se va a usar en el UserContext
  const [firebase, setFirebase] = useState({ auth: auth, storage: storage, user: user })
  const [remoteContactNumber, setRemoteContactNumber] = useState(null)

  const { ref: topbarRef, size: topbarSize } = useElementSize()
  const [showCart, setShowCart] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  useMemo(() => {
    if (cookieExists('user')) {
      const userCookie = readCookie('user', true)

      const cartCookie = cookieExists('cart') ? readCookie('cart') : []
      userCookie.cart = cartCookie

      const favsCookie = cookieExists('favs') ? readCookie('favs') : []
      userCookie.favs = favsCookie

      setFirebase(f => ({
        ...f,
        user: userCookie,
      }))
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (firebase.user) {
        const result = await fetchSignInMethodsForEmail(firebase.auth, firebase.user.email)
        if (!result.includes('password')) document.getElementById('no-password-dialog').click()
      }
    })()
  }, [firebase])

  useAbortableEffect(signal => {
    ;(async () => {
      try {
        const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_footer', {
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
          signal,
        })
        setRemoteContactNumber(result.data.media.contact_number)
      } catch (err) {
        if (err.code === 'ERR_CANCELED') return
        // console.log(err)
      }
    })()
  }, [])

  // useEffect(() => {
  //   ;(async () => {
  //     await fetchAndActivate(remoteConfig)
  //     getAll(remoteConfig)
  //     setRemoteContactNumber(getString(remoteConfig, 'contact_number'))
  //   })()
  // }, [remoteConfig])

  const showToast = useCallback(
    (text, type) => {
      if (toastRef.current) toastRef.current.show(text, type)
    },
    [toastRef]
  )

  return (
    <div className="relative w-full overflow-x-hidden min-h-screen bg-neutral-950 flex flex-col">
      <MissingUserDataDialog firebase={firebase} setFirebase={setFirebase} showToast={showToast} />
      {(!firebase?.user || firebase.user.role === 'user') &&
        !location.pathname.startsWith('/checkout') &&
        !location.pathname.endsWith('/budget') &&
        remoteContactNumber && (
          <FloatingWhatsApp
            phoneNumber={`549 ${remoteContactNumber}`}
            accountName="Santiago"
            statusMessage="Universal Tools"
            chatMessage="¡Hola! Soy Santiago. Si tenes alguna duda, no dudes en enviar un mensaje! 💪"
            avatar={WhatsAppProfilePic}
            placeholder="Escribí un mensaje..."
            allowClickAway={true}
            allowEsc={true}
            style={{ hyphens: 'none', color: 'black' }}
            chatboxHeight={400}
            buttonStyle={{ zIndex: 887 }}
          />
        )}
      <MainContext.Provider
        value={{
          firebase,
          setFirebase,
          showToast,
          showCart,
          setShowCart,
          topbarSize,
        }}>
        {!location.pathname.endsWith('/budget') && (
          <div ref={topbarRef} className="w-full h-max fixed top-0 left-0 z-[887]">
            <Topbar empty={empty.topbar} searchQuery={searchQuery} />
          </div>
        )}
        <div className={`w-full`} style={{ height: topbarSize.height }} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="help/:page" element={<Help />} />
          {/* <Route path="/pdf" element={<PDF />} /> */}
          <Route path="login/user" element={<Login step="email" />} />
          <Route path="login" element={<RouteWithParams params={['p']} />}>
            <Route path="pass" element={<Login step="password" />} />
            <Route path="forgot" element={<ForgotPassword />} />
          </Route>
          <Route path="signup" element={<Signup />} />
          <Route path="categories" element={<Categories />} />
          <Route path="brands" element={<Brands />} />
          <Route path="catalogue" element={<Catalogue setSearchQuery={setSearchQuery} />} />
          <Route
            path="catalogue/:category/:subCategory/:search"
            element={<Catalogue setSearchQuery={setSearchQuery} />}
          />
          <Route path="catalogue/:category/:search" element={<Catalogue setSearchQuery={setSearchQuery} />} />
          <Route path="catalogue/:search" element={<Catalogue setSearchQuery={setSearchQuery} />} />
          <Route path="product/:product_id/*" element={<Product />} />
          <Route path="favorites" element={<Favorites />} />
          <Route path="cart" element={<CartPage />} />
          <Route path="ut_coins" element={<UTCoins />} />
          <Route path="checkout/:product_id" element={<Checkout />} />
          <Route path="checkout/:product_id/:shippingParam" element={<Checkout />} />
          <Route path="checkout/:product_id/:shippingParam/:paymentParam" element={<Checkout paid={false} />} />
          <Route path="checkout/:product_id/:shippingParam/:paymentParam/paid" element={<Checkout paid={true} />} />
          <Route path="checkout/:order_id/congrats" element={<Congrats />} />
          <Route path="user/profile" element={<UserProfile />} />
          <Route path="user/orders" element={<UserOrders />} />
          <Route path="user/orders/:order_id" element={<UserOrder />} />
          <Route path="user/orders/:order_id/summary" element={<UserOrder summary={true} />} />
          <Route path="user/orders/:order_id/bank_transfer" element={<BankTransfer />} />
          <Route path="user/orders/:order_id/ticket_upload" element={<BankTransfer ticket={true} />} />
          <Route path="user/orders/:order_id/budget" element={<OrderBudget />} />
          <Route path="admin/home" element={<AdminHome />} />
          <Route path="admin/orders" element={<AdminOrders />} />
          <Route path="admin/orders/:order_id" element={<AdminOrder />} />
          <Route path="admin/orders/:order_id/summary" element={<AdminOrder summary={true} />} />
          <Route path="admin/orders/:order_id/bank_transfer" element={<BankTransfer admin={true} />} />
          <Route path="admin/orders/:order_id/ticket_upload" element={<BankTransfer admin={true} ticket={true} />} />
          <Route path="admin/orders/:order_id/budget" element={<OrderBudget />} />
          <Route path="admin/products" element={<AdminProducts />} />
          <Route path="admin/products/add_stock" element={<AdminAddStock />} />
          <Route path="admin/products/new" element={<AdminProduct creating={true} />} />
          <Route path="admin/products/:product_id/modify" element={<AdminProduct creating={false} />} />
          <Route path="admin/config/home" element={<AdminHomeConfig />} />
          <Route path="admin/config/brands_categories" element={<AdminBrandsCategories />} />
          <Route path="admin/config/vars" element={<AdminVarsConfig />} />
          <Route path="admin/users" element={<AdminUsers />} />
          <Route path="admin/users/:user_id" element={<AdminViewUser />} />
          <Route path="notloggedin/buy" element={<NotLoggedIn reason="buy" />} />
          <Route path="notloggedin/fav" element={<NotLoggedIn reason="fav" />} />
          <Route path="notloggedin/cart" element={<NotLoggedIn reason="cart" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainContext.Provider>
      <Footer empty={empty.footer} />
      <Toast ref={toastRef} />
    </div>
  )
}

function MissingUserDataDialog({ firebase, setFirebase, showToast }) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [error, setError] = useState({ input: '', text: '' })
  const [showText, setShowText] = useState({ password: false, confirm: false })

  useAbortableEffect(
    signal => {
      ;(async () => {
        try {
          if (!firebase.user) return

          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_user', {
            params: {
              user_id: firebase.user?.uid,
              ut_coins_only: false,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          setData(result.data.user)
        } catch (err) {
          // console.log(err)
          // setDone(true)
        }
      })()
    },
    [firebase.user]
  )

  const handleSubmit = async ev => {
    ev.preventDefault()
    let { password, confirm, name, last_name, area_code, phone_number, doc } = Object.fromEntries(
      new FormData(ev.target).entries()
    )

    if (confirm !== password) {
      setError({ input: 'confirm', text: 'La contraseña no coincide' })
      return
    }

    if (!new RegExp(process.env.REACT_APP_REGEX_PWD).test(password)) {
      setError({
        input: '',
        text: 'La contraseña tiene que tener al menos 6 dígitos',
      })
      return
    }

    setError({ input: null, text: null })
    setLoading(true)

    let cuit = ''

    try {
      const cuitResult = await axios.get(process.env.REACT_APP_FB_API_LINK + 'afip/persons', {
        params: {
          doc,
        },
      })
      const info = cuitResult.data.info
      cuit = FormatCuit(info.idPersona)
    } catch (err) {
      if (err.code === 'ERR_CANCELED') return
      // console.log(err)
      setLoading(false)
      setError({
        input: 'doc',
        text:
          (err.response.data.code === 18
            ? 'Ya hay un usuario registrado con ese documento'
            : err.response.data.message ?? 'Error desconocido al consultar CUIT / CUIL con el documento',
          'bad'),
      })
      return
    }

    let user = null

    try {
      const result = await reauthenticateWithPopup(firebase.auth.currentUser, new GoogleAuthProvider())
      user = result.user
    } catch (err) {
      if (err.code === 'ERR_CANCELED') return
      setLoading(false)
      setError({
        input: '',
        text:
          err.code === 'auth/popup-blocked'
            ? 'Habilitá los pop-ups para continuar'
            : err.code === 'auth/popup-closed-by-user' || err.code === 'auth/cancelled-popup-request'
            ? 'Cancelado por el usuario'
            : process.env.REACT_APP_TOAST_UNKNOWN_ERROR,
      })
      return
    }

    name = CapitalizeAllWords(name)
    last_name = CapitalizeAllWords(last_name)

    try {
      await axios.put(
        process.env.REACT_APP_API_LINK + 'update_user',
        {
          user_id: firebase.user.uid,
          section: 2,
          name,
          last_name,
          area_code,
          phone_number,
          cuit,
        },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
    } catch (err) {
      if (err.code === 'ERR_CANCELED') return
      // console.log(err)
      setLoading(false)
      setError({
        input: '',
        text: err.response?.data?.message ?? 'Error desconocido al actualizar los datos, intentá de nuevo.',
      })
      return
    }

    try {
      await updatePassword(user, password)

      const displayName = `${name}_${last_name}`
      await updateProfile(user, { displayName })
      saveCookie('user', { ...firebase.user, displayName }, true)
      setFirebase(f => ({ ...f, user: { ...firebase.user, displayName } }))

      setLoading(false)
      setDone(true)
      showToast('¡Listo! Ya se actualizaron tus datos.', 'good')
    } catch (err) {
      if (err.code === 'ERR_CANCELED') return
      // console.log(err)
      setLoading(false)
      setError({ input: '', text: 'error' })
    }
  }
  return (
    <DefaultAlertDialog
      title={data?.cuit ? 'Creá tu contraseña' : 'Faltan datos en tu cuenta'}
      content={
        data ? (
          <>
            <div className="mb-4">
              {data.cuit ? (
                <>
                  <p>Como te registraste con Google, necesitás una contraseña para ingresar con e-mail.</p>
                  <p>Recomendamos que la crees ahora.</p>
                  <p className="text-gray-500 text-sm">
                    Vas a tener que ingresar nuevamente con Google para aplicar los cambios.
                  </p>
                </>
              ) : (
                <>
                  <p>Como te registraste con Google, hay datos faltantes que necesitamos que completes.</p>
                  <p>Recomendamos que los completes ahora ya que los vas a necesitar para realizar pedidos.</p>
                  <p className="text-gray-500 text-sm">
                    Vas a tener que ingresar nuevamente con Google para aplicar los cambios.
                  </p>
                </>
              )}
            </div>
            <div className={`${data.cuit ? 'hidden' : 'flex'} gap-4`}>
              <div className="flex-1">
                <InputLabel text="Nombre" error={error.input === 'name'} />
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  className={`w-full h-12 mt-1 border ${
                    error.input === 'name'
                      ? 'border-red-600'
                      : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                  } px-2 rounded-md focus:border-2 outline-none`}
                  required
                  minLength={1}
                  maxLength={50}
                  defaultValue={data.name || firebase.user?.displayName?.split('_')[0]}
                />
              </div>
              <div className="flex-1">
                <InputLabel text="Apellido" error={error.input === 'last_name'} />
                <input
                  type="text"
                  name="last_name"
                  autoComplete="off"
                  className={`w-full h-12 mt-1 border ${
                    error.input === 'last_name'
                      ? 'border-red-600'
                      : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                  } px-2 rounded-md focus:border-2 outline-none`}
                  required
                  minLength={1}
                  maxLength={50}
                  defaultValue={data.last_name || firebase.user?.displayName?.split('_')[1]}
                />
              </div>
            </div>
            <div className="w-full mt-2 flex max-xl:flex-col gap-4">
              <div className="w-full xl:w-0 xl:grow">
                <InputLabel text="Contraseña" error={error.input === 'password'} />
                <div className="w-full h-max relative">
                  <input
                    type={showText.password ? 'text' : 'password'}
                    name="password"
                    autoComplete="off"
                    className={`w-full h-12 mt-1 border ${
                      error.input === 'password'
                        ? 'border-red-600'
                        : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                    } px-2 rounded-md focus:border-2 outline-none`}
                    required
                    minLength={6}
                    maxLength={128}
                  />
                  <button
                    type="button"
                    onClick={() => setShowText(st => ({ ...st, password: !st.password }))}
                    className={`absolute top-1/2 -translate-y-1/2 right-2 w-7 h-7 ${
                      showText.password ? 'text-black' : 'text-gray-400'
                    } transition-colors`}>
                    {showText.password ? (
                      <AiOutlineEye className="w-full h-full" />
                    ) : (
                      <AiOutlineEyeInvisible className="w-full h-full" />
                    )}
                  </button>
                </div>
              </div>
              <div className="w-full xl:w-0 xl:grow">
                <InputLabel text="Confirmar contraseña" error={error.input === 'confirm'} />
                <div className="w-full h-max relative">
                  <input
                    type={showText.confirm ? 'text' : 'password'}
                    name="confirm"
                    autoComplete="off"
                    className={`w-full h-12 mt-1 border ${
                      error.input === 'confirm'
                        ? 'border-red-600'
                        : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                    } px-2 rounded-md focus:border-2 outline-none`}
                    required
                    minLength={6}
                    maxLength={128}
                  />
                  <button
                    type="button"
                    onClick={() => setShowText(st => ({ ...st, confirm: !st.confirm }))}
                    className={`absolute top-1/2 -translate-y-1/2 right-2 w-7 h-7 ${
                      showText.confirm ? 'text-black' : 'text-gray-400'
                    } transition-colors`}>
                    {showText.confirm ? (
                      <AiOutlineEye className="w-full h-full" />
                    ) : (
                      <AiOutlineEyeInvisible className="w-full h-full" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className={`w-full mt-2 ${data.cuit ? 'hidden' : 'flex'} gap-4`}>
              <div className="w-0 grow-[0.3] xl:grow-[0.2]">
                <InputLabel text="Cód. Area" error={error.input === 'area_code'} />
                <input
                  type="text"
                  name="area_code"
                  inputMode="numeric"
                  autoComplete="off"
                  className={`w-full h-12 mt-1 border ${
                    error.input === 'area_code'
                      ? 'border-red-600'
                      : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                  } px-2 rounded-md focus:border-2 outline-none`}
                  onChange={ev => {
                    ev.target.value = OnlyNumbers(ev.target.value)
                  }}
                  placeholder="11"
                  required
                  maxLength={4}
                  pattern="[0-9]{1,4}$"
                  defaultValue={data.area_code}
                />
              </div>
              <div className="w-0 grow-[0.7] xl:grow-[0.8]">
                <InputLabel text="Número" error={error.input === 'phone_number'} />
                <input
                  type="text"
                  name="phone_number"
                  inputMode="numeric"
                  autoComplete="off"
                  className={`w-full h-12 mt-1 border ${
                    error.input === 'phone_number'
                      ? 'border-red-600'
                      : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                  } px-2 rounded-md focus:border-2 outline-none`}
                  onChange={ev => {
                    ev.target.value = OnlyNumbers(ev.target.value)
                  }}
                  placeholder="12345678"
                  required
                  maxLength={10}
                  pattern="[0-9]{1,10}$"
                  defaultValue={data.phone_number}
                />
              </div>
            </div>
            <div className={`w-full mt-2 ${data.cuit ? 'hidden' : ''}`}>
              <InputLabel text="DNI" error={error.input === 'doc'} />
              <div className="w-full h-12 relative">
                <input
                  type="text"
                  name="doc"
                  inputMode="numeric"
                  autoComplete="off"
                  className={`w-full h-full mt-1 border ${
                    error.input === 'doc'
                      ? 'border-red-600'
                      : 'border-gray-400 hover:border-gray-500 focus:border-dewalt'
                  } px-2 rounded-md focus:border-2 outline-none`}
                  onChange={ev => {
                    ev.target.value = OnlyNumbers(ev.target.value)
                  }}
                  placeholder="12345678"
                  required
                  minLength={8}
                  maxLength={8}
                  pattern="[0-9]+"
                  defaultValue={data.cuit !== '' ? data.cuit.substring(3, 11) : ''}
                />
              </div>
            </div>
            {error.text && (
              <div className="w-full mt-2 text-red-700 hyphens-none flex items-center">
                <MdError className="w-5 h-5" />
                <p className="flex-1 ml-2">{error.text}</p>
              </div>
            )}
          </>
        ) : (
          <LoadingBox text={'Esperá un momento...'} />
        )
      }
      cancel={{ text: 'Más tarde' }}
      trigger={<div id="no-password-dialog" hidden />}
      submit={{ text: data?.cuit ? 'Crear contraseña' : 'Actualizar datos', onSubmit: handleSubmit }}
      loading={loading}
      done={done}
    />
  )
}

function RouteWithParams({ params, all = true }) {
  const [searchParams] = useSearchParams()
  const paramsExist = all ? params.every(v => searchParams.has(v)) : params.some(v => searchParams.has(v))
  return paramsExist ? <Outlet /> : <NotFound />
}

// Esto lo hice para poder usar el location en el useEffect y mandar scrollToTop en todas al abrir cada pagina
// eslint-disable-next-line
export default () => (
  <Router>
    <App />
  </Router>
)

import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from '../../../Sidebar'
import Breadcrumb from '../../../utils/Breadcrumb'
import ButtonWithProgress from '../../../utils/ButtonWithProgress'
import { delay, fileToBase64, setDocumentTitle, useWindowSize } from '../../../utils/Html'
import { useMainContext } from '../../../utils/MainContext'
import axios from 'axios'
import { TbScan } from 'react-icons/tb'
import Quagga from '@ericblade/quagga2'
import BarcodeScanner from '../../../utils/BarcodeScanner'
import { MdOutlineDocumentScanner, MdOutlineStopCircle } from 'react-icons/md'
import ShowImage from '../../../utils/ShowImage'
import PlusMinusInput from '../../../utils/PlusMinusInput'
import LoadingBox from '../../../utils/LoadingBox'
import { ProductLink } from '../../../utils/Strings'

function AdminAddStock() {
  const { firebase, showToast } = useMainContext()
  const navigate = useNavigate()

  const [upc, setUpc] = useState('')
  const [product, setProduct] = useState(null)

  const [scannerActive, setScannerActive] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!firebase.user || firebase.user.role === 'user') {
      navigate('/', { replace: true })
    }
    setDocumentTitle('Stock por UPC')
  }, [firebase.user, navigate])

  const searchProduct = useCallback(
    foundUpc => {
      const controller = new AbortController()
      const signal = controller.signal

      ;(async () => {
        const code = foundUpc ?? upc
        try {
          setProduct(null)
          setLoading(true)
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_product', {
            params: {
              user_id: firebase.user?.uid,
              product_id: '',
              upc: code,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })

          // console.log(result)

          setProduct(result.data.product)
          setLoading(false)
        } catch (err) {
          if (err.code === 'ERR_CANCELED') return
          if (err.response.data.code === 0) showToast(`No existe producto con UPC: ${code}`, 'bad')
          else showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
          // console.log(err)
          setLoading(false)
        }
      })()

      return () => controller.abort()
    },
    [upc, firebase.user?.uid] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={true} />
      <div className="w-full xl:w-content-max-width mx-auto">
        <div className="w-full px-2 xl:px-60 pb-8">
          <div className="my-8 max-xl:my-4">
            <Breadcrumb
              items={[
                {
                  name: 'Productos',
                  link: '/admin/products',
                },
                {
                  name: 'Stock por UPC',
                },
              ]}
            />
          </div>
          <div className="w-full p-4 bg-white rounded-md shadow-md">
            <div className="flex flex-col gap-4">
              <div className="flex max-xl:flex-col gap-2">
                <form
                  className="w-full xl:w-96 flex gap-2"
                  onSubmit={ev => {
                    ev.preventDefault()
                    searchProduct()
                  }}>
                  <input
                    type="text"
                    inputMode="numeric"
                    className="flex-1 h-10 p-2 rounded-md outline outline-1 outline-offset-0 outline-gray-200 hover:outline-gray-500 focus-within:outline-2 focus-within:outline-dewalt"
                    value={upc}
                    name="upc"
                    id="upc"
                    onChange={ev => setUpc(ev.target.value)}
                    placeholder={'Ingresá el UPC'}
                    maxLength={13}
                    autoFocus
                  />
                  <div className="h-10">
                    <ButtonWithProgress clickable={upc !== ''} loading={loading} text={'Buscar'} />
                  </div>
                </form>
                <div className="xl:flex-1 flex gap-2">
                  {!scannerActive && (
                    <div className="flex-1">
                      <div className="w-full h-10">
                        <label
                          htmlFor="file"
                          className="w-full h-full p-2 flex items-center justify-center rounded-md
                    border border-gray-200 hover:border-black transition-all
                    cursor-pointer">
                          <MdOutlineDocumentScanner className="w-6 h-6" />
                          <span className="ml-2">Leer foto</span>
                        </label>
                        <input
                          id="file"
                          type="file"
                          accept="image/jpeg, image/png, application/pdf"
                          className="hidden"
                          onChange={async ev => {
                            const file = ev.target.files[0]

                            let base64 = ''
                            try {
                              base64 = await fileToBase64(file)
                            } catch (err) {
                              // console.log(err)
                              showToast('Error al leer la imágen.', 'bad')
                              return
                            }

                            if (file) {
                              setLoading(true)
                              const result = await Quagga.decodeSingle({
                                decoder: { readers: ['upc_e_reader', 'upc_reader', 'ean_8_reader', 'ean_reader'] },
                                locate: true,
                                src: base64,
                              })
                              // console.log(result)
                              if (result?.codeResult) {
                                const code = result.codeResult.code
                                setUpc(code)
                                searchProduct(code)
                              } else {
                                showToast('No se encontró un código de barras UPC en la imágen', 'bad')
                              }
                              setLoading(false)
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="flex-1">
                    <div className="w-full h-10">
                      <button
                        type="button"
                        onClick={() => setScannerActive(!scannerActive)}
                        htmlFor="file"
                        className="w-full h-full p-2 flex items-center justify-center rounded-md
                    border border-gray-200 hover:border-black transition-all
                    cursor-pointer">
                        {scannerActive ? <MdOutlineStopCircle className="w-6 h-6" /> : <TbScan className="w-6 h-6" />}
                        <span className="ml-2">{scannerActive ? 'Detener' : 'Escanear'}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {scannerActive && (
                <div className="w-full mx-auto">
                  {/* <AspectRatio.Root ratio={16 / 9}> */}
                  <BarcodeScanner
                    onCodeFound={code => {
                      setUpc(code)
                      setScannerActive(false)
                      searchProduct(code)
                    }}
                    active={scannerActive}
                  />
                  {/* </AspectRatio.Root> */}
                </div>
              )}
            </div>
          </div>
          {product && (
            <div className="w-full p-4 mt-4 bg-white rounded-md shadow-lg">
              <p className="w-max mx-auto xl:text-lg font-medium mb-4">Producto encontrado</p>
              <Product product={product} setProduct={setProduct} />
            </div>
          )}
          {loading && (
            <div className="w-full mt-4">
              <LoadingBox text={'Buscando producto...'} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function Product({ product, setProduct }) {
  const { firebase, showToast } = useMainContext()
  const [windowWidth] = useWindowSize()
  const [input, setInput] = useState({ stock: product.stock, offer_cost_stock: product.offer_cost_stock })

  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const updateProduct = useCallback(() => {
    const controller = new AbortController()
    const signal = controller.signal

    ;(async () => {
      try {
        setLoading(true)
        // const result =
        await axios.put(
          process.env.REACT_APP_API_LINK + 'update_product',
          {
            user_id: firebase.user?.uid,
            product_id: product.product_id,
            action: 'update',
            fields: input,
          },
          {
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          }
        )
        // console.log(result)

        setProduct({ ...product, ...input })

        setLoading(false)
        setDone(true)

        showToast('Stock actualizado', 'good')

        await delay(1000)

        setDone(false)
      } catch (err) {
        setLoading(false)
        if (err.code === 'ERR_CANCELED') return
        else showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
        // console.log(err)
      }
    })()

    return () => controller.abort()
  }, [input, firebase.user?.uid, product]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex gap-2">
      <div className="w-20 h-20 xs:w-28 xs:h-28 xl:w-40 xl:h-40 p-1 border border-gray-200 rounded-md">
        <ShowImage image={product.images[0]} />
      </div>
      <div className="flex-1 flex flex-col text-sm xl:text-base">
        <p className="line-clamp-1">{product.title}</p>
        <p className="uppercase text-gray-500">
          {product.brand.name} · {product.model}
        </p>
        <div className="flex gap-2">
          <Link
            to={ProductLink(product.product_id, product.title)}
            className="font-medium text-amber-500 hover:text-amber-600 transition-colors">
            Ver producto
          </Link>
          {firebase.user?.role === 'admin' && (
            <>
              ·
              <Link
                to={`/admin/products/${product.product_id}/modify`}
                className="font-medium text-amber-500 hover:text-amber-600 transition-colors">
                Modificar
              </Link>
            </>
          )}
        </div>
        <div className="my-2 flex max-xl:flex-col gap-2">
          <div className="h-10 flex gap-2 items-center max-xl:justify-between">
            <p>Stock:</p>
            {firebase.user?.role === 'admin' ? (
              <div className="w-28 xl:w-36 h-10">
                <PlusMinusInput min={0} max={99999} state={[input.stock, v => setInput({ ...input, stock: v })]} />
              </div>
            ) : (
              <span>{product.stock}</span>
            )}
          </div>
          <div className="h-10 flex gap-2 items-center max-xl:justify-between">
            <p>Stock oferta:</p>
            {firebase.user?.role === 'admin' ? (
              <div className="w-28 xl:w-36 h-10">
                <PlusMinusInput
                  min={0}
                  max={99999}
                  state={[input.offer_cost_stock, v => setInput({ ...input, offer_cost_stock: v })]}
                />
              </div>
            ) : (
              <span>{product.offer_cost_stock}</span>
            )}
          </div>
        </div>
        {firebase.user?.role === 'admin' && (
          <div className="h-10">
            <ButtonWithProgress
              clickable={input.stock !== product.stock || input.offer_cost_stock !== product.offer_cost_stock}
              loading={loading}
              done={done}
              text={'Actualizar stock'}
              fullWidth={windowWidth < 480}
              onClick={updateProduct}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminAddStock

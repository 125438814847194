import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { MdOutlineClose, MdOutlineSearch, MdSearchOff } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from '../../../Sidebar'
import DefaultAlertDialog from '../../../utils/DefaultAlertDialog'
import DropdownMenu from '../../../utils/DropdownMenu'
import { useAbortableEffect } from '../../../utils/Hooks'
import { setDocumentTitle, delay } from '../../../utils/Html'
import LoadingBox from '../../../utils/LoadingBox'
import { useMainContext } from '../../../utils/MainContext'
import ShowImage from '../../../utils/ShowImage'
import { FormatPrice, ProductLink } from '../../../utils/Strings'
import Toggle from '../../../utils/Toggle'

function AdminProducts() {
  const { firebase, showToast } = useMainContext()
  const navigate = useNavigate()

  const [products, setProducts] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const searchInputRef = useRef(null)
  const [search, setSearch] = useState('')
  // const [offersOnly, setOffersOnly] = useState(false)
  const [filters, setFilters] = useState({
    offersOnly: false,
    noStock: false,
    noCategory: false,
    noBrand: false,
  })

  useEffect(() => {
    if (!firebase.user || firebase.user?.role === 'user') {
      navigate('/', { replace: true })
    }

    setDocumentTitle('Productos')
  }, [firebase.user, navigate])

  useAbortableEffect(
    signal => {
      ;(async () => {
        try {
          setProducts(null)
          setSelectedProducts([])

          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_products', {
            params: {
              user_id: firebase.user?.uid,
              search: search,
              offers_only: filters.offersOnly,
              no_stock: filters.noStock,
              no_category: filters.noCategory,
              no_brand: filters.noBrand,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)

          setProducts(result.data.products)
        } catch (err) {
          if (err.code === 'ERR_CANCELED') return
          // console.log(err)
        }
      })()
    },
    [firebase.user?.uid, search, filters]
  )

  const handlePause = async (value, ids) => {
    if (!ids || ids.length === 0) return

    try {
      if (firebase.user.role === 'user') {
        showToast('No podés realizar esa acción.', 'bad')
        return
      }

      setLoading(ids)

      for (const product_id of ids) {
        const result = await axios.put(
          process.env.REACT_APP_API_LINK + 'update_product',
          {
            user_id: firebase.user.uid,
            product_id: product_id,
            action: 'update',
            fields: {
              paused: value,
            },
          },
          {
            headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY },
          }
        )
        // console.log(result)
      }

      setLoading(false)
      showToast(
        value
          ? ids.length > 1
            ? '¡Listo! Los productos fueron pausados.'
            : '¡Listo! El producto fue pausado.'
          : ids.length > 1
          ? '¡Listo! Los productos fueron reactivados.'
          : '¡Listo! El producto fue reactivado.',
        'good'
      )
      setProducts(products.map(p => (ids.some(id => id === p.product_id) ? { ...p, paused: value } : p)))
      setSelectedProducts([])
    } catch (err) {
      // console.log(err)
      setLoading(false)
      showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
    }
  }

  const handleRemove = async productsIds => {
    if (!productsIds || productsIds.length === 0) return

    try {
      if (firebase.user.role === 'user') {
        showToast('No podés realizar esa acción.', 'bad')
        return
      }

      setLoading(productsIds)

      for (const product_id of productsIds) {
        const result = await axios.put(
          process.env.REACT_APP_API_LINK + 'update_product',
          {
            user_id: firebase.user.uid,
            product_id: product_id,
            action: 'delete',
          },
          {
            headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY },
          }
        )
        // console.log(result)
      }

      setLoading(false)
      setDone(true)
      showToast(
        productsIds.length > 1 ? '¡Listo! Los productos fueron eliminados.' : '¡Listo! El producto fue eliminado.',
        'good'
      )
      setLoading(false)
      setProducts(products.filter(p => !productsIds.some(id => id === p.product_id)))
      setSelectedProducts([])

      await delay(1000)

      setDone(false)
    } catch (err) {
      // console.log(err)
      setLoading(false)
      showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
    }
  }

  useEffect(() => {
    if (products && search) {
      searchInputRef.current.value = search
    }
  }, [products, search])

  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={true} />

      <div className="w-full xl:w-content-max-width mx-auto xl:px-10 pb-4">
        <div
          className="max-xl:px-2 mb-0 max-xl:mb-2 flex flex-col max-xl:bg-white
                     max-xl:border-b max-xl:border-gray-200 max-xl:shadow-sm">
          <div className="flex my-4 xl:my-8 items-center">
            <h1 className="w-max text-2xl max-xl:text-xl font-medium">Productos</h1>
            <div className="w-0 grow flex gap-2 justify-end">
              <Link
                to="/admin/products/add_stock"
                className="w-max py-2 px-4 bg-dewalt text-sm text-black font-medium rounded-md hover:bg-black hover:text-dewalt transition-colors">
                Stock por UPC
              </Link>
              <Link
                to="/admin/products/new"
                className="w-max py-2 px-4 bg-dewalt text-sm text-black font-medium rounded-md hover:bg-black hover:text-dewalt transition-colors">
                Nuevo
              </Link>
            </div>
          </div>
          {products && (
            <div className="xl:mb-4 flex flex-col gap-4">
              <div className="flex max-xl:flex-col gap-2">
                <div className="h-9 flex items-center gap-4">
                  <div className="relative w-64 max-xl:w-52 h-full">
                    <MdOutlineSearch className="absolute top-1/2 left-2 -translate-y-1/2 w-5 h-5 text-gray-500" />
                    <form
                      onSubmit={ev => {
                        ev.preventDefault()
                        setSearch(searchInputRef.current.value)
                      }}
                      className="w-full h-full">
                      <input
                        ref={searchInputRef}
                        type="text"
                        className="w-full h-full text-sm px-8 bg-white rounded-full xl:outline-none 
                                  max-xl:outline max-xl:outline-2 max-xl:outline-gray-200
                                  focus:outline-2 focus:outline-offset-0 focus:outline-dewalt transition-all
                                  placeholder:text-xs"
                        placeholder="Buscar por título o UPC..."
                      />
                    </form>
                    {search && (
                      <div className="absolute top-0 right-0 w-9 h-9 p-1">
                        <button
                          className="w-full h-full p-1 rounded-full hover:bg-neutral-100 transition-all"
                          onClick={() => {
                            searchInputRef.current.value = ''
                            setSearch('')
                          }}>
                          <MdOutlineClose className="w-full h-full text-gray-500" />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="w-[1px] h-6 bg-neutral-300" />
                  <span className="text-sm text-neutral-600">{`${products.length} ${
                    products.length === 1 ? 'producto' : 'productos'
                  }`}</span>
                  <div className="w-[1px] h-6 mr-2 bg-neutral-300 max-xl:hidden" />
                </div>
                <div className="flex gap-2 items-center flex-wrap">
                  <div className="flex gap-1 items-center text-sm">
                    <Toggle
                      size="sm"
                      toggled={filters.offersOnly}
                      clickable={products}
                      onClick={() => setFilters(f => ({ ...f, offersOnly: !f.offersOnly }))}
                    />
                    <span>Solo ofertas</span>
                  </div>
                  <div className="flex gap-1 items-center text-sm">
                    <Toggle
                      size="sm"
                      toggled={filters.noStock}
                      clickable={products}
                      onClick={() => setFilters(f => ({ ...f, noStock: !f.noStock }))}
                    />
                    <span>Sin stock</span>
                  </div>
                  <div className="flex gap-1 items-center text-sm">
                    <Toggle
                      size="sm"
                      toggled={filters.noCategory}
                      clickable={products}
                      onClick={() => setFilters(f => ({ ...f, noCategory: !f.noCategory }))}
                    />
                    <span>Sin categoría</span>
                  </div>
                  <div className="flex gap-1 items-center text-sm">
                    <Toggle
                      size="sm"
                      toggled={filters.noBrand}
                      clickable={products}
                      onClick={() => setFilters(f => ({ ...f, noBrand: !f.noBrand }))}
                    />
                    <span>Sin marca</span>
                  </div>
                </div>
                {/* {offersOnly ? (
                  <button
                    type="button"
                    onClick={() => setOffersOnly(false)}
                    className="w-max px-2 py-1 bg-gray-200 flex items-center rounded-md border border-gray-400 group">
                    <span className="text-black/60 text-sm font-medium pr-2">Solo ofertas</span>
                    <MdOutlineClose className="w-4 h-4 text-black/50 group-hover:text-black" />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setOffersOnly(true)}
                    className={`w-max px-2 max-xl:text-sm font-medium text-amber-500
                    [&:not]:disabled:text-amber-600 disabled:text-gray-300 transition-colors`}>
                    Solo ofertas
                  </button>
                )} */}
              </div>
              <div className="w-full px-3 py-3 max-xl:pt-0 bg-white flex items-center rounded-md">
                <Toggle
                  toggled={selectedProducts.length > 0}
                  onClick={() =>
                    setSelectedProducts(selectedProducts.length > 0 ? [] : [...products.map(p => p.product_id)])
                  }
                  done={selectedProducts.length === products?.length}
                  clickable={products.length > 0}
                />
                <div className="w-[1px] h-5 mx-2 xl:mx-3 bg-gray-200" />
                {selectedProducts.length > 0 && (
                  <span className="text-xs xl:text-sm text-gray-500">
                    {selectedProducts.length} {selectedProducts.length === 1 ? 'seleccionado' : 'seleccionados'}
                  </span>
                )}
                <div className="w-0 grow flex justify-end text-xs xl:text-sm">
                  {[
                    {
                      text: 'Pausar',
                      onClick: () => handlePause(true, selectedProducts),
                      enabled:
                        selectedProducts.length > 0 &&
                        selectedProducts.some(sp => !products?.find(p => p.product_id === sp)?.paused),
                    },
                    {
                      text: 'Reactivar',
                      onClick: () => handlePause(false, selectedProducts),
                      enabled:
                        selectedProducts.length > 0 &&
                        selectedProducts.some(sp => products?.find(p => p.product_id === sp)?.paused),
                    },
                    {
                      text: 'Eliminar',
                      onClick: () => handleRemove(selectedProducts),
                      enabled: selectedProducts.length > 0,
                    },
                  ].map((v, i) => (
                    <DefaultAlertDialog
                      key={i}
                      title="¿Estás seguro/a?"
                      content={
                        <>
                          <p>
                            Se {selectedProducts.length > 1 ? 'van' : 'va'} a {v.text.toLowerCase()}{' '}
                            {selectedProducts.length} {selectedProducts.length > 1 ? 'productos' : 'producto'}.
                          </p>
                        </>
                      }
                      submit={{
                        text: 'Confirmar',
                        onClick: v.onClick,
                      }}
                      trigger={
                        <button
                          type="button"
                          className={`px-2 font-medium text-amber-500 [&:not]:disabled:text-amber-600 disabled:text-gray-300
                                  transition-colors`}
                          disabled={!v.enabled || loading}>
                          {v.text}
                        </button>
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full max-xl:px-2 flex flex-col gap-4 max-xl:gap-2">
          {products ? (
            products.length > 0 ? (
              products.map(p => (
                <Product
                  key={p.product_id}
                  info={p}
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  loading={loading}
                  done={done}
                  handlePause={handlePause}
                  handleRemove={handleRemove}
                />
              ))
            ) : (
              <div className="w-full xl:py-8 flex flex-col justify-center items-center">
                <MdSearchOff className="w-16 h-16 xl:w-28 xl:h-28 text-gray-400" />
                <p className="max-xl:text-sm mt-2 text-neutral-600 text-xl text-center">
                  {search || Object.values(filters).some(f => f) ? 'No hay resultados.' : 'Todavía no hay productos.'}
                </p>
              </div>
            )
          ) : (
            <LoadingBox text="Cargando productos..." />
          )}
        </div>
      </div>
    </div>
  )
}

function Product({ info, selectedProducts, setSelectedProducts, loading, done, handlePause, handleRemove }) {
  const { product_id, title, normal_price, offer_price, images, stock, offer_cost_stock, sales, paused, upc } = info

  const toggleSelection = () => {
    setSelectedProducts(
      selectedProducts.some(p => product_id === p)
        ? selectedProducts.filter(p => product_id !== p)
        : [...selectedProducts, product_id]
    )
  }

  return (
    <div
      className={`relative py-4 ${
        paused ? 'bg-neutral-100' : 'bg-white'
      } flex rounded-md shadow-sm hover:shadow-lg transition-all`}>
      {loading && loading.some(id => id === product_id) && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 rounded-md flex justify-center items-center">
          <img src="/loading.svg" className="w-12 h-12" alt="Loading" />
        </div>
      )}
      <div className="pt-2 px-3 h-full flex justify-center items-start">
        <Toggle toggled={selectedProducts.some(p => product_id === p)} onClick={toggleSelection} />
      </div>
      <Link
        to={`/admin/products/${product_id}/modify`}
        className="w-20 h-20 p-1 bg-white rounded-md border border-gray-200 overflow-hidden">
        <div className={`w-full h-full ${paused ? 'opacity-70' : ''}`}>
          <ShowImage image={images[0]} alt={`Imágen de ${title}`} />
        </div>
      </Link>
      <div className="w-0 grow px-4 xl:pr-6 flex max-xl:flex-col gap-4">
        <div className="w-full xl:w-0 xl:grow flex flex-col gap-2 text-xs">
          <span className="text-gray-500">{`${upc}`}</span>
          <Link to={`/admin/products/${product_id}/modify`} className="line-clamp-2 font-medium tracking-wide">
            {title}
          </Link>
          <span className="text-gray-500">
            {stock + offer_cost_stock === 0 ? 'Sin stock' : `${stock} en stock | ${offer_cost_stock} en stock oferta`}
            <span className="text-gray-500 xl:hidden">
              {` | ${sales === 0 ? 'No hay ventas' : `${sales} ${sales === 1 ? 'vendida' : 'vendidas'}`}`}
            </span>
          </span>
        </div>
        <div className="w-0 grow flex flex-col items-end gap-2 text-xs max-xl:hidden">
          <span className="text-gray-500">
            {sales === 0 ? 'No hay ventas' : `${sales} ${sales === 1 ? 'unidad vendida' : 'unidades vendidas'}`}
          </span>
        </div>
        <div className="w-full xl:w-0 xl:grow flex xl:flex-col justify-between items-center xl:items-end">
          <div className="flex max-xl:flex-row-reverse items-center gap-2">
            {offer_price && <span className="text-xs xl:text-sm text-green-600 font-bold">En oferta</span>}
            <span>{FormatPrice(offer_price ?? normal_price)}</span>
          </div>
          <DropdownMenu
            identifierClass={`dpdmToggle${product_id}`}
            items={[
              {
                text: 'Modificar',
                link: `/admin/products/${product_id}/modify`,
              },
              {
                text: 'Ver producto',
                link: ProductLink(product_id, title),
              },
              ...(paused
                ? [
                    {
                      text: 'Reactivar',
                      onClick: () => handlePause(false, [product_id]),
                    },
                  ]
                : [
                    {
                      text: 'Pausar',
                      onClick: () => handlePause(true, [product_id]),
                    },
                  ]),
              {
                text: 'Eliminar',
                dialog: {
                  id: `${product_id}-delete`,
                  title: '¿Estás seguro/a?',
                  content: (
                    <div className="flex flex-col">
                      <span>El producto se eliminará permanentemente.</span>
                      <span className="font-medium">Esta acción no se puede deshacer.</span>
                    </div>
                  ),
                  loading: loading,
                  done: done,
                  submit: {
                    onClick: () => handleRemove([product_id]),
                    text: 'Si, eliminar producto',
                  },
                },
              },
            ]}
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}

// function LoadingSkeletonSearch() {
//   return (
//     <div className="xl:mb-4 flex flex-col gap-4">
//       <div className="h-9 flex items-center gap-4">
//         <Skeleton className="w-64 max-xl:w-52 h-full" />
//         <div className="w-[1px] h-6 bg-neutral-300"></div>
//         <Skeleton className="w-24 h-full" />
//       </div>
//       <Skeleton className={"w-full h-7 xl:h-11 max-xl:my-2"} />
//     </div>
//   )
// }

// function LoadingSkeletonProducts() {
//   return Array(3)
//     .fill("")
//     .map((_, i) => <Skeleton key={i} className="w-full h-[10.5rem] xl:h-[7rem]" />)
// }

export default AdminProducts

import { MdChevronRight } from "react-icons/md";
import { Link } from "react-router-dom";

function Breadcrumb({ items }) {
  return (
    <div className="flex gap-1 flex-wrap">
      {items.map((v, i) => (
        <div className="flex gap-1" key={i}>
          {i !== 0 && <MdChevronRight className="w-5 h-5 pt-[1px] text-gray-500" />}
          {v.link ? (
            <Link
              to={v.link}
              className="w-max text-sm text-amber-500 hover:text-amber-700 transition-all"
            >
              {v.name}
            </Link>
          ) : (
            <span className="w-max text-sm text-gray-500">{v.name}</span>
          )}
        </div>
      ))}
    </div>
  );
}

export default Breadcrumb;

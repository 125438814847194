import Sidebar from "../../../Sidebar";
import ViewOrder from "../../ViewOrder";

function AdminOrder({ summary }) {
  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={true} />
      <ViewOrder admin={true} summary={summary} />
    </div>
  );
}

export default AdminOrder;

import { isArray } from 'lodash'
import { useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import ButtonWithProgress from '../ButtonWithProgress'

function Section({ info, creating = false }) {
  const {
    title,
    subtitle,
    optional = false,
    content,
    allowActions,
    handleCancel,
    handleSend,
    loading,
    done,
    errors,
    modifiable,
  } = info
  const actions = !creating,
    defaultOpen = creating,
    toggleable = !creating

  const [open, setOpen] = useState(defaultOpen)

  return (
    <div
      className={`w-full relative ${modifiable ? 'bg-white' : 'bg-neutral-50'} flex flex-col rounded-md ${
        open ? 'shadow-lg' : 'shadow-sm'
      } ${!modifiable ? 'overflow-hidden' : ''}`}>
      {!modifiable && <div className="absolute top-0 left-0 w-full h-full bg-neutral-50/50 z-[1]" />}
      <div
        className={`w-full px-6 py-4 xl:px-8 xl:py-6 flex items-center border-b ${
          open ? 'border-gray-200' : 'border-transparent'
        } ${toggleable && modifiable ? 'cursor-pointer' : ''} transition-all`}
        onClick={() => {
          if (toggleable && modifiable) setOpen(!open)
        }}>
        <div className="w-0 grow flex flex-col items-start">
          <span className="text-sm xl:text-lg font-medium flex items-center">
            {title} {optional && <span className="ml-2 text-sm font-light text-neutral-400">| Opcional</span>}
          </span>
          {subtitle && (
            <span className="pr-20 xl:pr-64 text-xs xl:text-sm text-neutral-400 font-light line-clamp-1 hyphens-none">
              {subtitle}
            </span>
          )}
        </div>
        {toggleable && (
          <FiChevronDown
            className={`w-6 h-6 xl:w-8 xl:h-8 text-dewalt ${open ? 'rotate-180' : 'rotate-0'} transition-transform`}
          />
        )}
      </div>
      <div className={`w-full p-6 xl:p-8 flex flex-col ${!open ? 'hidden' : ''} relative`}>
        {content}
        {isArray(errors) && errors.length > 0 && (
          <ul className="w-full pl-4 mt-4 text-sm xl:text-base text-red-600">
            {errors.map((error, i) => (
              <li key={i} className="list-disc">
                {error.text}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div
        className={`w-full px-6 py-4 xl:px-8 xl:py-6 flex justify-end gap-4 ${
          !open || !actions ? 'hidden' : ''
        } border-t border-gray-200`}>
        <button
          type="button"
          onClick={handleCancel}
          className={`px-4 h-12 ${
            allowActions && !loading && !done ? 'hover:bg-dewalt' : 'text-neutral-400'
          } font-medium flex justify-center items-center rounded-md transition-colors`}
          disabled={!allowActions || loading || done}>
          Cancelar
        </button>
        <div className="h-12">
          <ButtonWithProgress
            text={'Confirmar'}
            clickable={allowActions}
            loading={loading}
            done={done}
            onClick={handleSend}
          />
        </div>
      </div>
    </div>
  )
}

export default Section

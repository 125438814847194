import { isEqual } from 'lodash'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { MdOutlineAdd, MdOutlineDelete, MdOutlineImage } from 'react-icons/md'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import { isImageFileValid } from '../Html'
import ShowImage from '../ShowImage'
import { useMainContext } from '../MainContext'

const BannerDnD = forwardRef(({ object, onChange }, ref) => {
  const { showToast } = useMainContext()
  const [defaultValue, setDefaultValue] = useState([])
  const [items, setItems] = useState(defaultValue)

  useEffect(() => {
    const value = object.map((v, i) => ({ id: '' + i, src: v.image, link: v.link }))
    setDefaultValue(value)
    setItems(value)
  }, [object]) //eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    value: items,
    reset: () => setItems(defaultValue),
    valueChanged: !isEqual(items, defaultValue),
    toSend() {
      return {
        banner: items.map(image => ({
          src: image.src,
          link: image.link,
          blob: image.blob,
          remove: !!image.remove,
        })),
      }
    },
  }))

  const handleDragEnd = result => {
    if (!result.destination) {
      return
    }

    const { source, destination } = result

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    const updatedImages = Array.from(items.filter(item => !item.remove))
    const [removed] = updatedImages.splice(source.index, 1)
    updatedImages.splice(destination.index, 0, removed)

    setItems([...updatedImages, ...(items.filter(item => item.remove) ?? [])])
    onChange()
  }

  const handleFile = (file, item) => {
    // console.log(file);
    isImageFileValid(file, { width: 1600, height: 340 }, err => {
      if (err) {
        if (err === 'type') showToast('El formato de imágen no es válido.', 'bad')
        else if (err === 'resolution') showToast('La resolución de la imágen no es válida.', 'bad')
        else showToast('Error inesperado al leer la imágen.', 'bad')
        return
      }
      setItems(items.map(it => (it.id === item.id ? { ...it, blob: file, src: URL.createObjectURL(file) } : it)))
      onChange()
    })
  }

  const handleDrop = (ev, item) => {
    ev.preventDefault()

    if (ev.dataTransfer.items) {
      // console.log("item");
      const droppedItem = [...ev.dataTransfer.items][0]
      if (droppedItem.kind === 'file') handleFile(droppedItem.getAsFile(), item)
    } else {
      // console.log("file");
      handleFile(ev.dataTransfer.files[0], item)
    }
  }

  const handleNewItem = async () => {
    const id = '' + (items.reduce((max, v) => (+v.id > max ? +v.id : max), -1) + 1)
    setItems([...items, { id: id, src: '', link: '' }])
    setTimeout(() => {
      const node = document.getElementById(`banner-${id}`)
      if (node) node.scrollIntoView()
    }, 100)
    onChange()
  }

  return (
    <div className="w-full flex flex-col">
      <div className="w-full overflow-y-scroll">
        <div className={`w-full flex flex-col`}>
          <button
            type="button"
            className={`w-full h-14 ${
              items.length > 0 ? 'mb-2' : ''
            } flex justify-center items-center rounded-md border border-gray-200
                            hover:border-gray-500 transition-colors`}
            onClick={handleNewItem}>
            <MdOutlineAdd className="w-6 h-6 text-gray-500" />
            <span className="ml-2 text-gray-500">Nuevo banner</span>
          </button>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="image-list" direction="vertical">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="flex flex-col">
                  {items
                    .filter(item => !item.remove)
                    .map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {provided => (
                          <div
                            id={`banner-${item.id}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="relative bg-white w-full my-2 flex flex-col transition-shadow group
                                        select-none hover:shadow-md"
                            style={{
                              ...provided.draggableProps.style,
                            }}>
                            <div className="w-full">
                              <AspectRatio.Root ratio={1600 / 340}>
                                <div className="w-full h-full">
                                  <label
                                    htmlFor={`banner-picture-${item.id}`}
                                    className="w-full h-full cursor-pointer"
                                    onDragOver={ev => ev.preventDefault()}
                                    onDrop={ev => handleDrop(ev, item)}>
                                    {item.src ? (
                                      <div className="w-full h-full">
                                        <ShowImage image={item.src} alt={`Imágen ${index + 1}`} contain={false} />
                                      </div>
                                    ) : (
                                      <div
                                        className="w-full h-full border-2 border-dashed border-dewalt rounded-md
                                      flex flex-col justify-center items-center group hover:shadow-md transition-all">
                                        <MdOutlineImage className="w-6 h-6 text-dewalt" />
                                        <span className="text-sm text-neutral-400">Agregar</span>
                                      </div>
                                    )}
                                  </label>
                                  <input
                                    type="file"
                                    id={`banner-picture-${item.id}`}
                                    accept="image/jpeg, image/png, image/webp"
                                    className="hidden"
                                    onChange={ev => {
                                      handleFile(ev.target.files[0], item)
                                    }}
                                  />
                                </div>
                              </AspectRatio.Root>
                            </div>
                            <div className="w-full p-4 flex items-center gap-4">
                              <div className="w-0 grow h-full flex flex-col justify-center">
                                <span className="text-xs xl:text-sm text-gray-500">Redirecciona a:</span>
                                <input
                                  type="text"
                                  value={item.link}
                                  onChange={ev => {
                                    setItems(
                                      items.map(it => (it.id === item.id ? { ...it, link: ev.target.value } : it))
                                    )
                                    onChange()
                                  }}
                                  className="w-full max-xl:text-xs border-b border-gray-200 hover:border-gray-500 focus:border-dewalt
                                    outline-none"
                                />
                              </div>
                              <DeleteButton
                                onClick={() => {
                                  setItems(items.map(it => (it.id === item.id ? { ...it, remove: true } : it)))
                                  onChange()
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  )
})

export function DeleteButton({ onClick }) {
  return (
    <button
      type="button"
      className="w-7 h-7 p-1 bg-dewalt hover:bg-amber-300 text-white rounded-full 
                  xl:opacity-0 xl:group-hover:opacity-100 transition-all"
      onClick={onClick}>
      <MdOutlineDelete className="w-full h-full" />
    </button>
  )
}

export default BannerDnD

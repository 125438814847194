import { createRef, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMainContext } from '../../utils/MainContext'
import axios from 'axios'
import Sidebar from '../../Sidebar'
import { MdError, MdOutlineEdit } from 'react-icons/md'
import { useRef } from 'react'
import Dropdown from '../../utils/Dropdown'
import ButtonWithProgress from '../../utils/ButtonWithProgress'
import { CapitalizeAllWords, FormatCuit, OnlyNumbers } from '../../utils/Strings'
import LoadingBox from '../../utils/LoadingBox'
import { sendPasswordResetEmail, updateProfile } from 'firebase/auth'
import { AES } from 'crypto-js'
import { delay, saveCookie, setDocumentTitle } from '../../utils/Html'
import { useAbortableEffect } from '../../utils/Hooks'

function UserProfile() {
  const { firebase, showToast } = useMainContext()
  const navigate = useNavigate()
  const provinces = JSON.parse(process.env.REACT_APP_PROVINCES)

  const [userInfo, setUserInfo] = useState(null)
  // const [error, setError] = useState(null)

  useEffect(() => {
    if (!firebase.user || firebase.user.role !== 'user') {
      navigate('/', { replace: true })
    }
    setDocumentTitle('Mi cuenta')
  }, [firebase.user, navigate])

  useAbortableEffect(
    signal => {
      ;(async () => {
        try {
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_user', {
            params: { user_id: firebase.user.uid, ut_coins_only: false },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)
          setUserInfo(result.data.user)
        } catch (err) {
          // console.log(err)
          // setError({ data: err.response.data });
          // TODO: Mandar error a DB
        }
      })()
    },
    [firebase.user?.uid]
  )

  const [sendingPasswordReset, setSendingPasswordReset] = useState(false)
  const handlePasswordReset = async () => {
    try {
      const data = { ue: userInfo.email }
      const encryptedData = AES.encrypt(JSON.stringify(data), 'xd').toString()

      setSendingPasswordReset(true)
      await sendPasswordResetEmail(firebase.auth, userInfo.email, {
        url: `${window.location.origin}/login/user?p=${encodeURIComponent(encryptedData)}`,
        handleCodeInApp: true,
      })

      navigate(`/login/forgot?p=${encodeURIComponent(encryptedData)}`)
    } catch (err) {
      // setError({
      //   input: "login",
      //   code: err.code ?? null,
      //   text: err.message ?? "Error desconocido",
      // });
      // TODO: Mandar error a DB
      // console.log(err)
      setSendingPasswordReset(false)
      showToast(process.env.REACT_APP_UNKNOWN_ERROR, 'bad')
    }
  }

  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={false} />
      <div className="w-full xl:w-[700px] mx-auto">
        <div
          className="max-xl:px-2 mb-0 max-xl:mb-2 flex flex-col max-xl:bg-white
                     max-xl:border-b max-xl:border-gray-200 max-xl:shadow-sm">
          <h1 className="text-2xl max-xl:text-xl font-medium xl:my-8 max-xl:my-4">Cuenta</h1>
        </div>
        <div className="max-xl:p-2 xl:mb-10">
          <div className="w-full flex flex-col gap-4">
            {userInfo ? (
              <>
                {/* <Section
                  title="Datos de la cuenta"
                  info={[
                    {
                      id: 'email',
                      text: 'E-Mail',
                      value: userInfo.email,
                      required: true,
                      maxLength: 100,
                      onlyLowerCase: true,
                    },
                    {
                      id: 'password',
                      text: 'Contraseña',
                      value: '***********',
                      required: false,
                      input: 'button',
                      linkText: 'Cambiar contraseña',
                      onClick: handlePasswordReset,
                    },
                  ]}
                  uid={firebase.user?.uid}
                  sectionNumber="1"
                /> */}

                <div>
                  <div className="mb-4 flex items-center">
                    <p className="text-lg">Datos de la cuenta</p>
                  </div>
                  <div className="w-full bg-white border border-gray-200 rounded-md shadow-sm">
                    <div className="p-4 max-xl:text-sm flex items-center border-b border-gray-200 last:border-0">
                      <div className="w-20 xs:w-32 xl:w-52 text-xs xs:text-sm xl:text-base hyphens-none">E-Mail</div>
                      <div className="flex-1 text-gray-500">{userInfo.email}</div>
                    </div>
                    <div className="p-4 max-xl:text-sm flex items-center border-b border-gray-200 last:border-0">
                      <div className="w-20 xs:w-32 xl:w-52 text-xs xs:text-sm xl:text-base hyphens-none">
                        Contraseña
                      </div>
                      <div className="flex-1">
                        <button
                          type="button"
                          onClick={handlePasswordReset}
                          className="font-semibold text-amber-500 disabled:text-gray-500
                            enabled:hover:text-amber-600 transition-colors hyphens-none"
                          disabled={sendingPasswordReset}>
                          Cambiar contraseña
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <PersonalDataForm user={userInfo} uid={firebase.user?.uid} />
                <DomicileForm user={userInfo} provinces={provinces} uid={firebase.user?.uid} />
              </>
            ) : (
              <LoadingBox text={'Cargando tu cuenta...'} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export function Section({ title, info, uid, sectionNumber, preEditable = false, onCancel, cancelable = true }) {
  const items = useMemo(() => {
    let index = 0
    return info.map(item => {
      if (item.inputs) {
        item.inputs = item.inputs.map(subitem => {
          subitem.index = index++
          return subitem
        })
      } else {
        item.index = index++
      }
      return item
    })
  }, [info])
  const { firebase, showToast } = useMainContext()
  const formRef = useRef(null)
  const inputsRef = useRef(items.flatMap(item => (item.inputs ? item.inputs.map(() => createRef()) : createRef())))
  const [editable, setEditable] = useState(preEditable)
  const [sendable, setSendable] = useState(false)
  const [form, setForm] = useState({ user_id: uid, section: sectionNumber })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [sent, setSent] = useState(false)

  const handleForm = (v, value) => {
    // Agarro la ref buscando por el index del id en info
    const ref = inputsRef.current[v.index]
    // Si el campo tenia que ser numero, cambio el value a solo numeros
    const newValue = v.format
      ? v.format(value)
      : v.inputType === 'number'
      ? OnlyNumbers(value)
      : v.capitalizeWords
      ? CapitalizeAllWords(value)
      : v.onlyLowerCase
      ? value.toLowerCase()
      : value
    // Como este metodo se ejecuta antes de renderizar (cuando inputsRef = [ref.current = null, ref.current = null]), chequeo
    // que exista la ref
    if (ref && ref.current) ref.current.value = newValue
    setForm(f => ({ ...f, [v.id]: newValue }))
    setError(null)
  }

  useEffect(() => {
    items.forEach(v => {
      if (v.inputs) v.inputs.forEach(subv => handleForm(subv, subv.value))
      else handleForm(v, v.value)
    })
  }, [items])

  useEffect(() => {
    setSendable(
      !error &&
        items.every(v =>
          v.required ? (v.inputs ? v.inputs.every(input => form[input.id] !== '') : form[v.id] !== '') : true
        )
    )
  }, [items, form, error])

  const handleSubmit = async ev => {
    ev.preventDefault()
    setLoading(true)

    // let user = null
    // if (form.section === '1') {
    //   try {
    //     console.log(firebase.auth.currentUser)
    //     const methodsResult = await fetchSignInMethodsForEmail(firebase.auth, firebase.user.email)
    //     const popUpResult = await reauthenticateWithRedirect(firebase.auth.currentUser, new GoogleAuthProvider())
    //     user = popUpResult.user
    //   } catch (err) {
    //     console.log(err)
    //     setLoading(false)
    //     setError({
    //       data: {
    //         code: 99,
    //         message: 'Tenemos que verificar que sos vos para actualizar estos datos, intentá de nuevo.',
    //       },
    //     })
    //     return
    //   }
    // }

    if (form.section === '2') {
      try {
        const result = await axios.get(process.env.REACT_APP_FB_API_LINK + 'afip/persons', {
          params: {
            doc: form.doc,
          },
        })
        const info = result.data.info
        const cuit = FormatCuit(info.idPersona)

        form.cuit = cuit
        delete form.doc
      } catch (err) {
        if (err.code === 'ERR_CANCELED') return
        showToast(err.response.data.message ?? 'Error desconocido al consultar CUIT / CUIL con el documento', 'bad')
        // console.log(err)
        return
      }
    }

    try {
      await delay(500)

      const result = await axios.put(process.env.REACT_APP_API_LINK + 'update_user', form, {
        headers: {
          'X-Api-Key': process.env.REACT_APP_API_KEY,
        },
      })

      // if (form.section === '1') {
      //   await updateEmail(firebase.auth.currentUser, form.email)
      //   EmailAuthProvider.crede
      // }
      if (form.section === '2') {
        const displayName = `${form.name}_${form.last_name}`
        await updateProfile(firebase.auth.currentUser, { displayName })

        saveCookie('user', { ...firebase.user, displayName }, true)
      }
      // console.log(result)

      setLoading(false)
      setSent(true)

      await delay(1000)
      window.location.reload(false)
    } catch (err) {
      // console.log(err)
      setLoading(false)
      setError({
        data: err.response?.data
          ? {
              ...err.response.data,
              message:
                err.response.data.code === 18
                  ? 'Ya hay un usuario registrado con ese documento'
                  : err.response.data.message,
            }
          : { code: 100, message: process.env.REACT_APP_TOAST_UNKNOWN_ERROR },
      })
      // TODO: Mandar error a DB
    }
  }

  const input = v => {
    return editable ? (
      v.input === 'button' ? (
        <button
          type="button"
          className="px-4 h-10 flex justify-center items-center rounded-md border border-gray-200 shadow-sm 
                     hover:border-black transition-colors"
          onClick={v.onClick}>
          {v.linkText}
        </button>
      ) : v.input === 'dropdown' ? (
        <div className="w-full h-10">
          <Dropdown items={v.items} title={v.text} onChange={dv => handleForm(v, dv.value)} defaultValue={v.value} />
        </div>
      ) : (
        <input
          ref={inputsRef.current[v.index]}
          className="w-full h-10 px-1 xs:px-3 rounded-md border border-gray-200 hover:border-black shadow-sm outline-none 
                     focus-within:outline-dewalt focus-within:outline-offset-0 focus-within:hover:border-gray-200"
          defaultValue={v.value}
          id={v.id}
          name={v.id}
          required={v.required}
          maxLength={v.maxLength}
          pattern={`${v.inputType === 'number' ? '[0-9]' : '.'}${v.maxLength ? `{1,${v.maxLength}}` : '+'}$`}
          onChange={e => {
            // console.log(e.target.value)
            handleForm(v, e.target.value)
          }}
          onBlur={e => {
            e.preventDefault()
            if (v.onBlur && !v.onBlur.condition(e.target.value, form[v.onBlur.input])) {
              setError({ data: { message: v.onBlur.errorMessage } })
            }
          }}
          placeholder={v.text}
        />
      )
    ) : (
      <div className="flex-1 text-gray-500 line-clamp-1">
        {v.input === 'dropdown' ? v.items.find(i => i.value === v.value).text : v.value}
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <div className="mb-4 flex items-center">
        <p className="text-lg">{title}</p>
        <div className="w-0 grow flex justify-end">
          {!editable && (
            <button type="button" onClick={() => setEditable(!editable)} className="w-8 h-8 p-1 group">
              <MdOutlineEdit className="w-full h-full text-gray-500 group-hover:text-black transition-colors" />
            </button>
          )}
        </div>
      </div>
      <div className="w-full bg-white border border-gray-200 rounded-md shadow-sm">
        {items.map(v => (
          <div key={v.title ?? v.text} className="p-4 flex items-center border-b border-gray-200 last:border-0">
            <div className="w-20 xs:w-32 xl:w-52 text-xs xs:text-sm xl:text-base hyphens-none">
              {v.title ?? v.text}
              {editable && v.required ? <span className="text-red-600">&nbsp;*</span> : ''}
            </div>
            <div className="relative flex-1 max-xl:text-sm">
              {v.inputs ? (
                <div className="flex-1 flex gap-2 xl:gap-4">
                  {v.inputs.map(subv => (
                    <div key={subv.text} className="relative w-0 flex" style={{ flexGrow: subv.grow }}>
                      {input(subv)}
                      {/* {editable &&
                        subv.option &&
                        subv.option(value => handleForm(subv, value), inputsRef.current[subv.index])} */}
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {input(v)}
                  {/* {editable && v.option && v.option(value => handleForm(v, value), inputsRef.current[v.index])} */}
                </>
              )}
            </div>
          </div>
        ))}
        {editable && (
          <>
            <div className="p-4 flex max-xl:flex-col xl:items-center xl:justify-end border-b border-gray-200">
              {error && (
                <div className="w-full xl:flex-1 xl:pr-4 max-xl:pb-2 flex text-red-600 items-center">
                  <MdError className="w-6 h-6" />
                  <span className="text-sm ml-2 hyphens-none">{error.data.message}</span>
                </div>
              )}
              <div className="w-full xl:w-max flex gap-4 font-medium max-xl:justify-end">
                {cancelable && (
                  <button
                    type="button"
                    className="py-2 px-4 bg-white rounded-md border border-gray-200 hover:border-black shadow-sm transition-colors"
                    onClick={() => {
                      setEditable(false)
                      setError(null)
                      if (onCancel) onCancel()
                    }}>
                    Cancelar
                  </button>
                )}
                <div className="h-12">
                  <ButtonWithProgress text="Actualizar" loading={loading} clickable={sendable} done={sent} />
                </div>
              </div>
            </div>
            <div className="p-4 text-sm">
              <span className="text-red-600">* </span>Campos requeridos
            </div>
          </>
        )}
      </div>
    </form>
  )
}

export function PersonalDataForm({ user, uid, preEditable = false, onCancel, cancelable }) {
  return (
    <Section
      title="Datos personales"
      info={[
        {
          title: 'Nombre y apellido',
          inputs: [
            {
              id: 'name',
              text: 'Nombre',
              value: user.name,
              capitalizeWords: true,
              grow: 1,
            },
            {
              id: 'last_name',
              text: 'Apellido',
              value: user.last_name,
              capitalizeWords: true,
              grow: 1,
            },
          ],
          required: true,
        },
        {
          title: 'Nro. celular',
          inputs: [
            {
              id: 'area_code',
              text: 'Cód. Area',
              value: user.area_code,
              maxLength: 4,
              grow: 0.2,
            },
            {
              id: 'phone_number',
              text: 'Número',
              value: user.phone_number,
              maxLength: 10,
              grow: 0.8,
            },
          ],
          required: true,
        },
        {
          id: 'doc',
          text: 'DNI',
          value: user.cuit ? user.cuit.substring(3, 11) : '',
          required: true,
          maxLength: 13,
          // format: v => FormatCuit(v),
          // option: (setState, ref) => <GetCUILButton setCuit={setState} cuitRef={ref} />,
        },
      ]}
      uid={uid}
      sectionNumber="2"
      preEditable={preEditable}
      onCancel={onCancel}
      cancelable={cancelable}
    />
  )
}

export function DomicileForm({ user, provinces, uid, preEditable = false, onCancel }) {
  return (
    <Section
      title="Domicilio"
      info={[
        {
          id: 'province',
          text: 'Provincia',
          value: user.province ?? '',
          required: true,
          input: 'dropdown',
          items: [
            { value: '', text: 'Seleccione una provincia...' },
            ...Object.entries(provinces).map(v => ({
              value: v[0],
              text: v[1].name,
            })),
          ],
          inputType: 'text',
        },
        {
          id: 'city',
          text: 'Localidad',
          value: user.city,
          required: true,
          maxLength: 35,
          inputType: 'text',
          capitalizeWords: true,
        },
        {
          id: 'street',
          text: 'Calle',
          value: user.street,
          required: true,
          maxLength: 150,
          inputType: 'text',
          capitalizeWords: true,
        },
        {
          id: 'street_number',
          text: 'Altura',
          value: user.street_number,
          required: true,
          maxLength: 6,
          inputType: 'number',
        },
        {
          id: 'flat',
          text: 'Piso',
          value: user.flat,
          required: false,
          maxLength: 3,
          inputType: 'text',
        },
        {
          id: 'floor',
          text: 'Depto',
          value: user.floor,
          required: false,
          maxLength: 3,
          inputType: 'text',
        },
        {
          id: 'zip_code',
          text: 'Código postal',
          value: user.zip_code,
          required: true,
          maxLength: 4,
          inputType: 'number',
          onBlur: {
            input: 'province',
            condition: (v, p) => {
              const provinceCps = provinces[p].cp
              return Array.isArray(provinceCps[0])
                ? provinceCps.some(cp => v >= cp[0] && v <= cp[1])
                : provinceCps.find(cp => cp == v) // eslint-disable-line eqeqeq
            },
            errorMessage: 'El código postal no corresponde a la provincia seleccionada',
          },
        },
        {
          id: 'additional_info',
          text: 'Información adicional',
          value: user.additional_info,
          required: false,
          maxLength: 30,
          inputType: 'text',
          capitalizeWords: false,
        },
      ]}
      uid={uid}
      sectionNumber="3"
      preEditable={preEditable}
      onCancel={onCancel}
    />
  )
}

export default UserProfile

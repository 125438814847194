import axios from 'axios'
import { round, isEmpty } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { BsWhatsapp } from 'react-icons/bs'
import { FiChevronDown } from 'react-icons/fi'
import { MdOutlineFileUpload, MdReceiptLong } from 'react-icons/md'
import { TbCoin, TbExternalLink } from 'react-icons/tb'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Breadcrumb from '../utils/Breadcrumb'
import DefaultAlertDialog from '../utils/DefaultAlertDialog'
import Dropdown from '../utils/Dropdown'
import { useAbortableEffect } from '../utils/Hooks'
import { delay, saveCookie, setDocumentTitle, uploadFile, useWindowSize } from '../utils/Html'
import InfoBlock from '../utils/InfoBlock'
import LoadingBox from '../utils/LoadingBox'
import { useMainContext } from '../utils/MainContext'
import ShowImage from '../utils/ShowImage'
import {
  DeleteFormatFromPhoneNumber,
  DigitGrouping,
  FormatPrice,
  GetRoleLink,
  PickUpAddressToURL,
  ProductLink,
  TimestampToDateText,
  TimestampToDateTextWithTime,
  TimestampToFullDate,
} from '../utils/Strings'
import { ShippingMethodIcon } from '../utils/Switches'
import './ViewOrder.css'

const getOrderTotal = order =>
  FormatPrice(
    round(
      order.products.reduce((a, p) => a + (p.offer_price ?? p.normal_price) * (p.amount + p.amount_offer_stock), 0) -
        (order.ut_coins.spent * order.ut_coins.dollar.buy) / 10 +
        order.shipping.price +
        (order.surcharge ?? 0),
      2
    )
  )

function ViewOrder({ admin, summary }) {
  const { firebase, showToast } = useMainContext()
  const { order_id } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [data, setData] = useState(null)
  const order = data?.order ?? null
  const userInfo = order?.user ?? null
  const pickUpAddress = data?.pick_up_address ?? null
  const possibleEvents = data?.possible_events ?? null
  const lastEvent = order ? order.events.at(-1).event : null

  const [paymentMethods, setPaymentMethods] = useState(null)

  // const mp = useMercadopago.v2("TEST-ed924867-0668-4d25-ac12-31c1f5c457b6")
  // const [preferenceId, setPreferenceId] = useState(null)

  const summaryRef = useCallback(
    node => {
      const divElement = node

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            navigate(`/${GetRoleLink(firebase.user?.role)}/orders/${order_id}`, { replace: true })
          }
        })
      })

      if (divElement) {
        observer.observe(divElement)
      }
    },
    [firebase.user, navigate, order_id]
  )

  useEffect(() => {
    if (!firebase.user) {
      navigate('/', { replace: true })
    } else if (!admin && (firebase.user.role === 'admin' || firebase.user.role === 'operator')) {
      navigate(`/admin/orders/${order_id}`, { replace: true })
    } else if (admin && firebase.user.role === 'user') {
      navigate(`/user/orders/${order_id}`, { replace: true })
    }

    ;(async () => {
      setData(null)
      setDocumentTitle(`Cargando...`)

      try {
        const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_order', {
          params: { order_id: order_id, user_id: firebase.user?.uid },
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
        })
        console.log(result)
        setData(result.data)

        setDocumentTitle(`Pedido #${order_id}`)

        // if (
        //   order.events?.at(-1)?.event === "pending_payment" &&
        //   order.payment.payment_method?.title === "MercadoPago"
        // ) {
        //   if (searchParams.has("payment_id")) {
        //     const payment_id = searchParams.get("payment_id"),
        //       payment_type = searchParams.get("payment_type"),
        //       status = searchParams.get("status"),
        //       preference_id = searchParams.get("preference_id"),
        //       site_id = searchParams.get("site_id")

        //     if (
        //       preference_id &&
        //       order.payment?.mercado_pago?.preference_id === preference_id &&
        //       site_id === "MLA" &&
        //       payment_id &&
        //       payment_type &&
        //       status
        //     ) {
        //       let result = await axios.put(
        //         process.env.REACT_APP_API_LINK + "update_order",
        //         {
        //           order_id: order.order_id,
        //           new_event: "payment_approved",
        //           mercado_pago: {
        //             payment_id: payment_id,
        //             payment_type: payment_type,
        //             status: status,
        //           },
        //           user_id: firebase.user?.uid,
        //         },
        //         { headers: { "X-Api-Key": process.env.REACT_APP_API_KEY } }
        //       )
        //       // console.log(result)
        //       navigate(`/user/orders/${order_id}`, { replace: true })
        //       return
        //     }
        //   } else {
        //     setPreferenceId(order.payment?.mercado_pago.preference_id)
        //   }
        // }
      } catch (err) {
        // console.log(err)
        if (err.response?.data?.code === 0) {
          showToast('La órden no pertence al usuario.', 'bad')
        }
        navigate('/user/orders', { replace: true })
      }
    })()
  }, [admin, firebase.user, navigate, order_id, searchParams]) //eslint-disable-line react-hooks/exhaustive-deps

  useAbortableEffect(
    signal => {
      setPaymentMethods(null)

      if (
        order &&
        lastEvent &&
        lastEvent === 'pending_pickup' &&
        order.shipping.shipping_method.icon === 'person' &&
        order.payment.payment_method.title === 'En persona'
      ) {
        ;(async () => {
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_payment_methods', {
            params: {
              shipping_method_id: order.shipping.shipping_method.shipping_method_id,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)

          setPaymentMethods(
            result.data.payment_methods.filter(
              pm => pm.payment_method_id !== order.payment.payment_method.payment_method_id
            )
          )
        })()
      }
    },
    [order, lastEvent]
  )

  // useEffect(() => {
  //   if (summary) return

  //   if (mp && preferenceId) {
  //     mp.checkout({
  //       preference: {
  //         id: preferenceId,
  //       },
  //       render: {
  //         container: ".cho-container",
  //         label: "Pagar ahora",
  //       },
  //     })
  //   }
  // }, [mp, preferenceId, summary])

  return (
    <div className="w-full xl:w-content-max-width mx-auto min-h-full xl:px-20 flex max-xl:flex-col">
      {data ? (
        <>
          {/* <TrackShippingDialog track_number={order.shipping.track_number} /> */}
          <SetShippingNumberDialog order_id={order_id} user_id={firebase?.user?.uid} />
          {paymentMethods && <PaymentMethodChange paymentMethods={paymentMethods} />}
          <div className="w-0 grow-[1.4] h-full max-xl:w-full max-xl:h-max xl:pr-10 max-xl:px-2">
            <div className="my-8 max-xl:my-4">
              <Breadcrumb
                items={[
                  {
                    name: firebase.user?.role === 'user' ? 'Mis pedidos' : 'Pedidos',
                    link: `/${GetRoleLink(firebase.user?.role)}/orders`,
                  },
                  {
                    name: `#${order_id}`,
                    ...(summary && {
                      link: `/${GetRoleLink(firebase.user?.role)}/orders/${order_id}`,
                    }),
                  },
                  ...(summary ? [{ name: 'Resúmen' }] : []),
                ]}
              />
            </div>
            {summary ? (
              <Summary info={{ order, userInfo, pickUpAddress }} />
            ) : (
              <div className="pb-28 xl:pb-8 flex flex-col gap-4">
                <Box
                  info={{
                    title: 'Carrito',
                    subtitle:
                      order.products?.length > 1 || order.products[0]?.amount > 1
                        ? `(${order.products.reduce((a, p) => a + p.amount, 0)} ${
                            order.products.reduce((a, p) => a + p.amount, 0) === 1 ? 'producto' : 'productos'
                          })`
                        : '',
                    content: order.products.map((v, i) => <CartProduct info={v} key={i} />),
                    isDropdown: true,
                    contentHeight: `calc(5rem*${order.products.length})`,
                    openByDefault: order.products.length === 1,
                  }}
                />
                <Box
                  info={{
                    title: 'Ver presupuesto',
                    link: `/${GetRoleLink(firebase.user?.role)}/orders/${order_id}/budget`,
                    newTab: true,
                  }}
                />
                <Box
                  info={{
                    title: 'Estado',
                    content: (
                      <>
                        <div className="w-full py-4">
                          {/* Reviso si el envío no es por moto y si el pedido esta en curso y no fue pagado todavía */}
                          {order.shipping.shipping_method.shipping_method_id !== 'av3D3F8qEyYMAqp8Kr0g' &&
                            !order.events.some(
                              ({ event }) =>
                                event === 'receipt_uploaded' ||
                                event === 'payment_approved' ||
                                event === 'delivered' ||
                                event === 'cancelled'
                            ) && (
                              <div className="px-4 mb-4">
                                <InfoBlock
                                  text={`Tenés hasta el ${TimestampToDateTextWithTime(
                                    order.date_created + 60 * 60 * 24 * 3
                                  )} para pagar o se cancelará el pedido.`}
                                />
                              </div>
                            )}
                          {(order.payment.payment_method.title === 'RapiPago' ||
                            order.payment.payment_method.title === 'PagoFacil') &&
                            !order.events.some(
                              ({ event }) =>
                                event === 'payment_approved' || event === 'delivered' || event === 'cancelled'
                            ) && (
                              <div className="px-4 mb-4">
                                <InfoBlock text={`El pago tarda de 1 a 3 días hábiles en acreditarse.`} />
                              </div>
                            )}
                          {order.events.map((v, i, arr) => (
                            <div key={i} className="relative flex items-center">
                              {i === arr.length - 1 ? (
                                <div
                                  className={`absolute top-1/2 left-8 -translate-x-1/2 -translate-y-1/2 w-5 h-5 p-1 
                                            rounded-full ${
                                              v.event === 'cancelled'
                                                ? 'bg-red-600 outline-red-600'
                                                : v.event === 'delivered'
                                                ? 'bg-green-600 outline-green-600'
                                                : 'bg-dewalt outline-dewalt'
                                            } border-[3px] border-white outline outline-1 z-[1]`}
                                />
                              ) : (
                                <div
                                  className={`absolute top-1/2 left-8 -translate-x-1/2 -translate-y-1/2 w-[0.6rem] h-[0.6rem] 
                                            rounded-full ${
                                              arr.at(-1).event === 'cancelled'
                                                ? 'bg-red-600'
                                                : arr.at(-1).event === 'delivered'
                                                ? 'bg-green-600'
                                                : 'bg-dewalt'
                                            } z-[1]`}
                                />
                              )}
                              {i !== 0 && (
                                <div
                                  className={`absolute top-0 left-8 -translate-x-1/2 w-[0.1rem] h-1/2 ${
                                    arr.at(-1).event === 'cancelled'
                                      ? 'bg-red-600'
                                      : arr.at(-1).event === 'delivered'
                                      ? 'bg-green-600'
                                      : 'bg-dewalt'
                                  } z-0`}
                                />
                              )}
                              {i !== arr.length - 1 && (
                                <div
                                  className={`absolute top-1/2 left-8 -translate-x-1/2 w-[0.1rem] h-1/2 ${
                                    arr.at(-1).event === 'cancelled'
                                      ? 'bg-red-600'
                                      : arr.at(-1).event === 'delivered'
                                      ? 'bg-green-600'
                                      : 'bg-dewalt'
                                  } z-0`}
                                />
                              )}
                              <div className="ml-16 py-2 flex flex-col">
                                <span
                                  className={`${
                                    i === arr.length - 1
                                      ? `${
                                          v.event === 'cancelled'
                                            ? 'text-red-600'
                                            : v.event === 'delivered'
                                            ? 'text-green-600'
                                            : 'text-amber-500'
                                        } font-medium`
                                      : 'text-gray-500'
                                  }`}>{`${possibleEvents[v.event]}`}</span>
                                <span className="text-xs text-gray-500">
                                  {TimestampToFullDate(v.time)}
                                  {v.user_name
                                    ? ` - Por ${v.user_name}${
                                        v.user_role === 'admin'
                                          ? ' (ADMIN)'
                                          : v.user_role === 'operator'
                                          ? ' (OPERADOR)'
                                          : v.user_role === 'bot'
                                          ? ' (BOT)'
                                          : ''
                                      }`
                                    : ''}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                        {order.shipping.shipping_method.title === 'Retiro en persona' && (
                          <div className="w-full p-4 pt-0 flex flex-col gap-2">
                            <span className="font-medium text-lg">¿Dónde retiro?</span>
                            <div className="rounded-xl overflow-hidden">
                              <iframe
                                title="Ubicación para retirar"
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBAPfcne-dRGxByZ-IxT8HSPeJqg6zxu7M&q=${PickUpAddressToURL(
                                  pickUpAddress
                                )}`}
                                className="w-full h-[350px] border-0 focus:outline-0"
                                loading="lazy"
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ),
                    actions:
                      (admin ||
                        lastEvent !== 'completed' ||
                        (lastEvent === 'completed' && order.products.length > 1)) &&
                      lastEvent !== 'cancelled'
                        ? [
                            ...(admin
                              ? [
                                  {
                                    node: {
                                      on_the_way: (
                                        <NewEventButton
                                          text="Ya recibió"
                                          newEvent={'delivered'}
                                          possibleEvents={possibleEvents}
                                          order_id={order.order_id}
                                          user_id={firebase.user?.uid}
                                        />
                                      ),
                                      pending_payment: (
                                        <NewEventButton
                                          text="Marcar como pagado"
                                          newEvent={'payment_approved'}
                                          possibleEvents={possibleEvents}
                                          order_id={order.order_id}
                                          user_id={firebase.user?.uid}
                                        />
                                      ),
                                      pending_pickup: (
                                        <NewEventButton
                                          text="Ya retiró"
                                          newEvent={'delivered'}
                                          possibleEvents={possibleEvents}
                                          order_id={order.order_id}
                                          user_id={firebase.user?.uid}
                                        />
                                      ),
                                      pending_shipping: (
                                        <NewEventButton
                                          text="Ya despaché"
                                          newEvent={'on_the_way'}
                                          possibleEvents={possibleEvents}
                                          order_id={order.order_id}
                                          user_id={firebase.user?.uid}
                                        />
                                      ),
                                      pending_payment_approval: (
                                        <NewEventButton
                                          text="Pago recibido"
                                          newEvent={'payment_approved'}
                                          possibleEvents={possibleEvents}
                                          order_id={order.order_id}
                                          user_id={firebase.user?.uid}
                                        />
                                      ),
                                    }[lastEvent],
                                    side: 'left',
                                  },
                                  order.shipping.track_number === ''
                                    ? {
                                        node: (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              document.getElementById('track-number-dialog')?.click()
                                            }}
                                            className="link">
                                            Cargar envío
                                          </button>
                                        ),
                                        side: 'left',
                                      }
                                    : {},
                                  paymentMethods
                                    ? {
                                        node: (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              document.getElementById('form-dialog').click()
                                            }}
                                            className="link">
                                            Cambiar forma de pago
                                          </button>
                                        ),
                                        side: 'right',
                                      }
                                    : {},
                                  order.events.length > 1 && lastEvent !== 'cancelled' && lastEvent !== 'delivered'
                                    ? {
                                        node: (
                                          <NewEventButton
                                            text="Deshacer evento"
                                            newEvent={''}
                                            possibleEvents={possibleEvents}
                                            order_id={order.order_id}
                                            user_id={firebase.user?.uid}
                                          />
                                        ),
                                        side: 'right',
                                      }
                                    : {},
                                  lastEvent !== 'cancelled' && lastEvent !== 'delivered'
                                    ? {
                                        node: (
                                          <CancelOrderButton
                                            order_id={order.order_id}
                                            user_id={firebase.user?.uid}
                                            admin={admin}
                                          />
                                        ),
                                        side: 'right',
                                      }
                                    : {},
                                ]
                              : lastEvent === 'delivered'
                              ? [
                                  {
                                    node: (
                                      <Link
                                        to={ProductLink(order.products[0].product_id, order.products[0].title)}
                                        className="link">
                                        Volver a comprar
                                      </Link>
                                    ),
                                    side: 'left',
                                  },
                                ]
                              : [
                                  ...(lastEvent === 'pending_payment'
                                    ? order.payment.payment_method.title === 'MercadoPago'
                                      ? [
                                          {
                                            node: <div className="cho-container" />,
                                            side: 'left',
                                          },
                                        ]
                                      : order.payment.payment_method.title.includes('Transferencia')
                                      ? [
                                          {
                                            node: (
                                              <Link to={`/orders/${order_id}/bank_transfer`} className="link">
                                                Pagar ahora
                                              </Link>
                                            ),
                                            side: 'left',
                                          },
                                        ]
                                      : order.payment.payment_method.title === 'RapiPago' ||
                                        order.payment.payment_method.title === 'PagoFácil'
                                      ? [
                                          {
                                            node: (
                                              <a
                                                href={order.payment.mercado_pago.external_resource_url}
                                                className="link"
                                                target="_blank"
                                                rel="noreferrer">
                                                Ver talón de pago
                                              </a>
                                            ),
                                            side: 'left',
                                          },
                                          {
                                            node: (
                                              <Link to={`/user/orders/${order_id}/ticket_upload`} className="link">
                                                Subir comprobante
                                              </Link>
                                            ),
                                            side: 'left',
                                          },
                                        ]
                                      : [{}]
                                    : order.shipping.shipping_method.type === 'home' ||
                                      order.shipping.shipping_method.title.includes('correo')
                                    ? [
                                        {
                                          node: (
                                            <a
                                              href={`https://andreani.com/#!/informacionEnvio/${order.shipping.track_number}`}
                                              rel="noreferrer"
                                              target={'_blank'}
                                              className={`link ${!order.shipping.track_number ? 'disabled' : ''}`}
                                              aria-disabled={!order.shipping.track_number}>
                                              Seguir envío
                                            </a>
                                            // <button
                                            //   type="button"
                                            //   onClick={() => {
                                            //     document.getElementById('track-dialog')?.click()
                                            //   }}
                                            //   className="link">
                                            //   Seguir envío
                                            // </button>
                                          ),
                                          side: 'left',
                                        },
                                      ]
                                    : [{}]),
                                  {
                                    node: (
                                      <CancelOrderButton
                                        order_id={order.order_id}
                                        user_id={firebase.user?.uid}
                                        admin={admin}
                                      />
                                    ),
                                    side: 'right',
                                  },
                                ]),
                          ]
                        : null,
                    moreShadow: true,
                  }}
                />
                {(order?.payment?.payment_method?.title.startsWith('Transferencia') ||
                  order?.payment?.payment_method?.title === 'RapiPago' ||
                  order?.payment?.payment_method?.title === 'PagoFacil') && (
                  <BoxWithIcon
                    info={{
                      title: 'Comprobante',
                      content: (
                        <>
                          <span>
                            {order?.payment.receipt.link ? `Comprobante cargado` : 'Comprobante de pago no disponible.'}
                          </span>
                          {order?.payment.receipt.link && (
                            <span className="mb-1 text-sm text-gray-500">
                              Cargado el {TimestampToDateText(order.payment.receipt.date_uploaded)}
                            </span>
                          )}
                          <div className="flex max-xs:flex-col gap-2 xl:items-center">
                            <>
                              {order?.payment.receipt.link && (
                                <>
                                  <a
                                    href={order.payment.receipt.link}
                                    className="font-medium text-amber-500 hover:text-amber-600 transition-all"
                                    rel="noreferrer"
                                    target="_blank">
                                    Ver comprobante
                                  </a>
                                  <div className="w-[2px] h-3 bg-gray-200 max-xs:hidden" />
                                </>
                              )}
                              {order?.payment?.payment_method?.title === 'RapiPago' ||
                              order?.payment?.payment_method?.title === 'PagoFacil' ? (
                                <Link
                                  to={`/${GetRoleLink(firebase.user?.role)}/orders/${order_id}/ticket_upload`}
                                  className="font-medium text-amber-500 hover:text-amber-600 transition-all">
                                  Subir comprobante
                                </Link>
                              ) : (
                                <Link
                                  to={`/${GetRoleLink(firebase.user?.role)}/orders/${order_id}/bank_transfer`}
                                  className="font-medium text-amber-500 hover:text-amber-600 transition-all">
                                  {firebase.user?.role === 'user' &&
                                  !order?.events.some(
                                    ({ event }) => event === 'payment_approved' || event === 'cancelled'
                                  )
                                    ? 'Pagar y subir'
                                    : 'Subir'}{' '}
                                  comprobante
                                </Link>
                              )}
                              {/* {!order?.events.some(
                                v => v.event === 'payment_approved' || v.event === 'cancelled'
                              ) && (
                                <>
                                  <Link
                                    to={`/user/orders/${order.order_id}/bank_transfer`}
                                    className="font-medium text-amber-500 hover:text-amber-600 transition-colors">
                                    Pagar de nuevo
                                  </Link>
                                </>
                              )} */}
                            </>
                          </div>
                        </>
                      ),
                    }}
                    icon={<MdReceiptLong className="w-full h-full" />}
                  />
                )}
                {(lastEvent !== 'cancelled' || order.invoice.link) && (
                  <BoxWithIcon
                    info={{
                      title: 'Factura',
                      content: order.invoice.link ? (
                        <>
                          <span>
                            {firebase.user?.role === 'user'
                              ? `Tu factura ${order.invoice.type_a ? 'Tipo A' : 'Consumidor Final'} está disponible.`
                              : `Factura ${order.invoice.type_a ? 'Tipo A' : 'Consumidor Final'} disponible.`}
                          </span>
                          <span className="text-sm text-gray-500">
                            Cargada el {TimestampToDateText(order.invoice.date_uploaded)}
                          </span>
                          <div className="flex max-xl:flex-col">
                            <div className="flex flex-col">
                              <a
                                href={order.invoice.link}
                                className="w-max font-medium text-amber-500 hover:text-amber-600 transition-colors"
                                rel="noreferrer"
                                target="_blank">
                                Ver factura
                              </a>
                              {order.ut_coins.credit_note && (
                                <a
                                  href={order.ut_coins.credit_note.link}
                                  className="w-max font-medium text-amber-500 hover:text-amber-600 transition-colors"
                                  rel="noreferrer"
                                  target="_blank">
                                  Ver factura de descuento
                                </a>
                              )}
                            </div>
                            {firebase.user?.role === 'admin' &&
                              (order.invoice.manual || (!order.credit_note && order.invoice.number > 0)) && (
                                <div className="flex">
                                  <div className="w-[1px] h-full bg-gray-500 mx-2 max-xl:hidden" />
                                  {order.invoice.manual ? (
                                    <UploadInvoiceButton order_id={order_id} user_id={firebase.user?.uid} />
                                  ) : (
                                    !order.credit_note && (
                                      <MakeInvoiceButton
                                        order_id={order_id}
                                        already_billed={order.invoice.link !== ''}
                                      />
                                    )
                                  )}
                                </div>
                              )}
                          </div>
                          {order.credit_note && (
                            <div className="mt-2 flex flex-col">
                              <span>Nota de crédito realizada.</span>
                              <span className="text-sm text-gray-500">
                                {`Nota de crédito nro.: ${order.invoice.type_a ? '03' : '08'}-${(
                                  '' + order.credit_note.sales_point
                                ).padStart(5, '0')}-${('' + order.credit_note.number).padStart(8, '0')}`}
                              </span>
                              <span className="text-sm text-gray-500">
                                Cargada el {TimestampToDateText(order.credit_note.date_uploaded)}
                              </span>
                              <a
                                href={order.credit_note.link}
                                className="w-max font-medium text-amber-500 hover:text-amber-600 transition-colors"
                                rel="noreferrer"
                                target="_blank">
                                Ver nota de crédito
                              </a>
                            </div>
                          )}
                        </>
                      ) : firebase.user?.role === 'user' ? (
                        <span>
                          Tu factura {order.invoice.type_a ? 'Tipo A' : 'Consumidor Final'} todavía no está disponible.
                        </span>
                      ) : (
                        <>
                          <span>Factura {order.invoice.type_a ? 'Tipo A' : 'Consumidor Final'} no disponible.</span>
                          <div className="flex gap-1">
                            <MakeInvoiceButton order_id={order_id} already_billed={order.invoice.link !== ''} />
                            <span>·</span>
                            <UploadInvoiceButton order_id={order_id} user_id={firebase.user?.uid} />
                          </div>
                        </>
                      ),
                    }}
                    icon={<MdReceiptLong className="w-full h-full" />}
                  />
                )}
                {firebase.user?.role === 'user' && (
                  <BoxWithIcon
                    info={{
                      title: 'Contacto',
                      content: (
                        <>
                          <span className="hyphens-none">
                            Por cualquier duda, contactate directamente con nosotros.
                          </span>
                          <span>+54 9 {data.contact_number}</span>
                          <a
                            href={`https://wa.me/549${DeleteFormatFromPhoneNumber(data.contact_number)}`}
                            className="w-max text-sm font-semibold text-amber-500 hover:text-amber-600 transition-colors"
                            target="_blank"
                            rel="noreferrer">
                            Abrir en WhatsApp
                          </a>
                        </>
                      ),
                    }}
                    icon={<BsWhatsapp className="w-full h-full" />}
                  />
                )}
                <div className="xl:hidden">
                  <Box
                    info={{
                      title: 'Resúmen de la compra',
                      link: `/${GetRoleLink(firebase.user?.role)}/orders/${order_id}/summary`,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div ref={summaryRef} className="max-xl:hidden" />
          {!summary && (
            <div className="w-0 grow min-h-full pt-24 pb-10 bg-neutral-50 max-xl:hidden">
              <Summary info={{ order, userInfo, pickUpAddress }} />
            </div>
          )}
        </>
      ) : (
        <LoadingBox text={'Cargando pedido...'} />
      )}
    </div>
  )
}

function Summary({ ref, info }) {
  const { firebase } = useMainContext()
  const [windowWidth] = useWindowSize()
  const { order, userInfo, pickUpAddress } = info

  return (
    <div ref={ref}>
      <div className="max-xl:pb-28 xl:px-10 flex flex-col gap-6 xl:gap-4 text-gray-500">
        <div className="max-xl:p-4 max-xl:bg-white max-xl:rounded-md flex flex-col gap-4 max-xl:shadow-lg">
          <div className="flex flex-col">
            <span className="max-xl:hidden font-medium text-black">Resumen de la compra</span>
            <span className="text-sm">
              {`${TimestampToDateTextWithTime(order.date_created)} | ${
                windowWidth > 1280 ? 'Número de pedido: ' : 'Pedido #'
              }${order.order_id}`}
            </span>
          </div>
          <div className="w-full h-[1px] bg-gray-300" />
          <div className="flex">
            <span className="w-0 grow">
              {order.products.reduce((a, p) => a + (p.amount + p.amount_offer_stock), 0) === 1
                ? 'Producto'
                : `Productos (${order.products.reduce((a, p) => a + (p.amount + p.amount_offer_stock), 0)})`}
            </span>
            <span>
              {FormatPrice(order.products.reduce((a, p) => a + p.normal_price * (p.amount + p.amount_offer_stock), 0))}
            </span>
          </div>
          {order.products.some(p => p.offer_price) && (
            <div className="flex">
              <span className="w-0 grow">Descuento</span>
              <span className="text-green-600">
                {'- ' +
                  FormatPrice(
                    order.products.reduce(
                      (a, p) =>
                        a +
                        (p.offer_price &&
                          p.normal_price * (p.amount + p.amount_offer_stock) -
                            p.offer_price * (p.amount + p.amount_offer_stock)),
                      0
                    )
                  )}
              </span>
            </div>
          )}
          {order.ut_coins.spent > 0 && (
            <div className="p-2 flex bg-white font-bold shadow-md rounded-md scale-105">
              <span className="w-0 grow text-green-600">UT Coins ({DigitGrouping(order.ut_coins.spent)})</span>
              <span className="text-green-600">
                {'- ' + FormatPrice(round((order.ut_coins.spent * order.ut_coins.dollar.buy) / 10, 2))}
              </span>
            </div>
          )}
          <div className="flex">
            <span className="w-0 grow">{order.shipping.shipping_method.type === 'home' ? 'Envío' : 'Retiro'}</span>
            <span className={`${order.shipping.price === 0 && 'text-green-600'}`}>
              {order.shipping.price === 0 ? 'Gratis' : FormatPrice(order.shipping.price)}
            </span>
          </div>
          {order.surcharge && (
            <div className="flex">
              <span className="w-0 grow">Recargo {order.payment.payment_method.title}</span>
              <span>{FormatPrice(order.surcharge)}</span>
            </div>
          )}
          <div className="w-full h-[1px] bg-gray-300" />
          <div className="flex font-medium items-center">
            <span className="w-0 grow">Total</span>
            <span className="text-xl font-bold">{getOrderTotal(order)}</span>
          </div>
        </div>
        <div className="w-full h-[1px] bg-gray-300 max-xl:hidden" />
        <div>
          <p className="max-xl:text-sm text-black font-medium mb-2 xl:mb-4">UT Coins</p>
          <div className="p-4 max-xl:bg-white flex gap-4 rounded-md xl:border xl:border-gray-300 max-xl:shadow-sm">
            <div className="w-14 h-14 p-2 bg-white rounded-full max-xl:border max-xl:border-gray-200">
              <div className="w-full h-full rounded-full select-none">
                <TbCoin className="w-full h-full text-gray-400" />
              </div>
            </div>
            <div className="w-0 grow flex flex-col justify-center">
              <span>Esta compra otorga:</span>
              <strong>{DigitGrouping(order.ut_coins.to_gain)} UT Coins</strong>
            </div>
          </div>
        </div>
        <div>
          <p className="max-xl:text-sm text-black font-medium mb-2 xl:mb-4">Forma de pago</p>
          <div className="p-4 max-xl:bg-white flex gap-4 rounded-md xl:border xl:border-gray-300 max-xl:shadow-sm">
            <div className="w-14 h-14 p-2 bg-white rounded-full max-xl:border max-xl:border-gray-200">
              <div className="w-full h-full rounded-full select-none">
                <ShowImage
                  image={order.payment.payment_method.image}
                  alt={`Imágen de ${order.payment.payment_method.title}`}
                  draggable={false}
                />
              </div>
            </div>
            <div className="w-0 grow flex flex-col justify-center">
              <span className="font-medium">{order.payment.payment_method.title}</span>
              <span className="text-sm">{order.payment.payment_method.sub_title}</span>
              {order.payment.mercado_pago?.external_resource_url && (
                <a
                  href={order.payment.mercado_pago.external_resource_url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm font-medium text-amber-500 hover:text-amber-600 transition-colors">
                  Ver talón de pago
                </a>
              )}
            </div>
          </div>
        </div>
        <div>
          <p className="max-xl:text-sm text-black font-medium mb-2 xl:mb-4">
            {order.shipping.shipping_method.type === 'home' ? 'Envío' : 'Retiro'}
          </p>
          <div className="max-xl:bg-white flex flex-col rounded-md xl:border xl:border-gray-300 max-xl:shadow-sm">
            <div className="p-4 flex gap-4">
              {order.shipping.shipping_method.type === 'home' ? (
                <>
                  <div className="w-14 h-14 p-3 bg-white rounded-full max-xl:border max-xl:border-gray-200">
                    {ShippingMethodIcon(order.shipping.shipping_method.icon, 'w-full h-full')}
                  </div>
                  <div className="w-0 grow flex flex-col justify-center text-sm">
                    <span className="text-base font-medium">{order.shipping.shipping_method.title}</span>
                    <span className="mb-2">{order.shipping.shipping_method.sub_title}</span>
                    <span>{`${userInfo.street} ${userInfo.street_number}`}</span>
                    <span>{`${userInfo.name} ${userInfo.last_name}, ${userInfo.city}, ${
                      JSON.parse(process.env.REACT_APP_PROVINCES)[userInfo.province].name
                    }`}</span>
                    {(firebase.user?.role === 'admin' || firebase.user?.role === 'operator') && (
                      <DefaultAlertDialog
                        title={'Información para el envío'}
                        trigger={
                          <button type="button" className="w-max mt-1 font-medium text-amber-500">
                            Info. para el envío
                          </button>
                        }
                        content={
                          <>
                            <div className="mb-2 flex flex-col max-xl:text-sm">
                              <span className="mb-1 text-base xl:text-lg font-medium">
                                {order.shipping.shipping_method.title}
                              </span>
                              <p>{order.shipping.shipping_method.sub_title}</p>
                              <p>
                                Precio:{' '}
                                <span className="text-gray-600 font-medium">
                                  {order.shipping.price ? FormatPrice(order.shipping.price) : 'Gratis'}
                                </span>
                              </p>
                              <p>
                                Nombre completo:{' '}
                                <span className="text-gray-600 font-medium">{`${userInfo.name} ${userInfo.last_name}`}</span>
                              </p>
                              <p>
                                Provincia:{' '}
                                <span className="text-gray-600 font-medium">
                                  {JSON.parse(process.env.REACT_APP_PROVINCES)[userInfo.province].name}
                                </span>
                              </p>
                              <p>
                                Localidad: <span className="text-gray-600 font-medium">{userInfo.city}</span>
                              </p>
                              <p>
                                Calle: <span className="text-gray-600 font-medium">{userInfo.street}</span>
                              </p>
                              <p>
                                Altura: <span className="text-gray-600 font-medium">{userInfo.street_number}</span>
                              </p>
                              <p>
                                Piso: <span className="text-gray-600 font-medium">{userInfo.flat || '-'}</span>
                              </p>
                              <p>
                                Depto: <span className="text-gray-600 font-medium">{userInfo.floor || '-'}</span>
                              </p>
                              <p>
                                Info. adicional:{' '}
                                <span className="text-gray-600 font-medium">{userInfo.additional_info || '-'}</span>
                              </p>
                              <p>
                                CP: <span className="text-gray-600 font-medium">{userInfo.zip_code}</span>
                              </p>
                              <p>
                                Teléfono:{' '}
                                <span className="text-gray-600 font-medium">{`${userInfo.area_code} ${userInfo.phone_number}`}</span>
                              </p>
                              <p>
                                Email: <span className="text-gray-600 font-medium">{userInfo.email}</span>
                              </p>
                            </div>
                            <div className="w-full mb-2">
                              <p className="mb-1 block font-medium xl:text-lg">Productos</p>
                              <div className="w-full max-h-60 flex flex-col overflow-y-auto">
                                {order.products.map(product => (
                                  <div
                                    key={product.product_id}
                                    className="w-full flex flex-col border border-gray-200 border-b-0 last:border-b">
                                    <span className="w-full py-1 text-sm flex justify-center border-b border-gray-200">
                                      {`${product.brand} ${product.model} · ${FormatPrice(
                                        product.offer_price ?? product.normal_price
                                      )}`}
                                    </span>
                                    <div className="flex text-sm flex-wrap">
                                      <div className="flex-1 min-w-max flex justify-center py-1 px-2">
                                        Peso: {product.weight} kg
                                      </div>
                                      <div className="flex-1 min-w-max flex justify-center py-1 px-2">
                                        Largo: {product.depth} cm
                                      </div>
                                      <div className="flex-1 min-w-max flex justify-center py-1 px-2">
                                        Ancho: {product.width} cm
                                      </div>
                                      <div className="flex-1 min-w-max flex justify-center py-1 px-2">
                                        Alto: {product.height} cm
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="w-full mb-2">
                              <p className="mb-1 block font-medium xl:text-lg">Total del pedido</p>
                              <p className="max-xl:text-sm">{getOrderTotal(order)}</p>
                            </div>
                          </>
                        }
                        cancel={{ text: 'Cerrar' }}
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="w-14 h-14 p-3 bg-white rounded-full max-xl:border max-xl:border-gray-200">
                    {ShippingMethodIcon(order.shipping.shipping_method.icon, 'w-full h-full')}
                  </div>
                  <div className="w-0 grow flex flex-col justify-center text-sm">
                    <span className="text-base font-medium">{order.shipping.shipping_method.title}</span>
                    <span className="mb-2">{order.shipping.shipping_method.sub_title}</span>
                    <span>{`${userInfo.name} ${userInfo.last_name}, ${userInfo.cuit}, ${userInfo.area_code}${userInfo.phone_number}`}</span>
                    {order.shipping.shipping_method.title === 'Retiro en persona' && (
                      <>
                        <span className="mt-3 mb-1 text-base font-medium">Retira en:</span>
                        <span>{`${pickUpAddress.street} ${pickUpAddress.street_number}`}</span>
                        <span>{`C.P. ${pickUpAddress.zip_code}, ${pickUpAddress.city}, ${pickUpAddress.province}`}</span>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            {order.shipping.track_number && (
              <div className="p-4 text-sm flex flex-col items-start border-t border-gray-300">
                <div>
                  Código de seguimiento:&nbsp;
                  {/* <button
                type="button"
                className="w-max font-medium hover:text-amber-600 transition-colors"
                title="Seguír envío"
                onClick={() => document.getElementById('track-dialog')?.click()}>
                {order.shipping.track_number}
              </button> */}
                  <a
                    href={`https://andreani.com/#!/informacionEnvio/${order.shipping.track_number}`}
                    rel="noreferrer"
                    target={'_blank'}
                    title="Seguir envío"
                    className="w-max font-medium hover:text-amber-600 transition-colors flex items-center">
                    {order.shipping.track_number}
                    <TbExternalLink size={15} className="pl-[2px]" />
                  </a>
                </div>
                {(firebase?.user?.role === 'admin' || firebase?.user?.role === 'operator') && (
                  <button
                    type="button"
                    onClick={() => document.getElementById('track-number-dialog')?.click()}
                    className="font-medium text-amber-500 hover:text-amber-600 transition-colors">
                    Actualizar código
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function Box({ info }) {
  const { title, subtitle, content, actions, link, isDropdown, contentHeight, openByDefault, moreShadow, newTab } = info

  const actionsLeft =
      actions && actions.filter(a => a.side === 'left' && !isEmpty(a.node)).map((a, i) => <div key={i}>{a.node}</div>),
    actionsRight =
      actions && actions.filter(a => a.side === 'right' && !isEmpty(a.node)).map((a, i) => <div key={i}>{a.node}</div>)
  const totalActions = (actionsLeft?.length ?? 0) + (actionsRight?.length ?? 0)
  // if (title === 'Estado') {
  //   console.log({ actionsLeft })
  //   console.log({ totalActions })
  // }

  const [open, setOpen] = useState(openByDefault ?? false)

  const Tag = isDropdown ? 'button' : 'div'
  return (
    <div className={`relative w-full bg-white rounded-md ${moreShadow ? 'shadow-lg' : 'shadow-sm'} overflow-hidden`}>
      {link && (
        <Link
          to={link}
          className="absolute top-0 left-0 w-full h-full z-50"
          {...(newTab ? { target: '_blank', rel: 'noreferrer' } : {})}
        />
      )}
      <Tag
        className={`w-full p-4 max-xl:py-3 flex items-center border-b ${
          !isDropdown || open ? 'border-gray-200' : 'border-transparent'
        } group transition-colors duration-200`}
        {...(isDropdown ? { type: 'button', onClick: () => setOpen(!open) } : {})}>
        <span className="w-max font-medium max-xl:text-sm">
          {title} {subtitle && <span className="text-gray-500">{subtitle}</span>}
        </span>
        {(isDropdown || link) && (
          <div className="w-0 grow min-h-full flex justify-end">
            <div className="w-6 h-6 rounded-full text-neutral-500 group-hover:text-black transition-colors">
              <FiChevronDown
                className={`w-full h-full ${link ? '-rotate-90' : open ? 'rotate-180' : 'rotate-0'} transition-all`}
              />
            </div>
          </div>
        )}
      </Tag>
      <div
        className={`w-full max-xl:text-sm ${isDropdown ? 'transition-all duration-200' : ''} hyphens-none`}
        style={{
          height: isDropdown ? (open ? contentHeight : 0) : 'max-content',
        }}>
        {content}
      </div>
      {actions && (
        <>
          <div className={`p-4 flex max-xl:flex-col gap-4 border-t border-gray-200`}>
            <div className={`w-full flex flex-col gap-2`}>{actionsLeft}</div>
            {totalActions > 0 && <div className="w-full h-[1px] bg-gray-200 xl:hidden" />}
            <div className={`w-full flex flex-col gap-2`}>{actionsRight}</div>
          </div>
        </>
      )}
    </div>
  )
}

function BoxWithIcon({ info, icon }) {
  info.content = (
    <div className="w-full p-4 flex gap-4 ">
      <div className="w-16 h-16 p-4 bg-neutral-100 rounded-full text-gray-500">{icon}</div>
      <div className="w-0 grow flex flex-col justify-center">{info.content}</div>
    </div>
  )
  return <Box info={info} />
}

function ActionButton({ trigger, ...rest }) {
  return (
    <DefaultAlertDialog
      trigger={
        <button
          className={`w-full flex-1 h-10 px-4 
                    ${
                      trigger.good
                        ? 'bg-dewalt text-black hover:bg-black hover:text-dewalt'
                        : 'bg-white text-gray-500 hover:text-black hover:border-black border border-gray-500'
                    } font-medium rounded-md transition-colors`}>
          {trigger.text}
        </button>
      }
      {...rest}
    />
  )
}

// function TrackShippingDialog({ track_number }) {
//   const { showToast } = useMainContext()

//   return (
//     <DefaultAlertDialog
//       title={'Seguir envío'}
//       content={
//         <div className="flex flex-col">
//           <span className="mb-1">El código de seguimiento para tu pedido es:</span>
//           <div className="flex gap-2 items-center">
//             <span className="font-bold">{track_number}</span>
//             <CopyToClipboard text={track_number} onCopy={() => showToast('Número de seguimiento copiado', 'good')}>
//               <button className="w-max text-sm px-2 py-1 bg-gray-200 text-gray-600 flex items-center rounded-md border border-gray-500">
//                 Copiar <MdContentCopy className="w-4 h-4 ml-1" />
//               </button>
//             </CopyToClipboard>
//           </div>
//           <span className="mt-2 mb-1">
//             Tocá en "Copiar" y pegalo en la página que se abrirá al tocar en el siguiente botón:
//           </span>
//           <a
//             href="https://www.correoargentino.com.ar/formularios/e-commerce"
//             className="w-max px-4 py-2 bg-dewalt text-black font-medium hover:bg-black hover:text-dewalt transition-colors rounded-md"
//             rel="noreferrer"
//             target={'_blank'}>
//             Ir al seguimiento
//           </a>
//         </div>
//       }
//       cancel={{
//         text: 'Cerrar',
//       }}
//       trigger={<div id="track-dialog" className="invisible" />}
//     />
//   )
// }

function SetShippingNumberDialog({ order_id, user_id }) {
  const { showToast } = useMainContext()
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const numberRef = useRef(null)

  const handleSubmit = async ev => {
    ev.preventDefault()
    const number = new FormData(ev.target).get('number').toString()
    try {
      setLoading(true)

      // const result =
      await axios.put(
        process.env.REACT_APP_API_LINK + 'update_order',
        {
          order_id,
          user_id,
          track_number: number,
        },
        {
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
        }
      )
      // console.log(result)

      setLoading(false)
      setDone(true)

      await delay(1000)

      window.location.reload(false)
    } catch (err) {
      // console.log(err)
      setLoading(false)
      showToast(err.result?.data?.message || process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
    }
  }

  return (
    <DefaultAlertDialog
      title={'Cargar seguimiento de envío'}
      content={
        <div className="flex flex-col">
          <span className="mb-4">Ingresá el número de seguimiento del envío asociado a este pedido.</span>
          <input
            ref={numberRef}
            type="text"
            className="w-full h-10 p-2 pr-6 rounded outline outline-1 -outline-offset-1
                  outline-gray-200 hover:outline-gray-500 focus:outline-2 focus:outline-dewalt peer"
            placeholder="Número de seguimiento"
            pattern="[A-Z0-9]{23}"
            name="number"
          />
        </div>
      }
      submit={{
        text: 'Actualizar',
        onSubmit: handleSubmit,
      }}
      cancel={{
        text: 'Cancelar',
        onClick: () => {
          if (numberRef.current) numberRef.current.value = ''
          setLoading(false)
          setDone(false)
        },
      }}
      loading={loading}
      done={done}
      trigger={<div id="track-number-dialog" className="invisible" />}
    />
  )
}

function UploadInvoiceButton({ order_id, user_id }) {
  const { firebase } = useMainContext()
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const onSubmit = async ev => {
    ev.preventDefault()
    // console.log(selectedFile)

    if (
      !selectedFile ||
      !(
        selectedFile.type === 'image/jpeg' ||
        selectedFile.type === 'image/png' ||
        selectedFile.type === 'application/pdf'
      )
    )
      return

    try {
      setLoading(true)
      const fileUrl = await uploadFile(
        firebase.storage,
        selectedFile,
        `/invoices/${order_id}.${selectedFile.type.split('/')[1]}`
      )
      // console.log(fileUrl)

      // const result =
      await axios.put(
        process.env.REACT_APP_API_LINK + 'update_order',
        { order_id: order_id, invoice: fileUrl, user_id: user_id },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      window.location.reload(false)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      // console.log(err)
      // TODO: Mandar error a DB
    }
  }

  return (
    <DefaultAlertDialog
      trigger={
        <button className="w-max font-medium text-amber-500 hover:text-amber-600 transition-colors">
          Cargar factura
        </button>
      }
      title={'Cargar factura'}
      content={
        <>
          <p>Elegí el archivo de la factura.</p>
          <p>Puede ser una imágen o archivo PDF.</p>

          <div className="w-full h-12 mt-4 flex items-center">
            <label
              htmlFor="file"
              className="w-max h-full p-2 flex items-center rounded-md
                text-gray-500 hover:text-black
                border border-gray-200 hover:border-black transition-all
                cursor-pointer">
              <MdOutlineFileUpload className="w-6 h-6" />
              <span className="ml-2">Buscar archivo</span>
            </label>
            <div className="w-0 grow ml-2 flex flex-col">
              <span className="leading-5">Archivo seleccionado:</span>
              <span className="text-gray-500 line-clamp-1">{selectedFile ? selectedFile.name : 'Ninguno'}</span>
            </div>
            <input
              id="file"
              type="file"
              accept="image/jpeg, image/png, application/pdf"
              className="hidden"
              onChange={ev => setSelectedFile(ev.target.files[0])}
            />
          </div>
        </>
      }
      submit={{ text: 'Cargar', onSubmit: onSubmit }}
      loading={loading}
    />
  )
}

function MakeInvoiceButton({ already_billed, order_id }) {
  const { firebase, showToast } = useMainContext()
  const [loading, setLoading] = useState(false)

  const onSubmit = async ev => {
    ev.preventDefault()

    try {
      setLoading(true)
      // const result =
      await axios.post(
        process.env.REACT_APP_API_LINK + 'create_voucher',
        { order_id: order_id, user_id: firebase.user?.uid, action: already_billed ? 'credit_note' : 'voucher' },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      window.location.reload(false)
      setLoading(false)
    } catch (err) {
      if (err.code === 'ERR_CANCELED') return
      setLoading(false)
      // console.log(err)
      showToast(err.response?.data?.message || process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
      // TODO: Mandar error a DB
    }
  }

  return (
    <DefaultAlertDialog
      trigger={
        <button className="w-max font-medium text-amber-500 hover:text-amber-600 transition-colors">
          {already_billed ? 'Nota de crédito' : 'Facturar'}
        </button>
      }
      title={already_billed ? 'Hacer Nota de crédito' : 'Facturar'}
      content={
        <>
          {already_billed ? (
            <>
              <p>Se va a hacer una nota de crédito para la factura que se realizó de esta órden.</p>
            </>
          ) : (
            <>
              <p>Se va a facturar la órden.</p>
            </>
          )}
          <strong>Esta acción no se puede deshacer.</strong>
        </>
      }
      submit={{ text: 'Aceptar', onSubmit }}
      loading={loading}
    />
  )
}

function NewEventButton({ text, possibleEvents, newEvent, order_id, user_id }) {
  const { firebase, setFirebase, showToast } = useMainContext()
  const [loading, setLoading] = useState(false)

  const onSubmit = async ev => {
    ev.preventDefault()
    try {
      setLoading(true)
      const result = await axios.put(
        process.env.REACT_APP_API_LINK + 'update_order',
        { order_id: order_id, user_id: user_id, new_event: newEvent },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )

      // console.log(result)

      if (result.data.ut_coins) {
        const user = JSON.parse(JSON.stringify(firebase.user))
        user.ut_coins = result.data.ut_coins
        setFirebase({ ...firebase, user: user })
        saveCookie('user', user, true)
      }

      window.location.reload(false)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      showToast(err.response?.data?.message ?? process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
      // console.log(err)
      // TODO: Mandar error a DB
    }
  }

  return (
    <ActionButton
      trigger={{ text: text, good: true }}
      title="¿Estás seguro/a?"
      content={
        <>
          <p>Se actualizará el pedido {newEvent ? `a "${possibleEvents[newEvent]}"` : 'al estado anterior'}.</p>
          <p className="font-medium">{newEvent && 'Esta acción se podrá deshacer si es necesario.'}</p>
        </>
      }
      submit={{ text: 'Si, actualizar pedido', onSubmit: onSubmit }}
      loading={loading}
    />
  )
}

function CancelOrderButton({ order_id, user_id, admin }) {
  const [loading, setLoading] = useState(false)

  const onSubmit = async ev => {
    ev.preventDefault()
    try {
      setLoading(true)
      // const result =
      await axios.put(
        process.env.REACT_APP_API_LINK + 'update_order',
        { order_id: order_id, user_id: user_id, new_event: 'cancelled' },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )

      // console.log(result)

      window.location.reload(false)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      // console.log(err)
      // TODO: Mandar error a DB
    }
  }
  return (
    <ActionButton
      trigger={{ text: 'Cancelar pedido' }}
      title="¿Estás seguro/a?"
      content={
        <>
          <p>
            Si cancelas el pedido, {admin ? 'el comprador perderá la reserva' : 'perderás la reserva'} y se devolverá el
            stock.
          </p>
          <p className="font-medium">
            Esta acción <strong>NO</strong> se podrá deshacer.
          </p>
        </>
      }
      submit={{ text: 'Si, cancelar pedido', onSubmit: onSubmit }}
      loading={loading}
    />
  )
}

function CartProduct({ info }) {
  const { product_id, title, brand, model, amount, amount_offer_stock, image } = info
  return (
    <Link
      to={ProductLink(product_id, title)}
      className="w-full h-20 bg-white hover:bg-neutral-50 p-4 flex items-center transition-colors">
      <div className="w-0 grow flex flex-col">
        <span className="xl:mb-1 pr-4 line-clamp-1">{title}</span>
        <span className="text-sm text-gray-500">
          {brand} {model}・
          {`${amount + amount_offer_stock} ${amount + amount_offer_stock === 1 ? 'unidad' : 'unidades'}`}
        </span>
      </div>
      <div src={image} className="w-10 h-10 bg-white xl:w-12 xl:h-12 rounded-full select-none">
        <ShowImage image={image} alt={`Imágen de ${title}`} draggable={false} />
      </div>
    </Link>
  )
}

function PaymentMethodChange({ paymentMethods }) {
  const { firebase, showToast } = useMainContext()
  const { order_id } = useParams()

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0].payment_method_id)
  const [loading, setLoading] = useState(false)

  const handlePaymentMethodChange = async () => {
    try {
      setLoading(true)

      // const result =
      await axios.put(
        process.env.REACT_APP_API_LINK + 'update_order',
        {
          order_id,
          user_id: firebase.user?.uid,
          payment_method: selectedPaymentMethod,
        },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      setLoading(false)
      window.location.reload(false)
    } catch (err) {
      // console.log(err)
      setLoading(false)
      showToast('Hubo un error al actualizar la forma de pago.', 'bad')
    }
  }

  return (
    <DefaultAlertDialog
      title={'Cambiar forma de pago'}
      content={
        <div className="flex flex-col">
          Elegí la nueva forma de pago
          <div className="w-full mt-2 xl:w-80 h-10">
            <Dropdown
              items={paymentMethods.map(pm => ({ text: pm.title, value: pm.payment_method_id }))}
              onChange={item => setSelectedPaymentMethod(item.value)}
            />
          </div>
        </div>
      }
      submit={{ text: 'Cambiar', onClick: handlePaymentMethodChange }}
      loading={loading}
      done={false}
      trigger={<div id="form-dialog" className="invisible" />}
    />
  )
}

export default ViewOrder

import { useState, useMemo, useRef } from "react";
import { DraggableCore } from "react-draggable";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { FaYoutube } from "react-icons/fa";

import "./Carousel.css";
import { Clamp } from "./Numbers";
import ShowImage from "./ShowImage";

function Carouselv2({ items, startingIndex, contain = false }) {
  const [activeItem, setActiveItem] = useState(0);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const zoomContainer = 442;
  const zoomAmount = 2;
  const zoomRange = 1 - 1 / zoomAmount;

  const [dragging, setDragging] = useState(false);
  const [dragDirection, setDragDirection] = useState(0);
  const [position, setPosition] = useState(0);
  const firstItem = useRef(null);
  const container = useRef(null);

  const handleOnMouseMove = (e) => {
    let { left, top, width, height } = e.target.getBoundingClientRect();
    left += (width - width * zoomRange) / 2;
    width *= zoomRange;
    top += (height - height * zoomRange) / 2;
    height *= zoomRange;

    const x = Clamp(((e.clientX - left) / width) * 100, 0, 100);
    const y = Clamp(((e.clientY - top) / height) * 100, 0, 100);
    setZoomPosition({ x: x, y: y });
  };

  const handleDrag = (e, data) => {
    const width = container.current.getBoundingClientRect().width;
    setDragging(true);
    setDragDirection(data.deltaX < 0 ? -1 : data.deltaX > 0 ? 1 : 0); // < 0 ? LEFT : > 0 ? RIGHT
    const newPos = position + data.deltaX;
    setPosition(newPos > 0 || newPos < -(width * (items.length - 1)) ? position : newPos);
  };

  const handleStoppedDrag = (e, data) => {
    const width = container.current.getBoundingClientRect().width;
    setDragging(false);
    // En vez de usar el medio de la imagen como referencia, uso un 10% menos del lado contrario al que esta swipeando
    // O sea en vez de tener que mover el 50% de la imagen para cambiar, con mover el 10% ya lo toma
    setPosition(
      -Math.floor(Math.abs(position - width / (dragDirection < 0 ? 1.111 : 10)) / width) * width
    );
    setDragDirection(0);
  };

  useMemo(() => {
    setActiveItem(startingIndex ?? 0);
  }, [startingIndex]);

  return (
    <>
      {items ? (
        <>
          <div className="w-full h-full overflow-x-hidden xl:hidden" ref={container}>
            <DraggableCore axis="x" onDrag={handleDrag} onStop={handleStoppedDrag}>
              <div
                className={`w-full h-full flex ${!dragging ? "transition-all duration-500" : ""}`}
                style={{ translate: position }}
              >
                {items.map((v, i) => (
                  <div key={i} className="w-full h-full" ref={i === 0 ? firstItem : null}>
                    {v.includes("youtube") ? (
                      <AspectRatio.Root ratio={16 / 9}>
                        <iframe
                          className="w-full h-full"
                          src={v}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </AspectRatio.Root>
                    ) : (
                      <ShowImage
                        image={v}
                        alt={`Imágen de ${v}`}
                        draggable={false}
                        contain={contain}
                      />
                    )}
                  </div>
                ))}
              </div>
            </DraggableCore>
          </div>
          <div className="w-full aspect-square max-xl:hidden">
            <div className="w-full h-full relative group">
              <div
                className="w-full h-full flex justify-center items-center rounded-md cursor-zoom-in"
                onMouseMove={handleOnMouseMove}
              >
                {items[activeItem].includes("youtube") ? (
                  <AspectRatio.Root ratio={16 / 9}>
                    <iframe
                      className="w-full h-full"
                      src={`https://www.youtube.com/embed/${
                        items[activeItem]?.includes("www.youtube.com")
                          ? items[activeItem]?.split("?v=")[1]
                          : items[activeItem]?.split("youtu.be/")[1]
                      }`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />
                  </AspectRatio.Root>
                ) : (
                  <ShowImage
                    image={items[activeItem]}
                    alt={`Imágen de ${items[activeItem]}`}
                    draggable={false}
                  />
                )}
              </div>
              {!items[activeItem]?.includes("youtube") && (
                <>
                  <div className="absolute w-full h-full top-0 left-0 pointer-events-none flex justify-center items-center">
                    <div
                      className="relative"
                      style={{
                        width: `${zoomRange * zoomContainer}px`,
                        height: `${zoomRange * zoomContainer}px`,
                      }}
                    >
                      <div
                        className="absolute bg-black bg-opacity-30
                               rounded-md pointer-events-none hidden group-hover:block
                               -translate-x-1/2 -translate-y-1/2"
                        style={{
                          width: zoomContainer / zoomAmount,
                          height: zoomContainer / zoomAmount,
                          left: `${zoomPosition.x}%`,
                          top: `${zoomPosition.y}%`,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="absolute top-0 left-full ml-4 aspect-square bg-white rounded-md 
                           border border-gray-200 hidden group-hover:block overflow-hidden 
                           select-none pointer-events-none"
                    style={{
                      width: zoomContainer,
                      aspectRatio: "1 / 1",
                    }}
                  >
                    <div className="w-full h-full relative">
                      <div
                        className="absolute"
                        style={{
                          width: `${100 * zoomAmount}%`,
                          height: `${100 * zoomAmount}%`,
                          left: `${-zoomPosition.x}%`,
                          top: `${-zoomPosition.y}%`,
                        }}
                      >
                        <ShowImage
                          image={items[activeItem]}
                          alt={`Imágen con zoom`}
                          contain={contain}
                          draggable={false}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {items.length > 1 && (
              <div className="w-full h-14 mt-4">
                <div className="w-max h-full mx-auto flex justify-between gap-3">
                  {items.map((v, i) => (
                    <Thumbnail
                      img={v}
                      video={v.includes("youtube")}
                      onClick={() => setActiveItem(i)}
                      active={activeItem === i}
                      key={i}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>{" "}
        </>
      ) : (
        ""
      )}
    </>
  );
}

function Thumbnail({ img, video, onClick, active }) {
  return (
    <button
      type="button"
      className={`h-full p-[2px] aspect-square rounded-md shadow-sm outline outline-offset-0 ${
        active
          ? "outline-2 outline-dewalt shadow-md"
          : "outline-1 outline-gray-200 hover:outline-gray-500 hover:shadow-md"
      } transition-all group`}
      onClick={onClick}
    >
      <div className="w-full h-full rounded-md overflow-hidden">
        {video ? (
          <FaYoutube
            className={`w-full h-full p-2 ${
              active ? "text-[#ff0000]" : "text-gray-300 group-hover:text-gray-500"
            } transition-all`}
          />
        ) : (
          <ShowImage image={img} alt={`Imágen de ${img}`} />
        )}
      </div>
    </button>
  );
}

export default Carouselv2;

import axios from 'axios'
import { round, isEqual, toNumber } from 'lodash'
import { createContext, forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import { MdOutlineChevronRight } from 'react-icons/md'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Sidebar from '../../../Sidebar'
import Breadcrumb from '../../../utils/Breadcrumb'
import ButtonWithProgress from '../../../utils/ButtonWithProgress'
import DropdownInput from '../../../utils/forms/DropdownInput'
import ImageDnD from '../../../utils/forms/ImageDnD'
import Section from '../../../utils/forms/Section'
import TextInput from '../../../utils/forms/TextInput'
import ToggleInput from '../../../utils/forms/ToggleInput'
import { inputsToObj } from '../../../utils/forms/Utils'
import { useAbortableEffect } from '../../../utils/Hooks'
import { deleteFile, fileExists, uploadFile, delay, setDocumentTitle } from '../../../utils/Html'
import InputLabel from '../../../utils/InputLabel'
import LoadingBox from '../../../utils/LoadingBox'
import { useMainContext } from '../../../utils/MainContext'
import {
  CapitalizeAllWords,
  FormatPrice,
  NewProductImagePath,
  OnlyNumbers,
  ProductLink,
  TimestampToDateTextWithTime,
} from '../../../utils/Strings'
import { z } from 'zod'

const ProductContext = createContext({
  errorsState: [],
  loadingState: [],
  doneState: [],
  productState: [],
  reactiveValuesState: [],
  activeSectionState: [],
  creating: false,
})
const useProductContext = () => useContext(ProductContext)

const FormValidate = z
  .object({
    title: z
      .string()
      .trim()
      .min(1, { message: 'El título no puede estar vacío.' })
      .max(100, { message: 'El título no puede exceder los 100 carácteres.' })
      .optional(),
    model: z
      .string()
      .trim()
      .min(1, { message: 'El modelo no puede estar vacío' })
      .max(15, { message: 'El modelo no puede exceder los 15 carácteres.' })
      .optional(),
    images: z
      .array(z.object({}).passthrough())
      .nonempty({ message: 'El producto tiene que tener al menos 1 imágen.' })
      .optional(),
    stock: z
      .number()
      .min(1, { message: 'El stock tiene que ser mayor a 0.' })
      .max(999999, { message: 'El stock no puede superar los 999.999' })
      .optional(),
    offer_cost_stock: z.number().max(999999, { message: 'El stock de oferta no puede superar los 999.999' }).optional(),
    upc: z
      .string()
      .min(1, { message: 'El UPC no puede estar vacío.' })
      .max(13, { message: 'El UPC no puede superar los 13 dígitos.' })
      .optional(),
    normal_price: z.number().min(0.01, 'El precio normal tiene que ser mayor a $0.').optional(),
    cost_price: z.number().min(0.01, 'El precio costo tiene que ser mayor a $0.').optional(),
    width: z.number().min(1, 'El ancho tiene que ser mayor a 0 cm.').optional(),
    height: z.number().min(1, 'El alto tiene que ser mayor a 0 cm.').optional(),
    depth: z.number().min(1, 'El largo tiene que ser mayor a 0 cm.').optional(),
    weight: z.number().min(0.01, 'El peso tiene que ser mayor a 0.01 kg.').optional(),
    description: z.string().trim().optional(),
    video: z
      .string()
      .refine(v => v === '' || z.string().url().safeParse(v).success)
      .optional(),
    warranty_number: z.number().optional(),
    warranty_unit: z.string().optional(),
  })
  .passthrough()

function AdminProduct({ creating }) {
  const { firebase, showToast } = useMainContext()
  const navigate = useNavigate()
  const { product_id } = useParams()

  const [pageLoaded, setPageLoaded] = useState(creating)
  const [product, setProduct] = useState(null)
  const [brands, setBrands] = useState(null)
  const [categories, setCategories] = useState(null)
  const [reactiveValues, setReactiveValues] = useState({})

  const [activeSection, setActiveSection] = useState('')
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const formSectionsRef = useRef([])

  const categoriaInputs = useRef([])
  const caracteristicasInputs = useRef([])
  const precioInputs = useRef([])
  const dimensionesInputs = useRef([])
  const descripcionInputs = useRef([])
  const videoInputs = useRef([])
  const garantiaInputs = useRef([])

  useAbortableEffect(
    signal => {
      if (!firebase.user || firebase.user.role === 'user') {
        navigate('/', { replace: true })
      }
      setDocumentTitle(product_id ? 'Modificar producto' : 'Nuevo producto')
      ;(async () => {
        setPageLoaded(false)
        setProduct(null)
        setActiveSection('')
        setErrors([])
        setLoading(false)
        setDone(false)

        try {
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_product_admin', {
            params: { product_id: product_id ?? '', user_id: firebase.user?.uid },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)

          if (!product_id) {
            setProduct({
              category: '',
              subcategory: '',
              title: '',
              brands: '',
              model: '',
              images: [],
              stock: null,
              recommended_by_seller: undefined,
              normal_price: null,
              cost_price: null,
              offer_price: null,
              width: null,
              height: null,
              depth: null,
              weight: null,
              description: '',
              video: '',
              warranty_number: '',
              warranty_unit: '',
            })
          } else {
            setProduct(result.data.product)
          }
          setBrands(result.data.brands)
          setCategories(result.data.categories)

          setPageLoaded(true)
        } catch (err) {
          if (err.code === 'ERR_CANCELED') return
          // console.log(err)
          showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
        }
      })()
    },
    [firebase.user, navigate, product_id, showToast]
  )

  const handleCreate = async () => {
    if (!creating) return

    try {
      setErrors([])
      setLoading(true)

      let params = {}
      for (const section of formSectionsRef.current) {
        const obj = await section.send()
        params = { ...params, ...obj }
      }

      const validation = FormValidate.safeParse(params)

      // console.log(params)

      const errors = []

      if (params.offer_cost_stock > 0 && params.offer_cost_price === 0) {
        errors.push({
          path: ['offer_cost_price'],
          message: 'El precio de costo en oferta no puede ser $0 si hay stock de oferta.',
        })
      }

      if (params.warranty_unit !== 'lifetime' && toNumber(params.warranty_number) < 1) {
        errors.push({
          path: ['warranty_number'],
          message: 'La garantía no puede estar vacía.',
        })
      }

      if (!validation.success) {
        errors.push(...validation.error.errors)
      }

      if (errors.length > 0) {
        setLoading(false)
        // console.log(errors)

        if (errors.length > 1) {
          showToast('Hay errores en el formulario.', 'bad')
          setErrors(errors.map(e => ({ input: e.path.at(-1), text: e.message })))
        } else {
          showToast(errors[0].message, 'bad')
          setErrors([{ input: errors[0].path.at(-1), text: errors[0].message }])
        }
        return
      }

      params = validation.data

      // console.log(params)

      if (params.images) {
        const images = params.images
        const imagesToSend = []

        for (const image of images) {
          if (image.blob) {
            const link = await uploadFile(
              firebase.storage,
              image.blob,
              NewProductImagePath(image.blob.type.split('/')[1])
            )
            imagesToSend.push(link)
          }
        }

        params.images = imagesToSend
      }

      if (params.description) {
        params.description = params.description.replace(/\n/g, '</br>')
      }

      // console.log(params)

      const result = await axios.post(
        process.env.REACT_APP_API_LINK + 'create_product',
        { user_id: firebase.user.uid, fields: params },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      setLoading(false)
      setDone(true)
      showToast('¡Producto creado correctamente!', 'good')

      await delay(2000)

      navigate(`/admin/products`, { replace: true })
    } catch (err) {
      // console.log(err)
      setLoading(false)
      showToast(err.response?.data?.message ?? process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
    }
  }

  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={true} />
      <div className="w-full xl:w-content-max-width mx-auto">
        {pageLoaded ? (
          <div className="w-full px-2 xl:px-60 pb-8">
            <div className="my-8 max-xl:my-4">
              <Breadcrumb
                items={[
                  {
                    name: 'Productos',
                    link: '/admin/products',
                  },
                  {
                    name: product_id ? 'Modificar producto' : 'Nuevo producto',
                  },
                ]}
              />
            </div>
            {!creating && product && (
              <div className="mb-8 xl:mb-12 flex flex-col gap-2">
                <h1 className="xl:text-xl font-medium">{product.title}</h1>
                <span className="text-xs xl:text-sm text-neutral-500">{`#${product.product_id}`}</span>
                <span className="text-xs xl:text-sm text-neutral-500">
                  Creado el {TimestampToDateTextWithTime(product.date_created)}
                </span>
                <span className="text-xs xl:text-sm text-neutral-500">{`${
                  product.sales > 0
                    ? `${product.sales} ${product.sales === 0 ? 'unidad' : 'unidades'} vendidas`
                    : 'Sin ventas'
                }`}</span>
                <Link
                  to={ProductLink(product.product_id, product.title)}
                  className="w-max text-xs xl:text-sm text-amber-500">
                  Ver producto
                </Link>
              </div>
            )}
            <div className="w-full flex flex-col gap-4 xl:gap-6">
              <ProductContext.Provider
                value={{
                  errorsState: [errors, setErrors],
                  loadingState: [loading, setLoading],
                  doneState: [done, setDone],
                  productState: [product, setProduct],
                  reactiveValuesState: [reactiveValues, setReactiveValues],
                  activeSectionState: [activeSection, setActiveSection],
                  creating: creating,
                }}>
                {product && (
                  <>
                    {((index, inputsRef) => {
                      return (
                        <SectionContainer
                          ref={el => (formSectionsRef.current[index] = el)}
                          info={{
                            title: 'Categoría',
                            content: (
                              <div className="w-full flex max-xl:flex-col items-center gap-2 xl:gap-4">
                                <div className="w-full xl:w-0 xl:grow">
                                  <InputLabel text={'Categoría'} error={errors.some(e => e.input === 'category')} />
                                  <DropdownInput
                                    object={product}
                                    name={'category'}
                                    ref={el => (inputsRef.current[0] = el)}
                                    items={categories.map(c => ({
                                      text: c.name,
                                      value: c.category_id,
                                    }))}
                                    onDefaultValueSet={v => setReactiveValues(rv => ({ ...rv, category: v }))}
                                    onChange={v => {
                                      setReactiveValues(rv => ({ ...rv, category: v }))
                                      formSectionsRef.current[index]?.handleInputChange()
                                    }}
                                  />
                                </div>
                                {reactiveValues['category'] && (
                                  <>
                                    <MdOutlineChevronRight className="w-6 h-6 max-xl:-mb-4 xl:mt-4 text-neutral-400 max-xl:rotate-90" />
                                    <div className="w-full xl:w-0 xl:grow">
                                      <InputLabel
                                        text={'Subcategoría'}
                                        error={errors.some(e => e.input === 'subcategory')}
                                      />
                                      <DropdownInput
                                        object={product}
                                        name={'subcategory'}
                                        ref={el => (inputsRef.current[1] = el)}
                                        items={categories
                                          .find(parent => parent.category_id === reactiveValues['category'])
                                          .children.map(child => ({
                                            text: child.name,
                                            value: child.subcategory_id,
                                          }))}
                                        onChange={() => {
                                          formSectionsRef.current[index]?.handleInputChange()
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            ),
                            loading,
                            done,
                            errors: errors.filter(e => ['category', 'subcategory'].includes(e.input)),
                          }}
                          inputsRef={inputsRef}
                        />
                      )
                    })(0, categoriaInputs)}
                    {((index, inputsRef) => {
                      return (
                        <SectionContainer
                          ref={el => (formSectionsRef.current[index] = el)}
                          info={{
                            title: 'Características',
                            subtitle: 'Información principal del producto',
                            content: (
                              <div className="w-full flex flex-col gap-4">
                                <div>
                                  <InputLabel text={'Título'} error={errors.some(e => e.input === 'title')} />
                                  <TextInput
                                    object={product}
                                    error={errors.some(e => e.input === 'title')}
                                    name="title"
                                    ref={el => (inputsRef.current[0] = el)}
                                    onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                    format={v => CapitalizeAllWords(v)}
                                    squared={true}
                                    maxLength={100}
                                    showCount={true}
                                  />
                                </div>
                                <div className="flex max-xl:gap-4">
                                  <div className="w-0 grow xl:pr-40">
                                    <InputLabel text={'Marca'} error={errors.some(e => e.input === 'brand')} />
                                    <DropdownInput
                                      object={product}
                                      name={'brand'}
                                      ref={el => (inputsRef.current[1] = el)}
                                      items={brands
                                        .sort((a, b) => a.name > b.name)
                                        .map(b => ({
                                          text: b.name,
                                          value: b.brand_id,
                                        }))}
                                      onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                    />
                                  </div>
                                  <div className="w-0 grow xl:pr-40">
                                    <InputLabel text={'Modelo'} error={errors.some(e => e.input === 'model')} />
                                    <TextInput
                                      object={product}
                                      error={errors.some(e => e.input === 'model')}
                                      name="model"
                                      ref={el => (inputsRef.current[2] = el)}
                                      onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                      format={v => v.toUpperCase()}
                                      maxLength={15}
                                      showCount={true}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <InputLabel text={'Imágenes'} error={errors.some(e => e.input === 'images')} />
                                  <ImageDnD
                                    ref={el => (inputsRef.current[3] = el)}
                                    error={errors.some(e => e.input === 'images')}
                                    object={product}
                                    name="images"
                                    onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                    showOnFirst={
                                      <span
                                        className="absolute bottom-0 left-1/2 -translate-x-1/2 px-2 py-1 text-[0.6rem]
                                                  bg-white bg-opacity-70 hyphens-none">
                                        PORTADA
                                      </span>
                                    }
                                    maxLength={5}
                                    showCount={true}
                                  />
                                </div>
                                <div className="flex">
                                  <div className="w-0 grow">
                                    <InputLabel text={'Stock'} error={errors.some(e => e.input === 'stock')} />
                                    <div className="pr-12 xl:pr-60">
                                      <TextInput
                                        object={product}
                                        error={errors.some(e => e.input === 'stock')}
                                        name="stock"
                                        ref={el => (inputsRef.current[4] = el)}
                                        onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                        maxLength={6}
                                        type="number"
                                      />
                                    </div>
                                  </div>
                                  <div className="w-0 grow">
                                    <InputLabel
                                      text={'Stock de oferta'}
                                      error={errors.some(e => e.input === 'offer_cost_stock')}
                                    />
                                    <div className="pr-12 xl:pr-60">
                                      <TextInput
                                        object={product}
                                        error={errors.some(e => e.input === 'offer_cost_stock')}
                                        name="offer_cost_stock"
                                        ref={el => (inputsRef.current[5] = el)}
                                        onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                        maxLength={6}
                                        type="number"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="xl:w-60">
                                  <InputLabel text={'UPC'} error={errors.some(e => e.input === 'upc')} />
                                  <TextInput
                                    object={product}
                                    error={errors.some(e => e.input === 'upc')}
                                    name="upc"
                                    ref={el => (inputsRef.current[7] = el)}
                                    onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                    unformat={v => OnlyNumbers(v)}
                                    maxLength={13}
                                    showCount={true}
                                  />
                                </div>
                                <div className="mt-2 flex items-center">
                                  <ToggleInput
                                    object={product}
                                    name="recommended_by_seller"
                                    ref={el => (inputsRef.current[6] = el)}
                                    onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                  />
                                  <InputLabel
                                    text="Recomendado por Universal Tools"
                                    error={errors.some(e => e.input === 'recommended_by_seller')}
                                  />
                                </div>
                              </div>
                            ),
                            loading,
                            done,
                            errors: errors.filter(e =>
                              [
                                'title',
                                'brand',
                                'model',
                                'images',
                                'stock',
                                'offer_cost_stock',
                                'recommended_by_seller',
                              ].includes(e.input)
                            ),
                          }}
                          inputsRef={inputsRef}
                        />
                      )
                    })(1, caracteristicasInputs)}
                    {((index, inputsRef) => {
                      return (
                        <SectionContainer
                          ref={el => (formSectionsRef.current[index] = el)}
                          info={{
                            title: 'Precio',
                            content: (
                              <div className="w-full flex flex-col gap-4">
                                <div className="flex">
                                  <div className="w-0 grow">
                                    <InputLabel
                                      text={'Precio normal'}
                                      error={errors.some(e => e.input === 'normal_price')}
                                    />
                                    <div className="pr-12 xl:pr-40">
                                      <TextInput
                                        object={product}
                                        error={errors.some(e => e.input === 'normal_price')}
                                        name="normal_price"
                                        ref={el => (inputsRef.current[0] = el)}
                                        onDefaultValueSet={v => setReactiveValues(rv => ({ ...rv, normal_price: v }))}
                                        onChange={v => {
                                          setReactiveValues(rv => ({ ...rv, normal_price: v }))
                                          formSectionsRef.current[index]?.handleInputChange()
                                        }}
                                        maxLength={13}
                                        type="money"
                                        decimal={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="w-0 grow">
                                    <InputLabel
                                      text={'Precio de oferta'}
                                      error={errors.some(e => e.input === 'offer_price')}
                                    />
                                    <div className="pr-12 xl:pr-40">
                                      <TextInput
                                        object={product}
                                        error={errors.some(e => e.input === 'offer_price')}
                                        name="offer_price"
                                        ref={el => (inputsRef.current[1] = el)}
                                        onDefaultValueSet={v => setReactiveValues(rv => ({ ...rv, offer_price: v }))}
                                        onChange={v => {
                                          setReactiveValues(rv => ({ ...rv, offer_price: v }))
                                          formSectionsRef.current[index]?.handleInputChange()
                                        }}
                                        maxLength={13}
                                        type="money"
                                        decimal={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-0 grow">
                                    <InputLabel
                                      text={'Precio costo'}
                                      error={errors.some(e => e.input === 'cost_price')}
                                    />
                                    <div className="pr-12 xl:pr-40">
                                      <TextInput
                                        object={product}
                                        error={errors.some(e => e.input === 'cost_price')}
                                        name="cost_price"
                                        ref={el => (inputsRef.current[2] = el)}
                                        onDefaultValueSet={v => setReactiveValues(rv => ({ ...rv, cost_price: v }))}
                                        onChange={v => {
                                          setReactiveValues(rv => ({ ...rv, cost_price: v }))
                                          formSectionsRef.current[index]?.handleInputChange()
                                        }}
                                        maxLength={13}
                                        type="money"
                                        decimal={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="w-0 grow">
                                    <InputLabel
                                      text={'Precio costo en oferta'}
                                      error={errors.some(e => e.input === 'offer_cost_price')}
                                    />
                                    <div className="pr-12 xl:pr-40">
                                      <TextInput
                                        object={product}
                                        error={errors.some(e => e.input === 'offer_cost_price')}
                                        name="offer_cost_price"
                                        ref={el => (inputsRef.current[3] = el)}
                                        onDefaultValueSet={v =>
                                          setReactiveValues(rv => ({ ...rv, offer_cost_price: v }))
                                        }
                                        onChange={v => {
                                          setReactiveValues(rv => ({ ...rv, offer_cost_price: v }))
                                          formSectionsRef.current[index]?.handleInputChange()
                                        }}
                                        maxLength={13}
                                        type="money"
                                        decimal={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-full xl:w-0 xl:grow-[0.3]">
                                    <InputLabel
                                      text={'Alicuota de IVA'}
                                      error={errors.some(e => e.input === 'iva_aliquot')}
                                    />
                                    <DropdownInput
                                      object={product}
                                      name={'iva_aliquot'}
                                      ref={el => (inputsRef.current[4] = el)}
                                      items={[
                                        {
                                          text: '21%',
                                          value: 0.21,
                                        },
                                        {
                                          text: '27%',
                                          value: 0.27,
                                        },
                                        {
                                          text: '10.5%',
                                          value: 0.105,
                                        },
                                        {
                                          text: '5%',
                                          value: 0.05,
                                        },
                                        {
                                          text: '2.5%',
                                          value: 0.025,
                                        },
                                        {
                                          text: '0%',
                                          value: 0,
                                        },
                                      ]}
                                      onDefaultValueSet={v => setReactiveValues(rv => ({ ...rv, iva_aliquot: v }))}
                                      onChange={v => {
                                        setReactiveValues(rv => ({ ...rv, iva_aliquot: v }))
                                        formSectionsRef.current[index]?.handleInputChange()
                                      }}
                                    />
                                  </div>
                                </div>
                                <p className="max-xl:text-sm">
                                  Ganancia normal:{' '}
                                  {FormatPrice(
                                    round(
                                      (+reactiveValues.offer_price || +reactiveValues.normal_price) -
                                        +reactiveValues.cost_price,
                                      2
                                    )
                                  )}
                                </p>
                                <p className="max-xl:text-sm">
                                  Ganancia costo en oferta:{' '}
                                  {FormatPrice(
                                    +reactiveValues.offer_cost_price > 0
                                      ? round(
                                          +(reactiveValues.offer_price || reactiveValues.normal_price) -
                                            +reactiveValues.offer_cost_price,
                                          2
                                        )
                                      : 0
                                  )}
                                </p>
                              </div>
                            ),
                            loading,
                            done,
                            errors: errors.filter(e =>
                              ['normal_price', 'offer_price', 'cost_price', 'offer_cost_price', 'iva_aliquot'].includes(
                                e.input
                              )
                            ),
                          }}
                          inputsRef={inputsRef}
                        />
                      )
                    })(2, precioInputs)}
                    {((index, inputsRef) => {
                      return (
                        <SectionContainer
                          ref={el => (formSectionsRef.current[index] = el)}
                          info={{
                            title: 'Dimensiones',
                            subtitle: 'Se utilizarán para los envíos',
                            content: (
                              <div className="w-full flex flex-col gap-4">
                                <div className="flex">
                                  <div className="w-0 grow">
                                    <InputLabel text={'Ancho (cm)'} error={errors.some(e => e.input === 'width')} />
                                    <div className="pr-12 xl:pr-40">
                                      <TextInput
                                        object={product}
                                        error={errors.some(e => e.input === 'width')}
                                        name="width"
                                        ref={el => (inputsRef.current[0] = el)}
                                        onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                        maxLength={4}
                                        type="number"
                                      />
                                    </div>
                                  </div>
                                  <div className="w-0 grow">
                                    <InputLabel text={'Alto (cm)'} error={errors.some(e => e.input === 'height')} />
                                    <div className="pr-12 xl:pr-40">
                                      <TextInput
                                        object={product}
                                        error={errors.some(e => e.input === 'height')}
                                        name="height"
                                        ref={el => (inputsRef.current[1] = el)}
                                        onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                        maxLength={4}
                                        type="number"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-0 grow">
                                    <InputLabel text={'Largo (cm)'} error={errors.some(e => e.input === 'depth')} />
                                    <div className="pr-12 xl:pr-40">
                                      <TextInput
                                        object={product}
                                        error={errors.some(e => e.input === 'depth')}
                                        name="depth"
                                        ref={el => (inputsRef.current[2] = el)}
                                        onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                        maxLength={4}
                                        type="number"
                                      />
                                    </div>
                                  </div>
                                  <div className="w-0 grow">
                                    <InputLabel text={'Peso (kg)'} error={errors.some(e => e.input === 'weight')} />
                                    <div className="pr-12 xl:pr-40">
                                      <TextInput
                                        object={product}
                                        error={errors.some(e => e.input === 'weight')}
                                        name="weight"
                                        ref={el => (inputsRef.current[3] = el)}
                                        onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                        maxLength={4}
                                        type="number"
                                        decimal={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ),
                            loading,
                            done,
                            errors: errors.filter(e => ['width', 'height', 'depth', 'weight'].includes(e.input)),
                          }}
                          inputsRef={inputsRef}
                        />
                      )
                    })(3, dimensionesInputs)}
                    {((index, inputsRef) => {
                      return (
                        <SectionContainer
                          ref={el => (formSectionsRef.current[index] = el)}
                          info={{
                            title: 'Descripción',
                            subtitle: product['description'].replaceAll('</br>', ' '),
                            content: (
                              <div className="w-full flex flex-col">
                                <InputLabel text={'Descripción'} error={errors.some(e => e.input === 'description')} />
                                <TextInput
                                  object={product}
                                  Element={'textarea'}
                                  error={errors.some(e => e.input === 'description')}
                                  name="description"
                                  squared={true}
                                  ref={el => (inputsRef.current[0] = el)}
                                  onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                  format={v => v.replace(/<\/br>/g, '\n')}
                                  unformat={v => v.replace(/<\/br>/g, '\n')}
                                  maxLength={5000}
                                  showCount={true}
                                />
                              </div>
                            ),
                            optional: true,
                            loading,
                            done,
                            errors: errors.filter(e => ['description'].includes(e.input)),
                          }}
                          inputsRef={inputsRef}
                        />
                      )
                    })(4, descripcionInputs)}
                    {((index, inputsRef) => {
                      return (
                        <SectionContainer
                          ref={el => (formSectionsRef.current[index] = el)}
                          info={{
                            title: 'Video',
                            subtitle: 'Mostrá un video sobre el producto',
                            content: (
                              <div className="w-full flex flex-col">
                                <InputLabel text={'URL de YouTube'} error={errors.some(e => e.input === 'video')} />
                                <TextInput
                                  object={product}
                                  error={errors.some(e => e.input === 'video')}
                                  name="video"
                                  ref={el => (inputsRef.current[0] = el)}
                                  onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                  maxLength={2000}
                                  placeholder={'https://www.youtube.com/?v=... o https://youtu.be/...'}
                                  isVideo={true}
                                />
                              </div>
                            ),
                            optional: true,
                            loading,
                            done,
                            errors: errors.filter(e => ['video'].includes(e.input)),
                          }}
                          inputsRef={inputsRef}
                        />
                      )
                    })(5, videoInputs)}
                    {((index, inputsRef) => {
                      return (
                        <SectionContainer
                          ref={el => (formSectionsRef.current[index] = el)}
                          info={{
                            title: 'Garantía',
                            subtitle: '¿Cuánto tiempo de garantía tiene el producto?',
                            content: (
                              <div className="w-full flex gap-6">
                                <div className="w-28 max-xl:flex-1">
                                  <TextInput
                                    object={product}
                                    error={errors.some(e => e.input === 'warranty_number')}
                                    name="warranty_number"
                                    ref={el => (inputsRef.current[0] = el)}
                                    onChange={() => formSectionsRef.current[index]?.handleInputChange()}
                                    maxLength={2}
                                    type="number"
                                    disabled={reactiveValues['warranty_unit'] === 'lifetime'}
                                  />
                                </div>
                                <div className="w-40 max-xl:flex-1">
                                  <DropdownInput
                                    object={product}
                                    ref={el => (inputsRef.current[1] = el)}
                                    name="warranty_unit"
                                    items={[
                                      {
                                        text: 'Días',
                                        value: 'días',
                                      },
                                      {
                                        text: 'Meses',
                                        value: 'meses',
                                      },
                                      {
                                        text: 'Años',
                                        value: 'años',
                                      },
                                      {
                                        text: 'De por vida',
                                        value: 'lifetime',
                                      },
                                    ]}
                                    defaultValue={product['warranty_unit'] || 'años'}
                                    onDefaultValueSet={v => setReactiveValues(rv => ({ ...rv, warranty_unit: v }))}
                                    onChange={v => {
                                      setReactiveValues(rv => ({ ...rv, warranty_unit: v }))
                                      formSectionsRef.current[index]?.handleInputChange()
                                    }}
                                  />
                                </div>
                              </div>
                            ),
                            loading,
                            done,
                            errors: errors.filter(e => ['warranty_number', 'warranty_unit'].includes(e.input)),
                          }}
                          inputsRef={inputsRef}
                        />
                      )
                    })(6, garantiaInputs)}
                  </>
                )}
              </ProductContext.Provider>
              {creating && (
                <div className="flex justify-end">
                  <div className="h-12">
                    <ButtonWithProgress
                      text={'Crear producto'}
                      clickable={true}
                      loading={loading}
                      done={done}
                      onClick={handleCreate}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="w-full xl:px-60 pb-8">
            <LoadingBox text={product_id ? 'Cargando producto...' : ''} />
          </div>
        )}
      </div>
    </div>
  )
}

const SectionContainer = forwardRef(({ info, inputsRef }, ref) => {
  const { firebase, showToast } = useMainContext()
  const creating = useProductContext().creating
  const [product, setProduct] = useProductContext().productState
  const [activeSection, setActiveSection] = useProductContext().activeSectionState
  const [, setErrors] = useProductContext().errorsState
  const [, setLoading] = useProductContext().loadingState
  const [, setDone] = useProductContext().doneState

  const [allowActions, setAllowActions] = useState(false)

  useImperativeHandle(ref, () => ({
    send: async () => await handleSend(),
    handleInputChange: () => {
      // setErrors([])
      setAllowActions(true)
      setActiveSection(info.title)
    },
  }))

  const handleCancel = () => {
    inputsRef.current.forEach(r => r.reset())
    setErrors([])
    setAllowActions(false)
    setActiveSection('')
  }

  const handleSend = async () => {
    let params = inputsToObj(inputsRef, creating)

    if (creating) return params
    else if (!isEqual(params, {})) {
      try {
        setLoading(true)
        setErrors([])

        // console.log(params)

        const validation = FormValidate.safeParse({
          ...params,
          ...('images' in params ? { images: params.images.filter(image => !image.remove) } : {}),
        })

        const errors = []

        if (
          (params.offer_cost_stock ?? product.offer_cost_stock) > 0 &&
          (params.offer_cost_price ?? product.offer_cost_price) === 0
        ) {
          errors.push({
            path: ['offer_cost_price'],
            message: 'El precio de costo en oferta no puede ser $0 si hay stock de oferta.',
          })
        }

        const warranty_number = params.warranty_number ?? product.warranty_number
        const warranty_unit = params.warranty_unit ?? product.warranty_unit

        if (warranty_unit !== 'lifetime') {
          if (toNumber(warranty_number) < 1) {
            errors.push({
              path: ['warranty_number'],
              message: 'La garantía no puede estar vacía.',
            })
          }
        } else {
          params.warranty_number = ''
        }

        if (!validation.success) {
          errors.push(...validation.error.errors)
        }

        if (errors.length > 0) {
          // console.log(errors)
          setLoading(false)
          if (errors.length > 1) {
            showToast('Hay errores en el formulario.', 'bad')
            setErrors(errors.map(e => ({ input: e.path.at(-1), text: e.message })))
          } else {
            showToast(errors[0].message, 'bad')
            setErrors([{ input: errors[0].path.at(-1), text: errors[0].message }])
          }
          return
        }

        params = validation.data

        if (params.images) {
          const images = params.images
          const imagesToSend = []

          for (const image of images) {
            if (image.blob) {
              // Si el objeto 'image' tiene la key 'blob', significa que es una imágen agregada y se sube a Firebase
              let tryCount = 0
              let newPath = NewProductImagePath(image.blob.type.split('/')[1])
              while (await fileExists(firebase.storage, newPath)) {
                // Si existe un archivo con el path pasado, creo un nuevo path y lo intento de nuevo
                newPath = NewProductImagePath(image.blob.type.split('/')[1])

                tryCount++
                if (tryCount >= 10) {
                  setErrors({ input: 'images', text: 'Error al subir una o más fotos' })
                  return
                }
              }

              const link = await uploadFile(firebase.storage, image.blob, newPath)
              // console.log(`Nueva imagen: ${link}`)

              imagesToSend.push(link)
            } else if (image.remove) {
              // Si el objeto 'image' tiene la key 'remove', significa que es una imágen borrada y se borra de Firebase
              let path = image.src.includes('universal-tools-web.appspot.com')
                ? image.src.split('?')[0].split('universal-tools-web.appspot.com')[1].split('/').at(-1)
                : undefined
              if (path) {
                // Si path no es 'undefined', significa que es una imagen subida a Firebase anteriormente
                path = decodeURIComponent(path)
                await deleteFile(firebase.storage, path)
                // console.log(`Se borró imagen: ${path}`)
              }
              // Si la imagen no tiene un 'path' que nos interese, ignoramos la imagen y no se agrega al array
            } else {
              // Si no tiene ninguno, simplemente se agrega al array
              imagesToSend.push(image.src)
            }
          }

          params.images = imagesToSend
        }

        if (params.description) {
          params.description = params.description.replace(/\n/g, '</br>')
        }

        // console.log(params)

        const result = await axios.put(
          process.env.REACT_APP_API_LINK + 'update_product',
          {
            user_id: firebase.user?.uid,
            product_id: product.product_id,
            action: 'update',
            fields: params,
          },
          { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
        )
        // console.log(result)

        setLoading(false)
        setDone(true)

        setProduct({ ...product, ...params })
        showToast('¡Listo! Los cambios estan guardados.', 'good')

        await delay(1000)

        setDone(false)
        setAllowActions(false)
        setActiveSection('')
      } catch (err) {
        if (err.code === 'ERR_CANCELED') return
        // console.log(err)
        showToast(err.response?.data?.message ?? process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
        setLoading(false)
        setErrors([{ input: 'unkn', text: 'Ocurrió un error desconocido.' }])
      }
    } else {
      setLoading(false)
      setDone(false)
      setAllowActions(false)
      setActiveSection('')
    }
  }

  return (
    <Section
      info={{
        ...info,
        allowActions,
        handleCancel,
        handleSend,
        modifiable: creating || activeSection === '' || activeSection === info.title,
      }}
      creating={creating}
    />
  )
})

export default AdminProduct

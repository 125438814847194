import { BsDash } from 'react-icons/bs'
import { MdDone } from 'react-icons/md'

function Toggle({ toggled, onClick, done = true, clickable = true, size = 'sm' }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${
        size === 'sm' ? 'w-4 h-4 rounded-[0.2rem]' : size === 'lg' ? 'w-6 h-6 rounded-md' : 'w-full h-full rounded-md'
      } ${
        toggled
          ? 'bg-dewalt'
          : clickable
          ? 'bg-white border-2 border-gray-300 hover:border-gray-400'
          : 'bg-white border-2 border-gray-200'
      } transition-colors`}
      disabled={!clickable}>
      {toggled &&
        (done ? (
          <MdDone className="w-full h-full p-[1px] text-white" />
        ) : (
          <BsDash className="w-full h-full text-white" />
        ))}
    </button>
  )
}

export default Toggle

import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { setDocumentTitle } from '../utils/Html'
import { FormatPrice, PickUpAddressToURL } from '../utils/Strings'
import { RoundedButton } from '../Footer'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { FiShoppingCart } from 'react-icons/fi'
import { MdDeleteOutline } from 'react-icons/md'
import Accordion from '../utils/Accordion'

const pages = [
  {
    link: 'guia-de-compra',
    text: 'Guía de compra',
  },
  {
    link: 'quienes-somos',
    text: '¿Quiénes somos?',
  },
  {
    link: 'preguntas-frecuentes',
    text: 'Preguntas frecuentes',
  },
  {
    link: 'terminos-y-condiciones',
    text: 'Terminos y condiciones',
  },
  {
    link: 'medios-de-pago',
    text: 'Medios de pago',
  },
  {
    link: 'contacto',
    text: 'Contacto',
  },
]

function Help() {
  const { page } = useParams()
  const navigate = useNavigate()

  const pagesRef = useRef(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_footer', {
          headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY },
        })
        setData(result.data)
      } catch (err) {
        if (err.code === 'ERR_CANCELED') return
        // console.log(err)
      }
    })()
  }, [])

  useEffect(() => {
    if (!pages.some(item => item.link === page)) {
      navigate('/', { replace: true })
      return
    }

    setDocumentTitle(pages.find(item => item.link === page).text)
  }, [page, navigate])

  return (
    <div className="bg-white xl:bg-pagebg grow text-black">
      <div className="w-full xl:w-content-max-width xl:mx-auto p-4">
        <div className="xl:p-4 xl:px-12 xl:py-8 flex max-xl:flex-col gap-4">
          <div className="w-full xl:w-72 p-6 bg-white rounded-md flex flex-col shadow-lg">
            <p className="pb-4 xl:pb-8 text-lg xl:text-xl font-medium max-xl:text-center">Ayuda</p>
            <div ref={pagesRef} className="flex max-xl:py-2 gap-4 xl:gap-6 xl:flex-col max-xl:overflow-x-auto">
              {pages.map(item => (
                <Link
                  key={item.link}
                  to={`/help/${item.link}`}
                  className={`min-w-max text-lg ${
                    page === item.link ? 'font-semibold' : 'font-light hover:text-amber-600 transition-colors'
                  }`}
                  onClick={ev => {
                    const rect = ev.currentTarget.getBoundingClientRect()
                    let scrollLeft =
                      rect.left + pagesRef.current.scrollLeft - (pagesRef.current.clientWidth - rect.width) / 2
                    pagesRef.current.scrollTo({
                      left: scrollLeft,
                      behaviour: 'smooth',
                    })
                  }}>
                  {item.text}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex-1 p-4 bg-white rounded-md xl:shadow-md flex flex-col">
            <h1 className="w-max mx-auto pb-6 text-2xl font-medium">{pages.find(item => item.link === page)?.text}</h1>
            {page === 'guia-de-compra' ? (
              <div className="w-full pb-8 flex max-xl:flex-col gap-2">
                <div className="flex-1 relative">
                  <div className="w-full h-72 xl:h-60 mb-6 relative flex justify-center">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/universal-tools-web.appspot.com/o/screen%201.png?alt=media&token=d85bb109-6e09-4aa0-8f35-42d05e2e288b"
                      className="w-full max-w-[400px] h-full pr-5 object-left-top"
                      alt=""
                    />
                    <span className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2 p-5 bg-black rounded-full">
                      <span className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-white font-bold text-xl">
                        1
                      </span>
                    </span>
                  </div>
                  <p className="text-center text-lg">Elegí el producto</p>
                  <p className="text-center mt-2 hyphens-none font-light">
                    Realizá tu busqueda e ingresa al producto que te interese para saber más o agregalo directamente a
                    tu carrito.
                  </p>
                </div>
                <div className="flex-1 relative">
                  <div className="w-full h-72 xl:h-60 mb-6 relative flex justify-center">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/universal-tools-web.appspot.com/o/screen%202.png?alt=media&token=0c7b02d1-b843-40b1-aec8-1ca2263884e8"
                      className="w-full max-w-[400px] h-full pr-5 object-left-top"
                      alt=""
                    />
                    <span className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2 p-5 bg-black rounded-full">
                      <span className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-white font-bold text-xl">
                        2
                      </span>
                    </span>
                  </div>
                  <p className="text-center text-lg">Revisá tu carrito</p>
                  <p className="text-center mt-2 hyphens-none font-light">
                    Cuando hayas agregado todos tus productos al carrito, entra a "Ver&nbsp;carrito" para seleccionar la
                    cantidad y conseguir envío gratis.
                  </p>
                </div>
                <div className="flex-1 relative">
                  <div className="w-full h-72 xl:h-60 mb-6 relative flex justify-center">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/universal-tools-web.appspot.com/o/screen%203.png?alt=media&token=15922c4f-2fca-401b-8e07-752c931a31f8"
                      className="w-full max-w-[400px] h-full pr-5 object-left-top"
                      alt=""
                    />
                    <span className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2 p-5 bg-black rounded-full">
                      <span className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-white font-bold text-xl">
                        3
                      </span>
                    </span>
                  </div>
                  <p className="text-center text-lg">Terminá tu compra</p>
                  <p className="text-center mt-2 hyphens-none font-light">
                    Finalmente vas a elegir de que manera queres recibir los productos, como pagás y luego de revisar
                    todo, ¡ya está realizado tu pedido!
                  </p>
                </div>
              </div>
            ) : page === 'quienes-somos' ? (
              <div className="flex flex-col gap-2 hyphens-none">
                <p className="text-lg font-medium">
                  Bienvenido a <span className="font-bold">Universal Tools</span>, tu aliado en herramientas de calidad
                  para el hogar y la industria.
                </p>

                <p>
                  Hace dos años, nació <span className="font-medium">Universal Tools</span> con la misión de
                  proporcionar herramientas domésticas e industriales de la más alta calidad. No somos una ferretería
                  común y corriente; nos destacamos al ofrecer productos excepcionales para ayudarte a alcanzar tus
                  metas laborales o realizar proyectos en tu hogar.
                </p>

                <span className="mt-4 text-lg font-medium">Nuestra Misión</span>
                <p>
                  Facilitar tus tareas diarias, ya sea en el hogar o en el trabajo, proporcionándote las herramientas
                  adecuadas. En <span className="font-medium">Universal Tools</span>, queremos ser tu socio confiable en
                  cada proyecto, brindándote soluciones para reparaciones, mejoras y cambios.
                </p>

                <span className="mt-4 text-lg font-medium">Compromiso con la Calidad</span>
                <p>
                  En <span className="font-medium">Universal Tools</span>, nos enorgullece ofrecer herramientas de
                  ferretería industriales de las mejores marcas del mercado. Nuestro compromiso con la calidad significa
                  que siempre obtendrás productos superiores, porque entendemos que las herramientas adecuadas hacen la
                  diferencia en cada tarea.
                </p>

                <span className="mt-4 text-lg font-medium">Equipo Universal Tools</span>
                <p>
                  Aunque somos una empresa pequeña, nuestro equipo de dos personas está dedicado a brindarte un servicio
                  personalizado y experto. En <span className="font-medium">Universal Tools</span>, nos apasiona
                  proporcionarte asesoramiento especializado para que encuentres las herramientas perfectas para tus
                  necesidades.
                </p>

                <p className="mt-4">
                  Descubre la diferencia de trabajar con <span className="font-medium">Universal Tools</span>, donde no
                  solo vendemos herramientas, sino que ofrecemos soluciones que potencian tu capacidad para crear y
                  construir. ¡Bienvenido a la experiencia <span className="font-medium">Universal Tools</span>!
                </p>
              </div>
            ) : page === 'preguntas-frecuentes' && data ? (
              <div className="flex flex-col gap-2">
                <p className="text-lg font-medium">Comprar en Universal Tools</p>
                <Accordion
                  items={[
                    {
                      title: '¿Cómo es el proceso de compra?',
                      content: (
                        <>
                          <ol className="pl-4 flex flex-col gap-4 hyphens-none">
                            <li className="list-decimal">
                              Ingresá a{' '}
                              <a
                                href="https://universaltools.com.ar"
                                className="inline font-medium hover:text-amber-500 transition-colors">
                                universaltools.com.ar
                              </a>
                            </li>
                            <li className="list-decimal">
                              Te recomendamos que crees tu usuario, podés registrarte con e-mail y contraseña o con tu
                              cuenta de Google.
                            </li>
                            <li className="list-decimal">
                              Utilizá nuestro buscador para encontrar lo que buscás o ingresá a ver las categorías.
                            </li>
                            <li className="list-decimal">
                              Cuando encuentres el producto que buscabas, tocá en "Agregar al carrito" para sumarlo a tu
                              carrito de compras. Si queres sumar más unidades del mismo producto podes hacerlo con el
                              "+" o escribí la cantidad que quieras.
                            </li>
                            <li className="list-decimal">Repetí los pasos 3 y 4 si queres comprar más productos.</li>
                            <li className="list-decimal">
                              Cuando ya hayas armado tu carrito de compras, tocá el ícono
                              <FiShoppingCart className="inline-block w-4 h-4 mx-1" /> de la barra superior, tocá en
                              "Ver carrito" y verificá que las cantidades sean correctas. Si hay algo que quieras
                              modificar podés hacerlo ahí mismo, tanto sumar como restar o eliminar productos del
                              carrito. Además, vas a poder ver si tu carrito suma la cantidad suficiente para conseguir{' '}
                              <strong>Envío&nbsp;gratis</strong>. Para avanzar, toca en "Iniciar compra".
                            </li>
                            <li className="list-decimal">
                              Elegí de que manera querés recibir tu pedido. Te puede llegar a tu domicilio por correo o
                              por mensajería privada si aplica tu dirección. Luego, elegí de que manera querés pagar. Si
                              elegís MercadoPago, la compra tiene un recargo sino es el mismo precio tanto por
                              Transferencia como por Efectivo. Por ultimo, revisá si lo que seleccionaste esta bien y
                              persioná en "Confirmar pedido"
                            </li>
                            <li className="list-decimal">
                              ¡Listo!, ya hiciste tu pedido en Universal Tools. Entrá a tu pedido para ver el estado
                              actual, además vas a encontrar un número de contacto por cualquier duda o consulta.
                            </li>
                          </ol>
                        </>
                      ),
                    },
                    {
                      title: '¿Cómo busco el producto que quiero comprar?',
                      content: (
                        <>
                          <p>
                            En la barra superior, hay una barra de busqueda que podés utilizar para encontrar el
                            producto que buscas. Tambíen podés usar los filtros para encontrarlo más rápido en tu
                            búsqueda.
                          </p>
                        </>
                      ),
                    },
                    {
                      title: '¿Cómo elimino un producto del carrito?',
                      content: (
                        <>
                          <p>
                            Si querés eliminar un producto que agregaste al carrito, tocá en el ícono{' '}
                            <FiShoppingCart className="inline-block w-4 h-4 mx-1" /> de la barra superior, buscá el
                            producto que querés eliminar y tocá en el ícono{' '}
                            <MdDeleteOutline className="inline-block w-4 h-4 mx-1" /> ubicado en la esquina superior
                            derecha.
                          </p>
                        </>
                      ),
                    },
                    {
                      title: '¿Hay un monto mínimo de compra?',
                      content: (
                        <>
                          <p>No tenemos un monto mínimo de compra.</p>
                        </>
                      ),
                    },
                    {
                      title: '¿Cómo consigo el envío gratis?',
                      content: (
                        <>
                          <p>
                            Para poder conseguir el beneficio del envío gratis, el valor total de tu carrito (sin contar
                            el envío ni el recargo por MercadoPago) tiene que ser superior a{' '}
                            {FormatPrice(data.free_shipping_minimum)}.
                          </p>
                        </>
                      ),
                    },
                    {
                      title: '¿Necesito registrarme para realizar un pedido?',
                      content: (
                        <>
                          <p>
                            Si, para realizar un pedido es necesario que te registres ya que utilizamos los datos que
                            nos informas para el envío y/o para la facturación.
                          </p>
                        </>
                      ),
                    },
                  ]}
                />
                <p className="mt-4 text-lg font-medium">UT Coins</p>
                <Accordion
                  items={[
                    {
                      title: '¿Qué son los UT Coins?',
                      content: (
                        <>
                          <p>
                            Es un beneficio que brinda <strong>UNIVERSAL&nbsp;TOOLS</strong> a sus clientes, premiando
                            sus compras con saldo digital que <strong>cotiza a valor del Dólar&nbsp;Blue</strong>. Esta
                            moneda se puede utilizar en <strong>descuentos&nbsp;parciales</strong> sobre cualquier
                            producto que se venda en la plataforma web.
                          </p>
                        </>
                      ),
                    },
                    {
                      title: '¿Cómo los recibo?',
                      content: (
                        <>
                          <p>
                            Los <strong>UT&nbsp;Coins</strong> se entregan al concretar una compra. Cada producto y/o
                            pedido especifica cuantos <strong>UT&nbsp;Coins</strong> otorga. La cantidad de{' '}
                            <strong>UT&nbsp;Coins</strong> que brinda cada producto puede variar sin previo aviso,
                            pudiendo en algún momento no otorgar el beneficio.
                          </p>
                        </>
                      ),
                    },
                    {
                      title: '¿Cómo se calculan los UT Coins?',
                      content: (
                        <>
                          <p>
                            El valor de los <strong>UT&nbsp;Coins</strong> se calcula a una relación de{' '}
                            <strong>10&nbsp;UT&nbsp;Coins&nbsp;=&nbsp;1&nbsp;Dólar&nbsp;Blue</strong>. Al momento de
                            utilizar los <strong>UT&nbsp;Coins</strong> para un descuento en el pedido, se realiza el
                            cambio a <strong>Pesos&nbsp;Argentinos</strong>, tomando como cotización la cotización del
                            momento.
                          </p>
                        </>
                      ),
                    },
                  ]}
                />
                <p className="mt-4 text-lg font-medium">Precios y métodos de pago</p>
                <Accordion
                  items={[
                    {
                      title: '¿Cómo puedo pagar?',
                      content: (
                        <div className="flex flex-col gap-3">
                          <p>
                            Los precios están expresados en Pesos argentinos ($), incluyen IVA y están sujetos a
                            modificación sin previo aviso.
                          </p>
                          <p>Tenés 3 formas para pagar:</p>
                          <ul className="pl-4 flex flex-col gap-3">
                            <li className="list-disc">
                              Efectivo: Podés abonar en efectivo cuando elegís "Retiro en persona", o si elegís "Envío
                              por mensajería privada". En los otros casos, tenés la posibilidad de pagar en efectivo por
                              RapiPago o PagoFácil.
                            </li>
                            <li className="list-disc">
                              Dólar físico: Si compraste con opción "Efectivo en persona" tenés la posibilidad de abonar
                              con dólar físico a la cotización del Dólar Blue al momento del pago.
                            </li>
                            <li className="list-disc">Transferencia bancaria</li>
                            <li className="list-disc">
                              MercadoPago: Si lo deseás, podés pagar por MercadoPago mediante tarjeta de crédito,
                              tarjeta de débito, dinero en cuenta o Mercado Crédito.
                              <a
                                href={'https://www.mercadopago.com.ar/ayuda/264'}
                                target={'_blank'}
                                rel="noreferrer"
                                className="font-medium hover:text-amber-500 transition-colors">
                                Ver medios de pago de MercadoPago
                              </a>
                            </li>
                          </ul>
                        </div>
                      ),
                    },
                    {
                      title: '¿Cómo encuentro las ofertas?',
                      content: (
                        <>
                          <p>
                            Si estás en una computadora, en la barra superior hay un botón rojo de "Ofertas". Si estás
                            en un celular o tablet, en la barra superior tocá en las 3 rayas y vas a encontrar también
                            la opción "Ofertas".
                          </p>
                        </>
                      ),
                    },
                    {
                      title: '¿Puedo pagar en cuotas?',
                      content: (
                        <>
                          <p>
                            Si, al elegir "MercadoPago" como método de pago. (Los intereses son manejados por
                            MercadoPago)
                          </p>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            ) : page === 'medios-de-pago' ? (
              <div className="flex flex-col gap-2 hyphens-none">
                <p>
                  Los precios están expresados en Pesos argentinos ($), incluyen IVA y están sujetos a modificación sin
                  previo aviso.
                </p>
                <p>Tenés 4 formas para pagar:</p>
                <ul className="pl-4 flex flex-col gap-3">
                  <li className="list-disc">
                    Efectivo: Podés abonar en efectivo cuando elegís "Retiro en persona", o si elegís "Envío por
                    mensajería privada". En los otros casos, tenés la posibilidad de pagar en efectivo por RapiPago o
                    PagoFácil.
                  </li>
                  <li className="list-disc">
                    Dólar físico: Si compraste con opción "Efectivo en persona" tenés la posibilidad de abonar con dólar
                    físico a la cotización del Dólar Blue al momento del pago.
                  </li>
                  <li className="list-disc">Transferencia bancaria</li>
                  <li className="list-disc">
                    MercadoPago: Si lo deseás, podés pagar por MercadoPago mediante tarjeta de crédito, tarjeta de
                    débito, dinero en cuenta o Mercado Crédito.{' '}
                    <a
                      href={'https://www.mercadopago.com.ar/ayuda/264'}
                      target={'_blank'}
                      rel="noreferrer"
                      className="font-medium hover:text-amber-500 transition-colors">
                      Ver medios de pago de MercadoPago
                    </a>
                  </li>
                </ul>
                <p className="mt-4">
                  Si estás en una computadora, en la barra superior hay un botón rojo de "Ofertas". Si estás en un
                  celular o tablet, en la barra superior tocá en las 3 rayas y vas a encontrar también la opción
                  "Ofertas".
                </p>
                <p className="mt-4">
                  Para pagar en cuotas, tenés que elegir "MercadoPago" como método de pago. (Los intereses son manejados
                  por MercadoPago)
                </p>
              </div>
            ) : page === 'terminos-y-condiciones' && data ? (
              <div className="flex flex-col gap-2">
                <p className="text-lg">1. Información General</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">1.1 Empresa:</p>
                  <p>Universal Tools</p>

                  <p className="font-medium">1.2 Dirección:</p>
                  <p>{PickUpAddressToURL(data.pick_up_address).replaceAll('+', ' ')}</p>

                  <p className="font-medium">1.3 País de Operación:</p>
                  <p>Argentina</p>
                </div>

                <p className="mt-4 text-lg">2. Envíos y Entregas</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">2.1 Costos de Envío:</p>
                  <p>Los costos de envío pueden variar y están sujetos a modificación sin previo aviso.</p>

                  <p className="font-medium">2.2 Plazos de Entrega:</p>
                  <ul className="pl-4">
                    <li className="list-disc">Envío por Correo: 1 a 5 días hábiles.</li>
                    <li className="list-disc">Envío por Mensajería Privada: En el día.</li>
                  </ul>
                </div>

                <p className="mt-4 text-lg">3. Devoluciones y Cambios</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">3.1 Procedimiento:</p>
                  <p>
                    Para discutir devoluciones y cambios, contáctese por los medios informados después de realizar la
                    compra.
                  </p>
                </div>

                <p className="mt-4 text-lg">4. Garantía de Productos</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">4.1 Información de Garantía:</p>
                  <p>
                    La información sobre la garantía de cada producto se encuentra detallada en la publicación del
                    mismo.
                  </p>
                </div>

                <p className="mt-4 text-lg">5. Reclamos y Atención al Cliente</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">5.1 Contacto:</p>
                  <p>
                    La información de contacto para reclamos y atención al cliente se encuentra en la sección "Ayuda" de
                    nuestro sitio web.
                  </p>
                </div>

                <p className="mt-4 text-lg">6. Métodos de Pago</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">6.1 Métodos Aceptados:</p>
                  <ul className="pl-4">
                    <li className="list-disc">Efectivo (en persona, RapiPago o PagoFacil).</li>
                    <li className="list-disc">Dólar físico (a la cotización Blue del momento).</li>
                    <li className="list-disc">Transferencia Bancaria.</li>
                    <li className="list-disc">
                      MercadoPago (tarjeta de crédito, tarjeta de débito, dinero en cuenta, Mercado Crédito).
                    </li>
                  </ul>
                </div>

                <p className="mt-4 text-lg">7. Creación de Cuenta</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">7.1 Proceso:</p>
                  <p>El proceso para crear una cuenta se detalla en la sección "Ayuda" de nuestro sitio web.</p>
                </div>

                <p className="mt-4 text-lg">8. Política de Privacidad</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">8.1 Recopilación y Uso de Datos:</p>
                  <p>
                    Los datos personales se manejan de manera segura y no se comparten con terceros. La información
                    completa se encuentra en nuestra Política de Privacidad.
                  </p>
                </div>

                <p className="mt-4 text-lg">9. Uso de Cookies</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">9.1 Cookies:</p>
                  <p>
                    Se utilizan cookies para mantener la sesión del usuario y recopilar información mínima necesaria. No
                    se recopilan datos sensibles.
                  </p>
                </div>

                <p className="mt-4 text-lg">10. Marca Registrada</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">10.1 Marca:</p>
                  <p>"Universal Tools" es una marca registrada de nuestra empresa.</p>
                </div>

                <p className="mt-4 text-lg">11. Cambios en los Términos</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">11.1 Modificaciones:</p>
                  <p>
                    Nos reservamos el derecho de realizar cambios en estos términos y condiciones. Los cambios entrarán
                    en vigencia una vez publicados en nuestro sitio web.
                  </p>
                </div>

                <p className="mt-4 text-lg">12. Leyes y Jurisdicción</p>
                <div className="flex flex-col gap-1">
                  <p className="font-medium">12.1 Legislación Aplicable:</p>
                  <p>
                    Estos términos y condiciones se rigen por las leyes de Argentina. Cualquier disputa estará sujeta a
                    la jurisdicción de los tribunales argentinos.
                  </p>
                </div>
              </div>
            ) : page === 'contacto' && data ? (
              <div className="flex flex-col gap-2">
                <div className="w-full xl:w-80 xl:mx-auto h-8 mb-4 flex justify-around">
                  <RoundedButton
                    inverse={true}
                    logo={<FaFacebookF size="20" className="z-0" />}
                    link={data.media.facebook}
                  />
                  <RoundedButton
                    inverse={true}
                    logo={<FaInstagram size="20" className="z-0" />}
                    link={data.media.instagram}
                  />
                  <RoundedButton
                    inverse={true}
                    logo={<FaTwitter size="20" className="z-0" />}
                    link={data.media.twitter}
                  />
                  <RoundedButton
                    inverse={true}
                    logo={<FaYoutube size="20" className="z-0" />}
                    link={data.media.youtube}
                  />
                </div>
                <p className="hyphens-none font-light">
                  Contactanos por WhatsApp o llamada al{' '}
                  <a
                    href={`tel:${data.media.contact_number}`}
                    className="font-medium hover:text-amber-500 transition-colors"
                    target={'_blank'}
                    rel="noreferrer">
                    {data.media.contact_number}
                  </a>{' '}
                  o por teléfono fijo al{' '}
                  <a
                    href={`tel:${data.media.landline_phone}`}
                    className="font-medium hover:text-amber-500 transition-colors"
                    target={'_blank'}
                    rel="noreferrer">
                    {data.media.landline_phone}
                  </a>
                  .<br />
                  Tambíen podés enviarnos un e-mail a{' '}
                  <a
                    href={`mailto:${data.media.email}`}
                    className="font-medium hover:text-amber-500 transition-colors"
                    target={'_blank'}
                    rel="noreferrer">
                    {data.media.email}
                  </a>
                  .
                </p>
                <p className="mt-4 text-lg font-medium">Ubicación</p>
                <div className="w-full">
                  <p className="mb-1 font-light hyphens-none">
                    Encontranos en{' '}
                    <span className="font-medium">{PickUpAddressToURL(data.pick_up_address).replaceAll('+', ' ')}</span>
                  </p>
                  <p className="mb-2 font-light hyphens-none">
                    Horario de atención: <span className="font-medium">Lunes a Viernes de 9:00 hs a 16:00 hs</span>
                  </p>
                  <iframe
                    title="Ubicación para retirar"
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBAPfcne-dRGxByZ-IxT8HSPeJqg6zxu7M&q=${PickUpAddressToURL(
                      data.pick_up_address
                    )}`}
                    className="w-full h-[350px] border-0 focus:outline-0"
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Help

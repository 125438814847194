import Carousel from '../utils/Carousel'
import ProductCard from '../utils/ProductCard'
import Cardv2 from '../utils/Cardv2'
import { MdOutlineLocalShipping } from 'react-icons/md'
import { TbCoin } from 'react-icons/tb'
import { useState } from 'react'
import axios from 'axios'
import { FormatPrice } from '../utils/Strings'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import { setDocumentTitle, useWindowSize } from '../utils/Html'
import ShowImage from '../utils/ShowImage'
import LoadingBox from '../utils/LoadingBox'
import { useAbortableEffect } from '../utils/Hooks'

function Home() {
  const [data, setData] = useState(null)
  const homeConfig = data?.home_config ?? null

  const [windowWidth] = useWindowSize()

  useAbortableEffect(signal => {
    setDocumentTitle('')
    ;(async () => {
      try {
        const { data } = await axios.get(process.env.REACT_APP_API_LINK + 'get_home_config', {
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
          signal,
        })
        // console.log(data)
        setData(data)
      } catch (err) {
        if (err.code === 'ERR_CANCELED') return
        // console.log(err)
      }
    })()
  }, [])

  return (
    <div className="w-full grow bg-pagebg text-black">
      {data ? (
        <>
          <AspectRatio.Root ratio={windowWidth >= 1280 ? windowWidth / 340 : 3 / 1}>
            <Carousel propItems={homeConfig.banner} />
          </AspectRatio.Root>
          <section className="w-full">
            <div className="w-full text-black">
              <div className="xl:w-content-max-width mx-auto">
                <div className="w-full py-2 mb-6 xl:my-10 flex bg-white xl:rounded-md shadow-sm xl:border xl:border-gray-200">
                  {[
                    {
                      icon: <TbCoin className="w-full h-full text-dewalt" />,
                      title: 'UT Coins',
                      subTitle: 'Ahorrá en tu próxima compra',
                      always: true,
                    },
                    {
                      image: '/mercado-pago-notext.png',
                      title: 'MercadoPago',
                      subTitle: 'Tarjeta de crédito / débito',
                    },
                    {
                      icon: <MdOutlineLocalShipping className="w-full h-full text-dewalt" />,
                      title: 'Envío gratis',
                      subTitle: `A partir de ${FormatPrice(data.free_shipping_minimum)}`,
                    },
                  ].map((v, i) => (
                    <div
                      className={`w-max mx-auto xl:w-0 xl:grow flex items-center ${!v.always ? 'max-xl:hidden' : ''}`}
                      key={i}>
                      <div className="w-11 h-11 xl:w-12 xl:h-12 p-2 m-4 relative">
                        <div className="absolute w-full h-full left-0 top-0 rounded-full border border-gray-200" />
                        {v.icon ?? <ShowImage image={v.image} alt={`Imágen de ${v.title}`} />}
                      </div>
                      <div className="flex flex-col">
                        <span className="max-xl:text-sm">{v.title}</span>
                        <span className="text-sm max-xl:text-xs text-gray-500">{v.subTitle}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full bg-dewalt">
              <div className="xl:w-content-max-width mx-auto">
                <div className="xl:-ml-8 px-2 pt-6 xl:px-0 xl:pt-10 pb-4 mb-2 xl:mb-8">
                  <h2
                    className="w-max p-2 font-sans-condensed bg-black text-dewalt text-2xl xl:text-5xl font-bold
                   selection:bg-dewalt selection:text-black shadow-lg">
                    CATEGORÍAS POPULARES
                  </h2>
                </div>
                <div className="w-full overflow-x-scroll scrollbar-hide">
                  <div className="w-[calc(var(--content-max-width)/2)] xl:w-full px-2 xl:px-0 h-max flex justify-around pb-12">
                    {homeConfig.popular_categories.map((v, i) => (
                      <Cardv2
                        key={i}
                        img={v.image}
                        sub_img={v.sub_image}
                        title={v.category.name}
                        link={`/catalogue/${
                          (v.category.parent_category_id ? v.category.parent_category_id + '/' : '') +
                          v.category.category_id
                        }/all`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {[...homeConfig.sections, { title: 'ÚLTIMOS INGRESOS', products: data.latest_products }].map((v, i) => (
              <Section
                image={v.image}
                title={v.title}
                titleSide={i % 2 === 0 ? 'left' : 'right'}
                products={v.products}
                free_shipping_minimum={data.free_shipping_minimum}
                key={i}
              />
            ))}
          </section>
        </>
      ) : (
        <LoadingBox />
      )}
    </div>
  )
}

// function LoadingSkeleton() {
//   const [windowWidth] = useWindowSize();

//   return (
//     <>
//       <AspectRatio.Root ratio={windowWidth >= 1280 ? windowWidth / 340 : 3 / 1}>
//         <Skeleton className={"w-full h-full"} />
//       </AspectRatio.Root>

//       <section className="w-full">
//         <div className="w-full text-black">
//           <div className="xl:w-content-max-width mx-auto">
//             <Skeleton className="w-full h-24 mb-6 xl:my-10" />
//           </div>
//         </div>
//         <div className="w-full">
//           <div className="xl:w-content-max-width mx-auto">
//             <div className="px-2 pt-6 xl:px-0 xl:pt-10 pb-4 mb-4 xl:mb-8">
//               <Skeleton className={"w-52 h-11 xl:w-96 xl:h-14"} />
//             </div>
//             <div className="w-full xl:w-full overflow-x-scroll scrollbar-hide">
//               <div className="w-[calc(var(--content-max-width)/2)] px-2 xl:px-0 xl:w-full h-max flex justify-around gap-2 pb-12">
//                 {Array(4)
//                   .fill("")
//                   .map((_, i) => (
//                     <Skeleton key={i} className={"w-0 grow h-40 xl:h-72"} />
//                   ))}
//               </div>
//             </div>
//           </div>
//         </div>
//         {Array(2)
//           .fill("")
//           .map((_, i) => (
//             <div key={i} className="w-full relative">
//               <div className="xl:w-content-max-width mx-auto relative z-[2]">
//                 <div className="px-2 pt-6 xl:px-0 xl:pt-10 pb-4 mb-4 xl:mb-8">
//                   <Skeleton className={"w-52 h-11 xl:w-96 xl:h-14"} />
//                 </div>
//                 <div className="w-full mb-8 xl:w-full overflow-x-scroll scrollbar-hide">
//                   <div className="w-max px-2 xl:px-0 xl:w-full h-max flex gap-2 pb-6">
//                     {Array(5)
//                       .fill("")
//                       .map((_, j) => (
//                         <Skeleton
//                           key={j}
//                           className={
//                             "max-xl:w-[calc(var(--content-max-width)/1.5/5)] xl:w-[calc(var(--content-max-width)/5)] h-64 xl:h-96"
//                           }
//                         />
//                       ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//       </section>
//     </>
//   );
// }

function Section({ image, title, titleSide, products, free_shipping_minimum }) {
  return (
    products &&
    products.length > 0 && (
      <div className="w-full relative">
        {image && (
          <div
            className="absolute w-full h-44 xl:h-[27rem] top-4 left-0 z-[1]"
            style={{
              backgroundImage: `url(${image})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />
        )}
        <div className="xl:w-content-max-width mx-auto relative z-[2]">
          <div
            className={`w-full px-2 xl:px-0 pt-6 xl:pt-10 pb-4 mb-2 xl:mb-8 flex ${
              titleSide === 'left' ? 'justify-start' : 'flex-row-reverse justify-end'
            } items-center gap-4`}>
            <div className={`${titleSide === 'left' ? 'xl:-ml-8' : 'xl:-mr-8'}`}>
              <h3 className="w-max p-2 font-sans-condensed bg-white text-black text-2xl xl:text-5xl font-bold shadow-lg">
                {title}
              </h3>
            </div>
            <div className="w-0 grow h-1 xl:h-2 bg-white" />
          </div>
          <div className="w-full mb-8 xl:w-full overflow-x-scroll scrollbar-hide">
            <div className="w-max px-2 xl:px-0 xl:w-full h-max flex gap-4 pb-6">
              {products.map((product, index) => (
                <ProductCard product={product} key={index} free_shipping_minimum={free_shipping_minimum} />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default Home

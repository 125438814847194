import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import {
  MdChevronLeft,
  MdFavoriteBorder,
  MdHelpOutline,
  MdOutlineAdminPanelSettings,
  MdOutlineClose,
  MdOutlineCreate,
  MdOutlineDocumentScanner,
  MdOutlineHome,
  MdOutlineLocalOffer,
  MdOutlineLogin,
  MdOutlineLogout,
  MdOutlinePhone,
  MdOutlineSell,
  MdOutlineShoppingBag,
  MdPersonOutline,
  MdRefresh,
  MdSearch,
} from 'react-icons/md'
import { FiShoppingCart, FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { BsListNested, BsPersonCircle } from 'react-icons/bs'
import { FaBoxes } from 'react-icons/fa'
import { useMainContext } from './utils/MainContext'
import axios from 'axios'
import { TbCoin } from 'react-icons/tb'
import './Topbar.css'
import Cart from './utils/Cart'
import { removeCookie, useWindowSize } from './utils/Html'
import { useDolarBlue, useUTCoinsInSite } from './utils/Hooks'
import { DigitGrouping, FormatPrice, TimestampToDateTextWithTime } from './utils/Strings'
import { HiOutlineHashtag } from 'react-icons/hi'
import DefaultAlertDialog from './utils/DefaultAlertDialog'
import { isArray } from 'lodash'

const links = {
  signup: '/signup',
  login: '/login/user',
  adminHome: '/admin/home',
  adminStockUPC: '/admin/products/add_stock',
  adminUsers: '/admin/users',
  adminOrders: '/admin/orders',
  adminProducts: '/admin/products',
  userOrders: '/user/orders',
  userProfile: '/user/profile',
  favorites: '/favorites',
  cart: '/cart',
  categories: '/categories',
  brands: '/brands',
  ut_coins: '/ut_coins',
  offers: '/catalogue/ofertas',
  allProducts: '/catalogue/all',
  track: '/track',
  faq: '/help/quienes-somos',
  contact: '/help/contacto',
}

function Topbar({ empty, searchQuery }) {
  const { firebase, showCart, setShowCart } = useMainContext()
  const navigate = useNavigate()
  const location = useLocation()
  const user = firebase.user
  const [windowWidth] = useWindowSize()
  const cartProducts =
    firebase.user?.cart && isArray(firebase.user.cart)
      ? firebase.user.cart.reduce((acc, item) => acc + item.amount, 0)
      : null
  const favsProducts = firebase.user?.favs && isArray(firebase.user.favs) ? firebase.user.favs.length : null

  const [dolarBlue, reloadDolarBlue] = useDolarBlue()
  const [utCoinsInSite] = useUTCoinsInSite(user?.uid, user?.role === 'admin' || user?.role === 'operator')

  const [search, setSearch] = useState('')
  const [searchBarFocused, setSearchBarFocused] = useState(false)

  const [categories, setCategories] = useState([])
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  useEffect(() => {
    setSearch(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setShowMobileMenu(false)
  }, [location])

  useEffect(() => {
    if (categories.length > 0) return
    ;(async () => {
      try {
        const {
          data: { categories },
        } = await axios.get(process.env.REACT_APP_API_LINK + 'get_categories', {
          params: {
            products_amount: false,
          },
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
        })

        let menus = []
        categories.forEach(c => {
          let subMenus = []
          c.children.forEach(sc => {
            subMenus.push({
              title: sc.name,
              link: `/catalogue/${c.category_id}/${sc.category_id}/all`,
            })
          })
          subMenus = subMenus.toSorted((a, b) => a.title.toLowerCase() > b.title.toLowerCase())
          if (subMenus.length > 0) {
            subMenus.push({
              title: `Todo en ${c.name}`,
              link: `/catalogue/${c.category_id}/all`,
            })
          }
          menus.push(
            subMenus.length > 0
              ? { title: c.name, subMenu: subMenus }
              : { title: c.name, link: `/catalogue/${c.category_id}/all` }
          )
        })
        menus = menus.toSorted((a, b) => a.title.toLowerCase() > b.title.toLowerCase())
        menus.push({ title: 'Ver todas las categorías', link: '/categories' })
        setCategories(menus)
      } catch (err) {
        // console.log(err)
        // TODO: Mandar error a DB
      }
    })()
  }, [categories.length])

  const clickLogoutDialog = () => {
    setShowMobileMenu(false)
    setShowCart(false)
    document.getElementById('logout-dialog').click()
  }

  const handleSearchSubmit = ev => {
    ev.preventDefault()
    if (search === '') return
    navigate(`/catalogue/${search.trim().replace(/ /g, '_')}`)
    setSearchBarFocused(false)
  }

  return (
    <header
      className={`w-full max-h-screen bg-black ${searchBarFocused ? 'max-xl:bg-white' : ''} ${
        showMobileMenu ? 'overflow-y-auto' : ''
      } mx-auto relative z-10 shadow-md transition-colors`}>
      <LogOutDialog />
      {windowWidth > 1650 && (
        <div className="absolute top-6 right-6 px-4 pl-2 py-2 bg-white flex items-center gap-2 drop-shadow-lg transition-colors">
          <button
            onClick={reloadDolarBlue}
            title="Refrescar cotización"
            className="h-max hover:rotate-[360deg] group transition-transform duration-300 ease-out">
            <MdRefresh className="w-6 h-6 text-black/50 group-hover:text-black transition-colors duration-300" />
          </button>
          <div className="flex flex-col items-end text-black">
            {dolarBlue ? (
              <>
                <span className="text-lg font-bold">10 UT Coins = 1 U$D</span>
                <span className="text-black/60 font-bold">1 U$D = {FormatPrice(dolarBlue.value_buy)}</span>
              </>
            ) : (
              <span>Cargando cotización...</span>
            )}
          </div>
        </div>
      )}
      {windowWidth > 1650 && (user?.role === 'admin' || user?.role === 'operator') && (
        <div className="absolute top-32 right-6 px-4 py-2 bg-white flex items-center gap-2 drop-shadow-lg transition-colors">
          <div className="flex flex-col items-center text-black">
            {utCoinsInSite ? (
              <>
                <span className="text-black/60 text-xs">En circulación</span>
                <span className="text-lg font-bold">{DigitGrouping(utCoinsInSite.amount)} UT Coins</span>
                <span className="text-black/60 text-sm">{TimestampToDateTextWithTime(utCoinsInSite.fetched_at)}</span>
              </>
            ) : (
              <span>Cargando UT Coins...</span>
            )}
          </div>
        </div>
      )}
      <div className="w-full px-2 pr-4 xl:px-0 py-2 xl:py-3 max-w-content-max-width mx-auto h-12 xl:h-topbar-height relative flex">
        <div
          className={`absolute z-[999] top-full right-0 h-max bg-white text-black text-sm rounded-b-md
                      ${
                        showCart
                          ? 'w-screen sm:w-96 opacity-100 pointer-events-auto'
                          : 'w-72 opacity-0 pointer-events-none'
                      } shadow-xl drop-shadow-xl transition-all overflow-hidden`}>
          {/* <div className="w-full h-2 xl:h-3 bg-black" /> */}
          <div className="w-full h-max bg-white">
            <Cart />
          </div>
        </div>
        <Link
          to="/"
          className={`${
            searchBarFocused ? 'max-xl:opacity-0 max-xl:pointer-events-none' : 'opacity-100 pointer-events-auto'
          } min-w-max h-full pr-4 xl:mx-0 xl:ml-0 transition-all duration-200 max-xl:z-10`}>
          <img
            src="/universal-tools-logo-horizontal-v4.svg"
            className="min-w-max h-full object-contain hidden xl:block"
            alt="Universal Tools Logo"
          />
          <img
            src="/universal-tools-logo.svg"
            className="min-w-max h-full object-contain xl:hidden"
            alt="Universal Tools Logo"
          />
        </Link>
        {!empty && (
          <>
            <div className={`w-0 grow bg-white xl:w-max h-full xl:ml-10 flex items-center`}>
              <form
                className={`absolute top-0 left-0 xl:static w-full h-full flex group xl:shadow-sm xl:shadow-neutral-300 ${
                  searchBarFocused
                    ? ''
                    : `pl-[5.5rem] xl:pl-0 ${
                        user?.role === 'admin' || user?.role === 'operator' ? 'pr-16' : 'max-2xs:pr-16 pr-[6.5rem]'
                      } xl:pr-0 py-2 xl:py-0`
                } transition-all`}
                onSubmit={handleSearchSubmit}>
                <input
                  type="text"
                  name="search"
                  className={`w-0 max-xl:grow xl:w-[500px] h-full px-1 xl:px-3 text-sm xl:text-base text-black outline-none
                        xl:group-focus-within:border-white rounded-l-sm
                        placeholder-neutral-400 max-xl:placeholder:text-xs max-xl:group-focus-within:placeholder:text-sm transition-all ${
                          searchBarFocused ? 'max-xl:border-0 pl-10 xl:px-3 max-xl:pr-10' : ''
                        }`}
                  value={search}
                  onChange={ev => setSearch(ev.target.value)}
                  onMouseDown={() => setSearchBarFocused(true)}
                  onFocus={() => setSearchBarFocused(true)}
                  onBlur={() => setSearchBarFocused(false)}
                  placeholder="Buscar productos..."
                />
                <button
                  type="submit"
                  className={`${
                    searchBarFocused ? 'w-16 py-2 xl:w-14 xl:py-2 max-xl:border-0' : 'w-12 xl:w-14 py-1'
                  } h-full xl:py-2 xl:group-focus-within:border-white rounded-r-sm
                  text-dewalt hover:text-amber-500 transition-all`}>
                  <MdSearch className="w-full h-full border-l border-gray-500 transition-all" />
                </button>
                <button
                  type="button"
                  className={`absolute top-1/2 left-0 -translate-y-1/2 ${
                    searchBarFocused ? 'block xl:hidden' : 'hidden'
                  } h-5/6 aspect-square p-1`}
                  onClick={() => setSearchBarFocused(false)}>
                  <MdChevronLeft className="w-full h-full text-neutral-800" />
                </button>
                <button
                  type="button"
                  className={`absolute top-1/2 right-0 -translate-x-1/2 -translate-y-1/2 mr-12 ${
                    searchBarFocused && search !== '' ? 'block xl:hidden' : 'hidden'
                  } h-full aspect-square p-3`}
                  onMouseDown={ev => {
                    ev.preventDefault()
                    setSearch('')
                  }}>
                  <MdOutlineClose className="w-full h-full text-neutral-800" />
                </button>
              </form>
            </div>
            <nav
              className={`${
                searchBarFocused ? 'max-xl:opacity-0 max-xl:pointer-events-none' : 'opacity-100 pointer-events-auto'
              } ${empty ? 'hidden' : ''} xl:w-full xl:pl-4 h-full flex justify-end 
              transition-all duration-200 max-xl:z-10`}>
              {user === null ? (
                <>
                  <div className="hidden xl:flex">
                    {[
                      { link: links.signup, text: 'REGISTRATE', dewalt: true },
                      { link: links.login, text: 'INICIAR SESIÓN' },
                    ].map((l, i) => (
                      <Link
                        key={i}
                        className={`h-full p-4 flex items-center ${
                          l.dewalt ? 'text-dewalt' : 'text-neutral-300'
                        } border-solid border-white30
                  hover:text-white cursor-pointer transition-all`}
                        to={l.link}>
                        <div className="select-none text-sm">{l.text}</div>
                      </Link>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {user.role === 'user' && (
                    <Link
                      to="ut_coins"
                      className="w-max px-3 py-1 select-none flex items-center gap-1 
                               rounded-md border border-gray-200 hover:bg-neutral-800 hover:border-white group transition-all
                               max-xl:hidden hover:scale-110">
                      <TbCoin
                        className="w-7 h-7 p-[2px] text-dewalt rounded-full 
                    group-hover:w-7 group-hover:h-7 group-hover:p-0 group-hover:-rotate-12 transition-all"
                      />
                      <span className="text-sm pt-[1px] text-gray-200 group-hover:text-white transition-colors">
                        UT Coins:{' '}
                      </span>
                      <span className="text-dewalt font-semibold text-lg">{DigitGrouping(user.ut_coins)}</span>
                    </Link>
                  )}
                  <div
                    className="relative w-max h-full px-4 flex items-center text-neutral-300 border-solid border-white30
                  transition-all group max-xl:hidden">
                    <div className="flex group-hover:text-white">
                      <BsPersonCircle className="w-7 h-7 mr-3" />
                      <div className="select-none flex items-center gap-1">
                        <span className="text-sm">{`${user.displayName.split('_')[0]}${
                          user.role === 'admin' ? ' (ADMIN)' : user.role === 'operator' ? ' (OPERADOR)' : ''
                        }`}</span>
                        <FiChevronDown className="w-4 h-4" />
                      </div>
                    </div>
                    <div
                      className="absolute z-[999] top-full right-1 h-max bg-white text-black text-sm rounded-b-md
                                shadow-xl drop-shadow-xl transition-all
                                w-48 opacity-0 pointer-events-none group-hover:w-60 group-hover:opacity-100 group-hover:pointer-events-auto">
                      <div className="w-full h-3 bg-black" />
                      {(user.role === 'admin' || user.role === 'operator'
                        ? [
                            {
                              text: user.role === 'admin' ? 'Menú Admin' : 'Menú Operador',
                              link: links.adminHome,
                            },
                            {
                              text: 'Stock por UPC',
                              link: links.adminStockUPC,
                            },
                            {
                              text: 'Usuarios',
                              link: links.adminUsers,
                            },
                            {
                              text: 'Pedidos',
                              link: links.adminOrders,
                            },
                            {
                              text: 'Productos',
                              link: links.adminProducts,
                            },
                            {
                              text: 'Cerrar sesión',
                              onClick: clickLogoutDialog,
                              separated: true,
                            },
                          ]
                        : [
                            {
                              text: 'Mis Pedidos',
                              link: links.userOrders,
                            },
                            {
                              text: 'Mi Cuenta',
                              link: links.userProfile,
                            },
                            {
                              text: 'Cerrar sesión',
                              onClick: clickLogoutDialog,
                              separated: true,
                            },
                          ]
                      ).map((v, i) =>
                        v.link ? (
                          <Link
                            key={i}
                            to={v.link}
                            className={`w-full h-12 pr-4 flex justify-end items-center hover:bg-gray-100 hover:shadow-md hover:font-medium
                            ${v.separated && 'border-t border-gray-200'} select-none`}>
                            {v.text}
                          </Link>
                        ) : (
                          <button
                            key={i}
                            type="button"
                            className={`w-full h-12 pr-4 flex justify-end items-center rounded-b-md 
                                      hover:bg-gray-100 hover:shadow-md hover:font-medium
                                      ${v.separated && 'border-t border-gray-200'}`}
                            onClick={v.onClick}>
                            {v.text}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </>
              )}
              <button
                type="button"
                className="relative h-full px-[2px] py-[6px] ml-4 aspect-square xl:hidden"
                onClick={() => setShowMobileMenu(!showMobileMenu)}>
                {windowWidth < 340 && cartProducts !== null && cartProducts > 0 && (
                  <div
                    className={`absolute top-0 -right-2 xl:right-0 ${
                      cartProducts >= 100 ? 'w-6 xl:w-7' : 'w-4 xl:w-5'
                    } h-4 xl:h-5 flex justify-center items-center
                      ${showCart ? 'bg-white' : 'bg-dewalt group-hover:bg-white'}
                      rounded-full transition-colors pointer-events-none select-none z-[1]`}>
                    <span className="text-black text-xs xl:text-sm font-medium">{cartProducts}</span>
                  </div>
                )}
                <div className="w-full h-full relative group overflow-visible">
                  <div
                    className={`absolute top-0 left-0 ${
                      showMobileMenu ? 'rotate-45 top-[46%]' : ''
                    } w-full h-[2px] bg-gray-200 group-hover:bg-white rounded-sm transition-all`}
                  />
                  <div
                    className={`absolute top-1/2 -translate-y-1/2 left-0 ${
                      showMobileMenu ? 'opacity-0' : 'opacity-100'
                    } w-full h-[2px] bg-gray-200 group-hover:bg-white rounded-sm transition-all`}
                  />
                  <div
                    className={`absolute bottom-0 left-0 ${
                      showMobileMenu ? '-rotate-45 bottom-[46%]' : ''
                    } w-full h-[2px] bg-gray-200 group-hover:bg-white rounded-sm transition-all`}
                  />
                </div>
              </button>
              {/* <Link
                to={links.cart}
                title="Carrito"
                className="h-full p-4 xl:pr-0 flex items-center text-gray-200 border-solid border-white30
                      hover:text-white cursor-pointer transition-all"
              >
                <FiShoppingCart className="w-6 h-6" />
              </Link> */}
              {(!user || user.role === 'user') && (
                <div className="flex h-full">
                  <div className="relative w-max h-full text-neutral-300 transition-colors group max-xl:hidden">
                    {favsProducts !== null && favsProducts > 0 && (
                      <div
                        className={`absolute top-0 -right-2 xl:right-0 ${
                          favsProducts >= 100 ? 'w-6 xl:w-7' : 'w-4 xl:w-5'
                        } h-4 xl:h-5 flex justify-center items-center bg-dewalt group-hover:bg-white
                    rounded-full transition-colors pointer-events-none select-none`}>
                        <span className="text-black text-xs xl:text-sm font-medium">{favsProducts}</span>
                      </div>
                    )}
                    <Link
                      to={firebase.user ? `/favorites` : `/notloggedin/fav`}
                      className="w-max h-full px-4 max-xl:pr-0 group-hover:text-white flex items-center cursor-pointer cartButton"
                      title="Favoritos">
                      <MdFavoriteBorder className={`w-6 h-6 transition-colors pointer-events-none`} />
                    </Link>
                  </div>
                  <div className="relative w-max h-full text-neutral-300 transition-all group max-2xs:hidden">
                    {cartProducts !== null && cartProducts > 0 && (
                      <div
                        className={`absolute top-0 -right-2 xl:right-0 ${
                          cartProducts >= 100 ? 'w-6 xl:w-7' : 'w-4 xl:w-5'
                        } h-4 xl:h-5 flex justify-center items-center
                          ${showCart ? 'bg-white' : 'bg-dewalt group-hover:bg-white'}
                          rounded-full transition-colors pointer-events-none select-none`}>
                        <span className="text-black text-xs xl:text-sm font-medium">{cartProducts}</span>
                      </div>
                    )}
                    <div
                      className="w-max h-full px-4 max-xl:pr-0 flex items-center cursor-pointer cartButton"
                      onClick={() => setShowCart(!showCart)}
                      title="Carrito">
                      <FiShoppingCart
                        className={`w-6 h-6 ${
                          showCart ? 'text-white' : 'group-hover:text-white'
                        } transition-colors pointer-events-none`}
                      />
                    </div>
                  </div>
                </div>
              )}
            </nav>
          </>
        )}
      </div>
      {/* <span className="w-full pb-2 font-medium flex justify-center">
        ¡Recordá que los UT Coins cotizan a valor dólar!
      </span> */}
      <div
        className={`w-full ${
          showMobileMenu ? 'h-max border-t' : 'h-0 border-0 invisible'
        } bg-white text-black border-gray-200 overflow-hidden xl:hidden`}>
        {user && (
          <>
            <div className="w-full p-4 px-2 xs:pl-4 flex items-center">
              <div className="w-12 h-12 bg-neutral-50 p-1 flex justify-center items-center rounded-full border border-gray-200">
                <MdPersonOutline className="w-full h-full text-gray-300" />
              </div>
              <div className="flex-1 pl-4 pr-2 flex flex-col text-neutral-800">
                <span className="line-clamp-2">Hola, {user.displayName.split('_')[0]}</span>
                {user.role === 'user' ? (
                  <span className="text-sm font-bold">
                    Mis UT Coins:{' '}
                    <span className="text-base xs:text-lg text-amber-500 font-semibold leading-[0.5rem] xs:leading-3">
                      {DigitGrouping(user.ut_coins)}
                    </span>
                  </span>
                ) : (
                  <span className="text-sm">({user.role === 'admin' ? 'ADMIN' : 'OPERADOR'})</span>
                )}
              </div>
              <button
                type="button"
                onClick={reloadDolarBlue}
                className="px-2 py-1 bg-white flex flex-col items-end rounded-lg shadow-md drop-shadow-md">
                {dolarBlue ? (
                  <>
                    <span className="text-neutral-800 text-xs xs:text-sm font-medium">10 UT Coins = 1 U$D</span>
                    <div className="w-max flex items-center">
                      <MdRefresh size={16} className="w-max mr-2 text-black" />
                      <span className="text-neutral-600 text-xs line-clamp-1">
                        1 U$D = {FormatPrice(dolarBlue.value_buy)}
                      </span>
                    </div>
                  </>
                ) : (
                  <img src="/loading.svg" className="w-10 h-10 p-2" alt="" />
                )}
              </button>
            </div>
            <div className="mx-2 border-t border-gray-200" />
          </>
        )}
        <div className="w-full py-2">
          {[
            ...(windowWidth < 340
              ? [
                  {
                    text: `Carrito`,
                    link: links.cart,
                    label: cartProducts !== null ? cartProducts : undefined,
                    icon: <FiShoppingCart className="w-full h-full" />,
                    bold: true,
                  },
                  { divider: true },
                ]
              : []),
            {
              text: 'Inicio',
              link: '/',
              icon: <MdOutlineHome className="w-full h-full" />,
            },
            {
              text: 'Categorías',
              link: links.categories,
              icon: <BsListNested className="w-full h-full" />,
            },
            {
              text: 'Marcas',
              link: links.brands,
              icon: <HiOutlineHashtag className="w-full h-full" />,
            },
            {
              text: 'Ofertas',
              link: links.offers,
              icon: <MdOutlineLocalOffer className="w-full h-full" />,
              bold: true,
            },
            {
              text: 'Todos los productos',
              link: links.allProducts,
              icon: <FaBoxes className="w-full h-full" />,
            },
            ...(user !== null && user.role === 'user'
              ? [
                  {
                    text: 'UT Coins',
                    link: links.ut_coins,
                    icon: <TbCoin className="w-full h-full" />,
                  },
                ]
              : []),
            { divider: true },
            ...(user === null
              ? [
                  {
                    text: 'Registrate',
                    link: links.signup,
                    icon: <MdOutlineCreate className="w-full h-full" />,
                  },
                  {
                    text: 'Iniciar sesión',
                    link: links.login,
                    icon: <MdOutlineLogin className="w-full h-full" />,
                  },
                ]
              : user.role === 'admin' || user.role === 'operator'
              ? [
                  {
                    text: user.role === 'admin' ? 'Menú Admin' : 'Menú Operador',
                    link: links.adminHome,
                    icon: <MdOutlineAdminPanelSettings className="w-full h-full" />,
                  },
                  {
                    text: 'Stock por UPC',
                    link: links.adminStockUPC,
                    icon: <MdOutlineDocumentScanner className="w-full h-full" />,
                  },
                  {
                    text: 'Usuarios',
                    link: links.adminUsers,
                    icon: <MdPersonOutline className="w-full h-full" />,
                  },
                  {
                    text: 'Pedidos',
                    link: links.adminOrders,
                    icon: <MdOutlineSell className="w-full h-full" />,
                  },
                  {
                    text: 'Productos',
                    link: links.adminProducts,
                    icon: <FaBoxes className="w-full h-full" />,
                  },
                  {
                    text: 'Cerrar sesión',
                    onClick: clickLogoutDialog,
                    icon: <MdOutlineLogout className="w-full h-full" />,
                  },
                ]
              : [
                  {
                    text: `Mis favoritos${favsProducts ? ` (${favsProducts})` : ''}`,
                    link: links.favorites,
                    icon: <MdFavoriteBorder className="w-full h-full" />,
                  },
                  {
                    text: 'Mis pedidos',
                    link: links.userOrders,
                    icon: <MdOutlineShoppingBag className="w-full h-full" />,
                  },
                  {
                    text: 'Mi cuenta',
                    link: links.userProfile,
                    icon: <BsPersonCircle className="w-full h-full" />,
                  },
                  {
                    text: 'Cerrar sesión',
                    onClick: clickLogoutDialog,
                    icon: <MdOutlineLogout className="w-full h-full" />,
                  },
                ]),
            { divider: true },
            {
              text: 'Contacto',
              link: links.contact,
              icon: <MdOutlinePhone className="w-full h-full" />,
            },
            {
              text: 'Ayuda',
              link: links.faq,
              icon: <MdHelpOutline className="w-full h-full" />,
            },
          ].map((v, i) =>
            v.divider ? (
              <div key={i} className="mx-2 my-2 border-t border-gray-200" />
            ) : (
              <LinkMobile key={i} info={v} currentLink={location.pathname} />
            )
          )}
        </div>
      </div>
      <div className={`w-full h-1 ${!empty ? 'xl:h-12' : ''} bg-dewalt`}>
        {!empty && (
          <nav className="w-content-max-width mx-auto h-full flex justify-start max-xl:hidden">
            <LowerButton res={'1'} text="Categorías" subMenu={categories} />
            <LowerButton res={'1'} text="Marcas" link={links.brands} />
            <LowerButton res={'1'} text="Todos los productos" link={links.allProducts} />
            <LowerButton res={'2'} text="Ofertas" link={links.offers} />
            {/* <LowerButton text="Seguí tu pedido" link={links.track} /> */}
            {/* //TODO: Pagina de tracking */}
            <div className="grow flex justify-end">
              <LowerButton text="Ayuda" link={links.faq} />
              {/* //TODO: Pagina de ayuda*/}
              <LowerButton text="Contacto" link={links.contact} />
              {/* //TODO: Pagina de contacto*/}
            </div>
          </nav>
        )}
      </div>
    </header>
  )
}

function LinkMobile({ info, currentLink }) {
  const { text, link, icon, label, bold, onClick } = info
  const Tag = link !== undefined ? Link : 'button'
  return (
    <Tag
      to={link ?? null}
      onClick={onClick ?? null}
      className={`w-full h-12 ${
        link && currentLink === link ? 'bg-neutral-100 text-amber-500 font-medium' : 'bg-white text-neutral-800'
      } flex items-center px-6`}>
      <div className="w-6 aspect-square mr-4">{icon}</div>
      <span className={`text-sm ${bold ? 'font-bold' : ''}`}>{text}</span>
      {label && (
        <div
          className={`${
            label.length > 3 ? 'w-7' : 'w-5'
          } h-5 ml-2 flex justify-center items-center bg-dewalt group-hover:bg-white 
          rounded-full transition-colors pointer-events-none select-none`}>
          <span className="text-black text-xs font-medium">{label}</span>
        </div>
      )}
    </Tag>
  )
}

function LowerButton({ text, res, link, subMenu }) {
  const Tag = link !== undefined ? Link : 'div'
  return (
    <Tag
      to={Tag === Link ? link : null}
      className={`w-max h-full relative ${
        subMenu !== undefined ? 'pl-5 pr-4 hover:pl-6 hover:pr-5' : res !== '2' ? 'px-5 hover:px-6' : ''
      } flex items-center ${
        res === '2' ? 'py-1' : 'text-neutral-950 hover:bg-black hover:text-white'
      } font-medium transition-all group`}>
      <div
        className={`h-full ${
          res === '2'
            ? 'ml-1 px-4 bg-[#ff0000] text-white border-2 border-transparent hover:border-white hover:scale-125 rounded-lg transition-all'
            : ''
        } flex items-center overflow-hidden`}>
        <p
          className={`select-none ${
            res === '1' ? 'text-lg font-bold' : res === '2' ? 'text-lg font-bold uppercase' : ''
          }`}>
          {text}
        </p>
      </div>
      {subMenu && (
        <>
          <FiChevronDown className="w-5 h-5 ml-1" />
          <div
            className="absolute z-[999] left-0 top-full w-64 h-max py-3 flex flex-col bg-black
                       opacity-0 pointer-events-none group-hover:w-80 border border-t-0 border-dewalt
                       group-hover:opacity-100 group-hover:pointer-events-auto transition-all">
            {subMenu.map((v, i) => {
              const SubTag = v.subMenu === undefined ? Link : 'div'
              return (
                <SubTag
                  to={SubTag === Link ? v.link : null}
                  className="min-w-max h-9 px-6 relative flex items-center text-start
                            text-white hover:text-black hover:bg-dewalt hover:font-medium select-none group/sub"
                  key={i}>
                  {v.title}
                  {v.subMenu && (
                    <>
                      <FiChevronRight size="20" className="absolute w-max right-2 top-2" />
                      <div
                        className="absolute z-[999] left-full -top-3 w-64 h-max py-3 flex flex-col bg-dewalt
                                  opacity-0 pointer-events-none group-hover/sub:w-80 border border-l-0 border-black
                                  group-hover/sub:opacity-100 group-hover/sub:pointer-events-auto transition-all">
                        {v.subMenu.map((v, i) => (
                          <Link
                            to={v.link}
                            className="min-w-max h-9 px-5 relative text-start font-normal text-black select-none
                                    hover:text-white hover:bg-black hover:font-medium flex items-center"
                            key={i}>
                            {v.title}
                          </Link>
                        ))}
                      </div>
                    </>
                  )}
                </SubTag>
              )
            })}
          </div>
        </>
      )}
    </Tag>
  )
}

function LogOutDialog() {
  const { firebase } = useMainContext()

  const [loading, setLoading] = useState(false)

  const logOut = async () => {
    try {
      setLoading(true)

      await firebase.auth.signOut()

      removeCookie('user')
      removeCookie('cart')
      removeCookie('favs')

      setLoading(false)
      window.location.reload(false)
    } catch (e) {
      // console.log(e)
    }
  }

  return (
    <DefaultAlertDialog
      title="¿Estás seguro/a?"
      content={<p>Se va a cerrar la sesión.</p>}
      submit={{ text: 'Cerrar sesión', onClick: logOut }}
      loading={loading}
      trigger={<div id="logout-dialog" className="hidden" />}
    />
  )
}

export default Topbar

import axios from 'axios'
import { useEffect, useState } from 'react'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { PickUpAddressToURL } from './utils/Strings'

function Footer({ empty }) {
  const [data, setData] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_footer', {
          headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY },
        })
        setData(result.data)
      } catch (err) {
        if (err.code === 'ERR_CANCELED') return
        // console.log(err)
      }
    })()
  }, [])
  return (
    !empty && (
      <footer className="w-full px-4 xl:px-0 xl:w-content-max-width xl:mx-auto">
        <div className="w-full xl:py-8 flex flex-col xl:flex-row items-center">
          <div className="w-60 py-8 xl:py-0 h-max shrink-0">
            <img src="/universal-tools-logo.svg" className="h-28 px-7 object-contain" alt="Universal Tools Logo" />
            {data && (
              <div className="w-full h-8 mt-3 flex justify-around gap-1">
                <RoundedButton logo={<FaFacebookF size="20" className="z-0" />} link={data.media.facebook} />
                <RoundedButton logo={<FaInstagram size="20" className="z-0" />} link={data.media.instagram} />
                <RoundedButton logo={<FaTwitter size="20" className="z-0" />} link={data.media.twitter} />
                <RoundedButton logo={<FaYoutube size="20" className="z-0" />} link={data.media.youtube} />
              </div>
            )}
          </div>
          <div className="w-full h-max xl:h-52 xl:w-0 xl:grow flex flex-col xl:flex-row xl:py-2 xl:ml-10 text-sm text-neutral-300">
            <div className="w-full px-6 xl:px-0 xl:w-0 xl:grow basis-0 h-full">
              <p className="font-bold pb-2 font-sans-condensed text-lg">ACERCA DE UNIVERSAL TOOLS</p>
              <LinkTo text="¿Quiénes somos?" link="/help/quienes-somos" />
              <LinkTo text="Preguntas frecuentes" link="/help/preguntas-frecuentes" />
              <LinkTo text="Terminos y condiciones" link="/help/terminos-y-condiciones" />
            </div>
            <div className="w-full xl:w-[1px] h-[1px] xl:h-full bg-white my-6 xl:my-0 xl:mx-10" />
            <div className="w-full px-6 xl:px-0 xl:grow basis-0 h-full">
              <p className="font-bold pb-2 font-sans-condensed text-lg">CONTACTO</p>
              {data && (
                <>
                  <a className="pb-2 w-max hover:text-white" href={`tel:${data.media.contact_number}`}>
                    {data.media.contact_number}
                  </a>
                  <a className="pb-2 w-max hover:text-white" href={`tel:${data.media.contact_number}`}>
                    {data.media.landline_phone}
                  </a>
                  <a className="pb-2 w-max hover:text-white" href={`mailto:${data.media.email}`}>
                    {data.media.email}
                  </a>
                  <a
                    className="pb-2 w-max hover:text-white"
                    href={`https://www.google.com/maps/place/${PickUpAddressToURL(data.pick_up_address)}`}
                    target="_blank"
                    rel="noreferrer">
                    {`${data.pick_up_address.city}, ${data.pick_up_address.province}`}
                  </a>
                </>
              )}
            </div>
            <div className="w-full xl:w-[1px] h-[1px] xl:h-full bg-white my-6 xl:my-0 xl:mx-10" />
            <div className="w-full px-6 pb-6 xl:px-0 xl:pb-0 xl:grow basis-0 h-full flex flex-col">
              <p className="font-bold pb-2 font-sans-condensed text-lg">COMPRÁ CON SEGURIDAD</p>
              <LinkTo text="¿Cómo comprar?" link="/help/guia-de-compra" />
              <LinkTo text="Métodos de pago" link="/help/medios-de-pago" />
              <LinkTo text="Contacto" link="/help/contacto" />
              <div className="w-full h-16 flex items-center mb-6">
                <div className="w-full h-12 flex justify-start">
                  <img className="w-max h-full object-contain" src="/mercado-pago.png" alt="Mercado Pago" />
                </div>
                <img className="w-max h-full object-contain" src="/data-fiscal.jpg" alt="Data Fiscal AFIP" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-t border-white py-2 flex justify-center text-xs font-light text-neutral-400">
          @ 2024 Todos los derechos reservados
        </div>
      </footer>
    )
  )
}

function LinkTo({ text, link }) {
  return (
    <Link className="w-max pb-2 hover:text-white" to={link}>
      {text}
    </Link>
  )
}

export function RoundedButton({ logo, link, inverse }) {
  return (
    <div className="w-8 h-full relative group">
      <a
        className={`w-8 h-8 flex items-center justify-center transition-all relative z-10 ${
          inverse ? 'text-white hover:text-black' : 'text-black hover:text-white'
        }
                   before:absolute before:w-full before:h-full before:border-[16px] ${
                     inverse ? 'before:border-black' : 'before:border-white'
                   } before:rounded-3xl
                   before:hover:border before:transition-all before:duration-300`}
        href={link}
        target="_blank"
        rel="noreferrer">
        {logo}
      </a>
    </div>
  )
}

export default Footer

import { useEffect } from 'react'
import { useState } from 'react'
import { useCaretPosition } from 'react-use-caret-position'
import { OnlyNumbers } from './Strings'

function PlusMinusInput({ state: [value, setValue], min, max, onValueChange }) {
  const [inputValue, setInputValue] = useState(value)
  const { ref, updateCaret } = useCaretPosition()

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const updateValue = () => {
    let input = inputValue ?? 1
    input = input > max ? max : input < min ? min : input

    setInputValue(input)
    setValue(input)
    ref.current.value = input
    if (value !== input && onValueChange) onValueChange(input)
  }

  return (
    <form
      onSubmit={ev => {
        ev.preventDefault()
        updateValue()
      }}
      className="w-full h-full bg-white flex rounded-md overflow-hidden border border-gray-200"
      onBlur={() => {
        updateValue()
      }}>
      <button
        type="button"
        onClick={() => {
          if (value <= min) return

          const newValue = value - 1
          setInputValue(newValue)
          setValue(newValue)
          ref.current.value = newValue
          if (onValueChange) onValueChange(newValue)
        }}
        className={`w-0 grow-[0.32] h-full text-2xl ${
          value <= min ? 'text-gray-200' : 'text-amber-500'
        } flex justify-center items-center hover:bg-neutral-50 transition-colors`}>
        -
      </button>
      <input
        type="text"
        ref={ref}
        className="w-0 grow-[0.36] h-full text-center outline-none"
        value={inputValue}
        onChange={ev => {
          setInputValue(OnlyNumbers(ev.target.value))
          updateCaret()
        }}
        pattern="(.|\s)*\S(.|\s)*"
      />
      <input type="submit" hidden />
      <button
        type="button"
        onClick={() => {
          if (value >= max) return

          const newValue = value + 1
          setInputValue(newValue)
          setValue(newValue)
          ref.current.value = newValue
          if (onValueChange) onValueChange(newValue)
        }}
        className={`w-0 grow-[0.32] h-full text-2xl ${
          value >= max ? 'text-gray-200' : 'text-amber-500'
        } flex justify-center items-center hover:bg-neutral-50 transition-colors`}>
        +
      </button>
    </form>
  )
}

export default PlusMinusInput

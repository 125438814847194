import { isEqual } from 'lodash'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Dropdown from '../Dropdown'

const DropdownInput = forwardRef(({ object, name, items, onChange, defaultValue: dv, onDefaultValueSet }, ref) => {
  const [lastItems, setLastItems] = useState(items)

  const ddRef = useRef(null)
  const [defaultValue, setDefaultValue] = useState(dv ?? 0)
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    // if (!defaultValue) {
    const value = items.find(i => i.value === object[name]) ? object[name] : dv ?? items[0].value
    setDefaultValue(value)
    if (onDefaultValueSet) onDefaultValueSet(value)
    setValue(value)
    // }
  }, [object]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEqual(items, lastItems)) return

    setLastItems(items)
    const value = items.find(i => i.value === object[name]) ? object[name] : items[0].value
    // console.log(value)
    setValue(value)
    ddRef.current?.setItem(value)
  }, [items]) //eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    value: value,
    reset: () => {
      setValue(defaultValue)
      ddRef.current?.setItem(defaultValue)
    },
    valueChanged: !isEqual(value, defaultValue),
    toSend: () => ({ [name]: value }),
  }))

  return (
    defaultValue !== 0 &&
    value !== undefined && (
      <div className="w-full h-12">
        <Dropdown
          ref={ddRef}
          items={items}
          defaultValue={defaultValue}
          onChange={v => {
            onChange(v.value)
            setValue(v.value)
          }}
          itemsHeight={'3'}
        />
      </div>
    )
  )
})

export default DropdownInput

import axios from 'axios'
import { isEqual } from 'lodash'
import { createContext, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../../../Sidebar'
import BannerDnD from '../../../utils/forms/BannerDnD'
import Section from '../../../utils/forms/Section'
import TextInput from '../../../utils/forms/TextInput'
import { inputsToObj } from '../../../utils/forms/Utils'
import { deleteFile, fileExists, uploadFile, delay, setDocumentTitle } from '../../../utils/Html'
import InfoBlock from '../../../utils/InfoBlock'
import InputLabel from '../../../utils/InputLabel'
import LoadingBox from '../../../utils/LoadingBox'
import { useMainContext } from '../../../utils/MainContext'
import { NewBannerImagePath, NewSectionImagePath } from '../../../utils/Strings'
import Cardv2 from '../../../utils/Cardv2'
import SectionsDnD from '../../../utils/forms/SectionsDnD'
import { MdOutlineChevronRight } from 'react-icons/md'
import DropdownInput from '../../../utils/forms/DropdownInput'
import { useAbortableEffect } from '../../../utils/Hooks'

const HomeConfigContext = createContext({
  activeSectionState: [],
  errorState: [],
  loadingState: [],
  doneState: [],
})
const useHomeConfigContext = () => useContext(HomeConfigContext)

function AdminHomeConfig() {
  const { firebase, showToast } = useMainContext()
  const navigate = useNavigate()

  const [pageLoaded, setPageLoaded] = useState(false)
  const [data, setData] = useState(null)
  const banner = data?.home_config?.banner ?? null
  const popularCategories = data?.home_config?.popular_categories
  const categories = data?.categories ?? null
  const sections = data?.home_config?.sections ?? null

  const [activeSection, setActiveSection] = useState('')
  const [error, setError] = useState({ input: '', text: '' })
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const bannerRef = useRef(null)
  const bannerInputsRef = useRef([])
  const popularCategoriesRef = useRef(null)
  const popularCategoriesInputsRef = useRef([])
  const sectionsRef = useRef([])
  const sectionsInputsRef = useRef([])

  useAbortableEffect(
    signal => {
      if (!firebase.user || firebase.user.role === 'user') {
        navigate('/', { replace: true })
      }
      setDocumentTitle('Modificar página principal')
      ;(async () => {
        setPageLoaded(false)
        setData(null)
        setActiveSection('')
        setError({ input: '', text: '' })
        setLoading(false)
        setDone(false)

        try {
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_home_config_admin', {
            params: {
              user_id: firebase.user.uid,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)

          result.data.home_config.popular_categories = result.data.home_config.popular_categories.reduce(
            (acc, curr, i) => ({ ...acc, [i]: curr }),
            {}
          )
          setData(result.data)
          setPageLoaded(true)
        } catch (err) {
          if (err.code === 'ERR_CANCELED') return
          // console.log(err)
          showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
        }
      })()
    },
    [firebase.user, navigate, showToast]
  )

  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={true} />
      <div className="w-full xl:w-content-max-width mx-auto">
        {pageLoaded && categories ? (
          <div className="w-full px-2 xl:px-60 pb-8">
            <div className="my-8 max-xl:my-4">
              <h1 className="text-xl xl:text-2xl font-medium">Página principal</h1>
            </div>
            <div className="w-full flex flex-col gap-4 xl:gap-6">
              <HomeConfigContext.Provider
                value={{
                  activeSectionState: [activeSection, setActiveSection],
                  errorState: [error, setError],
                  loadingState: [loading, setLoading],
                  doneState: [done, setDone],
                }}>
                <SectionContainer
                  ref={bannerRef}
                  info={{
                    title: 'Banners',
                    subtitle: 'Avisá a los usuarios de novedades',
                    content: (
                      <div className="w-full flex flex-col gap-4">
                        <InfoBlock
                          text={
                            'Las imágenes tienen que ser en formato .jpg, .png o .webp y tener una resolución mínima de 1600x340.'
                          }
                        />
                        <BannerDnD
                          ref={el => (bannerInputsRef.current[0] = el)}
                          object={banner}
                          onChange={() => bannerRef.current.handleInputChange()}
                        />
                      </div>
                    ),
                  }}
                  inputsRef={bannerInputsRef}
                  state={banner}
                  setState={banner => setData(cd => ({ ...cd, banner }))}
                />
                <SectionContainer
                  ref={popularCategoriesRef}
                  info={{
                    title: 'Categorías populares',
                    subtitle: 'Elegí 4 categorías para destacar',
                    content: (
                      <div className="w-full flex flex-col gap-4">
                        <PopularCategoriesInput
                          ref={el => (popularCategoriesInputsRef.current[0] = el)}
                          object={popularCategories}
                          categories={categories}
                          onChange={() => popularCategoriesRef.current.handleInputChange()}
                        />
                      </div>
                    ),
                  }}
                  inputsRef={popularCategoriesInputsRef}
                  state={popularCategories}
                  setState={popular_categories => setData(cd => ({ ...cd, popular_categories }))}
                />
                <SectionContainer
                  ref={sectionsRef}
                  info={{
                    title: 'Secciones',
                    subtitle: 'Destacá productos en secciones personalizables',
                    content: (
                      <div className="w-full flex flex-col gap-4">
                        <InfoBlock
                          text={
                            'Las imágenes tienen que ser en formato .jpg, .png o .webp y tener una resolución mínima de 1600x340.'
                          }
                        />
                        <SectionsDnD
                          ref={el => (sectionsInputsRef.current[0] = el)}
                          object={sections}
                          onChange={() => sectionsRef.current.handleInputChange()}
                        />
                      </div>
                    ),
                    errors: error.input === 'sections' ? [error] : null,
                  }}
                  inputsRef={sectionsInputsRef}
                  state={sections}
                  setState={sections => setData(cd => ({ ...cd, sections }))}
                />
              </HomeConfigContext.Provider>
            </div>
          </div>
        ) : (
          <div className="w-full xl:px-60 pb-8">
            <LoadingBox text={'Cargando configuración...'} />
          </div>
        )}
      </div>
    </div>
  )
}

const SectionContainer = forwardRef(({ info, inputsRef, state, setState }, ref) => {
  const { firebase, showToast } = useMainContext()
  const [activeSection, setActiveSection] = useHomeConfigContext().activeSectionState
  const [, setError] = useHomeConfigContext().errorState
  const [loading, setLoading] = useHomeConfigContext().loadingState
  const [done, setDone] = useHomeConfigContext().doneState
  const [allowActions, setAllowActions] = useState(false)

  useImperativeHandle(ref, () => ({
    send: async () => await handleSend(),
    handleInputChange: () => {
      // setError({ input: '', text: '' })
      setAllowActions(true)
      setActiveSection(info.title)
    },
  }))

  const handleCancel = () => {
    inputsRef.current.forEach(r => r.reset())
    setAllowActions(false)
    setActiveSection('')
  }

  const handleSend = async () => {
    const params = inputsToObj(inputsRef)

    if (!isEqual(params, {})) {
      try {
        setLoading(true)

        if (params.banner) {
          const items = params.banner
          const itemsToSend = []

          // console.log(items)

          for (const item of items) {
            // console.log(item)
            if (item.blob) {
              // console.log('blob')
              // Si el objeto 'image' tiene la key 'blob', significa que es una imágen agregada y se sube a Firebase
              let tryCount = 0
              let newPath = NewBannerImagePath(item.blob.type.split('/')[1])
              while (await fileExists(firebase.storage, newPath)) {
                // Si existe un archivo con el path pasado, creo un nuevo path y lo intento de nuevo
                newPath = NewBannerImagePath(item.blob.type.split('/')[1])

                tryCount++
                if (tryCount >= 10) {
                  setError({ input: 'images', text: 'Error al subir una o más fotos' })
                  return
                }
              }

              // console.log(newPath)

              const src = await uploadFile(firebase.storage, item.blob, newPath)
              // console.log(`Nueva imagen: ${src}`)

              itemsToSend.push({ image: src, link: item.link })
            } else if (item.remove) {
              // console.log('remove')
              // Si el objeto 'image' tiene la key 'remove', significa que es una imágen borrada y se borra de a Firebase
              let path = item.src.includes('universal-tools-web.appspot.com')
                ? item.src.split('?')[0].split('universal-tools-web.appspot.com')[1].split('/').at(-1)
                : undefined

              // console.log(path)
              if (path) {
                // Si path no es 'undefined', significa que es una imagen subida a Firebase anteriormente
                path = decodeURIComponent(path)
                await deleteFile(firebase.storage, path)
                // console.log(`Se borró imagen: ${path}`)
              }
            } else {
              // console.log('edited')
              // Si no tiene ninguno, simplemente se agrega al array
              itemsToSend.push({ image: item.src, link: item.link })
            }
          }

          // console.log(itemsToSend)

          params.banner = itemsToSend
        } else if (params.sections) {
          const items = params.sections
          const itemsToSend = []

          if (items.some(item => item.products.some(product => product === ''))) {
            showToast('Hay secciones con productos mal ingresados.', 'bad')
            setError({ input: 'sections', text: 'Hay secciones con productos mal ingresados.' })
            setLoading(false)
            return
          }

          for (const item of items) {
            if (item.blob) {
              // Si el objeto 'image' tiene la key 'blob', significa que es una imágen agregada y se sube a Firebase
              let tryCount = 0
              let newPath = NewSectionImagePath(item.blob.type.split('/')[1])
              while (await fileExists(firebase.storage, newPath)) {
                // Si existe un archivo con el path pasado, creo un nuevo path y lo intento de nuevo
                newPath = NewSectionImagePath(item.blob.type.split('/')[1])

                tryCount++
                if (tryCount >= 10) {
                  setError({ input: 'images', text: 'Error al subir una o más fotos' })
                  return
                }
              }

              const src = await uploadFile(firebase.storage, item.blob, newPath)
              // console.log(`Nueva imagen: ${src}`)

              itemsToSend.push({ image: src, title: item.title, products: item.products })
            } else if (item.remove) {
              // Si el objeto 'image' tiene la key 'remove', significa que es una imágen borrada y se borra de a Firebase
              let path = item.src.includes('universal-tools-web.appspot.com')
                ? item.src.split('?')[0].split('universal-tools-web.appspot.com')[1].split('/').at(-1)
                : undefined
              if (path) {
                // Si path no es 'undefined', significa que es una imagen subida a Firebase anteriormente
                path = decodeURIComponent(path)
                await deleteFile(firebase.storage, path)
                // console.log(`Se borró imagen: ${path}`)
              }
            } else {
              // Si no tiene ninguno, simplemente se agrega al array
              itemsToSend.push({ image: item.src, title: item.title, products: item.products })
            }
          }

          params.sections = itemsToSend
        }

        // console.log(params)

        const result = await axios.put(
          process.env.REACT_APP_API_LINK + 'update_home_config',
          {
            user_id: firebase.user?.uid,
            action: 'update',
            ...params,
          },
          { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
        )
        // console.log(result)

        setLoading(false)
        setDone(true)

        // console.log(result)
        // console.log(result.data.return_data)
        // console.log(Object.entries(params)[0][1])
        // console.log(result.data.return_data ?? Object.entries(params)[0][1])
        setState(result.data.return_data ?? Object.entries(params)[0][1])
        showToast('¡Listo! Los cambios estan guardados.', 'good')

        await delay(1000)

        setDone(false)
        setAllowActions(false)
        setActiveSection('')
      } catch (err) {
        // console.log(err)
        setError({ input: 'unkn', text: 'Ocurrió un error desconocido.' })
        setLoading(false)
        showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
      }
    } else {
      setLoading(false)
      setDone(false)
      setAllowActions(false)
      setActiveSection('')
    }
  }

  return (
    <Section
      info={{
        ...info,
        allowActions,
        loading: loading,
        done,
        handleCancel,
        handleSend,
        modifiable: activeSection === '' || activeSection === info.title,
      }}
    />
  )
})

const PopularCategoriesInput = forwardRef(({ object, categories, onChange }, ref) => {
  const categoriasPopularesRef = useRef([])

  useImperativeHandle(ref, () => ({
    reset: () => categoriasPopularesRef.current.forEach(cp => cp.reset()),
    valueChanged: categoriasPopularesRef.current.some(cp => cp.valueChanged),
    toSend: () => ({ popular_categories: categoriasPopularesRef.current.map(cp => cp.toSend()) }),
  }))

  return Array(4)
    .fill('')
    .map((_, index) => (
      <PopularCategoryInput
        key={index}
        ref={el => (categoriasPopularesRef.current[index] = el)}
        index={index}
        object={object[index]}
        categories={categories}
        onChange={onChange}
      />
    ))
})

const PopularCategoryInput = forwardRef(({ index, object, categories, onChange }, ref) => {
  const [defaultValue, setDefaultValue] = useState(object)
  const [value, setValue] = useState(object)

  const inputsRef = useRef([])

  useEffect(() => {
    setDefaultValue(object)
    setValue(object)
  }, [object])

  useImperativeHandle(ref, () => ({
    value: value,
    reset: () => {
      setValue(defaultValue)
    },
    valueChanged: inputsRef.current.some(cp => cp.valueChanged),
    toSend: () => ({ ...inputsRef.current.reduce((c, a) => ({ ...c, ...a.toSend() }), {}) }),
  }))

  return (
    <>
      <span className="font-medium">{index + 1}.</span>
      <div className="w-full flex max-xl:flex-col gap-4 max-xl:items-center">
        <div className="w-full xl:w-0 xl:grow">
          <InputLabel text="Categoría" />
          <div className="w-full h-12">
            <DropdownInput
              ref={el => (inputsRef.current[0] = el)}
              object={value}
              name={'category_id'}
              items={[...categories?.map(item => ({ value: item.category_id, text: item.name }))]}
              onChange={v => {
                setValue({ ...value, category_id: v })
                onChange()
              }}
            />
          </div>
        </div>
        <MdOutlineChevronRight className="w-6 h-6 max-xl:-mb-4 xl:mt-8 text-neutral-400 max-xl:rotate-90" />
        <div className="w-full xl:w-0 xl:grow">
          <InputLabel text="Subcategoría" />
          <div className="w-full h-12">
            <DropdownInput
              ref={el => (inputsRef.current[1] = el)}
              object={value}
              name={'subcategory_id'}
              items={[
                { value: '', text: 'Elegí una subcategoría' },
                ...categories
                  ?.find(item => item.category_id === value.category_id)
                  .children.map(item => ({ value: item.category_id, text: item.name })),
              ]}
              onChange={v => {
                setValue({ ...value, subcategory_id: v })
                onChange()
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="w-40 xl:w-80 flex pointer-events-none">
          <Cardv2
            img={value?.image}
            sub_img={value?.sub_image}
            title={
              value.subcategory_id
                ? categories
                    ?.find(cat => cat.category_id === value.category_id)
                    ?.children.find(child => child.category_id === value.subcategory_id)?.name
                : categories?.find(cat => cat.category_id === value.category_id)?.name
            }
          />
        </div>
      </div>
      <div className="flex max-xl:flex-col gap-4">
        <div className="xl:w-0 xl:grow">
          <InputLabel text={'Imágen de fondo'} />
          <TextInput
            ref={el => (inputsRef.current[2] = el)}
            object={value}
            name="image"
            onChange={v => {
              setValue({ ...value, image: v })
              onChange()
            }}
          />
        </div>
        <div className="xl:w-0 xl:grow">
          <InputLabel text={'Imágen complementaria'} />
          <TextInput
            ref={el => (inputsRef.current[3] = el)}
            object={value}
            name="sub_image"
            onChange={v => {
              setValue({ ...value, sub_image: v })
              onChange()
            }}
          />
        </div>
      </div>
    </>
  )
})

export default AdminHomeConfig

import { useEffect, useRef, useState } from 'react'
import { Link, redirect, useSearchParams } from 'react-router-dom'

import { BsPersonCircle } from 'react-icons/bs'

import { useMainContext } from '../utils/MainContext'

import { AES, enc } from 'crypto-js'
import { sendPasswordResetEmail } from 'firebase/auth'
import ButtonWithProgress from '../utils/ButtonWithProgress'
import { delay, setDocumentTitle } from '../utils/Html'

function ForgotPassword() {
  const { firebase } = useMainContext()
  const searchParams = useSearchParams()[0]

  const [sendable, setSendable] = useState(false)
  const [emailInput, setEmailInput] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ input: null, text: null }) //eslint-disable-line no-unused-vars
  const resendTime = 30
  const [timer, setTimer] = useState(resendTime)

  const emailInputRef = useRef(null)

  useEffect(() => {
    try {
      const encryptedData = decodeURIComponent(searchParams.get('p'))
      const str = AES.decrypt(encryptedData, 'xd').toString(enc.Utf8)
      const decryptedData = JSON.parse(str)

      // ue = User E-mail (mail para recuperar la contraseña)
      if ('ue' in decryptedData) {
        const email = decryptedData['ue']
        setEmailInput(email)
        emailInputRef.current.value = email

        setDocumentTitle('Recuperar contraseña')
      }
    } catch (err) {
      // console.log(err);
      // TODO: Mandar error a DB
      return redirect('/nf')
    }
  }, [searchParams]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (timer === 0) {
      setSendable(true)
      return
    }
    const updateTimer = async () => {
      await delay(1000)
      setTimer(timer - 1)
    }
    updateTimer()
  }, [timer])

  const handleResend = async () => {
    if (sendable) {
      setLoading(true)
      try {
        await sendPasswordResetEmail(firebase.auth, emailInput)
        setLoading(false)
        setSendable(false)
        setTimer(resendTime)
      } catch (err) {
        setLoading(false)
        setError({
          input: 'reset',
          code: err.code ?? null,
          text: err.message ?? 'Error desconocido',
        })
        // TODO: Mandar error a DB
        // console.log(err);
      }
    }
  }

  return (
    <section className="w-full grow bg-white xl:bg-pagebg">
      <div className="w-full px-4 lg:w-[900px] lg:mx-auto pt-4 pb-20 xl:py-12 flex flex-col lg:flex-row text-black">
        <div className="w-full lg:w-0 lg:grow">
          <h1 className="text-2xl xl:text-4xl xl:font-light pb-4">Revisá tu correo</h1>
          <div
            className="w-72 h-12 mb-3 py-2 flex items-center
                         bg-white rounded-full border border-gray-200 shadow-sm">
            <BsPersonCircle className="w-6 h-6 mx-4 text-neutral-400" />
            <div className="grow flex flex-col">
              <span className="text-xs tracking-wide">{emailInput}</span>
              <Link className="w-max text-xs font-medium text-amber-500 tracking-wide" to="/login/user">
                Entrar a otra cuenta
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full max-xl:mt-4 lg:w-0 lg:grow-[1.2]">
          <div className="w-full xl:p-10 bg-white xl:border xl:border-gray-200 xl:rounded-md xl:shadow-sm">
            <input type="email" className="hidden" hidden ref={emailInputRef} disabled readOnly />
            <div className="hyphens-none">Te enviamos un e-mail para cambiar tu contraseña a:</div>
            <div className="mb-8 font-medium">{emailInput}</div>
            <div className="mb-2">¿No te llegó?</div>
            <div className="flex max-xl:flex-col items-center gap-4">
              <div className="w-full xl:w-36 h-12">
                <ButtonWithProgress
                  text="Reenviar"
                  clickable={sendable}
                  loading={loading}
                  done={false}
                  onClick={handleResend}
                  fullWidth={true}
                />
              </div>
              {timer > 0 ? (
                <span className="text-sm text-neutral-400">
                  Podés reenviar el e-mail en 00:
                  {timer.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ForgotPassword

import { isEqual } from 'lodash'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { MdError, MdOutlineAdd, MdOutlineDelete, MdOutlineImage } from 'react-icons/md'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import { isImageFileValid } from '../Html'
import ShowImage from '../ShowImage'
import axios from 'axios'
import { useMainContext } from '../MainContext'
import { FormatPrice, ProductLink } from '../Strings'
import { Link } from 'react-router-dom'
import { useAbortableEffect } from '../Hooks'

const SectionsDnD = forwardRef(({ object, onChange }, ref) => {
  const { firebase, showToast } = useMainContext()
  const [defaultValue, setDefaultValue] = useState([])
  const [items, setItems] = useState(defaultValue)
  const lastInputTime = useRef({})
  const [foundProducts, setFoundProducts] = useState({})

  // const getLastInputTime = useCallback(() => lastInputTime, [lastInputTime])

  useAbortableEffect(
    signal => {
      const value = object.map((v, i) => ({
        id: '' + i,
        src: v.image,
        title: v.title,
        products: v.products,
      }))
      setDefaultValue(value)
      setItems(value)
      ;(async () => {
        // console.log(value)
        for (const item of value) {
          // console.log(item)
          for (const product of item.products) {
            // console.log(product.upc)
            await getProduct(product.upc, signal)
          }
        }
      })()
    },
    [object]
  ) //eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    value: items,
    reset: () => setItems(defaultValue),
    valueChanged: !isEqual(items, defaultValue),
    toSend() {
      return {
        sections: items.map(item => ({
          src: item.src,
          title: item.title,
          products: item.products.map(product =>
            foundProducts[product.upc] && !foundProducts[product.upc].removed
              ? foundProducts[product.upc].product_id
              : ''
          ),
          blob: item.blob,
          remove: !!item.remove,
        })),
      }
    },
  }))

  const handleDragEnd = result => {
    if (!result.destination) {
      return
    }

    const { source, destination } = result

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    const updatedItems = Array.from(items.filter(item => !item.remove))
    const [removed] = updatedItems.splice(source.index, 1)
    updatedItems.splice(destination.index, 0, removed)

    setItems([...updatedItems, ...(items.filter(item => item.remove) ?? [])])
    onChange()
  }

  const handleFile = (file, item) => {
    // console.log(file);
    isImageFileValid(file, { width: 1600, height: 340 }, err => {
      if (err) {
        if (err === 'type') showToast('El formato de imágen no es válido.', 'bad')
        else if (err === 'resolution') showToast('La resolución de la imágen no es válida.', 'bad')
        else showToast('Error inesperado al leer la imágen.', 'bad')
        return
      }
      setItems(items.map(it => (it.id === item.id ? { ...it, blob: file, src: URL.createObjectURL(file) } : it)))
      onChange()
    })
  }

  const handleDrop = (ev, item) => {
    ev.preventDefault()

    if (ev.dataTransfer.items) {
      // console.log("item");
      const droppedItem = [...ev.dataTransfer.items][0]
      if (droppedItem.kind === 'file') handleFile(droppedItem.getAsFile(), item)
    } else {
      // console.log("file");
      handleFile(ev.dataTransfer.files[0], item)
    }
  }

  const handleNewItem = () => {
    const id = '' + (items.reduce((max, v) => (+v.id > max ? +v.id : max), -1) + 1)
    setItems([...items, { id: id, src: '', title: '', products: [] }])
    setTimeout(() => {
      const node = document.getElementById(`section-${id}`)
      if (node) node.scrollIntoView()
    }, 100)
    onChange()
  }

  const getProduct = async (upc, signal) => {
    try {
      if (!upc || upc === '' || upc in foundProducts) return

      const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_products', {
        params: {
          user_id: firebase.user?.uid,
          search: upc,
        },
        headers: {
          'X-Api-Key': process.env.REACT_APP_API_KEY,
        },
        signal,
      })
      if (result.data.products.length > 0) {
        const foundProduct = result.data.products[0]
        setFoundProducts(cv => ({ ...cv, [upc]: foundProduct }))
        setItems(ci =>
          ci.map(item => ({
            ...item,
            products: item.products.map(product =>
              product.upc === upc ? { ...product, product_id: foundProduct.product_id } : product
            ),
          }))
        )
      }
    } catch (err) {
      // console.log(err)
    }
  }

  return (
    <div className="w-full flex flex-col">
      <div className="w-full overflow-y-scroll">
        <div className={`w-full flex flex-col`}>
          <button
            type="button"
            className={`w-full h-14 ${
              items.length > 0 ? 'mb-2' : ''
            } flex justify-center items-center rounded-md border border-gray-200
                            hover:border-gray-500 transition-colors`}
            onClick={handleNewItem}>
            <MdOutlineAdd className="w-6 h-6 text-gray-500" />
            <span className="ml-2 text-gray-500">Nueva sección</span>
          </button>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="image-list" direction="vertical">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="flex flex-col">
                  {items
                    .filter(item => !item.remove)
                    .map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {provided => (
                          <div
                            id={`section-${item.id}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="relative bg-white w-full my-2 flex flex-col transition-shadow group
                                        select-none hover:shadow-md"
                            style={{
                              ...provided.draggableProps.style,
                            }}>
                            <div className="w-full p-4 flex items-center gap-4">
                              <div className="w-0 grow h-full flex flex-col justify-center">
                                <span className="text-xs xl:text-sm text-gray-500">Título</span>
                                <input
                                  type="text"
                                  value={item.title}
                                  onChange={ev => {
                                    setItems(
                                      items.map(it => (it.id === item.id ? { ...it, title: ev.target.value } : it))
                                    )
                                    onChange()
                                  }}
                                  className="w-full max-xl:text-xs border-b border-gray-200 hover:border-gray-500 focus:border-dewalt
                                  outline-none"
                                />
                              </div>
                              <DeleteButton
                                onClick={() => {
                                  setItems(items.map(it => (it.id === item.id ? { ...it, remove: true } : it)))
                                  onChange()
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <AspectRatio.Root ratio={1600 / 340}>
                                <div className="w-full h-full">
                                  <label
                                    htmlFor={`section-picture-${item.id}`}
                                    className="w-full h-full cursor-pointer"
                                    onDragOver={ev => ev.preventDefault()}
                                    onDrop={ev => handleDrop(ev, item)}>
                                    {item.src ? (
                                      <div className="w-full h-full">
                                        <ShowImage image={item.src} alt={`Imágen ${index + 1}`} contain={false} />
                                      </div>
                                    ) : (
                                      <div
                                        className="w-full h-full border-2 border-dashed border-dewalt rounded-md
                                      flex flex-col justify-center items-center group hover:shadow-md transition-all">
                                        <MdOutlineImage className="w-6 h-6 text-dewalt" />
                                        <span className="text-sm text-neutral-400">Agregar</span>
                                      </div>
                                    )}
                                  </label>
                                  <input
                                    type="file"
                                    id={`section-picture-${item.id}`}
                                    accept="image/jpeg, image/png, image/webp"
                                    className="hidden"
                                    onChange={ev => {
                                      handleFile(ev.target.files[0], item)
                                    }}
                                  />
                                </div>
                              </AspectRatio.Root>
                            </div>
                            <div className="w-full p-4 flex flex-col gap-4">
                              {item.products.map((product, index) => {
                                // getProduct(product.upc)
                                return (
                                  <div key={index} className="w-full flex items-center gap-4">
                                    <div className="w-0 grow">
                                      <span className="text-xs xl:text-sm text-gray-500">Producto {index + 1}</span>
                                      <input
                                        type="text"
                                        value={product.upc}
                                        placeholder="UPC"
                                        onChange={ev => {
                                          setItems(
                                            items.map(it =>
                                              it.id === item.id
                                                ? {
                                                    ...it,
                                                    products: it.products.map((product, i) =>
                                                      i === index ? { ...product, upc: ev.target.value } : product
                                                    ),
                                                  }
                                                : it
                                            )
                                          )
                                          onChange()
                                          lastInputTime.current[index] = new Date().getTime()
                                          setTimeout(async () => {
                                            const delta = new Date().getTime() - lastInputTime.current[index]
                                            if (delta < 1000) return

                                            await getProduct(ev.target.value)
                                          }, 1000)
                                        }}
                                        className="w-full max-xl:text-xs border-b border-gray-200 hover:border-gray-500 focus:border-dewalt
                                          outline-none"
                                      />
                                      {foundProducts[product.upc] && (
                                        <div className="w-full pt-2 flex">
                                          <Link
                                            to={ProductLink(product.product_id, foundProducts[product.upc].title)}
                                            className="w-12 h-12 xl:w-16 xl:h-16"
                                            target={'_blank'}
                                            rel="noreferrer">
                                            <ShowImage image={foundProducts[product.upc].images[0]} />
                                          </Link>
                                          <div className="pl-2 xl:pl-4 flex-1 flex flex-col">
                                            <Link
                                              to={ProductLink(product.product_id, foundProducts[product.upc].title)}
                                              className="max-xl:text-xs line-clamp-2"
                                              target={'_blank'}
                                              rel="noreferrer">
                                              {foundProducts[product.upc].title}
                                            </Link>
                                            {foundProducts[product.upc].removed ? (
                                              <p className="text-sm text-red-600 font-bold flex">
                                                <MdError className="w-5 h-5 mr-1" /> PRODUCTO ELIMINADO
                                              </p>
                                            ) : (
                                              <>
                                                <p className="text-gray-500 text-xs xl:text-sm">
                                                  {`${
                                                    foundProducts[product.upc].stock +
                                                    foundProducts[product.upc].offer_cost_stock
                                                  } en stock`}
                                                </p>
                                                <div className="flex items-center text-sm">
                                                  <span>
                                                    {FormatPrice(
                                                      foundProducts[product.upc].offer_price ??
                                                        foundProducts[product.upc].normal_price
                                                    )}
                                                  </span>
                                                  {foundProducts[product.upc].offer_price && (
                                                    <del className="pl-1 text-gray-500 text-xs">
                                                      {FormatPrice(foundProducts[product.upc].normal_price)}
                                                    </del>
                                                  )}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <DeleteButton
                                      size="small"
                                      onClick={() => {
                                        setItems(
                                          items.map(it =>
                                            it.id === item.id
                                              ? {
                                                  ...it,
                                                  products: it.products.filter((_, i) => i !== index),
                                                }
                                              : it
                                          )
                                        )
                                        onChange()
                                      }}
                                    />
                                  </div>
                                )
                              })}
                              <button
                                type="button"
                                className={`w-max flex`}
                                onClick={() => {
                                  setItems(
                                    items.map(it =>
                                      it.id === item.id
                                        ? {
                                            ...it,
                                            products: [...it.products, ''],
                                          }
                                        : it
                                    )
                                  )
                                  onChange()
                                }}>
                                <MdOutlineAdd className="w-4 h-4xl:w-6 xl:h-6 text-gray-500" />
                                <span className="ml-2 max-xl:text-xs text-gray-500">Agregar producto</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  )
})

export function DeleteButton({ onClick, size = 'normal' }) {
  return (
    <button
      type="button"
      className={`${
        size === 'small' ? 'w-5 h-5' : 'w-7 h-7'
      } p-1 bg-dewalt hover:bg-amber-300 text-white rounded-full xl:opacity-0 xl:group-hover:opacity-100 transition-all`}
      onClick={onClick}>
      <MdOutlineDelete className="w-full h-full" />
    </button>
  )
}

export default SectionsDnD

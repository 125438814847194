import axios from 'axios'
import { toNumber, round } from 'lodash'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { MdOutlineReceiptLong, MdPerson, MdRefresh } from 'react-icons/md'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Sidebar from '../../../Sidebar'
import Breadcrumb from '../../../utils/Breadcrumb'
import DefaultAlertDialog from '../../../utils/DefaultAlertDialog'
import { useAbortableEffect, useDolarBlue } from '../../../utils/Hooks'
import { delay, setDocumentTitle } from '../../../utils/Html'
import LoadingBox from '../../../utils/LoadingBox'
import { useMainContext } from '../../../utils/MainContext'
import ShowImage from '../../../utils/ShowImage'
import { DigitGrouping, FormatPrice, TimestampToDateTextWithTime, TimestampToFullDate } from '../../../utils/Strings'
import Toggle from '../../../utils/Toggle'

function AdminViewUser() {
  const { firebase, showToast } = useMainContext()
  const { user_id } = useParams()
  const navigate = useNavigate()

  const [data, setData] = useState(null)
  const user = data?.user ?? null

  useEffect(() => {
    if (!firebase.user || firebase.user.role === 'user') {
      navigate('/', { replace: true })
    }
    setDocumentTitle('Cargando...')
  }, [firebase.user, navigate])

  useAbortableEffect(
    signal => {
      ;(async () => {
        try {
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_user_admin', {
            params: {
              user_id: firebase.user?.uid,
              q_user_id: user_id,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)

          setData(result.data)
          setDocumentTitle(`${result.data.user.name} ${result.data.user.last_name}`)
        } catch (err) {
          if (err.code === 'ERR_CANCELED') return
          // console.log(err)
          showToast(process.env.REACT_APP_TOAST_UNKNOWN_ERROR, 'bad')
        }
      })()
    },
    [firebase.user, showToast]
  )

  const provinces = JSON.parse(process.env.REACT_APP_PROVINCES)

  return (
    <div className="relative w-full bg-pagebg grow text-black">
      <ManageUTCoinsDialog sum={true} updateUTCoins={v => setData({ ...data, user: { ...user, ut_coins: v } })} />
      <ManageUTCoinsDialog sum={false} updateUTCoins={v => setData({ ...data, user: { ...user, ut_coins: v } })} />
      <DefaultAlertDialog
        title={'Historial UT Coins'}
        content={
          <div className="max-h-96 overflow-y-scroll">
            <div className="flex flex-col gap-2">
              {user?.ut_coins_history
                .sort((a, b) => a.transaction_date < b.transaction_date)
                .map((item, index) => (
                  <div key={index} className="pb-2 last:pb-0 border-b border-gray-200 last:border-b-0">
                    <div className="flex">
                      <div className="flex-1">
                        <div
                          className={`inline-block font-bold ${
                            item.amount > 0 ? 'text-green-500' : 'text-red-500'
                          }`}>{`${item.amount > 0 ? '+' : ''}${item.amount}`}</div>{' '}
                        <span className="text-sm">(Cotiz.: {FormatPrice(item.dollar_price)})</span>
                      </div>
                      {item.invoice && (
                        <a href={item.invoice.link} target="_blank" rel="noreferrer">
                          <MdOutlineReceiptLong size={18} className="text-gray-600" />
                        </a>
                      )}
                    </div>
                    <div className="text-sm">
                      <span className="font-medium">Transacción: </span>
                      {TimestampToFullDate(item.transaction_date)}
                    </div>
                    <div className="text-sm">
                      <span className="font-medium">Vencimiento: </span>
                      {item.expires_at ? TimestampToFullDate(item.expires_at) : '-'}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        }
        trigger={<div id="ut_coins_history" />}
        cancel={{ text: 'Cerrar' }}
      />
      <Sidebar admin={true} />
      <div className="w-full xl:w-content-max-width mx-auto xl:px-10 pb-4">
        {data ? (
          <div className="px-2 xl:px-60 py-4 xl:py-8 flex flex-col gap-2 xl:gap-4">
            <Breadcrumb
              items={[
                {
                  name: 'Usuarios',
                  link: '/admin/users',
                },
                {
                  name: `${user.name} ${user.last_name}`,
                },
              ]}
            />
            <div className="w-full p-4 xl:p-6 mt-2 bg-white flex gap-4 xl:gap-6 items-center rounded-md shadow-lg">
              <div className="w-14 h-14 xl:w-20 xl:h-20 bg-gray-100 rounded-full border border-gray-200">
                {user.profile_pic ? (
                  <ShowImage image={user.profile_pic} contain={true} />
                ) : (
                  <MdPerson className="w-full h-full p-2 text-gray-400" />
                )}
              </div>

              <div className="w-0 grow flex flex-col max-xl:text-sm">
                <span className="text-lg xl:text-xl">
                  {user.name} {user.last_name}
                </span>
                <span className="text-gray-500">{user.cuit || 'CUIT no disponible'}</span>
                <span className="text-gray-500">{user.email}</span>
                <span className="text-gray-500">Registrado el {TimestampToDateTextWithTime(user.date_created)}</span>
              </div>
            </div>
            <div className="w-full p-4 bg-white flex flex-col gap-4 rounded-md">
              <span className="font-medium text-lg xl:text-xl">Información</span>
              <span className="text-sm">
                <span className="font-medium">ID: </span>
                <span className="text-gray-500">{user_id}</span>
              </span>
              <div className="flex max-xl:flex-col gap-4 xl:gap-6">
                <div className="w-full xl:w-0 xl:grow flex flex-col">
                  <span className="mx-auto font-medium">Domicilio</span>
                  <div className="w-full h-[1px] my-1 bg-gray-200" />
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">Calle: </span>
                    {user.street || '-'}
                  </span>
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">Altura: </span>
                    {user.street_number || '-'}
                  </span>
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">Piso: </span>
                    {user.floor || '-'}
                  </span>
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">Depto: </span>
                    {user.flat || '-'}
                  </span>
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">Código postal: </span>
                    {user.zip_code || '-'}
                  </span>
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">Ciudad: </span>
                    {user.city || '-'}
                  </span>
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">Provincia: </span>
                    {user.province ? provinces[user.province].name : '-'}
                  </span>
                  <span className="w-full flex gap-2 font-light">
                    <span className="font-normal">Info. adicional: </span>
                    <div className="w-0 grow flex justify-end">{user.additional_info || '-'}</div>
                  </span>
                </div>
                <div className="w-full xl:w-0 xl:grow flex flex-col">
                  <span className="mx-auto font-medium">Datos personales</span>
                  <div className="w-full h-[1px] my-1 bg-gray-200" />
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">Nombre completo: </span>
                    {user.name} {user.last_name}
                  </span>
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">Teléfono: </span>
                    {user.area_code} {user.phone_number}
                  </span>
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">E-mail: </span>
                    {user.email}
                  </span>
                  <span className="w-full flex justify-between font-light">
                    <span className="font-normal">CUIT / CUIL: </span>
                    {user.cuit}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex max-xl:flex-col gap-2 xl:gap-4">
              <div className="w-full xl:w-0 xl:grow p-4 bg-white flex flex-col gap-4 rounded-md">
                <span className="font-medium text-lg xl:text-xl">UT Coins</span>
                <span>Tiene {DigitGrouping(user.ut_coins)} UT Coins.</span>
                {firebase.user?.role === 'admin' && (
                  <div className="flex max-xl:flex-col gap-2">
                    <button
                      type="button"
                      className="w-full xl:w-0 xl:grow h-10 font-medium bg-green-400 hover:bg-black text-black hover:text-green-400
                              grid place-items-center rounded-md transition-colors"
                      onClick={() => document.getElementById('sum_ut_coins').click()}>
                      Sumar
                    </button>
                    <button
                      type="button"
                      className="w-full xl:w-0 xl:grow h-10 font-medium bg-red-400 hover:bg-black text-black hover:text-red-400
                              grid place-items-center rounded-md transition-colors"
                      onClick={() => document.getElementById('subtract_ut_coins').click()}>
                      Restar
                    </button>
                  </div>
                )}
                <button
                  className="w-max font-medium text-amber-500 hover:text-amber-600"
                  onClick={() => document.getElementById('ut_coins_history').click()}>
                  Ver historial
                </button>
              </div>
              <div className="w-full xl:w-0 xl:grow p-4 bg-white flex flex-col gap-4 rounded-md">
                <span className="font-medium text-lg xl:text-xl">Pedidos</span>
                <span>El usuario realizó {user.orders} pedidos.</span>
                <Link to={`/admin/orders?user=${user_id}`} className="font-medium text-amber-500 hover:text-amber-600">
                  Ver sus pedidos
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <LoadingBox text="Cargando usuario..." />
        )}
      </div>
    </div>
  )
}

function ManageUTCoinsDialog({ sum, updateUTCoins }) {
  const { firebase, showToast } = useMainContext()
  const { user_id } = useParams()
  const [dolarBlue, reloadDolar] = useDolarBlue()

  const [inputs, setInputs] = useState({ amount: '', invoice: '' })
  const [calculateUTCoins, setCalculateUTCoins] = useState(true)
  const [makeInvoice, setMakeInvoice] = useState(false)
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const controllerRef = useRef(new AbortController())

  const handleSubmit = async ev => {
    try {
      if (inputs.amount === '') {
        showToast(`Ingresá una cantidad a ${sum ? 'sumar' : 'restar'}`, 'bad')
        return
      } else if (!toNumber(inputs.amount)) {
        showToast(`Ingresá una cantidad válida`, 'bad')
        return
      } else if (makeInvoice) {
        if (inputs.invoice === '') {
          showToast(`Ingresá el documento al que se facturarán los UT Coins`, 'bad')
          return
        } else if (!toNumber(inputs.invoice)) {
          showToast(`Ingresá un documento válido`, 'bad')
          return
        }
      }

      setLoading(true)

      const result = await axios.put(
        process.env.REACT_APP_API_LINK + 'update_ut_coins_admin',
        {
          user_id: firebase.user?.uid,
          q_user_id: user_id,
          action: sum ? 'sum' : 'subtract',
          amount: calculateUTCoins
            ? toNumber(inputs.amount)
            : round(toNumber(inputs.amount) / (dolarBlue.value_sell / 10), 2),
          invoice_doc: inputs.invoice,
        },
        {
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
          signal: controllerRef.current.signal,
        }
      )
      // console.log(result)

      updateUTCoins(result.data.ut_coins)

      setDone(true)
      setInputs('')
      showToast(`UT Coins ${sum ? 'sumados' : 'restados'} correctamente!`, 'good')

      await delay(500)

      setDone(false)
    } catch (err) {
      if (err.code === 'ERR_CANCELED') return
      if (err.response.data.code) {
        showToast(err.response.data.message, 'bad')
      }
      // console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const handleInputs = ev => {
    setInputs({ ...inputs, [ev.target.name]: ev.target.value })
  }

  useEffect(() => {
    const controller = controllerRef.current
    return () => controller.abort()
  }, [])

  return (
    <DefaultAlertDialog
      title={`${sum ? 'Sumar' : 'Restar'} UT Coins`}
      content={
        <>
          {!sum && <p></p>}
          <div className="w-full h-10 relative">
            <input
              name="amount"
              type="text"
              inputMode="numeric"
              value={inputs.amount}
              className="w-full h-full bg-white disabled:bg-gray-200 p-2 rounded-md border border-gray-200
              hover:border-gray-500 disabled:border-gray-200 focus:border-dewalt outline-none max-xl:text-sm"
              placeholder="Cantidad"
              disabled={loading}
              onChange={handleInputs}
              maxLength={7}
            />
            <button
              type="button"
              className="absolute top-1/2 -translate-y-1/2 right-4 text-amber-500 font-medium"
              onClick={() => setCalculateUTCoins(!calculateUTCoins)}>
              {calculateUTCoins ? 'UT Coins' : 'Pesos'}
            </button>
          </div>
          <div className="mt-2 flex justify-between">
            <div className="w-0 grow flex flex-col">
              <span>
                <span className="font-medium">Valor: </span>
                {toNumber(inputs.amount)
                  ? calculateUTCoins
                    ? `
                    ${DigitGrouping(inputs.amount)} UT Coins = ${
                        dolarBlue
                          ? FormatPrice(round(toNumber(inputs.amount) * (dolarBlue.value_sell / 10), 2))
                          : 'Cargando...'
                      }`
                    : `${FormatPrice(inputs.amount)} = ${
                        dolarBlue
                          ? `${round(toNumber(inputs.amount) / (dolarBlue.value_sell / 10), 2)} UT Coins`
                          : 'Cargando...'
                      }`
                  : '? = ?'}
              </span>
              <span>
                <span className="font-medium">Cotización: </span>
                {dolarBlue
                  ? `Compra ${FormatPrice(dolarBlue.value_buy)} - Venta ${FormatPrice(dolarBlue.value_sell)}`
                  : 'Cargando...'}
              </span>
            </div>
            <button type="button" className="w-max pr-2" onClick={reloadDolar}>
              <MdRefresh size={30} />
            </button>
          </div>
          {sum && (
            <div className="flex mt-2 gap-2 items-center">
              <Toggle
                toggled={makeInvoice}
                onClick={() => {
                  if (makeInvoice) {
                    setMakeInvoice(false)
                    setInputs({ ...inputs, invoice: '' })
                  } else setMakeInvoice(true)
                }}
                clickable={!loading}
              />{' '}
              Facturar
              <input
                name="invoice"
                type="text"
                inputMode="numeric"
                value={inputs.invoice}
                className="max-xl:flex-1 xl:w-52 h-10 bg-white disabled:bg-gray-200 p-2 rounded-md border border-gray-200
                hover:border-gray-500 disabled:hover:border-gray-200 focu:border-dewalt outline-none max-xl:text-sm"
                placeholder="Documento"
                disabled={!makeInvoice || loading}
                onChange={handleInputs}
                maxLength={8}
              />
            </div>
          )}
        </>
      }
      trigger={<div id={sum ? 'sum_ut_coins' : 'subtract_ut_coins'} />}
      submit={{ text: sum ? 'Sumar' : 'Restar', onClick: handleSubmit }}
      loading={loading}
      done={done}
      cancel={{
        text: 'Cancelar',
        onClick: () => {
          setMakeInvoice(false)
        },
      }}
    />
  )
}

export default AdminViewUser

import axios from 'axios'
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { MdContentCopy, MdOutlineFileUpload, MdOutlineReceiptLong } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import Sidebar from '../../Sidebar'
import Breadcrumb from '../../utils/Breadcrumb'
import DefaultAlertDialog from '../../utils/DefaultAlertDialog'
import { useAbortableEffect } from '../../utils/Hooks'
import { setDocumentTitle, uploadFile } from '../../utils/Html'
import LoadingBox from '../../utils/LoadingBox'
import { useMainContext } from '../../utils/MainContext'
import { TimestampToDateText } from '../../utils/Strings'

function BankTransfer({ admin, ticket }) {
  const { firebase, showToast } = useMainContext()
  const { order_id } = useParams()
  const navigate = useNavigate()

  const [pageLoaded, setPageLoaded] = useState(true)
  const [order, setOrder] = useState(null)
  const [receipt, setReceipt] = useState(null)
  const [billing, setBilling] = useState(null)

  useAbortableEffect(
    signal => {
      setPageLoaded(false)
      setDocumentTitle(`Cargando...`)

      if (!firebase.user) {
        navigate('/', { replace: true })
      }

      ;(async () => {
        try {
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_order', {
            params: {
              user_id: firebase.user?.uid,
              order_id: order_id,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)
          const order = result.data.order
          const paymentMethodTitle = order.payment.payment_method.title
          if (
            (ticket && !(paymentMethodTitle === 'RapiPago' || paymentMethodTitle === 'PagoFacil')) ||
            (!ticket && !paymentMethodTitle.includes('Transferencia'))
          ) {
            navigate(`/${firebase.user?.role}/orders/${order_id}`, { replace: true })
            return
          }
          setOrder(order)
          setReceipt(result.data.order.payment.receipt)
          setBilling(result.data.billing)

          setDocumentTitle(`Pedido #${order_id} - ${ticket ? 'Comprobante' : 'Transferencia'}`)
          setPageLoaded(true)
        } catch (err) {
          // console.log(err)
          // TODO: Mandar error a DB
        }
      })()
    },
    [firebase.user, order_id, navigate]
  )

  return (
    <div className="w-full relative grow bg-pagebg text-black">
      <Sidebar admin={admin} />
      <div className="w-full px-2 max-xl:pb-8 xl:w-content-max-width mx-auto min-h-full xl:px-10 flex-col">
        {/* <h1 className="text-2xl max-xl:text-xl font-medium my-8 max-xl:my-4">
          Pedido {pageLoaded ? `#${order_id} - Transferencia Bancaria` : ""}
        </h1> */}
        <div className="my-8">
          <Breadcrumb
            items={[
              { name: `Pedido #${order_id}`, link: `/user/orders/${order_id}` },
              { name: ticket ? 'Comprobante ' : 'Transfrencia Bancaria' },
            ]}
          />
        </div>
        {pageLoaded ? (
          <div className="w-full pb-8 flex max-xl:flex-col gap-4">
            {!ticket && (
              <Box
                title={'¿A dónde transfiero?'}
                content={billing?.map((item, index) => (
                  <div className="w-full mb-4 last:mb-0">
                    <p className="mb-2 xl:text-lg font-semibold">Opción {index + 1}</p>
                    <div className="w-full max-xl:text-sm">
                      {[
                        { title: 'Cta. Corriente', value: item.is_checking ? 'Si' : 'No' },
                        { title: 'Banco', value: item.bank },
                        { title: 'Alias', value: item.alias, copy: true },
                        { title: 'CBU', value: item.cbu, copy: true },
                        { title: 'CUIL', value: item.cuit },
                        { title: 'Titular', value: item.full_name },
                      ].map((v, i) => (
                        <div key={i} className="flex leading-6 xl:leading-7">
                          <span className="w-32">{v.title}</span>
                          <span className="w-0 grow text-neutral-600">{v.value}</span>
                          {v.copy && (
                            <CopyToClipboard
                              text={v.value}
                              onCopy={() => showToast(`Se copió el ${v.title} '${v.value}' al portapapeles.`, 'good')}>
                              <button className="hover:brightness-0 transition-all" title={v.title}>
                                <MdContentCopy className="w-4 h-4 xl:w-5 xl:h-5 text-gray-500" />
                              </button>
                            </CopyToClipboard>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              />
            )}
            <Box
              title={'Comprobante'}
              content={
                <div className="w-full flex items-center gap-4">
                  <div className="w-16 h-16 xl:w-24 xl:h-24 p-4 rounded-full bg-gray-100 text-gray-500">
                    <MdOutlineReceiptLong className="w-full h-full" />
                  </div>
                  <div className="w-0 grow flex flex-col max-xl:text-sm">
                    {receipt?.link ? (
                      <>
                        <span>Comprobante cargado</span>
                        <span className="text-sm text-gray-500">
                          Cargado el {TimestampToDateText(receipt?.date_uploaded)}
                        </span>
                        <div className="mt-2 flex max-xs:flex-col gap-2 xl:items-center">
                          <a
                            href={receipt?.link}
                            className="font-medium text-amber-500 hover:text-amber-600 transition-all"
                            rel="noreferrer"
                            target="_blank">
                            Ver comprobante
                          </a>
                          {!order?.events.some(v => v.event === 'payment_approved') && (
                            <>
                              <div className="w-[2px] h-5 bg-gray-200 max-xs:hidden" />
                              <UploadReceiptButton
                                order_id={order_id}
                                user_id={firebase.user?.uid}
                                receipt_link={receipt.link}
                              />
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <span className="hyphens-none">
                          {firebase.user?.role === 'user'
                            ? 'Todavía no cargaste tu comprobante'
                            : 'El usuario todavía no cargó el comprobante'}
                        </span>
                        <UploadReceiptButton order_id={order_id} user_id={firebase.user?.uid} />
                      </>
                    )}
                  </div>
                </div>
              }
            />
          </div>
        ) : (
          <LoadingBox />
        )}
      </div>
    </div>
  )
}

function Box({ title, content }) {
  return (
    <div className="w-full xl:w-0 xl:grow flex flex-col bg-white rounded-md shadow-sm hover:shadow-lg transition-all">
      <div className="w-full p-4 border-b border-gray-200">
        <span className="font-medium">{title}</span>
      </div>
      <div className="p-4 h-full">{content}</div>
    </div>
  )
}

function UploadReceiptButton({ order_id, user_id, receipt_link }) {
  const { firebase } = useMainContext()
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const onSubmit = async ev => {
    ev.preventDefault()
    // console.log(selectedFile)

    if (
      !selectedFile ||
      !(
        selectedFile.type === 'image/jpeg' ||
        selectedFile.type === 'image/png' ||
        selectedFile.type === 'application/pdf'
      )
    )
      return

    try {
      setLoading(true)
      const fileUrl = await uploadFile(
        firebase.storage,
        selectedFile,
        `/receipts/${order_id}.${selectedFile.type.split('/')[1]}`
      )
      // console.log(fileUrl)

      const result = await axios.put(
        process.env.REACT_APP_API_LINK + 'update_order',
        { order_id: order_id, user_id: user_id, receipt: fileUrl },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      window.location.reload(false)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      // console.log(err)
      // TODO: Mandar error a DB
    }
  }

  return (
    <DefaultAlertDialog
      trigger={
        <button className="w-max font-medium text-amber-500 hover:text-amber-600 transition-colors">
          {receipt_link ? 'Cargar otro' : 'Cargar comprobante'}
        </button>
      }
      title={`Cargar ${receipt_link ? 'otro ' : ''}comprobante`}
      content={
        <>
          <p>Elegí el archivo del comprobante.</p>
          <p>Puede ser una imágen o archivo PDF.</p>

          <div className="w-full mt-4 flex max-xl:flex-col">
            <label
              htmlFor="file"
              className="w-full xl:w-max h-12 p-2 flex items-center max-xl:justify-center rounded-md
                border border-gray-200 hover:border-black transition-all
                cursor-pointer">
              <MdOutlineFileUpload className="w-6 h-6" />
              <span className="ml-2">Buscar archivo</span>
            </label>
            <div className="xl:w-0 xl:grow max-xl:mt-1 xl:ml-2 flex flex-col max-xl:text-sm">
              <span>Archivo seleccionado:</span>
              <span className="font-medium text-gray-500 line-clamp-1">
                {selectedFile ? selectedFile.name : 'Ninguno'}
              </span>
            </div>
            <input
              id="file"
              type="file"
              accept="image/jpeg, image/png, application/pdf"
              className="hidden"
              onChange={ev => setSelectedFile(ev.target.files[0])}
            />
          </div>
        </>
      }
      submit={{ text: 'Cargar', onSubmit: onSubmit }}
      cancel={{ text: 'Cancelar', onClick: () => setSelectedFile(null) }}
      loading={loading}
    />
  )
}

export default BankTransfer

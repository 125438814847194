import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import axios from 'axios'
import { round } from 'lodash'
import { useState } from 'react'
import { MdPrint } from 'react-icons/md'
import { TbCoin } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { useAbortableEffect } from '../utils/Hooks'
import { setDocumentTitle, useWindowSize } from '../utils/Html'
import LoadingBox from '../utils/LoadingBox'
import { useMainContext } from '../utils/MainContext'
import ShowImage from '../utils/ShowImage'
import { DigitGrouping, FormatPrice, TimestampToDateTextWithTime } from '../utils/Strings'
import { ShippingMethodIcon } from '../utils/Switches'

function OrderBudget() {
  const { firebase } = useMainContext()
  const navigate = useNavigate()
  const { order_id } = useParams()
  const [windowWidth] = useWindowSize()

  const [data, setData] = useState(null)
  const order = data?.order ?? null
  const buyer = order?.user ?? null
  const billing = data?.billing ?? null
  const pickUpAddress = data?.pick_up_address ?? null

  useAbortableEffect(
    signal => {
      setDocumentTitle(`Cargando...`)

      if (!firebase.user) {
        navigate('/', { replace: true })
      }

      ;(async () => {
        setData(null)
        try {
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_order', {
            params: { order_id: order_id, user_id: firebase.user?.uid },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)
          setData(result.data)

          setDocumentTitle(`Presupuesto de Pedido #${order_id}`)
        } catch (err) {
          // console.log(err)
          navigate('/user/orders', { replace: true })
        }
      })()
    },
    [firebase.user, navigate, order_id]
  )

  const provinces = JSON.parse(process.env.REACT_APP_PROVINCES)

  return (
    <div className="w-full min-h-screen bg-pagebg text-black">
      {data ? (
        <div className="relative w-full xl:w-[840px] min-h-[inherit] mx-auto p-2 sm:p-4 xl:p-8 bg-white flex flex-col items-center shadow-lg">
          <div className="absolute top-[0.15rem] sm:top-2 xl:top-3 left-1/2 -translate-x-1/2 w-max flex flex-col items-center">
            <span className="text-[0.5rem] xl:text-xs xl:mb-1">DOCUMENTO NO VÁLIDO COMO FACTURA</span>
            <span className="w-7 h-7 xl:w-10 xl:h-10 border border-black text-xl xl:text-3xl text-center">X</span>
          </div>
          <div className="w-full pb-2 border-b-4 border-dewalt flex justify-between items-end text-gray-800">
            <span className="text-xl xl:text-4xl font-bold">Presupuesto</span>
            <span className="text-sm xl:text-xl font-bold">Pedido #{order_id}</span>
          </div>
          <div className="w-full mt-4 flex">
            <div className="w-0 grow flex flex-col max-xl:text-xs">
              <div className="w-20 xl:w-40 mb-2 flex flex-col">
                <img src="/universal-tools-logo-notext.svg" className="w-full h-max brightness-0" alt="Logo1" />
                <img src="/universal-tools-logo-textonly.svg" className="w-full h-max mt-1 brightness-0" alt="Logo2" />
              </div>
              {/* <span>Universal Tools</span> */}
              <span>{billing[0]?.cuit}</span>
              <span>
                {pickUpAddress?.street} {pickUpAddress?.street_number}
              </span>
              <span>
                {pickUpAddress?.zip_code}, {pickUpAddress?.city}, {pickUpAddress?.province}
              </span>
              <span>Tel: {data.contact_number}</span>
            </div>
            <div className="w-0 grow flex flex-col max-xl:text-xs">
              <span className="font-medium mb-2">Cliente:</span>
              <span>{`${buyer?.name} ${buyer?.last_name}`}</span>
              <span>{buyer?.cuit}</span>
              {buyer.street && (
                <>
                  <span>
                    {buyer.street} {buyer.street_number}
                  </span>
                  <span>
                    {buyer.zip_code}, {buyer.city}, {provinces[buyer.province]?.name}
                  </span>
                </>
              )}
              <span>Tel: {`${buyer?.area_code} ${buyer?.phone_number}`}</span>
              <span>{buyer?.email}</span>
            </div>
          </div>
          <span className="w-full py-2 my-2 border-y-4 border-dewalt max-xl:text-xs flex justify-center">
            Fecha de compra: {TimestampToDateTextWithTime(order.date_created)}
          </span>
          <div
            className="w-full grid mt-2 max-xl:text-xs"
            style={{
              gridTemplateColumns: `${
                windowWidth > 1280 ? '0.15fr' : windowWidth > 1000 ? '0.06fr' : windowWidth > 660 ? '0.1fr' : '0.2fr'
              } 1fr auto auto`,
            }}>
            {/* <div className="w-full flex gap-1 xl:gap-2 text-gray-600"> */}
            <div className="border border-gray-300" />
            <div className="px-1 sm:px-2 py-1 border border-l-0 border-gray-300">Producto</div>
            <div className="px-1 sm:px-2 py-1 border border-l-0 border-gray-300 flex justify-end">Precio Unit.</div>
            <div className="px-1 sm:px-2 py-1 border border-l-0 border-gray-300 flex justify-end">Subtotal</div>
            {/* </div> */}
            {[
              ...order.products.map(product => (
                <>
                  {/* <div className="w-full flex items-center gap-1 xl:gap-2"> */}
                  <div className="p-0 sm:p-1 xl:p-2 border border-t-0 border-gray-300 flex justify-center items-center">
                    <AspectRatio.Root ratio={1}>
                      <ShowImage image={product.image} contain={true} />
                    </AspectRatio.Root>
                  </div>
                  <div className="px-1 sm:px-2 py-1 border-b border-r border-gray-300 flex flex-col justify-center">
                    <span className="xl:pr-6 line-clamp-1">{product.title}</span>
                    <span className="xl:text-sm">Cantidad: {product.amount + product.amount_offer_stock}</span>
                  </div>
                  <div className="px-1 sm:px-2 py-1 border-b border-r border-gray-300 flex justify-end items-center">
                    <span>{FormatPrice(product.offer_price ?? product.normal_price)}</span>
                  </div>
                  <div className="px-1 sm:px-2 py-1 border-b border-r border-gray-300 flex justify-end items-center">
                    <span>
                      {FormatPrice(
                        (product.amount + product.amount_offer_stock) * (product.offer_price ?? product.normal_price)
                      )}
                    </span>
                  </div>
                  {/* </div> */}
                </>
              )),
              ...(order.ut_coins.spent
                ? [
                    <>
                      {/* <div className="w-full flex items-center gap-1 xl:gap-2"> */}
                      <div className="p-0 sm:p-1 xl:p-2 border border-t-0 border-gray-300 flex justify-center items-center">
                        <AspectRatio.Root ratio={1}>
                          <TbCoin className="w-full h-full p-1 text-dewalt" />
                        </AspectRatio.Root>
                      </div>
                      <div className="px-1 sm:px-2 py-1 border-b border-r border-gray-300 flex flex-col justify-center">
                        <span className="line-clamp-1">Adelanto de compra - UT Coins</span>
                        <span className="xl:text-sm">Cantidad: {DigitGrouping(order.ut_coins.spent)}</span>
                      </div>
                      <div className="px-1 sm:px-2 py-1 border-b border-r border-gray-300 flex justify-end items-center">
                        <span>{FormatPrice(round(order.ut_coins.dollar.buy / 10, 2))}</span>
                      </div>
                      <div className="px-1 sm:px-2 py-1 text-green-600 font-bold border-b border-r border-gray-300 flex justify-end items-center">
                        <span>- {FormatPrice(round(order.ut_coins.spent * (order.ut_coins.dollar.buy / 10), 2))}</span>
                      </div>
                      {/* </div> */}
                    </>,
                  ]
                : []),
            ]}
          </div>
          <div className="w-full mt-4 flex gap-2 xl:gap-4">
            <div className="w-0 grow flex">
              <div className="w-12 xl:w-20">
                <AspectRatio.Root
                  ratio={1}
                  className="flex justify-center items-center text-gray-500 rounded-lg border border-gray-300">
                  {ShippingMethodIcon(order.shipping.shipping_method.icon, 'w-full h-full p-1 xl:p-3')}
                </AspectRatio.Root>
              </div>
              <div className="pl-2 xl:pl-4 w-0 grow flex flex-col text-xs xl:text-base">
                <span className="font-bold">Método de envío:</span>
                <span>{order.shipping.shipping_method.title}</span>
                <span>
                  Costo: <strong>{order.shipping.price === 0 ? 'Gratis' : FormatPrice(order.shipping.price)}</strong>
                </span>
              </div>
            </div>
            <div className="w-0 grow flex">
              <div className="w-12 xl:w-20">
                <AspectRatio.Root
                  ratio={1}
                  className="flex justify-center items-center text-gray-500 rounded-lg border border-gray-300">
                  <ShowImage image={order.payment.payment_method.image} imgClassName="p-1 xl:p-2" />
                </AspectRatio.Root>
              </div>
              <div className="pl-2 xl:pl-4 w-0 grow flex flex-col text-xs xl:text-base">
                <span className="font-bold">Método de pago:</span>
                <span>{order.payment.payment_method.title}</span>
                {order.surcharge && (
                  <span>
                    Recargo: <strong>{FormatPrice(order.surcharge)}</strong>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="w-full pt-4 mt-4 border-t-4 border-dewalt flex justify-between items-center max-xl:text-sm">
            <span>Total a pagar:</span>
            <span className="text-lg xl:text-2xl">
              <span className="font-bold">
                {FormatPrice(
                  round(
                    order.products.reduce(
                      (acc, product) =>
                        acc +
                        (product.amount + product.amount_offer_stock) * (product.offer_price ?? product.normal_price),
                      0
                    ) -
                      order.ut_coins.spent * (order.ut_coins.dollar.buy / 10) +
                      order.shipping.price +
                      (order.surcharge ?? 0),
                    2
                  )
                )}
              </span>
            </span>
          </div>
          <div className="w-full">
            <button
              onClick={() => window.print()}
              className="py-1 px-2 mt-4 bg-gray-100 mx-auto text-sm flex gap-2 items-center rounded-md border border-gray-300 hover:border-gray-500 
                        print:hidden transition-colors">
              <MdPrint className="w-4 h-4" /> Imprimir presupuesto
            </button>
          </div>
        </div>
      ) : (
        <LoadingBox text={'Cargando presupuesto...'} />
      )}
    </div>
  )
}

export default OrderBudget

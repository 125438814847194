import { createContext, useContext } from 'react'

export const MainContext = createContext({
  firebase: { auth: null, user: null },
  setFirebase: () => {},
  showToast: () => {},
  showCart: false,
  setShowCart: () => {},
  topbarSize: { width: 0, height: 0 },
})

export const useMainContext = () => useContext(MainContext)

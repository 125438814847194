export const DigitGrouping = str => {
  return str
    ?.toString()
    ?.replace('.', ',')
    ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
}

export const FormatPrice = str => {
  return '$ ' + DigitGrouping(str)
}

export const FormatCuit = str => {
  let v = str.replace(/[^-\d]+/g, '')
  v = v.length >= 3 && v[2] !== '-' ? `${v.substring(0, 2)}-${v.substring(2, v.length)}` : v
  v = v.length >= 12 && v[11] !== '-' ? `${v.substring(0, 11)}-${v.substring(11, v.length)}` : v
  return v
}

export const CapitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
}

export const CapitalizeAllWords = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(v => v.charAt(0).toUpperCase() + v.slice(1))
    .join(' ')
}

export const OnlyNumbers = (str, decimal = false) => {
  str = '' + str
  str = str.replace(decimal ? /[^0-9.]/g : /[^0-9]/g, '')

  // Remove extra decimals (allow only one decimal point)
  const parts = str.split('.')
  if (parts[1] && parts[1].length > 2) {
    str = parts[0] + '.' + parts[1].slice(0, 2)
  }
  if (str.indexOf('.') !== str.lastIndexOf('.')) {
    str = str.slice(0, str.lastIndexOf('.'))
  }

  return str
}

export const TimestampToDateText = timestamp => {
  const date = new Date(timestamp * 1000)
  return `${date.getDate()} de ${
    {
      1: 'enero',
      2: 'febrero',
      3: 'marzo',
      4: 'abril',
      5: 'mayo',
      6: 'junio',
      7: 'julio',
      8: 'agosto',
      9: 'septiembre',
      10: 'octubre',
      11: 'noviembre',
      12: 'diciembre',
    }[date.getMonth() + 1]
  }${date.getFullYear() !== new Date().getFullYear() ? ` de ${date.getFullYear()}` : ''}`
}

export const TimestampToDateTextWithTime = timestamp => {
  const date = new Date(timestamp * 1000)
  return `${date.getDate()} de ${
    {
      1: 'enero',
      2: 'febrero',
      3: 'marzo',
      4: 'abril',
      5: 'mayo',
      6: 'junio',
      7: 'julio',
      8: 'agosto',
      9: 'septiembre',
      10: 'octubre',
      11: 'noviembre',
      12: 'diciembre',
    }[date.getMonth() + 1]
  }${date.getFullYear() !== new Date().getFullYear() ? ` de ${date.getFullYear()}` : ''} ${String(
    date.getHours()
  ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')} hs`
}

export const TimestampToDate = timestamp => {
  const date = new Date(timestamp * 1000)
  let str = date.toLocaleDateString('es-AR')
  return `${str.split('/')[0].padStart(2, '0')}/${str.split('/')[1].padStart(2, '0')}/${str.split('/')[2]}`
}

export const TimestampToDateWithTime = timestamp => {
  const date = new Date(timestamp * 1000)
  let str = date.toLocaleDateString('es-AR')
  str = `${str.split('/')[0].padStart(2, '0')}/${str.split('/')[1].padStart(2, '0')}/${str.split('/')[2]}`
  return `${str} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')} hs`
}

export const TimestampToFullDate = timestamp => {
  const date = new Date(timestamp * 1000)
  return date.toLocaleString('es-AR')
}

export const ProductLink = (id, title) => {
  return `/product/${id}/${encodeURIComponent(title.replaceAll(' ', '-'))}`
}

export const DeleteFormatFromPhoneNumber = phone => {
  return phone.replaceAll(' ', '').replaceAll('-', '')
}

export const PickUpAddressToURL = address => {
  return `${address.street} ${address.street_number}, ${address.city}, ${address.province}, Argentina`.replaceAll(
    ' ',
    '+'
  )
}

export const NewProductImagePath = format => {
  return `products/${crypto.randomUUID()}.${format}`
}

export const NewBannerImagePath = format => {
  return `banners/${crypto.randomUUID()}.${format}`
}

export const NewSectionImagePath = format => {
  return `sections/${crypto.randomUUID()}.${format}`
}

export const NewBrandImagePath = format => {
  return `brands/${crypto.randomUUID()}.${format}`
}

export const GetRoleLink = role => {
  return role === 'user' ? 'user' : 'admin'
}

export const RemoveAccents = string => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

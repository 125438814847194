import axios from 'axios'
import { useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { MdDeleteOutline, MdSearchOff } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { useAbortableEffect } from '../utils/Hooks'
import { saveCookie, setDocumentTitle } from '../utils/Html'
import LoadingBox from '../utils/LoadingBox'
import { useMainContext } from '../utils/MainContext'
import { GetDiscountPercent } from '../utils/Numbers'
import PlusMinusInput from '../utils/PlusMinusInput'
import ShowImage from '../utils/ShowImage'
import { FormatPrice, ProductLink } from '../utils/Strings'

function Favorites() {
  const { firebase, setFirebase, showToast } = useMainContext()
  const navigate = useNavigate()

  const [products, setProducts] = useState(null)
  const [removing, setRemoving] = useState([])

  useEffect(() => {
    setDocumentTitle('Favoritos')

    if (firebase.user?.role !== 'user') {
      navigate('/', { replace: true })
    }
  }, [firebase.user, navigate])

  useAbortableEffect(
    signal => {
      ;(async () => {
        try {
          setProducts(null)
          const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_favorites', {
            params: {
              user_id: firebase.user?.uid,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          const products = result.data.products
          setProducts(products)

          const favs = products.map(product => product.product_id)
          firebase.user.favs = favs
          setFirebase({ ...firebase, user: firebase.user })
          saveCookie('favs', favs)
        } catch (err) {
          if (err.code === 'ERR_CANCELED') return
          // console.log(err)
          if (!err.response?.data?.message) {
            navigate('/', { replace: true })
            showToast(
              'Ocurrió un error desconocido al buscar tus productos favoritos, intentá de nuevo mas tarde.',
              'bad'
            )
          } else {
            showToast(err.response.data.message, 'bad')
          }
        }
      })()
    },
    [firebase.user]
  )

  const removeFavorite = async id => {
    try {
      setRemoving(r => [...r, id])
      const result = await axios.put(
        process.env.REACT_APP_API_LINK + 'update_fav_on_user',
        {
          product_id: id,
          user_id: firebase.user?.uid,
          action: 'delete',
        },
        {
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
          },
        }
      )
      setProducts(p => p.filter(prod => prod.product_id !== id))

      setRemoving(r => r.filter(rid => rid !== id))
      showToast('Producto borrado de favoritos.', 'good')

      firebase.user.favs = result.data.favs
      setFirebase({ ...firebase, user: firebase.user })
      saveCookie('favs', result.data.favs)
    } catch (err) {
      // console.log(err)
      if (err.response?.data?.code === 2) {
        setProducts(p => p.filter(prod => prod.product_id !== id))
        showToast(err.response.data.message, 'bad')
      }
    }
  }

  return (
    <div className="bg-white xl:bg-pagebg grow text-black">
      <div className="w-full xl:w-content-max-width xl:mx-auto xl:px-60">
        <h1 className="p-4 pb-0 text-xl xl:text-2xl mb-4 font-medium">Favoritos</h1>
        {products ? (
          products.length > 0 ? (
            <div className="flex flex-col xl:mb-8 bg-white rounded-md xl:shadow-md">
              {products.map(product => (
                <div
                  key={product.product_id}
                  className="relative w-full p-4 flex gap-2 xl:gap-4 border-b border-gray-200 last:border-b-0">
                  {removing.includes(product.product_id) && (
                    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-70 flex justify-center items-center">
                      <img src="/loading.svg" className="w-12 h-12" alt="Loading" />
                    </div>
                  )}
                  <Link to={ProductLink(product.product_id, product.title)} className="w-28 h-28 xl:w-36 xl:h-36">
                    <ShowImage image={product.images[0]} contain={true} draggable={false} />
                  </Link>
                  <div className="flex-1 flex flex-col">
                    <Link to={ProductLink(product.product_id, product.title)} className="line-clamp-2 max-xl:text-sm">
                      {product.title}
                    </Link>
                    {product.offer_price ? (
                      <Link to={ProductLink(product.product_id, product.title)} className="my-1">
                        <span className="max-xl:text-sm font-light text-gray-500">
                          <del>{FormatPrice(product.normal_price)}</del>
                        </span>
                        <div className="flex items-center xl:mb-2">
                          <span className="text-xl xl:text-2xl leading-5 xl:leading-6">
                            {FormatPrice(product.offer_price ?? product.normal_price)}
                          </span>
                          <span className="max-xl:text-sm ml-2 text-green-600">{`${GetDiscountPercent(
                            product.normal_price,
                            product.offer_price
                          )}% OFF`}</span>
                        </div>
                      </Link>
                    ) : (
                      <span className="my-1 text-xl leading-6">
                        {FormatPrice(product.offer_price ?? product.normal_price)}
                      </span>
                    )}
                    <div className="mt-1 xl:mt-2 flex gap-2 xl:gap-4 items-center">
                      <AddToCartButton product={product} />
                      <div className={`w-7 h-7 xl:w-8 xl:h-8`}>
                        <button
                          type="button"
                          title="Eliminar de favoritos"
                          className="w-full h-full rounded-full hover:bg-gray-100 transition-colors group/button"
                          onClick={() => removeFavorite(product.product_id)}>
                          <MdDeleteOutline className="w-full h-full p-1 text-gray-400 group-hover/button:text-gray-600 transition-colors" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full py-8 bg-white text-black flex flex-col justify-start items-center rounded-md xl:shadow-md">
              <MdSearchOff className="w-28 h-28 text-gray-200" />
              <p className="mt-2 text-neutral-600 text-xl text-center">No tenés productos en favoritos.</p>
              <Link
                to="/catalogue/all"
                className="mt-2 text-lg font-bold text-center text-amber-500 hover:text-amber-600 transition-colors">
                Buscar productos
              </Link>
            </div>
          )
        ) : (
          <LoadingBox text={'Cargando favoritos...'} />
        )}
      </div>
    </div>
  )
}

function AddToCartButton({ product }) {
  const { firebase, setFirebase, setShowCart, showToast } = useMainContext()
  const { user } = firebase
  const navigate = useNavigate()
  let { product_id, stock, offer_cost_stock } = product

  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState(
    user?.cart.some(item => item.product_id === product_id)
      ? user?.cart.find(item => item.product_id === product_id).amount
      : 0
  )

  useEffect(() => {
    if (!user?.cart) return

    const cartItem = user?.cart.find(item => item.product_id === product_id)
    if (!cartItem) return

    setAmount(cartItem.amount)
  }, [user?.cart, product_id])

  const handleUpdateCart = async (action, amount) => {
    try {
      if (!user) navigate('/notloggedin/cart')

      setLoading(true)
      const result = await axios.put(
        process.env.REACT_APP_API_LINK + 'update_cart',
        {
          user_id: user.uid,
          product_id: product_id,
          action: action,
          amount: action === 'remove' ? '' : amount,
        },
        { headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY } }
      )
      // console.log(result)

      const cart = result.data.cart
      user.cart = cart
      setFirebase({ ...firebase, user: user })
      saveCookie('cart', cart)

      showToast(action === 'remove' ? 'El producto se borró del carrito.' : '¡Se actualizó el carrito!', 'good')

      setLoading(false)
      // window.scroll({ left: 0, top: 0, behavior: 'smooth' })
      setShowCart(true)
    } catch (err) {
      setLoading(false)
      if (err.response?.status === 409 && err.response?.data?.message) {
        showToast(err.response.data.message, 'bad')
      }
      // console.log(err)
    }
  }

  return (
    <div className="w-max">
      {user?.cart.some(item => item.product_id === product_id) ? (
        <div className="w-full bg-dewalt flex flex-col items-center rounded-md">
          <span className="w-max py-[1px] text-xs xl:text-sm font-medium">En el carrito</span>
          <div className={`relative w-[8.5rem] xl:w-[10.5rem] h-7 xl:h-10`}>
            {loading && (
              <div className="absolute top-0 left-0 w-full h-full p-1 bg-white/70 flex justify-center items-center">
                <img src="/loading.svg" className="w-max h-full" alt="Cargando" />
              </div>
            )}
            <PlusMinusInput
              min={0}
              max={stock + offer_cost_stock}
              state={[amount, setAmount]}
              onValueChange={v => {
                // console.log(v)
                handleUpdateCart(v === 0 ? 'remove' : v > amount ? 'add' : 'subtract', Math.abs(v - amount))
              }}
            />
          </div>
        </div>
      ) : (
        <div className="relative">
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full p-1 bg-white/70 flex justify-center items-center">
              <img src="/loading.svg" className="w-max h-full" alt="Cargando" />
            </div>
          )}
          <button
            type="button"
            className="w-max h-7 p-2 xl:h-8 xl:p-4 xl:pl-2 bg-dewalt border border-transparent hover:border-gray-500
                shadow-sm hover:shadow-md flex items-center rounded-md transition-all"
            onClick={() => handleUpdateCart('add', 1)}>
            <BsPlus className="w-5 h-5 mr-1 xl:w-6 xl:h-6 xl:mr-2" />
            <span className="text-xs xl:text-sm font-medium">Agregar al carrito</span>
          </button>
        </div>
      )}
    </div>
  )
}

export default Favorites

import axios from 'axios'
import { useState } from 'react'
import { TbCoin } from 'react-icons/tb'
import { RiRefund2Fill } from 'react-icons/ri'
import { GiReceiveMoney } from 'react-icons/gi'
import { Link, useNavigate } from 'react-router-dom'
import LoadingBox from '../utils/LoadingBox'
import { useMainContext } from '../utils/MainContext'
import { DigitGrouping, FormatPrice, TimestampToDate, TimestampToDateWithTime } from '../utils/Strings'
import { MdChevronRight, MdInfoOutline, MdOutlineFrontHand } from 'react-icons/md'
import { saveCookie, setDocumentTitle, useWindowSize } from '../utils/Html'
import { useAbortableEffect, useDolarBlue } from '../utils/Hooks'
import { isEmpty, round } from 'lodash'

function UTCoins() {
  const { firebase, setFirebase, showToast } = useMainContext()
  const navigate = useNavigate()

  const [dolarBlue] = useDolarBlue()
  const [windowWidth] = useWindowSize()

  const [data, setData] = useState(null)
  const UTCoins = data?.ut_coins ?? null
  const UTCoinsHistory = data?.ut_coins_history ?? null

  const [showHistory, setShowHistory] = useState(false)

  useAbortableEffect(signal => {
    setDocumentTitle('UT Coins')
    ;(async () => {
      try {
        setData(null)

        if (firebase.user?.uid) {
          let result = await axios.get(process.env.REACT_APP_API_LINK + 'get_user', {
            params: {
              user_id: firebase.user?.uid ?? '',
              ut_coins_only: true,
            },
            headers: {
              'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
            signal,
          })
          // console.log(result)

          setData(result.data)

          const user = JSON.parse(JSON.stringify(firebase.user))
          user.ut_coins = result.data.ut_coins
          setFirebase({ ...firebase, user: user })
          saveCookie('user', user, true)
        } else {
          setData([])
        }
      } catch (err) {
        // console.log(err)
        showToast('Ocurrió un error al cargar la página.', 'bad')
        navigate('/', { replace: true })
      }
    })()
  }, [])

  const maxWidth = 420

  return (
    <div className="relative w-full grow bg-pagebg text-black">
      {data !== null ? (
        <>
          <div className="absolute top-0 left-0 w-full h-48 xl:h-52 gradient-dewalt-ttb" />
          <div className="relative w-full xl:w-content-max-width mx-auto">
            <div className="p-4 xl:px-60 xl:py-8">
              <div className="p-4 py-3 bg-white flex items-center gap-4 rounded-lg shadow-lg">
                <TbCoin className="w-14 h-14 xl:w-20 xl:h-20 text-dewalt" />
                <div className="py-1 xl:py-2 flex flex-col">
                  <span className="text-lg xl:text-2xl font-semibold tracking-wide">¡Acumulá beneficios!</span>
                  {firebase.user ? (
                    <div>
                      <span className="text-sm xl:text-xl">Tus UT Coins: </span>
                      <span className="xl:text-2xl">
                        {DigitGrouping(UTCoins)}
                        {UTCoins > 0 && dolarBlue
                          ? ` = ${FormatPrice(round((dolarBlue.value_buy / 10) * UTCoins), 2)}`
                          : ''}
                      </span>
                    </div>
                  ) : (
                    <div className="text-sm xl:text-lg">
                      <span>
                        <Link to="/login/user" className="inline font-bold text-amber-500">
                          Iniciá sesión
                        </Link>{' '}
                        para ganar UT Coins.
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {dolarBlue && (
                <div className="py-2 mt-4 bg-white flex flex-col items-center rounded-lg shadow-md">
                  <span className="xl:text-lg font-bold">10 UT Coins = 1 U$D</span>
                  <span className="text-xs xl:text-sm text-gray-500">
                    1 U$D = {FormatPrice(dolarBlue.value_buy)} —&nbsp;
                    {new Date(dolarBlue.last_update).toLocaleString('es-AR')}
                  </span>
                </div>
              )}
              {firebase.user && !isEmpty(UTCoinsHistory) && (
                <div className={`w-full mt-4 bg-white rounded-lg shadow-md`}>
                  <button
                    className={`w-full py-2 px-2 xl:px-4 flex items-center justify-between group
                            border-b  ${showHistory ? 'border-gray-200' : 'border-transparent'}`}
                    onClick={() => setShowHistory(!showHistory)}>
                    <span className="text-sm xl:text-base font-medium">Historial</span>
                    <MdChevronRight
                      className={`w-6 h-6 xl:w-8 xl:h-8 ${showHistory ? '-rotate-90' : 'rotate-90'}
                              text-gray-500 group-hover:text-black transition-all`}
                    />
                  </button>
                  <div
                    className={`w-full ${
                      showHistory ? 'h-max py-2' : 'h-0 overflow-hidden'
                    } px-2 xl:px-4 flex flex-col gap-1`}>
                    <div className="w-full pb-1 flex gap-1 max-xl:text-xs font-medium">
                      <span className="w-0 grow-[0.5]">Cantidad:</span>
                      <span className="w-0 grow">{windowWidth > maxWidth ? 'Fecha transacción:' : 'Transacción:'}</span>
                      <span className="w-0 grow">{windowWidth > maxWidth ? 'Fecha vencimiento:' : 'Vence el:'}</span>
                      <span className="w-0 grow-[0.5]">{windowWidth > maxWidth ? 'Cotización:' : 'Cotiz.:'}</span>
                    </div>
                    <div className="w-full max-h-60 overflow-y-scroll flex flex-col gap-1">
                      {UTCoinsHistory.sort((a, b) => a.transaction_date < b.transaction_date).map((doc, index) => (
                        <div key={index} className="w-full flex gap-1 max-xl:text-xs">
                          <span className={`w-0 grow-[0.5] ${doc.amount > 0 ? 'text-green-600' : 'text-red-600'}`}>
                            {`${doc.amount > 0 ? '+' : ''}${DigitGrouping(doc.amount)}`}
                          </span>
                          <span className="w-0 grow">
                            {windowWidth > maxWidth
                              ? TimestampToDateWithTime(doc.transaction_date)
                              : TimestampToDate(doc.transaction_date)}
                          </span>
                          <span className="w-0 grow">
                            {doc.expires_at
                              ? windowWidth > maxWidth
                                ? doc.expires_at === 4102444800
                                  ? 'Sin vencimiento'
                                  : TimestampToDateWithTime(doc.expires_at)
                                : TimestampToDate(doc.expires_at)
                              : '-'}
                          </span>
                          <span className="w-0 grow-[0.5]">{FormatPrice(doc.dollar_price)}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-8 flex flex-col">
                <span className="mb-4 xl:text-xl font-medium">Conocé más sobre los UT Coins</span>
                <div className="flex flex-col gap-4">
                  {[
                    {
                      title: '¿Qué son los UT Coins?',
                      icon: <RiRefund2Fill className="w-full h-full" />,
                      text: `Es un beneficio que brinda <strong>UNIVERSAL&nbsp;TOOLS</strong> a sus clientes, premiando sus compras con saldo digital que 
                      <strong>cotiza a valor del Dólar&nbsp;Blue</strong>. Esta moneda se puede utilizar en <strong>descuentos&nbsp;parciales</strong> 
                      sobre cualquier producto que se venda en la plataforma web.`,
                    },
                    {
                      title: '¿Cómo los recibo?',
                      icon: <GiReceiveMoney className="w-full h-full" />,
                      text: `Los <strong>UT&nbsp;Coins</strong> se entregan al concretar una compra. Cada producto y/o pedido especifica cuantos 
                      <strong>UT&nbsp;Coins</strong> otorga. La cantidad de <strong>UT&nbsp;Coins</strong> que brinda cada producto puede variar 
                      sin previo aviso, pudiendo en algún momento no otorgar el beneficio.`,
                    },
                    {
                      title: 'Más información',
                      icon: <MdInfoOutline className="w-full h-full" />,
                      text: `El valor de los <strong>UT&nbsp;Coins</strong> se calcula a una relación de 
                      <strong>10&nbsp;UT&nbsp;Coins&nbsp;=&nbsp;1&nbsp;Dólar&nbsp;Blue</strong>.
                      </br>Al momento de utilizar los <strong>UT&nbsp;Coins</strong> para un descuento en el pedido, se realiza el cambio 
                      a <strong>Pesos&nbsp;Argentinos</strong>, tomando como cotización la cotización del momento.`,
                    },
                    {
                      title: 'Responsabilidad',
                      icon: <MdOutlineFrontHand className="w-full h-full" />,
                      text: `<strong>UNIVERSAL&nbsp;TOOLS</strong> no se hace responsable por <strong>hackeos y/o robos</strong> de las 
                      credenciales de acceso a las cuentas que posibiliten el uso de <strong>UT&nbsp;Coins</strong> por personas ajenas a la cuenta vinculada.`,
                    },
                  ].map((item, index) => (
                    <div key={index} className="p-2 xl:pr-20 bg-white flex gap-4 rounded-lg shadow-md hyphens-none">
                      <div className="w-10 h-10 xl:w-12 xl:h-12 p-1 xl:p-2 text-dewalt rounded-md border border-gray-200">
                        {item.icon}
                      </div>
                      <div className="w-0 grow hyphens-none flex flex-col">
                        <span className="mb-1 font-medium xl:text-lg">{item.title}</span>
                        <p className="max-xl:text-sm font-light" dangerouslySetInnerHTML={{ __html: item.text }} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadingBox />
      )}
    </div>
  )
}

export default UTCoins

import axios from 'axios'
import { useState } from 'react'
import LoadingBox from '../utils/LoadingBox'
import { useMainContext } from '../utils/MainContext'
import { CartProduct, CartSummary } from '../utils/Cart'
import { DigitGrouping, FormatPrice } from '../utils/Strings'
import { Link, useNavigate } from 'react-router-dom'
import { MdOutlineRemoveShoppingCart } from 'react-icons/md'
import { setDocumentTitle, saveCookie } from '../utils/Html'
import { RoundTo1Decimal, UTCoinsFromValue } from '../utils/Numbers'
import { useAbortableEffect, useDolarBlue } from '../utils/Hooks'

function CartPage() {
  const { firebase, setFirebase } = useMainContext()
  const navigate = useNavigate()

  const [data, setData] = useState(null)
  const [cart, setCart] = useState(null)
  const [dolarBlue] = useDolarBlue()

  const freeShippingMinimum = data?.free_shipping_minimum ?? null
  const utCoinsMultiplier = data?.ut_coins_multiplier ?? null

  const cartValue = cart
    ? cart.reduce((acc, curr) => acc + (curr.product.offer_price ?? curr.product.normal_price) * curr.amount, 0)
    : 0

  const freeShippingState = cartValue / freeShippingMinimum > 1 ? 100 : (cartValue / freeShippingMinimum) * 100
  const UTCoinsToGain =
    cart && dolarBlue && utCoinsMultiplier ? UTCoinsFromValue(cartValue, dolarBlue, utCoinsMultiplier) : null

  useAbortableEffect(signal => {
    if (!firebase.user || firebase.user.role !== 'user') {
      navigate('/', { replace: true })
    }

    ;(async () => {
      try {
        setCart(null)
        setDocumentTitle('Carrito')

        const result = await axios.get(process.env.REACT_APP_API_LINK + 'get_cart', {
          params: { user_id: firebase.user.uid, page: true },
          headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY },
          signal,
        })
        // console.log(result)

        setData(result.data)
        setCart(result.data.cart)

        const cookieCart = result.data.cart.map(v => ({
          product_id: v.product.product_id,
          amount: v.amount,
        }))
        firebase.user.cart = cookieCart
        setFirebase({ ...firebase, user: firebase.user })
        saveCookie('cart', cookieCart)
      } catch (err) {
        // console.log(err)
      }
    })()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full grow bg-pagebg">
      <div className="w-full xl:w-content-max-width mx-auto">
        {cart ? (
          cart.length > 0 ? (
            <div className="w-full max-xl:p-2 xl:py-8 flex max-xl:flex-col gap-2 xl:gap-4">
              <div className="w-full h-max bg-white text-black rounded-md border border-gray-200 overflow-hidden">
                <div className="p-4 xl:px-6 xl:py-5 text-lg font-medium border-b border-gray-200">Carrito</div>
                <div className="w-full max-h-[400px] p-2 xs:p-4 xl:p-6 overflow-y-auto">
                  <div className="w-full flex flex-col gap-2 xs:gap-4">
                    {cart.map(item => (
                      <CartProduct
                        key={item.product.product_id}
                        product={item.product}
                        amount={item.amount}
                        cart={cart}
                        setCart={setCart}
                        user_id={firebase.user.uid}
                        isPage={true}
                      />
                    ))}
                  </div>
                </div>
                <div className="w-full bg-neutral-50 p-4 xl:p-6 flex flex-col">
                  <div className="flex items-center gap-6">
                    <div className="w-0 grow">
                      <div className="relative w-full h-2 rounded-full bg-gray-200">
                        <div className="h-full bg-dewalt rounded-full" style={{ width: `${freeShippingState}%` }} />
                        <div
                          className="absolute top-1/2 -translate-y-1/2 -translate-x-1/2 w-4 h-4 rounded-full bg-dewalt"
                          style={{ left: `${freeShippingState}%` }}
                        />
                      </div>
                    </div>
                    <span
                      className={`xl:text-lg ${
                        freeShippingState < 100 ? 'text-gray-500' : 'font-bold text-amber-500'
                      }`}>
                      Envío gratis
                    </span>
                  </div>
                  <span className="mt-2 xl:text-lg hyphens-none">
                    {freeShippingState < 100
                      ? `Agregá ${FormatPrice(freeShippingMinimum - cartValue)} más para conseguir envío gratis. `
                      : `Aprovechá el envío gratis agregando más productos. `}
                    <Link to="/catalogue/all" className="w-max inline font-bold text-amber-500">
                      Ver&nbsp;más&nbsp;productos
                    </Link>
                  </span>
                </div>
              </div>
              <div className="w-full xl:w-[500px] flex flex-col gap-4">
                <div className="w-full h-max bg-white text-black rounded-md border border-gray-200">
                  <div className="p-4 xl:px-6 xl:py-5 text-lg font-medium border-b border-gray-200">Resúmen</div>
                  <div className="w-full p-4 xl:p-6">
                    <CartSummary cart={cart} isPage={true} mpAddPct={data?.mp_add_pct} />
                  </div>
                </div>
                {UTCoinsToGain && (
                  <div className="w-full h-max bg-white text-black rounded-md border border-gray-200">
                    <div className="w-full p-4 xl:p-6">
                      Esta compra te otorgará{' '}
                      <span className="font-bold text-green-600">
                        {DigitGrouping(UTCoinsToGain)}&nbsp;UT&nbsp;Coins
                      </span>
                      <span className="block mt-1 text-sm">
                        {DigitGrouping(UTCoinsToGain)} UT Coins al día de hoy:{' '}
                        {FormatPrice(RoundTo1Decimal(UTCoinsToGain * (dolarBlue?.value_buy / 10)))}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="w-full max-xl:p-2 xl:py-8 flex max-xl:flex-col gap-2 xl:gap-4">
              <div className="w-full h-max xl:bg-gray-50 py-12 rounded-md flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <MdOutlineRemoveShoppingCart className="w-16 h-16 xl:w-20 xl:h-20 text-gray-400" />
                  <span className="xl:text-lg mt-8 text-black">¡Armá un carrito de compras!</span>
                  <span className="xl:text-lg mt-2 text-gray-500">Sumá productos y conseguí envío gratis</span>
                  <Link
                    to="/catalogue/all"
                    className="w-full xl:w-52 h-12 mt-8 bg-dewalt text-black hover:bg-black hover:text-dewalt font-bold
                              rounded-md flex justify-center items-center transition-all">
                    Ver productos
                  </Link>
                </div>
              </div>
              <div className="w-full xl:w-[500px] h-max bg-gray-50 text-black rounded-md">
                <div className="p-6 py-5 text-lg text-gray-500 font-medium border-b border-gray-200">Resúmen</div>
                <div className="w-full p-6 text-gray-500">
                  Acá verás el resúmen de tu compra una vez que agregues productos al carrito.
                </div>
              </div>
            </div>
          )
        ) : (
          <LoadingBox text="Cargando carrito..." />
        )}
      </div>
    </div>
  )
}

export default CartPage

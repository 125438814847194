function InputLabel({ text, error }) {
  return (
    <label>
      <span
        className={`block pl-2 text-xs xl:text-sm ${
          error ? "text-red-600" : "text-neutral-400"
        } hyphens-none`}
      >
        {text}
      </span>
    </label>
  );
}

export default InputLabel;
